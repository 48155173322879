import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { CampaignStructureItem, campaignStructureSection } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { useStyles } from './campaign-info.style';
import {
  INVALID_CURRENCY_ERROR_TEXT,
  MANDATORY_FIELD_ERROR_TEXT,
  VALIDATION_FIELD_ERROR_TEXT,
} from 'src/app/common/constants';
import { validationMandatoryDependsChecking } from 'src/app/modules/AgencyCampaign/utils/validations';
import { convertArrayToObject } from 'src/app/common/utils/common-utils';
import { Box, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import moment from 'moment';
import { Button } from 'src/app/common/components/button/button.component';
import { ValidationError } from 'yup';

interface HookProps {
  campaignTypeStructureData?: CampaignStructureItem;
  formDispatch: (data: any) => void;
  formState: any;
  errorState: any;
  campaign: any;
  disabledEdit?: boolean;
  disabled?: boolean;
  validationHandler: any;
  campaignEndDateEqualToStartDate?: any;
  campaignEndDateGreaterThanTarget?: any;
  formStateUpdated?: boolean;
  isCampaignOwner: boolean;
  startDateLimitation?: any;
  checkStepHaveError: () => Record<string, boolean>;
}

export const useCampaignInfo = ({
  campaignTypeStructureData,
  formDispatch,
  formState,
  errorState,
  disabledEdit,
  disabled,
  validationHandler,
  campaignEndDateEqualToStartDate,
  campaignEndDateGreaterThanTarget,
  formStateUpdated,
  isCampaignOwner,
  startDateLimitation,
  checkStepHaveError,
}: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  const handleChange = (val: string | string[], sectionItemKey: string, fieldsItemKey: string) => {
    const value = typeof val === 'string' ? val.split(',') : val;
    validationHandler.onDismissErrorHandler(`${sectionItemKey}_${fieldsItemKey}`, value);
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: { section: sectionItemKey, field: fieldsItemKey, value: value },
    });
  };

  const renderOptions = (fieldsItem: any) => {
    return fieldsItem.options.map((item: { key: string; label: string }) => {
      const { key, label } = item;
      return (
        <MenuItem key={key} value={key}>
          {label ? label : key}
        </MenuItem>
      );
    });
  };

  const renderMultiSelectOptions = (sectionItem: any, fieldsItem: any) => {
    const arrSelectedItem = get(formState, `${sectionItem.key}.${fieldsItem.key}`, []);
    return fieldsItem.options.map((item: { key: string; label: string }) => {
      const { key, label } = item;
      return (
        <MenuItem key={key} value={key}>
          <Checkbox checked={arrSelectedItem.indexOf(label) > -1} />
          <ListItemText primary={label} />
        </MenuItem>
      );
    });
  };

  const checkIsAutoPopulatedField = (sectionItem: any, fieldsItem: any) => {
    let isAutoPopulatedField = false;
    switch (fieldsItem.key) {
      case 'applicantName':
      case 'applicantAgentCode':
      case 'applicantPhoneNumber':
      case 'applicantEmail':
        isAutoPopulatedField = true;
        break;
      case 'campaignEndDate':
        if (campaignEndDateEqualToStartDate && campaignEndDateEqualToStartDate.setEqual) {
          isAutoPopulatedField = true;
        }
        break;
      default:
        break;
    }

    return isAutoPopulatedField;
  };

  const handleNumberError = (errorState: any, sectionItem: campaignStructureSection, fieldItem: any) => {
    const path = `${sectionItem.key}_${fieldItem.key}`;
    let errMsg = '';
    if (errorState.mandatory[path]) {
      errMsg = MANDATORY_FIELD_ERROR_TEXT;
    }
    if (errorState.immediate[path]) {
      errMsg = VALIDATION_FIELD_ERROR_TEXT;
      const arr = fieldItem.rules?.filter((item: any) => item.key === 'compare');
      if (arr.length > 0) {
        // rules format:
        // { "key": "compare", "operator": "gte","value": 30, "dependsOn": "submitDate" }
        const { operator, value, dependsOn } = arr[0];
        let fieldTitle = '';
        if (dependsOn) {
          const dependsOnField = sectionItem.sectionFields.filter((field) => field.key === dependsOn);
          if (dependsOnField.length > 0) {
            fieldTitle = dependsOnField[0].title || dependsOn;
          }
        }
        errMsg = TranslationWithVariable(`common.number.${operator}`, value) + fieldTitle;
      }
    }

    return errMsg;
  };
  const handleDateError = (errorState: any, sectionItem: campaignStructureSection, fieldItem: any) => {
    const path = `${sectionItem.key}_${fieldItem.key}`;
    let errMsg = '';
    if (errorState.mandatory[path]) {
      errMsg = MANDATORY_FIELD_ERROR_TEXT;
    }
    if (errorState.immediate[path]) {
      errMsg = VALIDATION_FIELD_ERROR_TEXT;
      const arr = fieldItem.rules?.filter((item: any) => item.key === 'compare');
      if (arr.length > 0) {
        // rules format:
        // { "key": "compare", "operator": "gte","value": 30, "dependsOn": "submitDate" }
        const { operator, value, dependsOn } = arr[0];
        let fieldTitle = '';
        if (dependsOn) {
          const dependsOnField = sectionItem.sectionFields.filter((field) => field.key === dependsOn);
          if (dependsOnField.length > 0) {
            fieldTitle = dependsOnField[0].title || dependsOn;
          }
        }
        errMsg = TranslationWithVariable(`common.date.${operator}`, value) + fieldTitle;
      }
    }

    return errMsg;
  };

  const _renderMandatory = (ruleObject: any, sectionItemKey: string, fieldItemKey: string) => {
    const mandatoryKey = get(ruleObject, 'Mandatory.key');
    const madatoryValue = get(ruleObject, 'Mandatory.value');
    if (mandatoryKey === 'mandatory' && madatoryValue) {
      return <span className={classes.mandatory}>*</span>;
    } else if (mandatoryKey === 'mandatoryDepends') {
      const isMandatory = validationMandatoryDependsChecking(
        sectionItemKey,
        fieldItemKey,
        formState,
        ruleObject?.Mandatory,
        false,
      );
      if (isMandatory) {
        return <span className={classes.mandatory}>*</span>;
      }
    }
    return null;
  };

  const checkSaveInfo = () => {
    const { isInfoValid, isParticipantValid, isAttachmentValid } = checkStepHaveError();
    return !isInfoValid;
  };

  const _renderSection = (
    sectionItem: campaignStructureSection,
    { isEdit, onEdit, onSave }: { isEdit: boolean; onEdit: () => void; onSave: () => void },
  ) => {
    return (
      <div
        className={`${styles.sectionContainer} ${sectionItem.key !== 'applicant' ? classes.mt_24 : null}`}
        key={sectionItem.key}
      >
        <div className={classes.sectionTitleContainer}>
          <div className={classes.sectionTitle}>{Translation(`agencyCampaign.section.${sectionItem.key}`)}</div>
          {sectionItem.key === 'applicant' && !(disabledEdit || disabled) && isCampaignOwner ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (checkSaveInfo()) {
                  return;
                }
                if (!isEdit) {
                  onEdit();
                } else {
                  onSave();
                }
              }}
            >
              {!isEdit ? Translation('app.button.edit') : Translation('app.button.confirm')}
            </Button>
          ) : null}
        </div>
        {sectionItem.key === 'applicant' && startDateLimitation?.value !== 0 && (
          <ul className={styles.startDateLimitation}>
            <li className={styles.lh_20}>
              {TranslationWithVariable('agencyCampaign.create.submit.days', startDateLimitation.value)}
            </li>
          </ul>
        )}
        <Grid container columnSpacing={2}>
          {sectionItem.sectionFields.map((fieldsItem, index) => {
            let ruleObject = convertArrayToObject(fieldsItem.rules, 'title');
            //Auto-populate current agent info
            let isAutoPopulatedField = checkIsAutoPopulatedField(sectionItem, fieldsItem);
            const hasError =
              errorState.mandatory[`${sectionItem.key}_${fieldsItem.key}`] ||
              errorState.immediate[`${sectionItem.key}_${fieldsItem.key}`];
            return (
              <React.Fragment key={fieldsItem.key}>
                {fieldsItem.type === 'number' ? (
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      disabled={!isEdit || disabledEdit || isAutoPopulatedField || disabled}
                      error={hasError}
                      margin="normal"
                      size="medium"
                      SelectProps={{ size: 'medium' }}
                      fullWidth
                      helperText={handleNumberError(errorState, sectionItem, fieldsItem)}
                      value={
                        formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
                          ? formState[sectionItem.key][fieldsItem.key]
                          : ''
                      }
                      onChange={(e) => {
                        validationHandler.onDismissErrorHandler(`${sectionItem.key}_${fieldsItem.key}`, e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: e.target.value },
                        });
                      }}
                      label={
                        <>
                          {Translation(`agencyCampaign.field.${fieldsItem.key}`)}
                          {_renderMandatory(ruleObject, sectionItem.key, fieldsItem.key)}
                        </>
                      }
                    />
                  </Grid>
                ) : fieldsItem.type === 'text' ? (
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      disabled={!isEdit || disabledEdit || isAutoPopulatedField || disabled}
                      error={hasError}
                      margin="normal"
                      fullWidth
                      size="medium"
                      helperText={
                        errorState.mandatory[`${sectionItem.key}_${fieldsItem.key}`] && MANDATORY_FIELD_ERROR_TEXT
                      }
                      value={
                        formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
                          ? formState[sectionItem.key][fieldsItem.key]
                          : ''
                      }
                      onChange={(e) => {
                        validationHandler.onDismissErrorHandler(`${sectionItem.key}_${fieldsItem.key}`, e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: e.target.value },
                        });
                      }}
                      label={
                        <>
                          {Translation(`agencyCampaign.field.${fieldsItem.key}`)}
                          {_renderMandatory(ruleObject, sectionItem.key, fieldsItem.key)}
                        </>
                      }
                    />
                  </Grid>
                ) : fieldsItem.type === 'date' ? (
                  <Grid item xs={6}>
                    <PruDateTimePicker
                      disabled={!isEdit || disabledEdit || isAutoPopulatedField || disabled}
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          margin: 'normal',
                          variant: 'outlined',
                          error: hasError,
                          helperText: handleDateError(errorState, sectionItem, fieldsItem),
                        },
                      }}
                      value={
                        formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
                          ? formState[sectionItem.key][fieldsItem.key]
                          : null
                      }
                      onChange={(date) => {
                        if (moment(date).isValid()) {
                          validationHandler.onDismissErrorHandler(`${sectionItem.key}_${fieldsItem.key}`, date);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: date },
                          });
                        }
                      }}
                      label={
                        <>
                          {Translation(`agencyCampaign.field.${fieldsItem.key}`)}
                          {_renderMandatory(ruleObject, sectionItem.key, fieldsItem.key)}
                        </>
                      }
                    />
                  </Grid>
                ) : fieldsItem.type === 'dropdown' ? (
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      disabled={!isEdit || disabledEdit || disabled}
                      error={hasError}
                      margin="normal"
                      fullWidth
                      select
                      size="medium"
                      SelectProps={{ size: 'medium' }}
                      className={classes.formInput}
                      helperText={
                        errorState.mandatory[`${sectionItem.key}_${fieldsItem.key}`] && MANDATORY_FIELD_ERROR_TEXT
                      }
                      value={
                        formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
                          ? formState[sectionItem.key][fieldsItem.key]
                          : ''
                      }
                      label={
                        <>
                          {Translation(`agencyCampaign.field.${fieldsItem.key}`)}
                          {_renderMandatory(ruleObject, sectionItem.key, fieldsItem.key)}
                        </>
                      }
                      onChange={(e) => {
                        validationHandler.onDismissErrorHandler(`${sectionItem.key}_${fieldsItem.key}`, e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            section: sectionItem.key,
                            field: `${fieldsItem.key}`,
                            value: e.target.value as string,
                          },
                        });
                      }}
                    >
                      <MenuItem aria-label="None" value="" />
                      {fieldsItem.options ? renderOptions(fieldsItem) : null}
                    </TextField>
                  </Grid>
                ) : fieldsItem.type === 'multipleChoice' ? (
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      disabled={!isEdit || disabledEdit || disabled}
                      className={classes.formInput}
                      fullWidth
                      select
                      size="medium"
                      SelectProps={{ multiple: true, renderValue: (selected: any) => selected.join(', ') }}
                      value={get(formState, `${sectionItem.key}.${fieldsItem.key}`, [])}
                      onChange={(e: any) => handleChange(e?.target?.value, sectionItem.key, fieldsItem.key)}
                      label={
                        <>
                          {Translation(`agencyCampaign.field.${fieldsItem.key}`)}
                          {_renderMandatory(ruleObject, sectionItem.key, fieldsItem.key)}
                        </>
                      }
                      helperText={
                        errorState.mandatory[`${sectionItem.key}_${fieldsItem.key}`] && (
                          <span className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</span>
                        )
                      }
                    >
                      {fieldsItem.options ? renderMultiSelectOptions(sectionItem, fieldsItem) : null}
                    </TextField>
                  </Grid>
                ) : fieldsItem.type === 'currency' ? (
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      disabled={!isEdit || disabledEdit || isAutoPopulatedField || disabled}
                      error={hasError}
                      margin="normal"
                      fullWidth
                      size="medium"
                      helperText={
                        errorState.immediate[`${sectionItem.key}_${fieldsItem.key}`]
                          ? INVALID_CURRENCY_ERROR_TEXT
                          : errorState.mandatory[`${sectionItem.key}_${fieldsItem.key}`] && MANDATORY_FIELD_ERROR_TEXT
                      }
                      value={
                        formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
                          ? formState[sectionItem.key][fieldsItem.key]
                          : '$0'
                      }
                      onChange={(e) => {
                        validationHandler.onDismissErrorHandler(`${sectionItem.key}_${fieldsItem.key}`, e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: e.target.value },
                        });
                      }}
                      label={
                        <>
                          {Translation(`agencyCampaign.field.${fieldsItem.key}`)}
                          {_renderMandatory(ruleObject, sectionItem.key, fieldsItem.key)}
                        </>
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xs={6} key={fieldsItem.title}>
                    <span className={classes.errorText}> {`${Translation('menu.exception')}: ${fieldsItem.type}`}</span>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </div>
    );
  };

  return {
    _renderSection,
    _renderMandatory,
    handleChange,
    checkSaveInfo,
    renderOptions,
    renderMultiSelectOptions,
    checkIsAutoPopulatedField,
    handleNumberError,
    handleDateError,
  };
};
