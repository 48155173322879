import { useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { workerSrc } from './constant';
import { useStyles } from './pdf-viewer.style';

export type PdfViewerProps = {
  file?: string;
  pageNumber: number;
};

export const PdfViewer = ({ file, pageNumber }: PdfViewerProps) => {
  const { classes } = useStyles();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
  }, []);

  return (
    <Document file={file}>
      <Page className={classes.page} pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
    </Document>
  );
};
