import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, find, findIndex, get, isArray, isEmpty, set } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'ahooks';

import { APP_CONFIG, getAppConfigs } from 'src/app/common/utils';
import { getAgentListByGroupLevel, getDivisions, GroupLevelEnum } from '../../network/insightCrud';

import { Division, InsightSession } from '../../types/types';
import { Option } from 'src/app/common/components/cascading-selector/cascading-selector.component';
import { useLang } from 'src/app/i18n';
import { useAuth } from 'src/app/common/hooks/auth/auth.hook';
import { PermissionRole } from 'src/app/modules/Auth/types/auth-types';
import { insightAction } from '../../redux/insight.slice';
import { InsightCascading } from '../../redux/insight.type';
import { RootState } from 'src/redux/store';
import { getAgentName } from 'src/app/common/utils/i18n.util';
import { globalStore } from 'src/app/common/helpers/global-store.util';

export const useInsightHome = () => {
  const locale = useLang();

  const history = useHistory();
  const { role, agentCode, divisionCode, agentInfo }: { [key: string]: any } = useAuth();
  // redux
  const dispatch = useDispatch();
  const cascadingData = useSelector<RootState>((state) => state.insight?.cascading) as InsightCascading;

  const handlePress = useCallback(() => {
    history.push('./export-report');
  }, []);
  const { data, error, loading, run, runAsync } = useRequest(
    () => {
      /* division example
        [{ "groupType": "DIVISION",
          "groupCode": "D0004",
          "groupName": "D0004 - AGENCY CHENG",
          "divisionCode": "D0004",
          "isLeader": true}]
      */
      return getDivisions(agentCode).then((response) => {
        const division = response.data;
        return division.map((item: Division) => {
          const { groupCode, groupType } = item;
          return {
            label: groupCode,
            value: groupCode,
            type: groupType.toLocaleLowerCase(),
          };
        });
      });
    },
    { manual: true },
  );
  const [defaultSelected, setDefaultSelected] = useState<Option[] | undefined>(cascadingData?.pickerSelected);
  const [pickerData, setPickerData] = useState<Option[] | undefined>(cascadingData?.pickerOption);
  const [loadingChild, setLoadingChild] = useState<boolean>(false);

  const [agentCodeList, setAgentCodeList] = useState<string | undefined>(cascadingData?.agentCodeList || agentCode); // selected agent-code from agent picker

  const onSelectAgent = useCallback(
    (option: Option[]) => {
      if (option.length === 2) {
        // click 2nd level: selecte all-agent or single agent
        const agentList = option[1].value; // '10001' | '10001,10002'
        setAgentCodeList(agentList);
        setDefaultSelected(option);
        sessionStorage.setItem(InsightSession.InsightSelectedAgentCode, agentList);
        dispatch(
          // keep-alive: save picker data for page back
          insightAction.setPicker({
            pickerOption: pickerData || data,
            pickerSelected: option,
            agentCodeList: agentList,
          }),
        );
        return;
      }
    },
    [data, pickerData, agentCodeList],
  );

  const getAgentList = useCallback(
    async (option: Option) => {
      setLoadingChild(true);
      const groupCode = option.value;
      try {
        const agentListMap = await getAgentListByGroupLevel(
          groupCode,
          (option.type as GroupLevelEnum) ?? GroupLevelEnum.DIVISION,
          locale,
          dispatch,
        );

        const arrDataCopy = cloneDeep(data);
        const foundIndex = findIndex(arrDataCopy, { value: groupCode });
        if (foundIndex !== -1) {
          arrDataCopy[foundIndex]['child'] = agentListMap;
          setPickerData(arrDataCopy);
        }
        setLoadingChild(false);
        return agentListMap;
      } catch (error) {
        setLoadingChild(false);
        return [];
      }
    },
    [data, locale],
  );

  useEffect(() => {
    if (cascadingData) {
      // use previous data on page back
      return;
    }
    if (agentCode && role === PermissionRole.MANAGER) {
      // run();
      runAsync()
        .then(async (arrDivision) => {
          const agentListMap = await getAgentListByGroupLevel(divisionCode, GroupLevelEnum.DIVISION, locale, dispatch);
          const foundIndex = findIndex(arrDivision, { value: divisionCode });
          if (foundIndex !== -1) {
            arrDivision[foundIndex]['child'] = agentListMap;
          }
          const currentGroup = arrDivision?.find((item: any) => item.value === divisionCode);
          const defaultSelected: Option[] = [
            {
              label: currentGroup?.value ?? arrDivision[0]?.value,
              value: currentGroup?.value ?? arrDivision[0]?.value,
              child: [
                {
                  label: agentCode,
                  value: agentCode,
                },
              ],
            },
            {
              label: agentInfo ? getAgentName(agentInfo.displayName, locale) : '',
              value: agentCode,
            },
          ];
          setDefaultSelected(defaultSelected);
          return arrDivision;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [role, agentCode, cascadingData]);

  useEffect(() => {
    // clear lead-status detail filter
    dispatch(insightAction.clearLeadStatusDetailFilter());
    sessionStorage.setItem(InsightSession.ProspectDetailFilterValues, JSON.stringify({}));

    // set default select Agent
    const currentAgent = sessionStorage.getItem(InsightSession.InsightSelectedAgentCode);
    if (!currentAgent) {
      sessionStorage.setItem(InsightSession.InsightSelectedAgentCode, globalStore.getAgentCode());
    }
  }, []);

  const visible = useMemo(() => {
    const config = {
      appointment: !get(getAppConfigs(APP_CONFIG.SALES_COMMON), 'Sales.prospect.disableEngagement'),
      servicing: get(getAppConfigs(APP_CONFIG.SALES_SDK), 'Sales.campaign.enableServicingCampaign', true),
    };
    return config;
  }, []);

  return {
    data: pickerData || data,
    visible,
    loadingChild,
    defaultSelected,
    handlePress,
    onSelectAgent,
    agentCodeList,
    getAgentList,
  };
};
