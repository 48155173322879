import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  selected: {
    backgroundColor: '#FFE4E6',
    color: '#E8192C',
  },
  unselected: {
    borderColor: '#F0F0F0',
    color: '#999999',
  },
}));
