import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';

import { ChipTabsConfig } from 'src/app/common/components/chip-tabs/chip-tabs.component';
import moment from 'moment';
import { TimeRange, ChartType } from 'src/app/common/components/charts/chart-button/chart-button.component';
import {
  transformDayData,
  transformWeekData,
  transformMonthData,
  formatLabel,
} from 'src/app/common/components/charts/util/format-response.util';
import { useRequest } from 'ahooks';
import { fetchInsightSLAGraphs } from 'src/app/modules/Insight/network/insightCrud';
import { SLAType } from 'src/app/modules/Insight/types/marketing-lead.types';

interface HookProps {
  agentCode: string;
  // other params
}

export const useGraphsView = ({ agentCode }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [selectedChipTab, setSelectedChipTab] = useState(String(SLAType.received));
  const [startDate, endDate] = useMemo(() => {
    const startDate = new Date(2020, 1, 1).toISOString();
    const endDate = moment().add(1, 'month').toDate().toISOString();
    return [startDate, endDate];
  }, []);
  const [timeRange, setTimeRange] = useState(TimeRange.day);
  const [chartType, setChartType] = useState(ChartType.area);
  const formatter = useCallback(
    (val: any) => {
      if (!val) return '';
      return formatLabel(val, timeRange);
    },
    [timeRange],
  );

  const { loading, data, run } = useRequest(
    () => fetchInsightSLAGraphs({ agentCode, dateType: timeRange, slaType: selectedChipTab }, dispatch),
    {
      manual: true,
    },
  );
  const chartData = useMemo(() => {
    let graphData = [];
    if (data?.data.length > 0) {
      switch (timeRange) {
        case TimeRange.day:
          graphData = transformDayData(data?.data, moment(startDate).year());
          break;
        case TimeRange.week:
          graphData = transformWeekData(data?.data, startDate);
          break;
        case TimeRange.month:
          graphData = transformMonthData(data?.data, moment(startDate).year());
          break;
      }
    }
    return [{ name: Translation('marketing_lead'), data: graphData }];
  }, [data?.data, timeRange, startDate]);

  const highlightX = useMemo(() => {
    const today = moment();
    const data = chartData[0].data;
    switch (timeRange) {
      case TimeRange.day:
        return [data.findIndex((d) => moment(d?.x?.[0]).isSame(today, 'day'))];
      case TimeRange.week:
        return [data.findIndex((d) => today.isBetween(moment(d?.x?.[0]), moment(d?.x?.[1])))];
      case TimeRange.month:
        return [data.findIndex((d) => moment(d?.x?.[0]).isSame(today, 'month'))];
    }
  }, [chartData, timeRange]);

  useEffect(() => {
    run();
  }, [timeRange, selectedChipTab, run]);

  const chipTabsConfig: Array<ChipTabsConfig> = [
    { value: SLAType.received, label: Translation('insight.marketing_lead.graphs.received') },
    { value: SLAType.acceptedSla, label: Translation('insight.marketing_lead.graphs.acceptedSLA') },
    { value: SLAType.missedAcceptedSla, label: Translation('insight.marketing_lead.graphs.missedSLA') },
  ];

  return {
    loading,
    chipTabsConfig,
    selectedChipTab,
    setSelectedChipTab,
    chartType,
    setChartType,
    timeRange,
    setTimeRange,
    chartData,
    formatter,
    highlightX,
  };
};
