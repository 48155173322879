import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';

import { getMassLeadSummary } from '../../../network/insightCrud';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { useHistory } from 'react-router-dom';

interface HookProps {
  // agent-code string, split by comma,
  // example: "00010001" or "00010001,00010002,00010003"
  agentCode?: string;
}

export const useMassLead = ({ agentCode }: HookProps) => {
  // i18n
  const { t } = useTranslation();

  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  const agentCodeList = agentCode || globalStore.getAgentCode();

  // useRequest to wrap api request.
  const { data, error, loading, run, runAsync } = useRequest(
    () => {
      return getMassLeadSummary({ agentCode: agentCodeList }).then((response) => {
        const { received, activeCampaign } = response.data;
        const result = [
          {
            value: received ?? '-',
            label: t('leads_received_label'),
          },
          {
            value: activeCampaign ?? '-',
            label: t('active_campaign_label'),
          },
        ];
        return result;
      });
    },
    {
      manual: true,
    },
  );
  const handlePress = useCallback(() => {
    history.push('./mass-lead');
  }, []);
  useEffect(() => {
    // manual call api request
    if (agentCodeList) {
      run();
    }
  }, [agentCodeList]);

  const defaultData = useMemo(() => {
    const result = [
      {
        value: '-',
        label: t('leads_received_label'),
      },
      {
        value: '-',
        label: t('active_campaign_label'),
      },
    ];
    return result;
  }, []);

  const tooltip = useMemo(() => {
    const tooltip = [
      {
        title: t('leads_received_label'),
        content: t('leads_received_tooltips_label'),
      },
      {
        title: t('active_campaign_label'),
        content: t('active_campaign_tooltips_label'),
      },
    ];
    return tooltip;
  }, []);

  return {
    loading,
    data: loading ? defaultData : data || defaultData,
    tooltip,
    run,
    handlePress,
  };
};
