import { Dispatch } from 'react';
import { getQueryPath } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, AGENT_URL, MESSAGE_URL, MY_AGENT_ENDPOINT } from 'src/app/common/network';
import {
  DialogueItemType,
  ExportLeadOverviewType,
  TalkBotExportType,
  TalkbotUnreadItem,
} from '../types/talkbot-export-leads-types';
import { talkbotEndpoint } from './constants';

export type TalkbotExportParam = {
  ids: string[];
  flowId: number;
  type: TalkBotExportType;
};

export const getCommingBirthdayReminders = (params: any, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = getQueryPath(`${MESSAGE_URL}/calendars/me/events/duration-events/prospect-birthday?`, params, true);
  return apiClient
    .get<any>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getProspectsByIds = (params: { ids: string[] }, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = getQueryPath(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/prospects/detail-list?`, params);
  return apiClient
    .get<any>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const exportToTalkbot = (body: TalkbotExportParam): Promise<any> => {
  return apiClient.post<any>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${talkbotEndpoint}/export`, body);
};

export const getDialogueList = (dispatch?: Dispatch<any>): Promise<DialogueItemType[]> => {
  const queryPath = getQueryPath(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${talkbotEndpoint}/flow?`, {
    sortBy: 'weight:-1',
    isActive: true,
  });
  return apiClient
    .get(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getExportLeadsOverview = (dispatch?: Dispatch<any>): Promise<ExportLeadOverviewType> => {
  return apiClient
    .get(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${talkbotEndpoint}/leads/overview`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getTalkbotUnread = (dispatch?: Dispatch<any>): Promise<TalkbotUnreadItem[]> => {
  return apiClient
    .get(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/campaigns/talkbot-unread-count`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
