import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useAppointment } from './appointment.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { Card } from 'src/app/common/components/card/card.component';
import {
  ChartType,
  SwitchButton,
  TimeRangeButton,
} from 'src/app/common/components/charts/chart-button/chart-button.component';
import { AreaChart } from 'src/app/common/components/charts/area-chart/area-chart.component';
import { BarChart } from 'src/app/common/components/charts/bar-chart/bar-chart.component';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const AppointmentPage: React.FC<PageProps> = (props: PageProps) => {
  const { chartType, setChartType, timeRange, setTimeRange, chartData, formatter, highlightX } = useAppointment({
    currentDate: undefined,
  });
  const { t } = useTranslation();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle title={t('appointment')} />
      <Box marginTop={'24px'}>
        <Card>
          <Box margin="24px">
            <Box display="flex" justifyContent={'flex-end'} marginBottom={'24px'} color={'#F0F0F0'}>
              <Box display="flex" flexDirection={'row'}>
                <Box>
                  <SwitchButton value={chartType} onChange={setChartType} />
                </Box>
                <Box marginLeft={'16px'}>
                  <TimeRangeButton value={timeRange} onChange={setTimeRange} />
                </Box>
              </Box>
            </Box>
            <Box>
              {chartType === ChartType.area ? (
                <AreaChart series={chartData} labelsFormatter={formatter} highlightX={highlightX} />
              ) : (
                <BarChart series={chartData} labelsFormatter={formatter} highlightX={highlightX} />
              )}
            </Box>
          </Box>
        </Card>
      </Box>
    </Page>
  );
};
