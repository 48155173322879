import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as H from 'history';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import queryString from 'query-string';

import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { getLeadStatusDetailList } from '../../network/insightCrud';

import { InsightSession, LeadStatusDetailListDto } from '../../types/types';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { SalesLeadStatusEnum } from 'src/app/modules/Leads/util/lead-status/lead-status.enum';
import { formatDeadReason } from 'src/app/modules/Leads/util/lead-status/lead-status.util';
import { getTableColumns } from './table.column';
import {
  insightLeadDetailsGaDataClick2,
  insightLeadDetailsGaDataClick3,
  insightLeadDetailsGaDataClick,
  insightLeadDetailsGaDataClick1,
} from 'src/app/common/ga/types/ga-click-lead';
import { FollowUpStatusEnum } from 'src/app/modules/Leads/types/types';
import { takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { GAData } from 'src/app/common/ga/redux/gaSlice';

const insightLeadStatusMetricsDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Insights',
  journey: 'Insight Lead Status',
  stage: 'Insight Lead Follow Up Status Metrics Detail',
  screen_name: 'Insight Lead Follow Up Status Metrics Detail',
};
const insightLeadStatusMetricsDetailGaDataRoute1: GAData = {
  module: 'PRULeads',
  feature: 'Insights',
  journey: 'Insight Lead Status',
  stage: 'Insight Lead Dead Reason Metrics Detail',
  screen_name: 'Insight Lead Dead Reason Metrics Detail',
};
const insightLeadStatusMetricsDetailGaDataRoute2: GAData = {
  module: 'PRULeads',
  feature: 'Insights',
  journey: 'Insight Lead Status',
  stage: 'Insight Lead Policy Issued Metrics Detail',
  screen_name: 'Insight Lead Policy Issued Metrics Detail',
};

export const useLeadStatusDetailList = () => {
  const { t } = useTranslation();
  const lang = useLang();

  const queryParams = queryString.parse(window.location.search);
  const query = {
    ...queryParams,
    agentCode: sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode(),
    deadReason: queryParams.reason,
  } as LeadStatusDetailListDto;
  const getTitle = () => {
    switch (queryParams.status) {
      case SalesLeadStatusEnum.dead:
        return formatDeadReason(t, [queryParams.reason as string]);
      case SalesLeadStatusEnum.pending:
        return t(`campaign.lead.followUp.pendingToContact`);
      default:
        return t(`campaign.lead.followUp.${queryParams.status}`);
    }
  };

  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<any> => {
      const { page, pageSize } = params;
      const apiParams: any = {
        ...query,
        page: page + 1,
        limit: pageSize,
      };
      if (sort) {
        const sortItem = Object.entries(sort)?.[0];
        if (sortItem) {
          apiParams.sortBy = sortItem[0];
          apiParams.sortOrder = sortItem[1] === 'asc' ? 1 : -1;
        }
      }

      return await getLeadStatusDetailList(apiParams, dispatch)
        .then((res) => {
          return {
            success: true,
            data: res.docs.map((i: any) => ({
              ...i,
              prospect: i.prospectDetail ?? i.prospect,
              followUpStatus: i.followUp.length === 0 ? FollowUpStatusEnum.contact : i.followUpStatus,
            })),
            total: res.totalDocs,
          };
        })
        .catch((err) => {
          return {
            success: false,
            data: [],
            total: 0,
          };
        });
    },
    [dispatch, query],
  );

  const tableColumns = useMemo(() => getTableColumns({ status: query.status as SalesLeadStatusEnum }), [query]);
  const oppProviderValue = useMemo(
    () => ({
      gaData: {
        accept: insightLeadDetailsGaDataClick2,
        reject: insightLeadDetailsGaDataClick3,
        update: insightLeadDetailsGaDataClick,
        newApp: insightLeadDetailsGaDataClick1,
      },
    }),
    [],
  );

  useEffect(() => {
    if (query.status === SalesLeadStatusEnum.dead) {
      takeUIScreenViewEvent(insightLeadStatusMetricsDetailGaDataRoute1);
    } else if (query.status === SalesLeadStatusEnum.policy) {
      takeUIScreenViewEvent(insightLeadStatusMetricsDetailGaDataRoute2);
    } else {
      takeUIScreenViewEvent(insightLeadStatusMetricsDetailGaDataRoute);
    }
  }, [query.status]);

  const defaultParams = useMemo(() => {
    let sort;
    if (query.status === SalesLeadStatusEnum.dead) {
      sort = { sorter: { updatedAt: 'asc' } };
    } else if (query.status === SalesLeadStatusEnum.policy) {
      sort = { sorter: { updatedAt: 'asc' } };
    } else {
      sort = { sorter: { updatedAt: 'asc' } };
    }
    return sort;
  }, [query.status]);

  return {
    title: getTitle(),
    getData,
    tableColumns,
    defaultParams,
    oppProviderValue,
  };
};
