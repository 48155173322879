import { Dispatch } from 'react';
import apiClient from '../../../common/network/axios';
import { convertServerLocale, RegionLocale } from 'src/app/i18n';
import { apiErrorHandler, AGENT_URL } from 'src/app/common/network';
import {
  PoliciesResponseModel,
  Prospect,
  ProspectCategoriesResponse,
  ProspectDetail,
  ProspectDetailForm,
  ProspectSpecifedColumn,
  ProspectsResponse,
  RequestProspect,
} from '../types/prospect-types';
import { getQueryDate } from '../util/purge-prospect.util';
import src from 'react-select/dist/declarations/src';
import { forEach, trim } from 'lodash';
import { DateToTs } from '../util/common.util';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { parsePhoneNumber } from '../pages/add-prospect/components/import-existing-customers/util/existing-customers.util';

export const fetchProspects = async (
  params: Record<string, any>,
  dispatch?: Dispatch<any>,
): Promise<ProspectsResponse> => {
  const queryPath = `${AGENT_URL}/agents/me/prospects/`;

  let queryText = `?page=${params?.page + 1}&limit=${params?.pageSize}`;
  if (params?.searchkey) {
    queryText += `&query=${encodeURIComponent(params?.searchkey)}`;
  }
  if (params?.filterTypes?.length) {
    params?.filterTypes.forEach((item: string[]) => {
      queryText += `&types=${item}`;
    });
  }
  if (params?.filterSourceTypes?.length) {
    params?.filterSourceTypes.forEach((item: string[]) => {
      queryText += `&source=${item}`;
    });
  }
  if (params?.filterCategoryIds?.length) {
    params?.filterCategoryIds.forEach((item: string[]) => {
      queryText += `&prospectCategory=${item}`;
    });
  }
  if (params?.queryDateFrom) {
    queryText += `&queryDateFrom=${encodeURIComponent(params?.queryDateFrom)}`;
  }
  if (params?.queryDateTo) {
    queryText += `&queryDateTo=${encodeURIComponent(params?.queryDateTo)}`;
  }
  if (params?.updatedDateFrom) {
    queryText += `&updatedDateFrom=${encodeURIComponent(params?.updatedDateFrom)}`;
  }
  if (params?.updatedDateTo) {
    queryText += `&updatedDateTo=${encodeURIComponent(params?.updatedDateTo)}`;
  }
  if (params?.nextEngagementFrom) {
    queryText += `&nextEngagementFrom=${encodeURIComponent(params?.nextEngagementFrom)}`;
  }
  if (params?.nextEngagementTo) {
    queryText += `&nextEngagementTo=${encodeURIComponent(params?.nextEngagementTo)}`;
  }
  if (params?.leadNumber !== undefined) {
    queryText += `&leadNumber=${params?.leadNumber}`;
  }
  if (params?.appModule) {
    queryText += `&appModule=${params?.appModule}`;
  }
  if (params.sortBy) {
    queryText += `&sortBy=${encodeURIComponent(params.sortBy.replace('activeLeads', 'activeLead'))}`;
  }

  return apiClient
    .get<any>(`${queryPath}${queryText}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchProspectCategories = async (
  lang: string,
  dispatch?: Dispatch<any>,
): Promise<ProspectCategoriesResponse> => {
  const queryPath = `${AGENT_URL}/agents/me/prospect-categories`;
  const params = {
    page: 1,
    limit: 20,
    sortItem: 'sendingTime',
    sortOder: 'Desc',
  };

  return apiClient
    .get<any>(queryPath, { params: { lang: convertServerLocale(lang as RegionLocale), ...params } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const checkPurgeProspects = () => fetchProspects({ page: 1, pageSize: 500, updatedDateTo: getQueryDate() });

export const batchEditProspectsCategory = async (
  requestBody: {
    target: string[];
    prospectCategoryId: string;
  },
  dispatch?: Dispatch<any>,
): Promise<void> => {
  const queryPath = `${AGENT_URL}/agents/me/prospects/batch-category`;
  return apiClient
    .patch<any>(queryPath, requestBody)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
/**
 * post a new prospect.
 */
export const postProspect = async (prospect: RequestProspect, dispatch?: Dispatch<any>): Promise<any> => {
  let path = `${AGENT_URL}/agents/me/prospects`;
  return apiClient
    .post<any>(path, prospect, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
  // const convertProspect = (raw: ProspectResponseModel): Prospect => {
  //   return {
  //     id: raw._id,
  //     firstName: raw.firstName,
  //     lastName: raw.lastName,
  //     nameWeight: raw.nameWeight,
  //     displayName: raw.displayName,
  //     regionCode: raw.regionCode,
  //     email: raw.email,
  //     phoneNumber: raw.phoneNumber,
  //     nextEngagement: typeof raw.nextEngagement === 'string' ? [raw.nextEngagement] : raw.nextEngagement,
  //     prospectCategory: raw.prospectCategory,
  //     gender: raw.gender,
  //     status: raw.status,
  //     types: raw.types,
  //     source: raw.sourceType,
  //     activeLeads: raw.activeLeads,
  //     createdAt: DateToTs(raw.createdAt),
  //     updatedAt: DateToTs(raw.updatedAt),
  //   };
  //   try {
  //     const resultProspect: Prospect = convertProspect(response.data);
  //     return { kind: 'ok', data: resultProspect };
  //   } catch (error) {
  //     return { kind: 'bad-data' };
  //   }
};

export const mergeProspect = async (
  groups: {
    target: string;
    list: string[];
  }[],
  dispatch?: Dispatch<any>,
): Promise<void> => {
  const queryPath = `${AGENT_URL}/agents/me/prospects/merge`;
  return apiClient
    .post<any>(queryPath, { groups })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const fetchClientList = async (params: any, dispatch: Dispatch<any>): Promise<any> => {
  const convertClientsFromPolicies = (rawData: PoliciesResponseModel[]) => {
    const resultsMap: any = {};
    for (let index = 0; index < rawData.length; index++) {
      const policy = rawData[index];
      if (policy?.polOwnerCode && policy?.polOwnerName && policy?.policyNo) {
        resultsMap[policy.polOwnerCode] = resultsMap[policy.polOwnerCode] || {
          clientName: policy.polOwnerName.toString().trim(),
          clientCode: policy.polOwnerCode.toString().trim(),
        };
        resultsMap[policy.polOwnerCode].policies = resultsMap[policy.polOwnerCode].policies || [];
        resultsMap[policy.polOwnerCode].policies.push(policy.policyNo.toString());
      }
    }
    const results = [];
    for (const key in resultsMap) {
      if (Object.prototype.hasOwnProperty.call(resultsMap, key)) {
        const client = resultsMap[key];
        results.push(client);
      }
    }
    return results.sort((a, b) => {
      return a.clientCode > b.clientCode ? 1 : -1;
    });
  };
  let path = `${AGENT_URL}/agents/me/policies?`;
  forEach(params, (param, key) => {
    path += `${key}=${param ? encodeURI(param) : ''}&`;
  });

  if (path.slice(-1) === '&') {
    // Remove additional substring
    path = path.substring(0, path.length - 1);
  }

  return apiClient
    .get<any>(path)
    .then((response) => {
      response.data = convertClientsFromPolicies(response.data);
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteProspect = async (target: string[], dispatch?: Dispatch<any>): Promise<void> => {
  const queryPath = `${AGENT_URL}/agents/me/prospects/batch`;
  return apiClient
    .delete<any>(queryPath, {
      data: { target },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const getProspectSpecifiedColumns = async (
  type = 'phoneNumber',
  dispatch: Dispatch<any>,
): Promise<Array<any>> => {
  const queryText = `?type=${type}`;
  const path = `${AGENT_URL}/agents/me/prospects/specifed-columns${queryText}`;
  return apiClient
    .get(path)
    .then((response) => {
      const rawData = response.data;
      console.log('rawData', rawData);
      return rawData.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getProspectById = async (id: string, dispatch: Dispatch<any>): Promise<any> => {
  const path = `${AGENT_URL}/agents/me/prospects/${id}`;

  const initRegionCode = '+852';
  const convertProspect = (raw: any): ProspectDetail => {
    const createTs = DateToTs(raw.createdAt);
    const updateTs = DateToTs(raw.updatedAt);
    const regionCode = trim(raw.countryCode) || initRegionCode;
    return {
      id: raw._id,
      firstName: raw.firstName,
      lastName: raw.lastName,
      nameWeight: raw.nameWeight,
      displayName: raw.displayName,
      gender: raw.gender,
      regionCode: regionCode?.startsWith('+') ? regionCode : '+' + (regionCode || ''),
      phoneNumber: raw.phoneNumber,
      email: raw.email,
      prospectCategory: raw.prospectCategory,
      createdAt: createTs,
      updatedAt: updateTs,
      engagement: raw.engagement,
      source: raw.sourceType,
      types: raw.types,
      nextEngagement: typeof raw.nextEngagement === 'string' ? [raw.nextEngagement] : raw.nextEngagement,
      birthDate: raw.birthDate,
      maritalStatus: raw.maritalStatus,
      occupation: raw.occupation,
      company: raw.company || raw.employmentCompany, // stupid ass saved 2 fields for this
      workPosition: raw.workPosition,
      income: raw.income,
      language: raw.language,
      race: raw.race,
      nationality: raw.nationality,
      chinaId: raw.chinaId,
      countryNationalId: raw.countryNationalId,
      permanentResident: raw.permanentResident,
      education: raw.education,
      smokeHabit: raw.smokeHabit,
      salutation: raw.salutation,
      passportNumber: raw.passportNumber,
      notes: raw.notes,
      referredBy: raw.referredBy,
      secondPhoneNumber: raw.secondPhoneNumber,
      secondEmail: raw.secondEmail,
      fbProfile: raw.fbProfile,
      whatsApp: raw.whatsApp,
      wechatId: raw.wechatId,
      address: raw.address,
      state: raw.state,
      city: raw.city,
      zipCode: raw.zipCode,
      optedOutContactStatus: raw.optedOutContactStatus,
      totalChild: raw.totalChild,
      childrenDetails: raw.childrenDetails,
      spouseDetails: raw.spouseDetails,
      parentDetails: raw.parentDetails,
      otherFamilyDetails: raw.otherFamilyDetails,
      categoryUpdatedLog: raw.categoryUpdatedLog,
      policies: raw.policies,
      activeLeads: raw.activeLeads,
      prospectClientCode: raw.prospectClientCode,
      expectedIncomeCoverMonths: raw.expectedIncomeCoverMonths,
      existingCISumAssured: raw.existingCISumAssured,
      preferredCISumAssured: raw.preferredCISumAssured,
    };
  };
  return apiClient
    .get(path)
    .then((response) => {
      const rawData = response.data;
      return convertProspect(rawData);
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

/**
 * update a prospect.
 */
export const updateProspect = async (prospect: ProspectDetailForm, dispatch: Dispatch<any>): Promise<any> => {
  // make the api call
  // const response: Response = await this.api.post(`${this.config.url}/invitations/${GlobalHelper.getStaffSelectedAgentCode() || 'me'}`, {
  let apiUrl = '';
  apiUrl = `${AGENT_URL}/agents/me/prospects/${prospect.id}`;
  return apiClient
    .patch<any>(apiUrl, prospect)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
  // response = await this.api.patch(apiUrl, prospect);
  // // the typical ways to die when calling an api
  // if (!response.ok) {
  //   const problem = getGeneralApiProblem(response);
  //   if (problem) return problem;
  // }

  // // transform the data into the format we are expecting
  // try {
  //   const resultProspect: any = response?.data;
  //   return { kind: 'ok', data: resultProspect };
  // } catch (error) {
  //   return { kind: 'bad-data' };
  // }
};

export const getProspectInfosFromCustomers = async (
  prospectFromCustomers: {
    prospectCategory: string;
    clientCodes: string[];
  },
  lang: string,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  // make the api call
  const requestors = [];
  for (let index = 0; index < prospectFromCustomers.clientCodes.length; index++) {
    const clientCode = prospectFromCustomers.clientCodes[index];
    const clientInfoPromise = apiClient.get(
      `${AGENT_URL}/agents/me/clients/get/${clientCode}?lang=${convertServerLocale(lang as any, false)}`,
    );
    requestors.push(clientInfoPromise);
  }
  try {
    const clientInfosRes = await Promise.all(requestors);
    const clientInfos = [];
    const convertClientToProspect = (client: any) => {
      const clientNameInfo = client.clientNameRaw?.filter((item: { lang: string }) => item.lang !== 'ENG')[0];
      const phone = parsePhoneNumber(client.mobileNumber);
      const prospect = {
        prospectCategory: prospectFromCustomers.prospectCategory,
        agentCode: globalStore.getAgentCode(),
        firstName: clientNameInfo.firstName,
        lastName: clientNameInfo.surName,
        birthDate: client?.dateOfBirth,
        maritalStatus: client.maritalStatus,
        occupation: client.occupation,
        company: client.campany,
        jobTitle: client.jobTitle,
        income: client.income,
        race: client.race,
        nationality: client.nationality,
        smokerIndicator: client?.somking === true ? true : false,
        countryNationalId: client.idNo,
        // gender: this.getProspectGender(client?.gender),
        phoneNumber: phone.nationalNumber,
        countryCode: '+' + phone.countryCallingCode,
        email: client?.email,
        address: Object.values(client?.permanentAddress).toString(),
        state: client?.permanentAddress?.state,
        zipCode: client?.permanentAddress?.zipcode,
        optOutStatus: client?.optOut,
        prospectClientCode: client.clientCode,
        clientCode: client.clientCode,
      };
      return prospect;
    };
    for (let index = 0; index < clientInfosRes.length; index++) {
      const res: any = clientInfosRes[index];
      if (res.data) {
        const clientInfo = (res.data as any)?.[0] ?? res.data;
        const requestDto = convertClientToProspect(clientInfo);
        clientInfos.push(requestDto);
      }
    }
    return { kind: 'ok', data: clientInfos };
  } catch (error: any) {
    throw apiErrorHandler(error, dispatch);
  }
};

export const batchProspectsFromCustomers = async (
  prospectFromCustomers: Prospect[],
  dispatch?: Dispatch<any>,
): Promise<any> => {
  // make the api call
  const path = `${AGENT_URL}/agents/me/prospects/addFromCustomer`;
  try {
    const response = await apiClient.post<any>(path, prospectFromCustomers, {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log('response', response);
    const convertProspect = (raw: any): Prospect => {
      return raw;
    };
    const resultProspect: Prospect[] = (response.data?.length ? response.data : []).map(convertProspect);
    const successProspects = response.data?.length ? response.data : [];
    const succeedClientCodes = resultProspect.map((item: any) => item.prospectClientCode);
    const failedProspects = prospectFromCustomers.filter(
      (item: any) => !succeedClientCodes.includes(item.prospectClientCode),
    );
    return { kind: 'ok', data: { fail: failedProspects, success: resultProspect, successProspects } };
  } catch (error: any) {
    throw apiErrorHandler(error, dispatch);
  }
};

export const deleteSpecificProspect = async (id: string, dispatch: Dispatch<any>): Promise<any> => {
  // make the api call
  const queryPath = `${AGENT_URL}/agents/me/prospects/${id}`;
  return apiClient
    .delete<any>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
