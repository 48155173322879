import { getEnumKeyByEnumValue, getRegionIdByCode } from 'src/app/modules/Prospect/util/common.util';
import {
  ProspectCityEnum,
  ProspectDetailForm,
  ProspectGenderEnum,
  ProspectLanguageEnum,
  ProspectMaritalStatusEnum,
  ProspectRaceEnum,
  ProspectStateEnum,
  SalutationEnum,
  YesOrNoEnum,
} from '../../../../../types/prospect-types';
import { IntlShape } from 'react-intl';

export const nonNegativeNumberRegex = /^[0-9]\d*(\.\d+)?$/;
export const emailRegex = /^[\w.+\-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const numberTypeFields = ['totalChild', 'income', 'expectedIncomeCoverMonths', 'existingCISumAssured'];
export const nonPositiveTypeFields = ['totalChild', 'income', 'expectedIncomeCoverMonths', 'existingCISumAssured'];
export const booleanTypeFields = ['permanentResident'];

export function getInitValue(routeItem: ProspectDetailForm & { engagement?: any[] }) {
  delete routeItem.engagement; // remove
  const obj: any = {
    ...routeItem,
    // regionCode: getRegionIdByCode(routeItem.regionCode ?? ''),
  };
  numberTypeFields.forEach((field: string) => {
    const fieldValue: any = obj[field];
    if (fieldValue === 0) {
      obj[field] = '0'; // convert to string
    }
  });
  return obj;
}

export const getProspectGenderMap = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return Object.values(ProspectGenderEnum).map((value) => {
    return {
      label: Translation(getEnumKeyByEnumValue(ProspectGenderEnum, value)),
      value: value,
    };
  });
};

export const getMaritalStatusMap = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return Object.values(ProspectMaritalStatusEnum).map((value) => {
    return {
      label: Translation(getEnumKeyByEnumValue(ProspectMaritalStatusEnum, value)),
      value: value,
    };
  });
};

export const getLanguageMap = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return Object.values(ProspectLanguageEnum).map((value) => {
    return {
      label: Translation(getEnumKeyByEnumValue(ProspectLanguageEnum, value)),
      value: value,
    };
  });
};

export const getPermanentResidentPools = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return [
    {
      label: Translation('app.select.yes'),
      value: true,
    },
    {
      label: Translation('app.select.no'),
      value: false,
    },
  ];
};

export const getSmokerIndicatorPools = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return Object.values(YesOrNoEnum).map((value) => {
    return {
      label: Translation(getEnumKeyByEnumValue(YesOrNoEnum, value)),
      value: value,
    };
  });
};

export const SalutationPools = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return Object.values(SalutationEnum).map((value) => {
    return {
      label: Translation(getEnumKeyByEnumValue(SalutationEnum, value)),
      value: value,
    };
  });
};

export const getRacePools = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return Object.values(ProspectRaceEnum).map((value) => {
    return {
      label: Translation(getEnumKeyByEnumValue(ProspectRaceEnum, value)),
      value: value,
    };
  });
};

export const getStatePools = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return Object.values(ProspectStateEnum).map((value) => {
    return {
      label: Translation(getEnumKeyByEnumValue(ProspectStateEnum, value)),
      value: value,
    };
  });
};

export const getCityDataSource = (state: string, intl: IntlShape) => {
  console.log('state', state);
  const Translation = (id: string) => intl.formatMessage({ id });
  const stateKey: string = getEnumKeyByEnumValue(ProspectStateEnum, state);
  const dataSource = [];
  const cityValues = Object.values(ProspectCityEnum);
  for (let index = 0; index < cityValues.length; index++) {
    const cityValue = cityValues[index];
    const cityKey: string = getEnumKeyByEnumValue(ProspectCityEnum, cityValue);
    if (cityKey.startsWith(stateKey + '_')) {
      dataSource.push({
        label: Translation(cityKey),
        value: cityValue,
      });
    }
  }
  return dataSource;
};

export const OptedOutContactStatusPools = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return Object.values(YesOrNoEnum).map((value) => {
    return {
      label: Translation(getEnumKeyByEnumValue(YesOrNoEnum, value)),
      value: value,
    };
  });
};

export const getPickerSelectionPool = (intl: IntlShape) => {
  const genderPools = getProspectGenderMap(intl);
  const maritalStatusPools = getMaritalStatusMap(intl);
  const languagePools = getLanguageMap(intl);
  const permanentResidentPools = getPermanentResidentPools(intl);
  const smokerIndicatorPools = getSmokerIndicatorPools(intl);
  const salutationPools = SalutationPools(intl);
  const racePools = getRacePools(intl);
  const statePools = getStatePools(intl);
  const optedOutContactStatusPools = OptedOutContactStatusPools(intl);
  return {
    genderPools,
    maritalStatusPools,
    languagePools,
    permanentResidentPools,
    smokerIndicatorPools,
    salutationPools,
    racePools,
    statePools,
    optedOutContactStatusPools,
  };
};
