import { get } from 'lodash';
import moment from 'moment';
import { HighlightMatchedText } from 'src/app/common/components/highlight-matched-text/highlight-matched-text.component';
import { ProColumns } from 'src/app/common/components/ProTable';
import { reconstuctNameByLastName } from 'src/app/common/utils';
import { DateFormat } from 'src/app/common/utils/date.util';
import { getFollowUpsColumns } from 'src/app/modules/Leads/pages/leads/leads-list/util/list.util';
import { SalesLeadStatusEnum } from 'src/app/modules/Leads/util/lead-status/lead-status.enum';
import { Columns } from 'src/app/modules/Leads/types/types';
import { COLUMN_ITEMS } from 'src/app/modules/Leads/util/column-items.util';

export const getTableColumns = ({
  // t,
  status, // lang,
}: {
  // t: (val: string) => string;
  status: SalesLeadStatusEnum | string | null;
  // lang: string;
}): ProColumns<any>[] => {
  if (status === SalesLeadStatusEnum.dead) {
    return deadColumn();
  } else if (status === SalesLeadStatusEnum.policy) {
    return policyIssuedColumn();
  }
  // return followupColumn(t, lang);
  return getFollowUpsColumns({});
};

function policyIssuedColumn(): ProColumns<any>[] {
  return [
    {
      title: 'name',
      width: '176px',
      dataIndex: 'prospectDetail',
      render: (prospect: any) => {
        return (
          <HighlightMatchedText>
            {prospect?.displayName || reconstuctNameByLastName(prospect.firstName, prospect.lastName)}
          </HighlightMatchedText>
        );
      },
    },
    {
      width: '176px',
      title: 'salesFollowUpLastUpdated',
      dataIndex: 'updatedAt',
      sorter: true,
      render: (updatedAt: string) => {
        return moment(updatedAt).format(DateFormat.datetime);
      },
    },
    {
      width: '176px',
      title: 'salesLdPolicyNumber',
      dataIndex: 'issuedPolicy',
      render: (issuedPolicy: any) => {
        return get(issuedPolicy, '[0].policyNumber', '');
      },
    },
  ];
}

function deadColumn(): ProColumns<any>[] {
  return [
    {
      title: 'name',
      dataIndex: 'prospectDetail',
      width: '176px',
      render: (prospect: any) => {
        return (
          <HighlightMatchedText>
            {prospect?.displayName || reconstuctNameByLastName(prospect.firstName, prospect.lastName)}
          </HighlightMatchedText>
        );
      },
    },
    COLUMN_ITEMS[Columns.source](),
    COLUMN_ITEMS[Columns.leadSource](),
    // {
    //   title: 'agent_lead_source',
    //   width: '176px',
    //   dataIndex: 'leadSource',
    // },
    {
      width: '176px',
      title: 'last_update_time',
      sorter: true,
      dataIndex: 'updatedAt',
      render: (updatedAt: string) => {
        return moment(updatedAt).format(DateFormat.datetime);
      },
    },
  ];
}
