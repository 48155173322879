import { FC } from 'react';
import { FormMode } from 'src/app/common/types';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form-agent';
import SurveyCreator from 'src/app/common/components/survey-v2/survey-creator';
import { EventFormCommonProps } from 'src/app/modules/event-v2/types';
import { TopButton } from '../../../common';
import { useWalkInForm } from './walk-in-form.hook';
import { defaultWalkInFormQuestionNames } from './constants';
import './walk-in-form.style.css';

type EventWalkInFormProps = ComponentProps<EventFormCommonProps>;

export const EventWalkInForm: FC<EventWalkInFormProps> = ({ formCommonProps, ...rest }) => {
  const { formMode, isPublished, disableEdit, eventItem } = formCommonProps;
  const { walkInFormRef, handleSave, onSubmit } = useWalkInForm({
    formCommonProps,
    ...rest,
  });
  const viewMode = formMode === FormMode.CREATE || formMode === FormMode.VIEW;
  const disableOperation = viewMode || isPublished || disableEdit;

  return (
    <>
      {eventItem && (
        <>
          <TopButton formMode={formMode} eventItem={eventItem} onSave={handleSave} />
          <div id="walk-in-form">
            <SurveyCreator
              key={formMode}
              ref={walkInFormRef}
              module="event"
              data={{
                content: eventItem.walkInFormBody as any,
              }}
              disableAddQuestionButton={disableOperation}
              disableLogo
              disableFileTypeQuestion
              customOptions={{
                haveCommercialLicense: true,
                showJSONEditorTab: false,
                showTranslationTab: true,
                showThemeTab: true,
              }}
              customOnPageAdded={(options, pageCounter) => {
                const page = options.page;
                page.name = `page${pageCounter}`;
                page.title = '';
              }}
              customOnQuestionAdded={(options) => {
                options.question.locRequiredErrorText.setLocaleText('default', 'Field is required');
                options.question.locRequiredErrorText.setLocaleText('zh-tw', '欄位為必填項');
              }}
              customOnDragDropAllow={(options) => {
                options.allow = true;
              }}
              customOnElementAllowOperations={(options) => {
                options.allowCopy = false;
                if (disableOperation || defaultWalkInFormQuestionNames.includes(options.obj.name)) {
                  options.allowDelete = false;
                  options.allowDragging = false;
                  options.allowChangeType = false;
                  options.allowChangeInputType = false;
                  options.allowChangeRequired = false;
                }
              }}
              customOnGetPropertyReadOnly={(options) => {
                if (disableOperation || defaultWalkInFormQuestionNames.includes(options.obj.name)) {
                  options.readOnly = true;
                } else {
                  options.readOnly = false;
                }
              }}
              onChangeContent={onSubmit}
            />
          </div>
        </>
      )}
    </>
  );
};
