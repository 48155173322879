import { get, isEmpty } from 'lodash';
import { stringify } from 'query-string';
import { formatDeadReason } from 'src/app/modules/Leads/util/lead-status/lead-status.util';
import { AccordionProps } from '../../../components/insight-accordion/insight-accordion.component';
import { DisplayCategoryEnum, LeadStatusData, StatusTypeEnum } from '../../../types/types';
import { ComponentProps } from '../detail-tab/detail-tab.component';

export interface Params {
  t: (key: string, values?: Record<string, any> | undefined) => string;
  data: LeadStatusData;
}
// sales_frontend/src/screens/lead-status/details-view/result-view/follow-up/follow-up.tsx
export function leadStatusFormat({ t, data, source, startDate, endDate, campaignTypeId }: Params & ComponentProps): {
  followup: AccordionProps;
  policyIssued: AccordionProps;
  dead: AccordionProps;
  chart: { value: number; color: string }[];
  workingLeadCount: number;
} {
  const { detail, summary, workingLeadCount } = data;
  const color = {
    followup: '#4386E5',
    policyIssued: '#24C791',
    dead: '#999',
  };
  const params = { source, startDate, endDate, campaignTypeId };
  const link = `./lead-status-list?${stringify(params)}`;
  let followupTotal = 0;
  const arrFollowUp = get(detail, 'followUp', []);
  const followupBody = arrFollowUp.map(({ stage, aging, count, percentage }) => {
    followupTotal += count;
    return {
      parent: {
        value: [
          stage === 'pending' ? t('campaign.lead.followUp.pendingToContact') : t(`campaign.lead.followUp.${stage}`),
          aging,
          `${count} (${percentage}%)`,
        ],
        link: `${link}&status=${stage}`,
      },
    };
  });

  const followup: AccordionProps = {
    title: { dotColor: color.followup, name: t('follow_up'), nameCount: followupTotal, hideTotalCount: true },
    data: {
      header: [
        { value: t('leads_status_title_stage') },
        { value: t('leads_status_title_aging') },
        { value: t('leads_status_title_total') + ' (%)', tooltip: t('leads_status_follow_up_tips') },
      ],
      body: followupBody,
    },
  };
  const policyIssued = {
    title: {
      dotColor: color.policyIssued,
      name: t('policy_issued'),
      nameCount: get(detail, 'policyIssued.count', '-'),
      hideTotalCount: true,
    },
    data: {
      body: [
        {
          parent: {
            value: [t('leads_status_conversion_rate'), `(${get(detail, 'policyIssued.conversionRate', '-')}%)`],
            link: `${link}&status=policy`,
            tooltip: t('policy.issued.toolTips'),
          },
        },
      ],
    },
  };
  let deadTotal = 0;
  const arrDead = get(detail, 'dead', []);
  const deadBody = arrDead.map(({ stage, lastFollowUpRecord, reasons, count, percentage }) => {
    deadTotal += count;
    const lastFollowUpStatus = lastFollowUpRecord?.status || '';
    const childData = isEmpty(reasons)
      ? undefined
      : reasons.map(({ reason, count }) => {
          return {
            value: [formatDeadReason(t, [reason]), count],
            link: `${link}&status=dead&lastFollowUpStatus=${lastFollowUpStatus}&reason=${encodeURIComponent(reason)}`,
          };
        });
    return {
      parent: {
        value: [t(`leads_dead_reason_${stage}`), `${count} (${percentage}%)`],
      },
      child: childData,
    };
  });
  const dead: AccordionProps = {
    title: { dotColor: color.dead, name: t('cant_be_converted'), nameCount: deadTotal, hideTotalCount: true },
    data: {
      header: [
        { value: t('leads_status_stage_reason') },
        { value: t('leads_status_title_total') + ' (%)', tooltip: t('leads_status_cant_convert_tips') },
      ],
      body: deadBody,
    },
  };

  const chart = [
    { label: t(`follow_up`), color: color.followup, value: get(summary, 'followUp.percentage') },
    {
      label: t(`campaign.lead.followUp.policy`),
      color: color.policyIssued,
      value: get(summary, 'policyIssued.percentage'),
    },
    { label: t(`campaign.lead.followUp.dead`), color: color.dead, value: get(summary, 'dead.percentage') },
  ];
  return { followup, policyIssued, dead, chart, workingLeadCount };
}

export function getLeadTypeButtonGroup(t: (key: string) => string): { label: string; value: StatusTypeEnum }[] {
  return [
    {
      label: t('new_lead'),
      value: StatusTypeEnum.newLead,
    },
    {
      label: t('contacted'),
      value: StatusTypeEnum.contacted,
    },
    {
      label: t('leads_status_title_policy_issued'),
      value: StatusTypeEnum.policyIssued,
    },
  ];
}

export function getDisplayCategoryButtonGroup(
  t: (key: string) => string,
): { label: string; value: DisplayCategoryEnum }[] {
  return [
    {
      label: t('insights_all'),
      value: DisplayCategoryEnum.all,
    },
    {
      label: t('lead_source'),
      value: DisplayCategoryEnum.leadSource,
    },
  ];
}
