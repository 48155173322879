import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './item.module.css';
import { useItem } from './item.hook';

type ComponentProps = {
  label: string;
  value: string;
};

export const ItemComponent: React.FC<ComponentProps> = memo(({ label, value }: ComponentProps) => {
  // i18n
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>{value}</div>
      </div>
    </>
  );
});
