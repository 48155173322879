import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
// import LeadsRoutes from './pages/InsightRoutes';
// import { isPermitted } from '../../common/permissions/permission-utils';
import {
  INSIGHT_TITLE,
  INSIGHT_BASE_PATH,
  INSIGHT_NAMESPACE,
  INSIGHT_APPOINTMENT_PATH,
  INSIGHT_STATUS_PATH,
  INSIGHT_STATUS_LIST_PATH,
  INSIGHT_MARKETING_PATH,
  INSIGHT_MASS_PATH,
  INSIGHT_COMPANY_PATH,
  INSIGHT_PROSPECT_PATH,
  INSIGHT_SERVICING_PATH,
  INSIGHT_EXPORT_PATH,
} from './constants';
import InsightRoutes from './pages/InsightRoutes';

export const insightModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const t = (id: string) => intl.formatMessage({ id });
  return {
    title: t(INSIGHT_TITLE),
    path: INSIGHT_BASE_PATH,
    component: InsightRoutes,
    namespace: INSIGHT_NAMESPACE,
    disableMenu: false,
    disableRoleControl: true,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
    page: [
      {
        title: t('appointment'),
        path: INSIGHT_APPOINTMENT_PATH,
      },
      {
        title: t('leads_status'),
        path: INSIGHT_STATUS_PATH,
      },
      {
        title: t('campaign.detail.details'),
        path: INSIGHT_STATUS_LIST_PATH,
      },
      {
        title: t('marketing_lead'),
        path: INSIGHT_MARKETING_PATH,
      },
      {
        title: t('mass_lead'),
        path: INSIGHT_MASS_PATH,
      },
      {
        title: t('company_assisted_lead'),
        path: INSIGHT_COMPANY_PATH,
      },
      {
        title: t('prospect'),
        path: INSIGHT_PROSPECT_PATH,
      },
      {
        title: t('servicing_campaign'),
        path: INSIGHT_SERVICING_PATH,
      },
      {
        title: t('export_report'),
        path: INSIGHT_EXPORT_PATH,
      },
    ],
  };
};
