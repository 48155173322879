export type MarketingLeadSLA = {
  acceptAchieved: number;
  acceptExpired: number;
  acceptMissed: number;
  acceptPending: number;
  contactAchieved: number;
  contactExpired: number;
  contactMissed: number;
  contactPending: number;
  rejected: number;
};

export enum SLAType {
  received = 'received',
  acceptedSla = 'acceptedSla',
  missedAcceptedSla = 'missedAcceptedSla',
}
