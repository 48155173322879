import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog, FormLabel, Paper } from '@mui/material';
import { FormMode } from 'src/app/common/types';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form-agent';
import { EventPublishStatusEnum, EventFormCommonProps } from 'src/app/modules/event-v2/types';
import { useCommonFormStyles, TopButton } from '../../../common';
import { AddAgents } from './components/add-agents';
import { usePublishStyles } from './publish-form.style';
import { usePublishForm } from './publish-form.hook';

type EventPublishFormProps = ComponentProps<EventFormCommonProps>;

export const EventPublishForm: FC<EventPublishFormProps> = ({ formCommonProps, ...rest }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = usePublishStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formMode, isPublished, disableEdit, eventItem } = formCommonProps;
  const {
    formState,
    showConfirmDialog,
    errorState,
    formDispatch,
    setShowConfirmDialog,
    onDismissErrorHandler,
    onSubmit,
    onPublish,
  } = usePublishForm({
    formCommonProps,
    ...rest,
  });
  const viewMode = formMode === FormMode.CREATE || formMode === FormMode.VIEW;

  return (
    <>
      {showConfirmDialog && (
        <Dialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)}>
          <div className={classes.dialogContainer}>{Translation('event.form.publish_today_reminder')}</div>
          <div className={classes.dialogButtonContainer}>
            <Button variant="contained" color="inherit" onClick={() => setShowConfirmDialog(false)}>
              {Translation('global.text.no')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => onSubmit(EventPublishStatusEnum.PUBLISHED)}>
              {Translation('global.text.yes')}
            </Button>
          </div>
        </Dialog>
      )}
      {/* Edit Mode (Draft): Save Draft, Publish */}
      {/* Edit Mode (Published): Save */}
      {viewMode ? (
        <TopButton formMode={formMode} eventItem={eventItem} />
      ) : (
        <>
          {(!isPublished || !disableEdit) && (
            <div className={commonFormClasses.topButtonContainer}>
              {!disableEdit && (
                <Button variant="outlined" color="inherit" onClick={() => onSubmit()}>
                  {isPublished ? Translation('app.button.save') : Translation('app.button.save_draft')}
                </Button>
              )}
              {!isPublished && (
                <Button variant="contained" color="secondary" onClick={() => onPublish()}>
                  {Translation('app.button.publish')}
                </Button>
              )}
            </div>
          )}
        </>
      )}
      <Paper className={commonFormClasses.paper}>
        <div className={commonFormClasses.smallGapContentContainer}>
          <FormLabel
            error={errorState.mandatory['pubAgent-eligibleAgents']}
            className={commonFormClasses.formLabelNoSpacing}
          >
            {Translation('common.publish_to')}
            <span className={commonFormClasses.mandatory}>*</span>
          </FormLabel>
          <AddAgents
            disabled={viewMode || disableEdit}
            error={errorState.mandatory['pubAgent-eligibleAgents']}
            initialAgentCodeList={formState.pubAgentConfig.eligibleAgents}
            onChange={({ agentCodeList, agentListFile }) => {
              onDismissErrorHandler('pubAgent-eligibleAgents', true);
              formDispatch({
                type: 'MODIFY_FIELD',
                payload: {
                  field: 'pubAgentConfig',
                  value: {
                    ...formState.pubAgentConfig,
                    eligibleAgents: agentCodeList || formState.pubAgentConfig.eligibleAgents,
                    eligibleAgentListFile: agentListFile,
                  },
                },
              });
            }}
          />
        </div>
      </Paper>
    </>
  );
};
