import { makeStyles } from 'tss-react/mui';
import { calculateItemWidth } from 'src/app/common/utils';

const PREVIEW_IMG_GAP = 24;
const PREVIEW_IMG_HEIGHT = 170;
const BAR_HEIGHT = 84;
const IMG_PADDING = 42;
const THUMBNAIL_WIDTH = 80;

export const useStyles = makeStyles()(() => ({
  previewImageList: {
    width: '100%',
    display: 'flex',
    columnGap: PREVIEW_IMG_GAP,
    rowGap: PREVIEW_IMG_GAP,
    flexWrap: 'wrap',
  },
  previewImageContainer: {
    position: 'relative',
    width: calculateItemWidth(PREVIEW_IMG_GAP, 3),
    height: PREVIEW_IMG_HEIGHT,
    cursor: 'pointer',
  },
  previewImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 8,
  },
  checkbox: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  autoCursor: {
    cursor: 'auto',
  },
  previewButton: {
    width: calculateItemWidth(PREVIEW_IMG_GAP, 3),
    height: PREVIEW_IMG_HEIGHT,
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 12,
  },
  previewButtonIcon: {
    width: 58,
    height: 58,
  },
  previewButtonText: {
    fontSize: 14,
    fontWeight: 500,
  },
  previewButtonTextHighlight: {
    color: '#E8192C',
  },
  errorText: {
    marginLeft: 14,
  },
  topBar: {
    width: '100%',
    height: BAR_HEIGHT,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    justifyContent: 'flex-end',
    columnGap: 8,
  },
  iconButton: {
    width: 40,
    height: 40,
  },
  icon: {
    color: 'white',
    fontSize: 24,
  },
  fileContainer: {
    height: `calc(100vh - ${BAR_HEIGHT * 2}px)`,
    display: 'flex',
    justifyContent: 'center',
    padding: `${IMG_PADDING}px 0`,
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  bottomBar: {
    width: '100%',
    height: BAR_HEIGHT,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    justifyContent: 'center',
    columnGap: 24,
    padding: 9,
    overflowX: 'auto',
  },
  thumbnailContainer: {
    width: THUMBNAIL_WIDTH,
    minWidth: THUMBNAIL_WIDTH,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 2,
    cursor: 'pointer',
  },
  thumbnail: {
    width: '100%',
    height: 50,
    objectFit: 'cover',
  },
  thumbnailIndex: {
    color: '#666666',
    fontSize: 12,
  },
  activeIndex: {
    color: 'white',
  },
}));
