import moment from 'moment';
import { appointmentGraph } from '../../../network/insightCrud';
import {
  AppointmentGraphParam,
  AppointmentGraphResponse,
  ChartItem,
  GraphDayItem,
  GraphMonthItem,
  GraphResBase,
  GraphWeekItem,
} from '../../../types/types';
import { Dispatch } from 'react';
import { TimeRange } from 'src/app/common/components/charts/chart-button/chart-button.component';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import {
  transformDayData,
  transformMonthData,
  transformWeekData,
} from 'src/app/common/components/charts/util/format-response.util';

const durationTypeMap = {
  [TimeRange.day]: '1',
  [TimeRange.week]: '2',
  [TimeRange.month]: '3',
};

export const getAppointmentRecord = async <T extends GraphResBase>(
  { durationType, ...rest }: Omit<AppointmentGraphParam, 'type' | 'durationType'> & { durationType: TimeRange },
  dispatch?: Dispatch<any>,
): Promise<AppointmentGraphResponse<T> | undefined> => {
  const type = 'lead';

  // api update
  const body: AppointmentGraphParam = {
    ...rest,
    durationType: durationTypeMap[durationType] as AppointmentGraphParam['durationType'],
    type,
  };

  try {
    return await appointmentGraph(body, dispatch);
  } catch (err) {
    console.error('getAppointmentRecord error: ', err);
  }
};

type RecordParams = Omit<AppointmentGraphParam, 'type' | 'durationType'>;

export const getAppointmentDayRecord = async (
  { ...rest }: RecordParams,
  dispatch?: Dispatch<any>,
): Promise<ChartItem[]> => {
  const body = {
    ...rest,
    durationType: TimeRange.day,
  };

  const res = await getAppointmentRecord<GraphDayItem>(body, dispatch);
  if (res) {
    const { dateCounts } = res;
    return transformDayData(dateCounts, moment(rest.startDate).year());
  }

  return [];
};

export const getAppointmentWeekRecord = async (
  { ...rest }: RecordParams,
  dispatch?: Dispatch<any>,
): Promise<ChartItem[]> => {
  const body = {
    ...rest,
    durationType: TimeRange.week,
  };

  const res = await getAppointmentRecord<GraphWeekItem>(body, dispatch);
  if (res) {
    const { dateCounts } = res;
    return transformWeekData(dateCounts, rest.startDate);
  }
  return [];
};

export const getAppointmentMonthRecord = async (
  { ...rest }: RecordParams,
  dispatch?: Dispatch<any>,
): Promise<ChartItem[]> => {
  const body = {
    ...rest,
    durationType: TimeRange.month,
  };

  const res = await getAppointmentRecord<GraphMonthItem>(body, dispatch);
  if (res) {
    const { dateCounts } = res;
    return transformMonthData(dateCounts, moment(rest.startDate).year());
  }
  return [];
};
