import React, { memo, useState } from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export enum TimeRange {
  day = 'daily',
  week = 'weekly',
  month = 'monthly',
}

export enum ChartType {
  area = 'area',
  bar = 'bar',
}

export interface TimeRangeButtonProps {
  value?: TimeRange;
  onChange?: (val: TimeRange) => void;
}

export interface SwitchButtonProps {
  value?: ChartType;
  onChange?: (val: ChartType) => void;
}

export interface PaginationProps {
  // value?: 'previous' | 'next';
  disablePrevious?: boolean;
  disableNext?: boolean;
  onChange?: (val: 'previous' | 'next') => void;
}

export const TimeRangeButton: React.FC<TimeRangeButtonProps> = memo(
  ({ value: selected, onChange }: TimeRangeButtonProps) => {
    const [val, setVal] = useState(selected);

    const { t } = useTranslation();

    return (
      <ButtonGroup variant="outlined" aria-label="filter button group" color="inherit">
        {[
          {
            label: t('day'),
            value: TimeRange.day,
          },
          {
            label: t('week'),
            value: TimeRange.week,
          },
          {
            label: t('month'),
            value: TimeRange.month,
          },
        ].map(({ label, value }, index) => (
          <Button
            key={index}
            onClick={() => {
              setVal(value);
              onChange && setTimeout(() => onChange(value));
            }}
          >
            <Typography color={val === value ? '#E8192C' : '#999999'}>{label}</Typography>
          </Button>
        ))}
      </ButtonGroup>
    );
  },
);

export const SwitchButton: React.FC<SwitchButtonProps> = memo(({ value: selected, onChange }: SwitchButtonProps) => {
  const [val, setVal] = useState(selected);

  return (
    <ButtonGroup variant="outlined" aria-label="filter button group" color="inherit">
      <Button
        onClick={() => {
          setVal(ChartType.bar);
          onChange && setTimeout(() => onChange(ChartType.bar));
        }}
      >
        <SignalCellularAltIcon sx={{ color: val === ChartType.bar ? '#E8192C' : '#999999' }} />
      </Button>
      <Button
        onClick={() => {
          setVal(ChartType.area);
          onChange && setTimeout(() => onChange(ChartType.area));
        }}
      >
        <ShowChartIcon sx={{ color: val === ChartType.area ? '#E8192C' : '#999999' }} />
      </Button>
    </ButtonGroup>
  );
});

export const PaginationButton: React.FC<PaginationProps> = memo(
  ({ disablePrevious, disableNext, onChange }: PaginationProps) => {
    return (
      <ButtonGroup variant="outlined" aria-label="filter button group" color="inherit">
        <Button
          disabled={disablePrevious}
          onClick={() => {
            if (disablePrevious) return;
            onChange?.('previous');
          }}
        >
          <KeyboardArrowLeftIcon sx={{ color: disablePrevious ? '#E6E6E6' : '#999999' }} />
        </Button>
        <Button
          disabled={disableNext}
          onClick={() => {
            if (disableNext) return;
            onChange?.('next');
          }}
        >
          <KeyboardArrowRightIcon sx={{ color: disableNext ? '#E6E6E6' : '#999999' }} />
        </Button>
      </ButtonGroup>
    );
  },
);
