import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Grid, CircularProgress } from '@mui/material';

import styles from './detail-tab.module.css';
import { useDetailTab } from './detail-tab.hook';
import { InsightAccordionComponent } from '../../../components/insight-accordion/insight-accordion.component';
import { Card } from 'src/app/common/components/card/card.component';
import { PercentageBarChart } from 'src/app/common/components/charts/percentage-bar-chart/percentage-bar-chart.component';
import { TooltipComponent } from 'src/app/common/components/tooltip/tooltip.component';
import { ErrorComponent } from 'src/app/common/components/error/error.component';

export interface ComponentProps {
  source?: string;
  startDate?: string;
  endDate?: string;
  campaignTypeId?: string;
}

export const DetailTabComponent: React.FC<ComponentProps> = memo(
  ({ source, startDate, endDate, campaignTypeId }: ComponentProps) => {
    // i18n
    const { t } = useTranslation();

    // custom-hook
    const { loading, error, data, disabledClick } = useDetailTab({ source, startDate, endDate, campaignTypeId });
    if (error) {
      return <ErrorComponent />;
    }
    if (!data) {
      return (
        <div className="tw-flex tw-justify-center tw-items-center">
          <CircularProgress size="2rem" color="secondary" />
        </div>
      );
    }

    const { followup, policyIssued, dead } = data;
    return (
      <>
        {loading && (
          <div className="tw-flex tw-justify-center tw-items-center">
            <CircularProgress size="2rem" color="secondary" />
          </div>
        )}
        <Card className={styles.wrapChart}>
          <div className={styles.workingLeads}>
            {t('leads_status_working_lead')}
            <TooltipComponent title={t('leads_status_working_leads_tips')} />
            <div className={styles.num}>{data.workingLeadCount}</div>
          </div>
          {data.workingLeadCount > 0 ? <PercentageBarChart data={data.chart} /> : null}
        </Card>
        <Grid container spacing={2} className={styles.wrap}>
          <Grid item xs={6}>
            <InsightAccordionComponent title={followup.title} data={followup.data} disabledClick={disabledClick} />
            <div className={styles.wrap}>
              <InsightAccordionComponent
                title={policyIssued.title}
                data={policyIssued.data}
                disabledClick={disabledClick}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <InsightAccordionComponent title={dead.title} data={dead.data} disabledClick={disabledClick} />
          </Grid>
        </Grid>
      </>
    );
  },
);
