import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getInsightsProspectsNewAddedList } from '../../../network/insightCrud';
import { InsightSession } from '../../../types/types';
import { DateRadioGroupType } from 'src/app/common/components/list-filter/components/list-filter-date-radio-group/list-filter-date-radio-group.component';
import { globalStore } from 'src/app/common/helpers/global-store.util';

interface HookProps {
  filterValues: { source?: string; dateRange?: DateRadioGroupType };
  // other params
}

export const useNewAddedTab = ({ filterValues }: HookProps) => {
  const currentAgentCode =
    sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode();
  const [prospectDetail, setProspectDetail] = useState<any[]>([]);

  useEffect(() => {
    const { source, dateRange } = filterValues;
    const sourceType = source ? source.replace('all', '') : '';
    let body: any = {
      agentCode: currentAgentCode ?? '',
      sourceType: sourceType,
    };

    if (dateRange && dateRange.value) {
      const { startDate, endDate } = dateRange.value;

      if (dateRange.key !== 'ALL') {
        body = {
          ...body,
          startDate: startDate,
          endDate: endDate,
        };
      }
    }
    getInsightsProspectsNewAddedList(body).then((res) => {
      if (res && res.data) {
        const sotedData = res.data.sort((a: any, b: any) => {
          if (!a.type) {
            return 1;
          } else if (!b.type) {
            return -1;
          } else {
            return a.type < b.type ? -1 : 1;
          }
        });
        setProspectDetail(sotedData);
      }
    });
  }, [filterValues]);

  return { prospectDetail };
};
