export const nameElements = [
  {
    type: 'text',
    name: 'registrant_firstName',
    title: {
      default: 'First Name',
      'zh-tw': '名',
    },
    isRequired: true,
    maxLength: 25,
    requiredErrorText: {
      default: 'Field is required',
      'zh-tw': '欄位為必填項',
    },
  },
  {
    type: 'text',
    name: 'registrant_lastName',
    title: {
      default: 'Last Name',
      'zh-tw': '姓',
    },
    isRequired: false,
    maxLength: 25,
  },
];

export const emailElement = {
  type: 'text',
  name: 'registrant_email',
  title: { default: 'Email', 'zh-tw': '電郵地址' },
  placeholder: {
    default: 'Please input',
    'zh-tw': '請輸入',
  },
  requiredErrorText: {
    default: 'Field is required',
    'zh-tw': '欄位為必填項',
  },
  validators: [
    {
      type: 'email',
      text: {
        default: 'Please enter a valid email address',
        'zh-tw': '請輸入正確格式之電郵地址',
      },
    },
  ],
  isRequired: true,
  allowAddPanel: false,
  allowRemovePanel: false,
};

export const phoneElement = {
  type: 'panel',
  name: 'countryCode_phoneNumber',
  title: { default: 'Phone Number', 'zh-tw': '電話號碼' },
  requiredErrorText: {
    default: 'Field is required',
    'zh-tw': '欄位為必填項',
  },
  placeholder: {
    default: 'Please input',
    'zh-tw': '請輸入',
  },
  elements: [
    {
      type: 'dropdown',
      name: 'registrant_countryCode',
      title: {
        default: 'Region',
        'zh-tw': '地區',
      },
      requiredErrorText: {
        default: 'Field is required',
        'zh-tw': '欄位為必填項',
      },
      isRequired: true,
      width: '40%',
      minWidth: '0',
    },
    {
      type: 'text',
      name: 'registrant_phoneNumber',
      startWithNewLine: true,
      title: {
        default: 'Phone',
        'zh-tw': '電話號碼',
      },
      requiredErrorText: {
        default: 'Field is required',
        'zh-tw': '欄位為必填項',
      },
      validators: [
        {
          type: 'expression',
          text: {
            default: 'Invalid phone number',
            'zh-tw': '無效的電話號碼',
          },
          expression: 'phoneNumberValidate({registrant_countryCode}, {registrant_phoneNumber})',
        },
      ],
      isRequired: true,
      width: '60%',
      minWidth: '0',
    },
  ],
  isRequired: true,
  allowAddPanel: false,
  allowRemovePanel: false,
  showNumber: true,
  showQuestionNumbers: 'off',
};

export const tncElement = {
  type: 'panel',
  name: 'registrant_terms_panel',
  title: { default: 'Personal Information Collection Statement', 'zh-tw': 'Personal Information Collection Statement' },
  description: {
    default:
      '[Please insert Agent’s Full Name](“Data User”) may use the personal information collected, including your name, contact number and e-mail address (“Personal Information”) collected above, for the purpose of training recruitment and related purposes (for example recruitment follow-up calls), activity record, follow up and future trainings. The Data User may use, store, disclose to Prudential Hong Kong Limited and its insurance agents your Personal Information for the purposes as stated above. You understand it is mandatory to provide the abovementioned Personal Information. In the event that any such Personal Information is not provided, the Data User may be unable to carry out the activities outlined above. The Personal Information collected will be destroyed after 12 months. Under the Personal Data (Privacy) Ordinance (“the Ordinance”), you have the right to request access to and correction of any Personal Information that you provided to the Data User. You may make such request by writing to the data user at [Please insert Agent’s company email address]. In accordance with the Ordinance, the Data User has the right to charge a reasonable fee for the processing of your request for access to the Personal Information.',
    'zh-tw':
      '[Please insert Agent’s Full Name](“Data User”) may use the personal information collected, including your name, contact number and e-mail address (“Personal Information”) collected above, for the purpose of training recruitment and related purposes (for example recruitment follow-up calls), activity record, follow up and future trainings. The Data User may use, store, disclose to Prudential Hong Kong Limited and its insurance agents your Personal Information for the purposes as stated above. You understand it is mandatory to provide the abovementioned Personal Information. In the event that any such Personal Information is not provided, the Data User may be unable to carry out the activities outlined above. The Personal Information collected will be destroyed after 12 months. Under the Personal Data (Privacy) Ordinance (“the Ordinance”), you have the right to request access to and correction of any Personal Information that you provided to the Data User. You may make such request by writing to the data user at [Please insert Agent’s company email address]. In accordance with the Ordinance, the Data User has the right to charge a reasonable fee for the processing of your request for access to the Personal Information.',
  },
  elements: [
    {
      type: 'boolean',
      name: 'registrant_terms',
      title: {
        default:
          'I have read and agree to the [Personal Information Collection Statement](https://www.prudential.com.hk/privacy)',
        'zh-tw':
          'I have read and agree to the [Personal Information Collection Statement](https://www.prudential.com.hk/privacy)',
      },
      isRequired: true,
      requiredErrorText: {
        default: 'Field is required',
        'zh-tw': '欄位為必填項',
      },
      validators: [
        {
          type: 'expression',
          text: {
            default: 'Please accept the TnC before registering this event',
            'zh-tw': 'Please accept the TnC before registering this event',
          },
          expression: '{registrant_terms} = true',
        },
      ],
    },
  ],
};
