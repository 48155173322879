import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './insight-filter.style';
import { useInsightFilter } from './insight-filter.hook';
import {
  ListFilterComponent,
  ListFilterConfig,
  ListFilterData,
} from 'src/app/common/components/list-filter/list-filter.component';

type ComponentProps = ParamsProps & {
  width: number | 'auto';
  filterConfigs: Array<ListFilterConfig>;
  filterValues: ListFilterData;
  defaultValues?: ListFilterData;
  onConfirm: (filterState: ListFilterData) => void;
  onFilterSelect?: (filterState: ListFilterData) => void;
};

export const InsightFilterComponent: React.FC<ComponentProps> = memo(
  ({ width = 'auto', filterConfigs, filterValues, onConfirm, onFilterSelect, defaultValues }: ComponentProps) => {
    // custom-hook
    const { itemText, openFilter, setOpenFilter } = useInsightFilter({ value: filterValues, configs: filterConfigs });
    return (
      <>
        <Box width={width}>
          <Button
            variant="outlined"
            fullWidth
            sx={btnStyle}
            endIcon={openFilter ? <ExpandMoreIcon sx={{ rotate: 180 }} /> : <ExpandMoreIcon />}
            onClick={() => setOpenFilter(true)}
          >
            {itemText.join(', ')}
          </Button>
        </Box>
        {openFilter ? (
          <ListFilterComponent
            open={openFilter}
            filterValues={filterValues}
            defaultValues={defaultValues}
            filterConfigs={filterConfigs}
            onClose={() => setOpenFilter(false)}
            onConfirm={(value) => {
              onConfirm(value);
              setOpenFilter(false); // close filter
            }}
            onFilterSelect={onFilterSelect}
          />
        ) : null}
      </>
    );
  },
);

const btnStyle = {
  // height: 32, // do not fixed height
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  borderColor: '#F0F0F0',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '20px',
};
