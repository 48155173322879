import { makeStyles } from 'tss-react/mui';

export const useAddAgentsStyles = makeStyles()(() => ({
  addAgentButton: {
    width: '100%',
    padding: 16,
    border: '1px dashed #CCCCCC',
    fontSize: 16,
    columnGap: 8,
  },
  removeAgentDialogPaper: {
    width: 300,
    maxWidth: 300,
  },
  removeAgentDialogActions: {
    padding: 18,
  },
}));
