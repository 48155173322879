import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 24px 0',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
  },
  closeIcon: {
    fontSize: 32,
    color: 'black',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
  },
  buttonContainer: {
    display: 'flex',
    alignItmes: 'center',
    columnGap: 12,
  },
  contentContainer: {
    padding: '0 24px 24px',
  },
}));
