import { Dispatch } from 'react';
import { getQueryPath } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { apiClient, apiErrorHandler, AGENT_URL, MY_AGENT_ENDPOINT } from 'src/app/common/network';
import { EventCategoryItem } from '../types';
import { eventCategoryEndpoint } from './constants';

export type EventCategoryListParam = {
  applicant?: string;
  accessibility?: string;
  audience?: string;
  status?: string;
  pagination?: boolean;
  page?: number;
  limit?: number;
  sort?: string;
};

export const fetchEventCategoryList = async (
  params: EventCategoryListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<EventCategoryItem>> => {
  const queryPath = getQueryPath(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventCategoryEndpoint}?`, params);
  return apiClient
    .get<PaginateList<EventCategoryItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEventCategoryItem = async (id: string, dispatch?: Dispatch<any>): Promise<EventCategoryItem> => {
  return apiClient
    .get<EventCategoryItem>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventCategoryEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
