export const firebaseConfig = {
apiKey: "AIzaSyCtkjtmcHHjaeqF64BklE8wuJrvqshpi34",
  authDomain: "coe-pruforce-sit.firebaseapp.com",
  projectId: "coe-pruforce-sit",
  storageBucket: "coe-pruforce-sit.appspot.com",
  messagingSenderId: "629758163605",
  appId: "1:629758163605:web:de105b597b3f0088f6f99c",
  measurementId: "G-4PKHT1R545"

};
