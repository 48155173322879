import { makeStyles } from 'tss-react/mui';

const TABLE_SELECT_FIELD_WIDTH = 220;

export const useRegistrationAttendanceStyles = makeStyles()(() => ({
  remarkMargin: {
    marginTop: 8,
    marginBottom: 14,
  },
  tableSelect: {
    width: TABLE_SELECT_FIELD_WIDTH,
    backgroundColor: 'white',
  },
}));
