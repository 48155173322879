import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Box, IconButton, Divider } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import styles from './view-log.module.css';
import { useViewLog } from './view-log.hook';
import AudioPlayer from '../audio-player/audio-player.component';
import { TalkbotDialogueDetailItem } from 'src/app/modules/ai-talkbot/types/talkbot-export-history-types';
import moment from 'moment';

interface ComponentProps {
  open: boolean;
  onClose: () => void;
  id: number;
}

export const ViewLogComponent: React.FC<ComponentProps> = ({ open, onClose, id }) => {
  // i18n
  const { t } = useTranslation();

  // custom-hook
  const { loading, audio, transcript, getRoleLabel } = useViewLog({ id });

  const renderMessage = (message: TalkbotDialogueDetailItem) => {
    if (!message.text) return null;
    const isBot = message.role === 'robot';
    return (
      <Box key={message.text} className={isBot ? styles.botMessage : styles.userMessage}>
        <div className={isBot ? styles.botRoleLabel : styles.userRoleLabel}>
          {getRoleLabel(message.role)}
          <span className={styles.timeLabel}>{moment(message.startTime).format('HH:mm:ss')}</span>
        </div>
        <Typography className={isBot ? styles.botMessageContent : styles.userMessageContent}>{message.text}</Typography>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className={styles.dialogTitle}>
        {t('talkbot.dialog.viewLog.title')}
        <IconButton onClick={onClose} className={styles.closeButton} size="small">
          <Close />
        </IconButton>
      </DialogTitle>
      <Box className={styles.dialogInner}>
        <Box className={styles.audioSection}>{!loading && audio && <AudioPlayer audio={audio} />}</Box>
        <Divider className={styles.divider} />
        <Box className={styles.conversationSection}>
          {loading ? (
            <Typography>{t('common.loading')}</Typography>
          ) : (
            <Box className={styles.messageContainer}>{transcript?.map(renderMessage)}</Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
