import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useRequest } from 'ahooks';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { getDialogueList, getExportLeadsOverview, getTalkbotUnread } from '../../../network/talkbot-export-leads-crud';
import { DialogueItemType } from '../../../types/talkbot-export-leads-types';

export const useLanding = () => {
  const { t } = useTranslation();
  const [curDialogue, setCurDialogue] = useState<DialogueItemType>();

  // redux
  const dispatch = useDispatch();

  const { data: overviewData, loading } = useRequest(() => getExportLeadsOverview(dispatch), { manual: false });
  const { data: dialogueList, loading: dialogueLoading } = useRequest(() => getDialogueList(dispatch), {
    manual: false,
    onSuccess: (res) => {
      setCurDialogue(res[0]);
    },
  });

  const { data: talkbotUnreadList } = useRequest(async () => getTalkbotUnread(dispatch), {
    manual: false,
  });

  return {
    loading,
    dialogueLoading,
    overviewData,
    talkbotUnreadList,
    dialogueList,
    curDialogue,
    setCurDialogue,
  };
};
