import React, { memo, useCallback } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Grid, Tooltip } from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';

import styles from './insight-accordion.module.css';
import { get } from 'lodash';
import { TooltipComponent } from 'src/app/common/components/tooltip/tooltip.component';
import { useHistory } from 'react-router-dom';

/**
 * data example:
 * title = { name: 'Example Title', subName: 'Example Subtitle', nameCount: 5 };
 * data = {
    header: [{ value: 'Column 1' }, { value: 'Column 2' }, { value: 'Column 3', tooltip: 'this is a tip' },],
    body: [
      {
        parent: { value: ['Value 1', 123, '33lll (20%)'], link: './lead-status-detail' },
        child: [
          { value: ['Child Value 1', 456, 789], link: './child-detail-link' },
          { value: ['Child Value 2', 333, 444] },
        ],
      },
      {
        parent: { value: ['Value 2', 123, '123 (50%)'], link: './lead-status-detail' },
      }]
 */
type RowData = { value: Array<string | number>; link?: string; tooltip?: string };
export type AccordionProps = {
  title?: {
    name: string;
    subName?: string;
    nameCount?: number; // count beside the name
    hideTotalCount?: boolean;
    totalCount?: number; // count on the right-side
    dotColor?: string;
  };
  data: {
    header?: Array<{ value: string; tooltip?: string }>;
    body: Array<{ parent: RowData; child?: Array<RowData> }>; // parent & child array must have same-number-of-columns as header array
  };
  disabledClick?: boolean;
};
export const InsightAccordionComponent: React.FC<AccordionProps> = memo(
  ({ title, data, disabledClick }: AccordionProps) => {
    // how many columns
    const columnLength = get(data.body, '[0].parent.value.length');
    const gridSize = 12 / columnLength; // Grid: total 12 by default

    const history = useHistory();

    const handlePress = useCallback((link: string | undefined) => {
      if (link && !disabledClick) {
        history.push(link);
      }
    }, []);

    return (
      <div className={styles.wrap}>
        {title ? (
          <>
            <div className={styles.titleWrap}>
              <div className={styles.title}>
                {title.dotColor ? (
                  <Brightness1Icon className={styles.dot} fontSize="small" htmlColor={title.dotColor} />
                ) : null}
                <span>{title.name}</span>
                <span className={styles.nameCount}>{title.nameCount}</span>
              </div>
              {!title.hideTotalCount && <span>{title.totalCount ?? 0}</span>}
            </div>
            {title.subName ? <span className={styles.subName}>{title.subName}</span> : null}
          </>
        ) : null}

        <div className={styles.accordionWrap}>
          {/** render header */}
          {Array.isArray(data.header) ? (
            <Accordion className={styles.accordion} expanded={false}>
              <AccordionSummary style={{ cursor: 'default' }} className={styles.accordionSummary} expandIcon={null}>
                <Grid container>
                  {data.header.map((header, index) => {
                    return (
                      <Grid
                        item
                        xs={gridSize}
                        key={index}
                        className={index === columnLength - 1 ? styles.headerItemLast : styles.headerItem}
                      >
                        <Typography>
                          {header.value}
                          {header.tooltip ? <TooltipComponent title={header.tooltip} /> : null}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </AccordionSummary>
            </Accordion>
          ) : null}
          {/** render body */}
          {data.body.map((body, index) => {
            const { parent, child } = body;
            // const ariaControls = child ? `content-panel-${index}` : '';
            // const summaryId = `header-panel-${index}`;

            // disabled Accordion expand if no child
            const expanded = !child ? { expanded: false } : {};
            return (
              <Accordion key={index} className={index === 0 ? styles.headerAccordion : styles.accordion} {...expanded}>
                <AccordionSummary
                  style={{ cursor: parent.link ? 'pointer' : 'default' }}
                  className={styles.accordionSummary}
                  expandIcon={child ? <ExpandMoreIcon /> : null}
                >
                  <Grid
                    container
                    onClick={() => handlePress(parent.link)}
                    sx={{ cursor: parent.link ? 'pointer' : 'default' }}
                  >
                    {parent.value.map((value, index) => {
                      return (
                        <Grid
                          item
                          xs={gridSize}
                          key={index}
                          className={index === columnLength - 1 ? styles.parentItemLast : styles.parentItem}
                        >
                          <Typography>
                            {value} {parent.tooltip && index === 0 ? <TooltipComponent title={parent.tooltip} /> : null}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </AccordionSummary>
                {/** render children */}
                <AccordionDetails className={styles.accordionDetails}>
                  {child &&
                    child.map((childElem) => {
                      return (
                        <Grid
                          container
                          className={childElem.link ? styles.pointer : ''}
                          onClick={() => handlePress(childElem.link)}
                        >
                          {childElem.value.map((childItem, index) => {
                            return (
                              <Grid
                                item
                                xs={gridSize}
                                key={index}
                                className={index === columnLength - 1 ? styles.childItemLast : styles.childItem}
                              >
                                <Typography>{childItem}</Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    );
  },
);
