import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    height: '100%',
    paddingTop: 40,
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 18,
  },
  component: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  hidden: {
    display: 'none',
  },
}));
