import moment, { Moment } from 'moment';
import { DateFormat, formatDate } from 'src/app/common/utils';
import { DataSum, GraphDayItem, GraphMonthItem, GraphWeekItem } from 'src/app/modules/Insight/types/types';
import { TimeRange } from '../chart-button/chart-button.component';

export const getWeeksIncludeToday = (fromDate: string, currentDate?: Moment) => {
  const startDate = moment(fromDate);
  currentDate = currentDate ?? moment();
  const currentYear = currentDate.year();
  const currentWeek = currentDate.isoWeek();
  const dates = [];

  let year = startDate.year();
  let week = startDate.isoWeek();

  while (year <= currentYear) {
    const monday = moment().year(year).isoWeek(week).startOf('isoWeek');
    const sunday = moment(monday).endOf('isoWeek');

    dates.push({
      year,
      week,
      monday,
      sunday,
    });

    if (year === currentYear && week === currentWeek) {
      break;
    }
    const nextWeek = week + 1;
    if (nextWeek > moment(`${year}-12-31`).isoWeeksInYear()) {
      year++;
      week = 1;
    } else {
      week = nextWeek;
    }
  }

  return dates;
};

export const getMonthsIncludeToday = (fromDate: string) => {
  let startDate = moment(fromDate);
  let year = moment(startDate).year();
  const currentYear = moment().year();
  const currentMonth = moment().month() + 1;
  const dates = [];

  while (year <= currentYear) {
    let month = 1;
    const monthsCount = 12;
    while (month <= monthsCount) {
      dates.push({
        year,
        month,
      });

      if (year === currentYear && month === currentMonth) {
        break;
      }

      month++;
    }
    year++;
  }

  return dates;
};

//A leap year is divisible by 4 (not divisible by 100), or it must be divisible by 100 and also divisible by 400. Otherwise, it is a common year.
export function isLeapYear(year: number) {
  if (year % 4 === 0) {
    if (year % 100 === 0) {
      if (year % 400 === 0) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

//Get the number of days in a specific month of a given year
export const findDayByYearMonth = (year: number, month: number) => {
  switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      return 31;
    case 2:
      return isLeapYear(year) ? 29 : 28;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    default:
      return 30;
  }
};

export const transformMonthData = (data: GraphMonthItem[], startYear: number, today?: Date) => {
  // Calculate from ${startYear}-01 to the last month
  let monthOneBarData = []; // Storage data required for monthly storage, single column chart and single line chart

  today = today ?? new Date();
  for (let year = startYear; year <= today.getFullYear(); year++) {
    // This loop is used to retrieve the sum of data for all categories in each month, and is used for a single column.
    for (let month = 1; month <= 12; month++) {
      if (year === today.getFullYear() && month > today.getMonth() + 1) {
        // Loop, get all the months from ${startYear}-01 to the last month of the current year (including the current month)
        break;
      }
      let count = 0;

      var items = data.filter((item) => item.year === year && item.month === month); //根据返回内容，匹配出当前月份的数据
      var sum = items.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.count;
      }, 0);
      count = count + sum; // Add all data matching the current month

      let monthItem = { x: [moment({ year, month: month - 1 }).toISOString(), ''], y: count };
      monthOneBarData.push(monthItem);
    }
  }
  return monthOneBarData;
};

export const transformWeekData = (data: GraphWeekItem[], fromDate: string, currentDate?: Moment) => {
  // Count from the first week of ${fromDate} to the last week
  let monthOneBarData: any[] = []; // Storage data required by week, single column chart and single line chart

  const weeksDates = getWeeksIncludeToday(fromDate, currentDate);
  weeksDates.forEach((date) => {
    let count = 0;
    var items = data.filter((item) => item.year === date.year && item.week === date.week); // Based on the returned content, match the data of the current month

    var sum = items.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue.count;
    }, 0);
    count = count + sum; // Add all data matching the current month

    let weekItem = { x: [date.monday.toISOString(), date.sunday.toISOString()], y: count };
    monthOneBarData.push(weekItem);
  });

  return monthOneBarData;
};

export const transformDayData = (data: GraphDayItem[], startYear: number, today?: Date) => {
  // Calculate from ${startYear}-01 to the previous month
  let dayOneBarData = []; // Storage data required by day, single column chart and single line chart
  today = today ?? new Date();

  for (let year = startYear; year <= today.getFullYear(); year++) {
    // This loop is used to extract the sum of data of all categories for each month, and is used for a single column.
    const isCurrentYear = year === today.getFullYear();
    for (let month = 1; (isCurrentYear && month <= today.getMonth() + 1) || (!isCurrentYear && month <= 12); month++) {
      let isBreak = false;
      for (let day = 1; day <= findDayByYearMonth(year, month); day++) {
        if (year === today.getFullYear() && month - 1 === today.getMonth() && day === today.getDate() + 1) {
          // Loop, get ${startYear}-01 to today (including the current day
          isBreak = true;
          break;
        }
        let count = 0;
        var items = data.filter((item) => item.year === year && item.month === month && item.day === day); // Based on the returned content, match the data of the current date

        var sum = items.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.count;
        }, 0);
        count = count + sum; // Add all data matching the current month

        let dayItem = { x: [moment({ year, month: month - 1, day }).toISOString(), ''], y: count };
        dayOneBarData.push(dayItem);
      }
      if (isBreak) {
        break;
      }
    }
  }

  return dayOneBarData;
};

export const formatLabel = ([date1, date2]: [string, string], timeRange: TimeRange) => {
  switch (timeRange) {
    case TimeRange.day:
      return formatDate(date1, DateFormat.monthDay);
    case TimeRange.week:
      let janMonth = moment(date1).month();
      let sunMonth = moment(date2).month();

      if (janMonth === sunMonth) {
        return `${formatDate(date1, DateFormat.monthDay)}-${formatDate(date2, DateFormat.day)}`;
      } else {
        return `${formatDate(date1, DateFormat.monthDay)}-${formatDate(date2, DateFormat.monthDay)}`;
      }
    case TimeRange.month:
      return formatDate(date1, DateFormat.monthYear);
  }
};

/**
 * Sums the counts for the same date and preserves other properties.
 * example:
 * input: [ [{"count": 2, date: "2023-05-16"},{"count": 10,date: "2023-05-17"}], [{"count": 3,date: "2023-05-16"}] ]
 * output: [{"count": 5,date: "2023-05-16"}, {"count": 10, date: "2023-05-17"}]
 * @param data - The 2D array of data with count and date properties.
 * @returns An array of objects with the summed counts for each unique date, preserving other properties.
 */
export function sumCountsByDate(data: DataSum[][]): DataSum[] {
  const resultMap: { [date: string]: DataSum } = {};

  // Iterate through the 2D array and sum the counts for the same date
  for (const arr of data) {
    for (const item of arr) {
      const { count, date, ...rest } = item;
      if (resultMap[date]) {
        resultMap[date].count += count;
      } else {
        resultMap[date] = { count, date, ...rest };
      }
    }
  }

  // Return the resulting array
  return Object.values(resultMap);
}
