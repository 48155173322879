import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Typography } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './prospect-type-list.module.css';
import { useLang } from 'src/app/i18n';
import { Card } from 'src/app/common/components/card/card.component';
import { getI18nData } from 'src/app/common/utils/i18n.util';
import { useSelector } from 'react-redux';
import { InsightSession } from '../../../types/types';
import { PermissionRole } from 'src/app/modules/Auth/types/auth-types';
import { useHistory } from 'react-router-dom';
import { INSIGHT_PROSPECT_TYPE_PATH } from '../../../constants';
import { getDisplayValue } from '../util/prospect-detail.util';
import { globalStore } from 'src/app/common/helpers/global-store.util';

type ComponentProps = ParamsProps & {
  data: ProspectType[];
  listType?: ListType;
};

type ProspectType = {
  name: string;
  type: string;
  count: number | string;
  targetNumber: number;
  backgroundColorLabel: string;
  colorLabel: string;
};

export enum ListType {
  ALL = 'all',
  NEW_ADD = 'newAdd',
}

export const ProspectTypeList: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const history = useHistory();
  const { t } = useTranslation();
  const locale = useLang();
  const { data, listType = ListType.ALL } = props;
  const { user } = useSelector((state: any) => state.auth);
  const currentAgentCode =
    sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode();

  const goToDetail = (prospect: any) => {
    const { agentCode, role } = user;
    if (role !== PermissionRole.MANAGER || (role === PermissionRole.MANAGER && currentAgentCode === agentCode)) {
      history.push(`${INSIGHT_PROSPECT_TYPE_PATH}/${listType}/${prospect._id}`, {
        prospect: prospect,
      });
    }
  };

  return (
    <div className={styles.detailWrap}>
      <Card className={styles.wrapChart}>
        <div className={styles.titleBox}>
          <Typography className={styles.title}>{t('insights_type')}</Typography>
          <Typography className={styles.title}>
            {listType === ListType.ALL ? t('insights_completed_target') : t('insights_new_added')}
          </Typography>
        </div>
        {data.map((item, index) => (
          <div
            className={styles.detail}
            onClick={() => {
              goToDetail(item);
            }}
          >
            <div className={styles.flexRowCenter}>
              <div className={styles.tag} style={{ backgroundColor: item.backgroundColorLabel }}>
                <Typography color={item.colorLabel} fontWeight={600}>
                  {item.type !== 'None' ? item.type : '?'}
                </Typography>
              </div>
              <Typography fontSize={18} fontWeight={600}>
                {getI18nData(item.name, locale)}
              </Typography>
            </div>
            {listType === ListType.ALL && <Typography fontSize={18}>{getDisplayValue(item, listType)}</Typography>}
            {listType === ListType.NEW_ADD && <Typography fontSize={18}>{getDisplayValue(item, listType)}</Typography>}
          </div>
        ))}
      </Card>
    </div>
  );
});
