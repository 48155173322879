import { get, set } from 'lodash';
import moment from 'moment';
import { Dispatch } from 'redux';
import {
  transformDayData,
  transformMonthData,
  transformWeekData,
} from 'src/app/common/components/charts/util/format-response.util';
import { getMassLeadGraph } from 'src/app/modules/Insight/network/insightCrud';

enum CHART_TYPE {
  BAR = 'bar',
  LINE = 'line',
}

enum DATE_TYPE {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export interface getMassLeadGraphParams {
  agentCode: string;
  dateType: string;
  chartType: string;
  startDate?: string;
  endDate?: string;
}

export const getMassLeadGraphData = async (params: getMassLeadGraphParams, dispatch?: Dispatch) => {
  const status = 'accepted';
  const { agentCode, dateType, chartType, startDate, endDate } = params;
  const body = {
    agentCode,
    dateType,
    chartType,
    status,
  };

  const res = await getMassLeadGraph(body, dispatch);

  if (res) {
    const { data } = res;
    switch (dateType) {
      case DATE_TYPE.DAY: {
        return transformDayData(data, moment(startDate).year());
      }
      case DATE_TYPE.WEEK: {
        return transformWeekData(data, startDate as string);
      }
      case DATE_TYPE.MONTH: {
        return transformMonthData(data, moment(startDate).year());
      }
      default:
        return [];
    }
  }
  return [];
};
