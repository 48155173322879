import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, Typography } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './sla-view.style';
import { useSlaView } from './sla-view.hook';
import { Card } from 'src/app/common/components/card/card.component';
import { MarketingLeadSLA } from 'src/app/modules/Insight/types/marketing-lead.types';

type ComponentProps = ParamsProps & {
  data: MarketingLeadSLA;
  /* otherProp: string */
};

type ItemProps = {
  title: string;
  value: string | number;
};
const Item = (props: ItemProps) => {
  const { title, value } = props;
  return (
    <div className="tw-flex tw-flex-row tw-w-44 tw-justify-between">
      <Typography fontWeight={500} fontSize={'14px'} lineHeight={'22px'} color={'#999999'}>
        {title}
      </Typography>
      <Typography fontWeight={700} fontSize={'18px'} lineHeight={'22px'} color={'#333333'}>
        {value}
      </Typography>
    </div>
  );
};
const SubTitle = (props: ItemProps) => {
  const { title, value } = props;
  return (
    <div className="tw-flex-1">
      <Typography fontWeight={500} fontSize={'14px'} lineHeight={'20px'} color={'#999999'}>
        {title}
      </Typography>
      <Typography fontWeight={700} fontSize={'30px'} lineHeight={'37px'}>
        {value}
      </Typography>
    </div>
  );
};

const getRate = function (a: number, b: number, c: number) {
  if (a == 0 && b == 0 && c == 0) return '-';
  else return Number((a / (a + b + c)) * 100).toFixed(0) + '%';
};

export const SlaViewComponent: React.FC<ComponentProps> = memo(({ data }) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  return (
    <div className="tw-flex tw-flex-row tw-space-x-4 tw-w-full">
      <Card className="tw-p-6 tw-w-full">
        <Typography fontWeight={600} fontSize={'24px'} lineHeight={'32px'}>
          {Translation('insight.marketing_lead.acceptSLA')}
        </Typography>

        <div className="tw-flex tw-flex-row tw-mt-5">
          <SubTitle
            title={Translation('insight.marketing_lead.totalAmount')}
            value={data.acceptAchieved + data.acceptExpired + data.acceptMissed + data.acceptPending}
          />
          <SubTitle
            title={Translation('insight.marketing_lead.ratio')}
            value={getRate(data.acceptAchieved, data.acceptMissed, data.acceptExpired)}
          />
        </div>
        <div className={styles.divider} />

        <div className="tw-space-y-4 tw-mt-6">
          <Item title={Translation('insight.marketing_lead.achieved')} value={data?.acceptAchieved ?? '0'}></Item>
          <Item title={Translation('insight.marketing_lead.missed')} value={data?.acceptMissed ?? '0'}></Item>
          <Item title={Translation('insight.marketing_lead.pending')} value={data?.acceptPending ?? '0'}></Item>
          <Item title={Translation('insight.marketing_lead.expired')} value={data?.acceptExpired ?? '0'}></Item>
        </div>
      </Card>

      <Card className="tw-p-6 tw-w-full">
        <Typography fontWeight={600} fontSize={'24px'} lineHeight={'32px'}>
          {Translation('insight.marketing_lead.contactSLA')}
        </Typography>

        <div className="tw-flex tw-flex-row tw-mt-5">
          <SubTitle
            title={Translation('insight.marketing_lead.totalAmount')}
            value={data.contactAchieved + data.contactExpired + data.contactMissed + data.contactPending}
          />
          <SubTitle
            title={Translation('insight.marketing_lead.ratio')}
            value={getRate(data.contactAchieved, data.contactMissed, data.contactExpired)}
          />
        </div>
        <div className={styles.divider} />

        <div className="tw-space-y-4 tw-mt-6">
          <Item title={Translation('insight.marketing_lead.achieved')} value={data?.contactAchieved ?? '0'}></Item>
          <Item title={Translation('insight.marketing_lead.missed')} value={data?.contactMissed ?? '0'}></Item>
          <Item title={Translation('insight.marketing_lead.pending')} value={data?.contactPending ?? '0'}></Item>
          <Item title={Translation('insight.marketing_lead.expired')} value={data?.contactExpired ?? '0'}></Item>
        </div>
      </Card>
    </div>
  );
});
