import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  header: {
    color: '#3F4254',
    fontSize: 14,
    fontWeight: 600,
  },
  copyButton: {
    color: '#4386E5',
    marginLeft: 20,
    columnGap: 4,
  },
  copyIcon: {
    fontSize: 20,
    height: 20,
    marginTop: -15,
  },
}));
