import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { useLang } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { Agent } from 'src/app/common/types';
import { getDisplayName } from 'src/app/common/utils';
import PruIcon from 'src/app/common/components/PruIcon';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { UniversalSelectorDialogComponent } from 'src/app/common/components/universal-selector-dialog';
import { AddTargetAgentsEnum } from 'src/app/modules/event-v2/types';
import { AddAgentFromListDialog } from './add-agent-from-list-dialog';
import { ImportAgentFromFileDialog } from './import-agent-from-file-dialog';
import { useAddAgentsStyles } from './add-agents.style';
import { useAddAgents, HookProps } from './add-agents.hook';

type AddAgentsProps = HookProps & {
  disabled?: boolean;
  error?: boolean;
};

export const AddAgents: FC<AddAgentsProps> = ({ disabled, error, initialAgentCodeList, onChange }) => {
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useAddAgentsStyles();
  const {
    isLoading,
    showSelectOptionDialog,
    showAddAgentDialog,
    selectedOption,
    agentList,
    removeAgentDialogState,
    setShowSelectOptionDialog,
    setShowAddAgentDialog,
    setSelectedOption,
    setRemoveAgentDialogState,
    handleRemoveAgent,
    handleAddAgent,
    handleImportAgent,
  } = useAddAgents({ initialAgentCodeList, onChange });

  return (
    <>
      <UniversalSelectorDialogComponent
        title={Translation('event.form.add_target_agents')}
        open={showSelectOptionDialog}
        options={[
          { label: Translation('common.add_from_list'), value: AddTargetAgentsEnum.ADD_FROM_LIST },
          { label: Translation('common.import_from_file'), value: AddTargetAgentsEnum.IMPORT_FROM_FILE },
        ]}
        onClose={() => {
          setShowSelectOptionDialog(false);
        }}
        onConfirm={(row, index) => {
          setSelectedOption(row[index].value);
          setShowSelectOptionDialog(false);
          setShowAddAgentDialog(true);
        }}
      />
      {showAddAgentDialog && (
        <>
          {selectedOption === AddTargetAgentsEnum.ADD_FROM_LIST && (
            <AddAgentFromListDialog
              defaultSelected={agentList}
              open={showAddAgentDialog}
              onClose={() => setShowAddAgentDialog(false)}
              onAddAgent={handleAddAgent}
            />
          )}
          {selectedOption === AddTargetAgentsEnum.IMPORT_FROM_FILE && (
            <ImportAgentFromFileDialog
              open={showAddAgentDialog}
              onClose={() => setShowAddAgentDialog(false)}
              onImportAgent={handleImportAgent}
            />
          )}
        </>
      )}
      {removeAgentDialogState.open && (
        <Dialog open={removeAgentDialogState.open} classes={{ paper: classes.removeAgentDialogPaper }}>
          <DialogTitle>{Translation('common.remove_agent')}</DialogTitle>
          <DialogContent>{Translation('common.remove_agent_reminder')}</DialogContent>
          <DialogActions className={classes.removeAgentDialogActions}>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => setRemoveAgentDialogState({ ...removeAgentDialogState, open: false })}
            >
              {Translation('app.button.cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => handleRemoveAgent()}>
              {Translation('app.button.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {(isLoading || agentList.length > 0) && (
        <PruTable
          hideListTitleRow
          disablePagination
          disableBulkSelect={disabled}
          indeterminateSelectAll={false}
          headerBtnDef={[]}
          operationDef={[]}
          columnDef={[
            {
              keyIndex: 'unit',
              displayName: Translation('common.agent.unit'),
              renderData: (row) => row.unit,
            },
            {
              keyIndex: 'name',
              displayName: Translation('common.agent.business_name'),
              renderData: (row) => getDisplayName(row, locale),
            },
            {
              isId: true,
              keyIndex: 'agentCode',
              displayName: Translation('common.agent.agent_code'),
              renderData: (row) => row.agentCode,
            },
            {
              keyIndex: 'designation',
              displayName: Translation('common.agent.designation_code'),
              renderData: (row) => row.designation.code,
            },
          ]}
          bulkSelectDef={[
            {
              title: Translation('app.button.remove'),
              color: 'secondary',
              onClick: (rows, onResetRowSelected) =>
                setRemoveAgentDialogState({
                  ...removeAgentDialogState,
                  open: true,
                  agentCodeList: rows.map((item: Agent) => item.agentCode),
                  onResetRowSelected,
                }),
            },
          ]}
          isLoading={isLoading}
          dataSource={agentList}
        />
      )}
      <div>
        <Button
          disabled={disabled}
          className={classes.addAgentButton}
          variant="outlined"
          onClick={() => setShowSelectOptionDialog(true)}
        >
          <PruIcon icon="instruction_add" style={{ fontSize: 20 }} />
          {Translation('event.form.add_target_agents')}
        </Button>
        {error && <FormHelperText error={error}>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
      </div>
    </>
  );
};
