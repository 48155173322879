import { Dispatch } from 'react';
import { getQueryPath } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { apiClient, apiErrorHandler, AGENT_URL, MY_AGENT_ENDPOINT } from 'src/app/common/network';
import { EventAssistantItem } from '../types';
import { eventEndpoint, eventAssistantEndpoint } from './constants';

export type EventAssistantListParam = {
  page: number;
  limit: number;
};

export type CreateUpdateEventAssistantBody = {
  name?: string;
  email?: string;
};

export type SendLinkBody = {
  langCode: string;
  assistantIds: string[];
};

export const fetchEventAssistantList = async (
  eventId: string,
  params: EventAssistantListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<EventAssistantItem>> => {
  const queryPath = getQueryPath(
    `${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/${eventId}/${eventAssistantEndpoint}?`,
    params,
  );
  return apiClient
    .get<PaginateList<EventAssistantItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createEventAssistantItem = async (
  eventId: string,
  body: CreateUpdateEventAssistantBody,
  dispatch?: Dispatch<any>,
): Promise<EventAssistantItem> => {
  return apiClient
    .post<EventAssistantItem>(
      `${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/${eventId}/${eventAssistantEndpoint}`,
      body,
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err?.errors?.[0] || err, dispatch);
    });
};

export const modifyEventAssistantItem = async (
  eventId: string,
  id: string,
  body: CreateUpdateEventAssistantBody,
  dispatch?: Dispatch<any>,
): Promise<EventAssistantItem> => {
  return apiClient
    .patch<EventAssistantItem>(
      `${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/${eventId}/${eventAssistantEndpoint}/${id}`,
      body,
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err?.errors?.[0] || err, dispatch);
    });
};

export const sendLink = async (eventId: string, body: SendLinkBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(
      `${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/${eventId}/${eventAssistantEndpoint}/send-email`,
      body,
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteEventAssistantItem = async (
  eventId: string,
  id: string,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .delete<void>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/${eventId}/${eventAssistantEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
