import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { FullScreenDialog } from 'src/app/common/components/Dialog/full-screen-dialog/full-screen-dialog.component';
import { ContactedLeadsComponent } from './components/contacted-leads/contacted-leads.component';
import { NewOpportunitiesComponent } from './components/new-opportunities/new-opportunities.component';
import { ColdLeadsComponent } from './components/cold-leads/cold-leads.component';
import { LeadListDialogType } from './types/types';

type ComponentProps = ParamsProps & {
  dialogType?: LeadListDialogType;
  leadList?: Array<any>;
  open: boolean;
  onClose: () => void;
  onLoad?: (type: LeadListDialogType, total?: number) => void;
};

export const LeadListFullScreenDialogComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { dialogType, open, onClose, onLoad } = props;
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const titleMap = {
    [LeadListDialogType.CONTACT_CHECK]: 'lead.contactCheck.header',
    [LeadListDialogType.NEW_OPPORTUNITIES]: 'lead.newOpportunities.header',
    [LeadListDialogType.COLD_LEADS]: 'salesLrmTitle',
  };

  const dialogChildren: React.ReactNode = useMemo(() => {
    if (dialogType === LeadListDialogType.CONTACT_CHECK) {
      return (
        <ContactedLeadsComponent
          onLoad={(record, pageInfo) => {
            onLoad?.(LeadListDialogType.CONTACT_CHECK, pageInfo?.total);
          }}
        />
      );
    } else if (dialogType === LeadListDialogType.NEW_OPPORTUNITIES) {
      return (
        <NewOpportunitiesComponent
          onLoad={(record, pageInfo) => {
            onLoad?.(LeadListDialogType.NEW_OPPORTUNITIES, pageInfo?.total);
          }}
        />
      );
    } else {
      return (
        <ColdLeadsComponent
          onLoad={(record, pageInfo) => {
            onLoad?.(LeadListDialogType.COLD_LEADS, pageInfo?.total);
          }}
        />
      );
    }
  }, [dialogType, onLoad]);

  return (
    <>
      {dialogType ? (
        <FullScreenDialog
          title={Translation(titleMap[dialogType])}
          open={open}
          onClose={onClose}
          children={<Box padding={'24px'}>{dialogChildren}</Box>} // for shadow
          noConfirm
        />
      ) : null}
    </>
  );
});
