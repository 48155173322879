import React, { memo } from 'react';
import { Box } from '@mui/material';
import { usePercentageBarChart } from './percentage-bar-chart.hook';
import { BarItem, DotItem } from './components/bar-item/bar-item.component';

export interface PercentageBarChartProps {
  data: { label?: string; value: number; color: string }[];
  valueToLabel?: (value: number) => string;
}

const BAR_HEIGHT = '12px';
const BAR_MIN_WIDTH = '4px';

export const PercentageBarChart: React.FC<PercentageBarChartProps> = memo((props: PercentageBarChartProps) => {
  // custom-hook
  const { barOptions } = usePercentageBarChart(props);

  return (
    <Box>
      <Box borderRadius={'10px'} height={BAR_HEIGHT} flexDirection={'row'} display={'flex'} overflow={'hidden'}>
        {barOptions.map(({ bgColor, flex }, index) => (
          <React.Fragment key={index}>
            <BarItem bgColor={bgColor} minWidth={BAR_MIN_WIDTH} flex={flex} />
            {index !== barOptions.length - 1 ? <BarItem minWidth={'4px'} flex={0} /> : null}
          </React.Fragment>
        ))}
      </Box>
      <Box marginTop="24px" display={'flex'} flexDirection={'row'}>
        {barOptions.map(({ bgColor, label }, index) => (
          <DotItem
            key={index}
            label={label}
            bgColor={bgColor}
            sx={{ marginRight: index !== barOptions.length - 1 ? '16px' : 0 }}
          />
        ))}
      </Box>
    </Box>
  );
});
