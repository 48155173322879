import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    margin: theme.spacing(1),
    borderRadius: 5,
  },
  error: {
    backgroundColor: 'red',
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
}));
