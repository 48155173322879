import { makeStyles } from 'tss-react/mui';

const CONTENT_PADDING = 24;

export const useStyles = makeStyles()(() => ({
  container: {
    padding: `0 ${CONTENT_PADDING}px ${CONTENT_PADDING}px`,
    overflowY: 'auto',
  },
  addSubSessionButton: {
    width: '100%',
    padding: 16,
    border: '1px dashed #CCCCCC',
    fontSize: 16,
    columnGap: 8,
    backgroundColor: '#F5F5F5',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: 20,
  },
}));
