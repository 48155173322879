import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchInsightSLALead } from '../../network/insightCrud';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { last_30_days_rages } from 'src/app/common/components/list-filter/components/list-filter-date-radio-group/util/list-filter-date-radio.util';
import { FilterComponentsEnum } from 'src/app/common/components/list-filter/filter-components.enum';
import { ListFilterConfig, ListFilterData } from 'src/app/common/components/list-filter/list-filter.component';
import { DateRadioGroupType } from 'src/app/common/components/list-filter/components/list-filter-date-radio-group/list-filter-date-radio-group.component';
import { InsightSession } from '../../types/types';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

export const useMarketingLeadDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<{ startDate: string; endDate: string }>(last_30_days_rages());
  const defaultValues = {
    dateRange: {
      key: 'last_30_days',
      value: {
        startDate: last_30_days_rages().startDate,
        endDate: last_30_days_rages().endDate,
        label: t('last_30_days'),
      },
    },
  };
  const [filterValues, setFilterValues] = useState<ListFilterData>(defaultValues);
  const agentCode = sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) ?? globalStore.getAgentCode();
  const { data, error, loading, run } = useRequest(
    () =>
      fetchInsightSLALead(
        {
          agentCode: agentCode,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        },
        dispatch,
      ),
    {
      manual: true,
    },
  );
  useEffect(() => {
    run();
  }, [dateRange.startDate, dateRange.endDate, run]);

  useEffect(() => {
    setDateRange({
      startDate: (filterValues['dateRange'] as DateRadioGroupType).value.startDate,
      endDate: (filterValues['dateRange'] as DateRadioGroupType).value.endDate,
    });
  }, [filterValues]);

  const filterConfig: Array<ListFilterConfig> = [
    {
      items: [],
      key: 'dateRange',
      title: 'Date',
      type: FilterComponentsEnum.RADIO_GROUP,
    },
  ];

  return {
    loading,
    data,
    filterConfig,
    filterValues,
    setFilterValues,
    defaultValues,
    agentCode,
  };
};
