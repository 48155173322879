import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { MenuList, MenuItem } from '@mui/material';
import { useLang } from 'src/app/i18n';
import PruDialog from 'src/app/common/components/PruDialog';
import { EventCategoryItem } from 'src/app/modules/event-v2/types';
import { useStyles } from './new-event-dialog.style';

type NewEventDialogProps = {
  open: boolean;
  eventCategoryList: EventCategoryItem[];
  onCancel: () => void;
  onConfirm: (selected: string) => void;
};

export const NewEventDialog: FC<NewEventDialogProps> = ({ open, eventCategoryList, onCancel, onConfirm }) => {
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const [selected, setSelected] = useState<string>('');

  return (
    <PruDialog
      open={open}
      disabledConfirm={!selected}
      containerClassName={classes.menuListContainer}
      btnContainerClassName={classes.btnContainer}
      dialogTitle={Translation('event.common.new_event')}
      confirmBtnText={Translation('app.button.next')}
      canncelBtnText={Translation('app.button.cancel')}
      onCancel={onCancel}
      onOk={() => {
        onConfirm(selected);
      }}
    >
      <MenuList className={classes.menuList}>
        {eventCategoryList.map((categoryItem) => (
          <MenuItem
            key={categoryItem._id}
            className={classes.menuItem}
            selected={selected === categoryItem._id}
            onClick={() => {
              setSelected(categoryItem._id);
            }}
          >
            {categoryItem.name[locale]}
          </MenuItem>
        ))}
      </MenuList>
    </PruDialog>
  );
};
