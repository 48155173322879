import { TargetAgentsByEnum, EventAgentConfig } from 'src/app/modules/event-v2/types';

export const initEventAgentConfig: EventAgentConfig = {
  targetAgentBy: TargetAgentsByEnum.UPLOAD_AGENT_LIST,
  eligibleDesignations: [],
  eligibleZones: [],
  eligibleAgents: [],
  excludedAgents: [],
  eligibleAgentListFile: undefined,
  excludedAgentListFile: undefined,
};
