import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './lead-status.module.css';
import { useLeadStatus } from './lead-status.hook';
import { InsightSectionComponent } from '../../../components/insight-section/insight-section.component';
import { InsightFilterComponent } from '../../../components/insight-filter/insight-filter.component';

type ComponentProps = ParamsProps & {
  // agent-code string, split by comma,
  // example: "00010001" or "00010001,00010002,00010003"
  agentCode?: string;
};

export const LeadStatusComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();

  // custom-hook
  const { data, tooltip, filterValues, filterConfig, defaultValues, onFilterConfirm, handlePress } = useLeadStatus({
    agentCode: props.agentCode,
  });
  return (
    <>
      <InsightSectionComponent
        onPress={handlePress}
        rightActions={
          <div className={styles.dateRange}>
            <InsightFilterComponent
              width={250}
              filterConfigs={filterConfig}
              defaultValues={defaultValues}
              filterValues={filterValues}
              onConfirm={onFilterConfirm}
            />
          </div>
        }
        title={t('leads_status')}
        data={data}
        tooltip={tooltip}
      />
    </>
  );
});
