import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { DateRadioGroupType } from 'src/app/common/components/list-filter/components/list-filter-date-radio-group/list-filter-date-radio-group.component';
import { getInsightsProspectList } from '../../../network/insightCrud';
import { getColumns } from '../util/prospect-detail.util';
import { ListType } from '../prospect-type-list/prospect-type-list.component';

interface HookProps {
  typeId: string;
  filterValues: { source?: string; dateRange?: DateRadioGroupType };
  insightType: string;
}

export const useProspectTypeDetail = ({ typeId, filterValues, insightType }: HookProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      getColumns({
        t: t,
      }),
    [],
  );

  const getData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        current?: number | undefined;
        keyword?: string | undefined;
      },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<any> => {
      const { source, dateRange } = filterValues;
      const sourceType = source ? source.replace('all', '') : '';
      let query: any = {
        sourceType: sourceType,
        prospectCategoryId: typeId,
        pageNum: params.page + 1,
        pageSize: params.pageSize,
      };

      if (dateRange && dateRange.value && insightType === ListType.NEW_ADD) {
        const { startDate, endDate } = dateRange.value;

        if (dateRange.key !== 'ALL') {
          query = {
            ...query,
            startDate: startDate,
            endDate: endDate,
          };
        }
      }
      let sortBy = '';
      let flag = '';

      if (sort && Object.keys(sort).length > 0) {
        const [key, val] = Object.entries(sort)[0];

        if (val === 'desc') {
          flag = '-';
        }
        sortBy = `${key}`;
        query['sortBy'] = sortBy;
        query['sortOrder'] = flag === '-' ? -1 : 1;
      }

      const res = await getInsightsProspectList(query, dispatch);
      if (res) {
        const { docs, totalDocs, ...rest } = res;
        return {
          success: true,
          data: docs ?? [],
          total: totalDocs,
          ...rest,
        };
      }

      return { success: false, data: [], total: 0 };
    },
    [filterValues],
  );

  return { getData, columns };
};
