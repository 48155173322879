import React, { FC, useEffect, useRef } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../common/constants';
import { GAData } from '../../../common/ga/redux/gaSlice';
import {
  INSIGHT_APPOINTMENT_PATH,
  INSIGHT_BASE_PATH,
  INSIGHT_HOME_PATH,
  INSIGHT_STATUS_PATH,
  INSIGHT_STATUS_LIST_PATH,
  INSIGHT_MARKETING_PATH,
  INSIGHT_MASS_PATH,
  INSIGHT_COMPANY_PATH,
  INSIGHT_PROSPECT_PATH,
  INSIGHT_SERVICING_PATH,
  INSIGHT_EXPORT_PATH,
  INSIGHT_PROSPECT_TYPE_PATH,
} from '../constants';
import { InsightHomePage } from './insight-home/insight-home.screen';
// import { DemoPage } from './demo/demo.screen';
import { LeadStatusDetailPage } from './lead-status-detail/lead-status-detail.screen';
import { MassLeadDetailPage } from './mass-lead-detail/mass-lead-detail.screen';
import { CompanyAssistedLeadDetailPage } from './company-assisted-lead-detail/company-assisted-lead-detail.screen';
import { ProspectDetailPage } from './prospect-detail/prospect-detail.screen';
import { ServicingCampaignDetailPage } from './servicing-campaign-detail/servicing-campaign-detail.screen';
import { ExportReportPage } from './export-report/export-report.screen';
import { AppointmentPage } from './appointment/appointment.screen';
import { MarketingLeadDetailPage } from './marketing-lead-detail/marketing-lead-detail.screen';
import { insightAction } from '../redux/insight.slice';
import { useDispatch } from 'react-redux';
import { LeadStatusDetailListPage } from './lead-status-detail-list/lead-status-detail-list.screen';
import { ProspectTypeDetailPage } from './prospect-detail/prospect-type-detail/prospect-type-detail.screen';
import { InsightSession } from '../types/types';
import { globalStore } from 'src/app/common/helpers/global-store.util';

const insightHomeGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Insights',
  journey: 'Insight Overview',
  stage: 'Insight Home',
  screen_name: 'Insight Home',
};

const insightLeadStatusDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Insights',
  journey: 'Insight Lead Status',
  stage: 'Insight Lead Status Detail',
  screen_name: 'Insight Lead Status Detail',
};

const insightMarketingLeadDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Insights',
  journey: 'Insight Marketing Lead',
  stage: 'Insight Marketing Lead',
  screen_name: 'Insight Marketing Lead Detail',
};

const insightMassCampaignDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Insights',
  journey: 'Insight Mass Campaign',
  stage: 'Insight Mass Campaign',
  screen_name: 'Insight Mass Campaign Detail',
};

const insightAgencyCampaignLeadDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Insights',
  journey: 'Insight Agency Campaign Lead',
  stage: 'Insight Agency Campaign Lead',
  screen_name: 'Insight Agency Campaign Lead Detail',
};

const insightProspectDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Insights',
  journey: 'Insight Prospect',
  stage: 'Insight Prospect',
  screen_name: 'Insight Prospect Detail',
};

const insightProspectMetricsDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Insights',
  journey: 'Insight Prospect',
  stage: 'Insight Prospect Metrics Detail',
  screen_name: 'Insight Prospect Metrics Detail',
};

const insightAppointmentDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Insights',
  journey: 'Insight Appointment',
  stage: 'Insight Appointment Detail',
  screen_name: 'Insight Appointment Detail',
};

const insightServicingCampaignDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Insight',
  journey: 'Insight Servicing Campaign',
  stage: 'Insight Servicing Campaign Detail',
  screen_name: 'Insight Servicing Campaign Detail',
};

const insightExportReportGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Insight',
  journey: 'Insight Export Report',
  stage: 'Insight Export Report',
  screen_name: 'Insight Export Report',
};

const InsightRoutes: FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      // Clear insight redux state
      dispatch(insightAction.resetState());
      // Remove session: selected agent-code list
      sessionStorage.setItem(InsightSession.InsightSelectedAgentCode, globalStore.getAgentCode());
    };
  }, []);
  return (
    <Switch>
      <Redirect exact from={`${INSIGHT_BASE_PATH}`} to={INSIGHT_HOME_PATH} />
      <PruRoute exact gaData={insightHomeGaDataRoute} path={INSIGHT_HOME_PATH} component={InsightHomePage} />
      <PruRoute
        exact
        gaData={insightLeadStatusDetailGaDataRoute}
        path={INSIGHT_STATUS_PATH}
        component={LeadStatusDetailPage}
      />
      {/* <PruRoute
        exact
        gaData={insightLeadStatusMetricsDetailGaDataRoute1}
        path={`${INSIGHT_BASE_PATH}/lead-status/:status/:followup/:reason`}
        render={LeadStatusDetailListPage}
      /> */}
      <PruRoute
        exact
        // gaData={insightLeadStatusMetricsDetailGaDataRoute}
        path={INSIGHT_STATUS_LIST_PATH}
        component={LeadStatusDetailListPage}
      />
      <PruRoute
        exact
        gaData={insightMarketingLeadDetailGaDataRoute}
        path={INSIGHT_MARKETING_PATH}
        component={MarketingLeadDetailPage}
      />
      <PruRoute
        exact
        gaData={insightMassCampaignDetailGaDataRoute}
        path={INSIGHT_MASS_PATH}
        component={MassLeadDetailPage}
      />
      <PruRoute
        exact
        gaData={insightAgencyCampaignLeadDetailGaDataRoute}
        path={INSIGHT_COMPANY_PATH}
        component={CompanyAssistedLeadDetailPage}
      />
      <PruRoute
        exact
        gaData={insightProspectDetailGaDataRoute}
        path={INSIGHT_PROSPECT_PATH}
        component={ProspectDetailPage}
      />
      <PruRoute
        exact
        gaData={insightProspectMetricsDetailGaDataRoute}
        path={`${INSIGHT_PROSPECT_TYPE_PATH}/:type/:id`}
        component={ProspectTypeDetailPage}
      />
      {/* <PruRoute exact path={`INSIGHT_APPOINTMENT_PATH} component={AppointmentDetailPage} /> */}
      <PruRoute
        exact
        gaData={insightServicingCampaignDetailGaDataRoute}
        path={INSIGHT_SERVICING_PATH}
        component={ServicingCampaignDetailPage}
      />
      <PruRoute exact gaData={insightExportReportGaDataRoute} path={INSIGHT_EXPORT_PATH} component={ExportReportPage} />
      {/* <PruRoute exact path={`${INSIGHT_BASE_PATH}/demo`} component={DemoPage} /> */}
      <PruRoute
        exact
        gaData={insightAppointmentDetailGaDataRoute}
        path={`${INSIGHT_APPOINTMENT_PATH}`}
        component={AppointmentPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default InsightRoutes;
