import React, { FC, useEffect, useState, useMemo, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box, Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LayoutSplashScreen } from 'src/app/layout';
import { useInsightHome } from './insight-home.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { InsightSectionComponent } from '../../components/insight-section/insight-section.component';
import styles from './insight-home.module.css';
import { LeadStatusComponent } from './lead-status/lead-status.component';
import { MarketingLeadComponent } from './marketing-lead/marketing-lead.component';
import { MassLeadComponent } from './mass-lead/mass-lead.component';
import { CompanyAssistedLeadComponent } from './company-assisted-lead/company-assisted-lead.component';
import { ProspectComponent } from './prospect/prospect.component';
import { ServicingCampaignComponent } from './servicing-campaign/servicing-campaign.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { CascadingSelector } from 'src/app/common/components/cascading-selector/cascading-selector.component';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Card } from 'src/app/common/components/card/card.component';

export type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const InsightHomePage: React.FC<PageProps> = (props: PageProps) => {
  const { t } = useTranslation();

  // custom-hook
  const { visible, data, agentCodeList, defaultSelected, loadingChild, handlePress, onSelectAgent, getAgentList } =
    useInsightHome();

  return (
    <Page>
      <PageTitle
        title={t('insights')}
        rightNode={
          <Button variant="contained" color="secondary" onClick={handlePress}>
            {t('lead.export')}
          </Button>
        }
      />
      {data ? (
        <div className={styles.picker}>
          <CascadingSelector
            showLoadingChild={loadingChild}
            closeLevel={2}
            data={data}
            defaultSelected={defaultSelected}
            onSelectComplete={onSelectAgent}
            placeholder={t('lead.select_agent')}
            getChildData={getAgentList}
          />
        </div>
      ) : null}
      <Card className={styles.sectionWrap}>
        <LeadStatusComponent agentCode={agentCodeList} />
        <MarketingLeadComponent agentCode={agentCodeList} />
        <MassLeadComponent agentCode={agentCodeList} />
        <CompanyAssistedLeadComponent agentCode={agentCodeList} />
      </Card>
      <Card className={styles.sectionWrap}>
        <ProspectComponent agentCode={agentCodeList} />
      </Card>
      {visible.appointment ? (
        <Card className={styles.sectionWrap}>
          <AppointmentComponent agentCode={agentCodeList} />
        </Card>
      ) : null}
      {visible.servicing ? (
        <Card className={styles.sectionWrap}>
          <ServicingCampaignComponent agentCode={agentCodeList} />
        </Card>
      ) : null}
    </Page>
  );
};
