import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, FormControl, MenuItem, Select, Stack } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { changeParticipantCacheParams, useParticipantList } from './participant-list.hook';
import { Card } from 'src/app/common/components/card/card.component';
import { OverviewComponent } from '../components/overview/overview.component';
import {
  AttendanceStatusEnum,
  EventDetailPageTabEnum,
  EventFormCommonProps,
  ParticipantItem,
  RegistrationTypeEnum,
} from 'src/app/modules/event-v2/types';
import { ProTableOperationBtnComponent } from 'src/app/common/components/ProTable/components';
import { getColumns, getTagConfig } from '../util/participant.util';
import ProTable from 'src/app/common/components/ProTable/ProTable';
import { useParams } from 'react-router-dom';
import { Dialog } from 'src/app/common/components/Dialog/dialog/dialog.component';
import moment from 'moment';
import { Tag } from 'src/app/common/components/tag';
import { ParticipantTableBarComponent } from '../components/participant-table-bar/participant-table-bar.component';
import { sessionKeyToDisplayName } from '../../../common';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  formCommonProps: EventFormCommonProps;
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
};

const SESSION_DEFAULT_VALUE = 'All';

export const ParticipantListComponent: React.FC<ComponentProps> = memo(
  ({ formCommonProps, cacheParams }: ComponentProps) => {
    // i18n
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { eventItem, walkInAllowed } = formCommonProps;

    // custom-hook
    const {
      overviewData,
      dataSource,
      loading,
      total,
      filterState,
      curRegistrationId,
      onSelect,
      setCurRegistrationId,
      onFilterChanged,
      reload,
      onClickRemove,
      onRemove,
      onClickAttendance,
    } = useParticipantList({ eventId: id, cacheParams });

    const disableRemoveAction = !id || (eventItem?.eventDate ? moment().isAfter(eventItem.eventDate, 'day') : false);
    const selectedSession = overviewData?.sessions?.find((session) => session._id === filterState.sessionId);
    const showWalkInListReminder =
      filterState?.registrationType === RegistrationTypeEnum.WALKIN &&
      eventItem?.multiSession === true &&
      !selectedSession;

    return (
      <Stack sx={{ m: 3 }}>
        {overviewData?.sessions && overviewData?.sessions?.length > 0 && (
          <Box sx={{ width: 350, position: 'absolute', right: 24, top: 8 }}>
            <FormControl fullWidth color="secondary">
              <Select
                displayEmpty
                value={selectedSession?.key || SESSION_DEFAULT_VALUE}
                renderValue={(selected) =>
                  selected !== SESSION_DEFAULT_VALUE ? sessionKeyToDisplayName(selected) : t('component.tagSelect.all')
                }
                onChange={onSelect}
              >
                <MenuItem value={SESSION_DEFAULT_VALUE}>{t('component.tagSelect.all')}</MenuItem>
                {overviewData?.sessions.map((session) => (
                  <MenuItem key={session.key} value={session.key}>
                    {sessionKeyToDisplayName(session.key)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        <OverviewComponent data={selectedSession || overviewData} walkInAllowed={walkInAllowed} />

        {/*participant list */}
        <Card sx={{ p: 3, mt: 3 }}>
          <ParticipantTableBarComponent
            isEmpty={total === 0}
            curTab={filterState?.registrationType}
            filterState={filterState}
            walkInAllowed={walkInAllowed}
            onTabChange={(value) => {
              changeParticipantCacheParams({ registrationType: value }, cacheParams);
              reload();
            }}
            onFilterChanged={onFilterChanged}
            eventItem={eventItem}
          />

          <ProTable
            enableRefresh={false}
            showSelect={false}
            rowKey="_id"
            isLoading={loading}
            columns={getColumns({
              t,
              isWalkInList: filterState?.registrationType === RegistrationTypeEnum.WALKIN,
              hideAttendanceStatus: eventItem?.multiSession === true && !selectedSession,
              actions: { onClickRemove, onClickAttendance, disableRemoveAction },
            })}
            dataSource={dataSource}
            page={filterState.page}
            pageSize={filterState.pageSize}
            total={total}
            onChangePage={(params) => {
              changeParticipantCacheParams(params, cacheParams);
              reload();
            }}
            onRefresh={reload}
            operationSticky
            emptyText={
              showWalkInListReminder ? t('event.form.walk_in_select_session_reminder') : t('prutable.noRecord')
            }
          />

          <Dialog
            title={t('app.button.remove')}
            content={t('event.form.remove_participant_reminder')}
            open={!!curRegistrationId}
            onConfirm={async () => {
              await onRemove?.(curRegistrationId!);
              setCurRegistrationId(undefined);
            }}
            onClose={() => setCurRegistrationId(undefined)}
          />
        </Card>
      </Stack>
    );
  },
);

export const renderAttendanceTag = (curValue: AttendanceStatusEnum, t: (key: string) => string) => {
  return curValue ? <Tag value={curValue} configs={getTagConfig(t)} /> : '-';
};

export const renderOperation = ({
  t,
  item,
  isWalkInList,
  actions,
}: {
  t: (key: string) => string;
  item: ParticipantItem;
  isWalkInList?: boolean;
  actions: {
    onClickAttendance: (id: string) => void;
    onClickRemove: (id: string) => void;
    disableRemoveAction?: boolean;
  };
}) => {
  return (
    <>
      <ProTableOperationBtnComponent
        label={t('event.form.attendance')}
        onClick={() => {
          actions.onClickAttendance(item.registrationId);
        }}
      />
      {!isWalkInList && !actions.disableRemoveAction && (
        <ProTableOperationBtnComponent
          label={t('app.button.remove')}
          onClick={() => actions.onClickRemove(item.registrationId)}
        />
      )}
    </>
  );
};
