import React, { memo, useMemo } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useCampaignLeadFilterTable } from './campaign-lead-filter-table.hook';
import ProFilterTable, { ProCoreActionType } from 'src/app/common/components/ProTable';
import { FilterProps } from 'src/app/common/components/ProTable/ProFilter';
import { AddLeadSettingItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { OperatorEnum } from 'src/app/modules/AgencyCampaign/types/common-types';
import { useHistory } from 'react-router-dom';
import { LEADS_DETAIL_PATH } from 'src/app/modules/Leads/constants';

export type LeadFilterProps = FilterProps<any> & {
  campaignId: string;
  campaignOwner: string;
  agentCode: string;
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  acmAddLeadSetting:
    | AddLeadSettingItem
    | {
        selfAddEnabled: true;
        needACMApproved: false;
        startDateSetting: {};
        endDateSetting: {
          operator: OperatorEnum.GTE;
          dependsOn: string;
          value: number;
        };
        startDate: string;
        endDate: string;
      };
  isACMApproved: boolean;
  isInSchedule: boolean;
};

type ComponentProps = ParamsProps & {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  filter?: React.ComponentType<LeadFilterProps>;
  campaignId: string;
  agentCode: string;
  campaignOwner: string;
  tableRef?: React.MutableRefObject<ProCoreActionType | undefined>;
  isACMApproved: boolean;
  isInSchedule: boolean;
  acmAddLeadSetting:
    | AddLeadSettingItem
    | {
        selfAddEnabled: true;
        needACMApproved: false;
        startDateSetting: {};
        endDateSetting: {
          operator: OperatorEnum.GTE;
          dependsOn: string;
          value: number;
        };
        startDate: string;
        endDate: string;
      };
};

export const CampaignLeadFilterTableComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // custom-hook
  const {
    filter: InitFilter,
    campaignId,
    agentCode,
    cacheParams,
    campaignOwner,
    tableRef,
    acmAddLeadSetting,
    isACMApproved,
    isInSchedule,
  } = props;
  const { columns, getData, onFilterChange } = useCampaignLeadFilterTable({
    campaignId,
    agentCodeLocal: agentCode,
    cacheParams,
  });
  const history = useHistory();
  const toLead = (i: any) => {
    history.push(`${LEADS_DETAIL_PATH}/${i._id}`);
  };

  const Filter = useMemo(() => {
    return (props: FilterProps<any>) =>
      InitFilter ? (
        <InitFilter
          {...props}
          campaignId={campaignId}
          agentCode={agentCode}
          cacheParams={cacheParams}
          campaignOwner={campaignOwner}
          acmAddLeadSetting={acmAddLeadSetting}
          isACMApproved={isACMApproved}
          isInSchedule={isInSchedule}
        />
      ) : null;
  }, [InitFilter, campaignId, agentCode, cacheParams, campaignOwner, acmAddLeadSetting, isACMApproved, isInSchedule]);
  return (
    <ProFilterTable
      rowKey="_id"
      columns={columns}
      request={getData}
      Filter={Filter}
      enableRefresh={false}
      filterState={cacheParams?.current}
      onFilterChange={onFilterChange}
      actionRef={tableRef}
      onClickRow={toLead}
    />
  );
});
