import { useIntl } from 'react-intl';
import { Button, Tabs, Tab } from '@mui/material';
import { LayoutSplashScreen } from 'src/app/layout';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import {
  StepStatusEnum,
  StepItem,
  StepIndicator,
  PruSteppedForm,
} from 'src/app/common/components/pru-stepped-form-agent';
import { EventDetailPageTabEnum, RegistrationMethodEnum, EventFormCommonProps } from '../../../types';
import { useEventDetailPage, ComponentEnum } from './event-detail-page.hook';
import { useStyles } from './event-detail-page.style';
import {
  EventBasicsForm,
  EventDetailsForm,
  EventRegistrationAttendanceForm,
  EventRegistrationForm,
  EventWalkInForm,
  EventPublishForm,
} from './components/event-setup';
import { EventAssistantListComponent } from './components/event-assistant';
import { ParticipantListComponent } from './components/participant';

const initialActiveStep: StepIndicator = {
  stepIndex: 0,
};

const getInitialSteps = (
  Translation: (id: string) => string,
  getDefaultStatus: (component: ComponentEnum) => StepStatusEnum | undefined,
): StepItem<EventFormCommonProps>[] => [
  {
    label: Translation('event.form.basics'),
    status: getDefaultStatus(ComponentEnum.BASICS),
    component: (props) => <EventBasicsForm {...props} />,
  },
  {
    disabled: (formCommonProps) => formCommonProps.eventItem === undefined,
    label: Translation('event.form.details'),
    status: getDefaultStatus(ComponentEnum.DETAILS),
    component: (props) => <EventDetailsForm {...props} />,
  },
  {
    disabled: (formCommonProps) => formCommonProps.eventItem === undefined,
    label: Translation('event.form.registration_attendance'),
    status: getDefaultStatus(ComponentEnum.REGISTRATION_ATTENDANCE),
    component: (props) => <EventRegistrationAttendanceForm {...props} />,
  },
  {
    disabled: (formCommonProps) => formCommonProps.eventItem === undefined,
    hidden: (formCommonProps) => formCommonProps.eventItem?.regMethod !== RegistrationMethodEnum.TEMPLATE,
    label: Translation('event.form.registration_form'),
    status: getDefaultStatus(ComponentEnum.REGISTRATION_FORM),
    component: (props) => <EventRegistrationForm {...props} />,
  },
  {
    disabled: (formCommonProps) => formCommonProps.eventItem === undefined,
    hidden: (formCommonProps) =>
      formCommonProps.eventItem?.regMethod !== RegistrationMethodEnum.TEMPLATE ||
      (formCommonProps.eventItem?.regAllowWalkIn !== true &&
        !!formCommonProps.eventItem?.sessions.every(
          (session) => !session.regAllowWalkIn && session.subSessions.every((subSession) => !subSession.regAllowWalkIn),
        )),
    label: Translation('event.form.walk_in_form'),
    status: getDefaultStatus(ComponentEnum.WALK_IN_FORM),
    component: (props) => <EventWalkInForm {...props} />,
  },
  {
    disabled: (formCommonProps) => formCommonProps.eventItem === undefined,
    label: Translation('event.form.publish_setting'),
    status: getDefaultStatus(ComponentEnum.PUBLISH_SETTING),
    component: (props) => <EventPublishForm {...props} />,
  },
];

export interface ComponentProps {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
}

export const EventDetailPage = ({ cacheParams }: ComponentProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const {
    formMode,
    isLoading,
    changesUnsaved,
    isReset,
    isPublished,
    disableEdit,
    walkInAllowed,
    tab,
    eventCategoryItem,
    eventTagList,
    eventItem,
    showLayoutSplashScreen,
    setIsLoading,
    setChangesUnsaved,
    setIsReset,
    handleChangeTab,
    reloadData,
    getDefaultStatus,
    onBack,
  } = useEventDetailPage({ cacheParams });
  const formCommonProps: EventFormCommonProps = {
    formMode,
    isLoading,
    changesUnsaved,
    isReset,
    isPublished,
    disableEdit,
    walkInAllowed,
    eventCategoryItem,
    eventTagList,
    eventItem,
    setIsLoading,
    setChangesUnsaved,
    setIsReset,
    reloadData,
  };
  const { classes } = useStyles();

  return (
    <>
      {showLayoutSplashScreen ? (
        <LayoutSplashScreen />
      ) : (
        <Page>
          <HeaderBreadCrumbs />
          <PageTitle
            className={classes.pageTitle}
            title={Translation('event.form.event_detail')}
            rightNode={
              <Button variant="outlined" color="inherit" onClick={onBack}>
                {Translation('app.button.back')}
              </Button>
            }
          />
          <div className={classes.pageContent}>
            <Tabs indicatorColor="secondary" textColor="secondary" value={tab} onChange={handleChangeTab}>
              <Tab
                className={classes.tab}
                label={Translation('title.application')}
                value={EventDetailPageTabEnum.APPLICATION}
              />
              <Tab
                className={classes.tab}
                label={Translation('event.form.assistant')}
                value={EventDetailPageTabEnum.ASSISTANT}
              />
              <Tab
                className={classes.tab}
                label={Translation('event.form.participant')}
                value={EventDetailPageTabEnum.PARTICIPANT}
              />
            </Tabs>
            {tab === EventDetailPageTabEnum.APPLICATION && (
              <PruSteppedForm
                initialActiveStep={initialActiveStep}
                initialSteps={getInitialSteps(Translation, getDefaultStatus)}
                formCommonProps={formCommonProps}
              />
            )}
            {tab === EventDetailPageTabEnum.ASSISTANT && (
              <EventAssistantListComponent formCommonProps={formCommonProps} />
            )}
            {tab === EventDetailPageTabEnum.PARTICIPANT && (
              <ParticipantListComponent formCommonProps={formCommonProps} cacheParams={cacheParams} />
            )}
          </div>
        </Page>
      )}
    </>
  );
};
