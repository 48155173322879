import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';

import styles from './company-assisted-lead-detail.module.css';
import { useCompanyAssistedLeadDetail } from './company-assisted-lead-detail.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { InsightFilterComponent } from '../../components/insight-filter/insight-filter.component';

import { Card } from 'src/app/common/components/card/card.component';
import { TooltipComponent } from 'src/app/common/components/tooltip/tooltip.component';
import { PercentageBarChart } from 'src/app/common/components/charts/percentage-bar-chart/percentage-bar-chart.component';
import { Masonry } from '@mui/lab';
import { InsightAccordionComponent } from '../../components/insight-accordion/insight-accordion.component';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const CompanyAssistedLeadDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { t } = useTranslation();

  // custom-hook
  const {
    leadsAdded,
    campaignsParticipated,
    summary,
    detail,
    filterValues,
    filterConfig,
    defaultFilterValues,
    onFilterChange,
  } = useCompanyAssistedLeadDetail();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={t('company_assisted_lead')}
        rightNode={
          <InsightFilterComponent
            width={350}
            filterConfigs={filterConfig}
            filterValues={filterValues}
            onConfirm={onFilterChange}
            defaultValues={defaultFilterValues}
          />
        }
      />
      <div className={styles.wrap}>
        <Card className={styles.wrapChart}>
          <div className={styles.flexRow}>
            <div className={styles.overview}>
              {t('company_assisted_lead_added')}
              <div className={styles.num}>{leadsAdded}</div>
            </div>
            <div className={styles.overview}>
              {t('company_assisted_camgaign_participated')}
              <div className={styles.num}>{campaignsParticipated}</div>
            </div>
          </div>

          <PercentageBarChart data={summary} />
        </Card>

        <Masonry columns={2} spacing={2} className={styles.detailBox}>
          {detail.map((data, index) => (
            <InsightAccordionComponent title={data.title} data={data.data} />
          ))}
        </Masonry>
      </div>
    </Page>
  );
};
