import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    borderRadius: 5,
    margin: theme.spacing(1),
  },
  error: {
    backgroundColor: 'red',
  },
  divider: {
    display: 'flex',
    width: '100%',
    height: 1,
    backgroundColor: '#F0F0F0',
    marginTop: 24,
  },
}));
