import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './prospect-type-detail.module.css';
import { useProspectTypeDetail } from './prospect-type-detail.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { Card } from 'src/app/common/components/card/card.component';
import { useLang } from 'src/app/i18n';
import { getI18nData } from 'src/app/common/utils/i18n.util';
import ProFilterTable from 'src/app/common/components/ProTable';
import { getDisplayValue } from '../util/prospect-detail.util';
import { PROSPECT_BASE_PATH, PROSPECT_DETAIL_PATH } from 'src/app/modules/Prospect/constants';
import { InsightSession } from '../../../types/types';
import { Button } from 'src/app/common/components/button/button.component';
import { get } from 'lodash';
import { TAB_TYPE } from '../prospect-detail.hook';

type PageProps = ParamsProps & RouteComponentProps & {};

export const ProspectTypeDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const locale = useLang();
  const history = useHistory<any>();
  const { type: insightType, id } = useParams<{ type: string; id: string }>();
  const { prospect } = history.location.state;
  const { t } = useTranslation();
  const filterValues = get(
    JSON.parse(sessionStorage.getItem(InsightSession.ProspectDetailFilterValues) || '{}'),
    insightType,
    {},
  );

  // custom-hook
  const { getData, columns } = useProspectTypeDetail({
    typeId: id,
    filterValues: filterValues as any,
    insightType: insightType,
  });

  const toDetail = (record: any) => {
    history.push(`${PROSPECT_BASE_PATH}${PROSPECT_DETAIL_PATH}/${record._id}`);
  };

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={t('leads_status_title_details')}
        rightNode={
          <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
            {t('app.button.back')}
          </Button>
        }
      />
      <Card className={styles.wrapChart}>
        <div className={styles.flexRowCenter}>
          <div className={styles.tag} style={{ backgroundColor: prospect.backgroundColorLabel }}>
            <Typography color={prospect.colorLabel} fontWeight={600}>
              {prospect.type !== 'None' ? prospect.type : '?'}
            </Typography>
          </div>
          <Typography fontSize={14} color={'#999999'}>
            {getI18nData(prospect.name, locale)}
          </Typography>
        </div>
        <Typography fontFamily={'Montserrat'} fontSize={30} fontWeight={700}>
          {getDisplayValue(prospect, insightType)}
        </Typography>
      </Card>

      <ProFilterTable
        showFilterIcon={false}
        showFilter={false}
        rowKey="_id"
        columns={columns}
        request={getData}
        enableRefresh={false}
        showSelect={false}
        onClickRow={toDetail}
      />
    </Page>
  );
};
