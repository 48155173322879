import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { regionLocale, RegionLocale } from 'src/app/i18n';
import PruIcon from 'src/app/common/components/PruIcon';
import { useStyles } from './multi-lang-section.style';
import { useCommonFormStyles } from '../common-form.style';

type MultiLangSectionProps = {
  disabled?: boolean;
  fillInOnChange: (locale: RegionLocale) => void;
  renderChildren: (locale: RegionLocale, index: number) => ReactElement;
};

export const MultiLangSection: FC<MultiLangSectionProps> = ({ disabled, fillInOnChange, renderChildren }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();

  return (
    <div className={`${commonFormClasses.contentContainer} ${classes.container}`}>
      {regionLocale.map((locale, index) => (
        <div key={locale} className={commonFormClasses.smallGapContentContainer}>
          <div className={classes.header}>
            {Translation('common.lang_version', {
              lang: Translation(`navBar.lang.${locale}`),
            })}
            {index > 0 && (
              <Button
                disabled={disabled}
                variant="text"
                className={classes.copyButton}
                onClick={() => fillInOnChange(locale)}
              >
                <PruIcon icon="copy" customClasses={classes.copyIcon} />
                <span>
                  {Translation('common.use_lang_to_fill_in', {
                    lang: Translation(`navBar.lang.${regionLocale[0]}`),
                  })}
                </span>
              </Button>
            )}
          </div>
          {renderChildren(locale, index)}
        </div>
      ))}
    </div>
  );
};
