import { EventItem, RegistrationNotificationMethodEnum } from 'src/app/modules/event-v2/types';
import { emailElement, phoneElement } from './constants';

export const updateContactElement = (page: any, eventItem: EventItem) => {
  const newElements: any[] = [];
  page.elements.forEach((element: any) => {
    if (
      element.name === 'registrant_email' &&
      eventItem.regNotificationMethod === RegistrationNotificationMethodEnum.SMS
    ) {
      newElements.push(phoneElement);
    } else if (
      element.name === 'countryCode_phoneNumber' &&
      eventItem.regNotificationMethod !== RegistrationNotificationMethodEnum.SMS
    ) {
      newElements.push(emailElement);
    } else {
      newElements.push(element);
    }
  });
  return {
    ...page,
    elements: newElements,
  };
};
