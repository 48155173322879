import { makeStyles } from 'tss-react/mui';
import { calculateItemWidth } from 'src/app/common/utils';

const ROW_GAP = 24;
const CONTENT_PADDING = 24;
const PREVIEW_GAP = 24;

export const useStyles = makeStyles<{ isUploading: boolean }>()((_, { isUploading }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: ROW_GAP,
  },
  uploadArea: {
    width: '100%',
    padding: CONTENT_PADDING,
    backgroundColor: '#FAFAFA',
    border: '1px dashed #CCCCCC',
    cursor: isUploading ? 'wait' : 'pointer',
  },
  uploadText: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 12,
  },
  uploadInstruction: {
    color: '#999999',
    fontSize: 14,
    fontWeight: 500,
  },
  uploadInstructionHighlight: {
    color: '#E8192C',
  },
  errorText: {
    marginLeft: 14,
  },
  previewImageContainer: {
    position: 'relative',
    width: calculateItemWidth(PREVIEW_GAP, 3),
    height: 170,
  },
  previewImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 8,
  },
  removeImageButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  removeImageIcon: {
    fontSize: 24,
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: 2,
  },
  previewFileContainer: {
    width: '50%',
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 12px',
    border: '1px solid #F0F0F0',
    borderRadius: 8,
  },
  previewFileInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 12,
  },
  fileIcon: {
    width: 32,
  },
  removeFileIcon: {
    fontSize: 20,
  },
  dialogPaper: {
    width: 450,
    maxWidth: 450,
  },
  reminderContent: {
    padding: '16px 24px 0px',
    marginBottom: 20,
  },
  reminderTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  reminderImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reminderFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 8,
  },
  blueText: {
    color: '#1976D2',
  },
}));
