import React, { useMemo, useContext } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, Button, Tabs, Tab, CircularProgress } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './lead-detail.style';
import { LeadDetailContext, useLeadDetail } from './lead-detail.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { get } from 'lodash';
import { AppointmentComponent } from '../../../../Prospect/pages/appointment/appointment.component';
import { LeadBasicInfo } from './component/lead-basic-info/lead-basic-info.component';
import { LeadInformationPage } from './component/lead-information/lead-information.screen';
import { LEAD_DETAIL_TABS, LEADS_LIST_PATH } from '../../../constants';
import { LeadStatusHistoryComponent } from '../../../components/lead-status-history/lead-status-history.component';
import { AgentStatusEnum, LeadCategoryEnum } from '../../../types/types';
import { CampaignTypeEnum } from 'src/app/modules/Campaign/types/campaign-types';

type PageProps = ParamsProps &
  RouteComponentProps & {
    cacheParams: any;
    /* otherProp: string */
  };

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

type LeadInfoActionsProps = {
  onConfirm: () => void;
  onEdit: () => void;
  editMode: boolean;
  canEdit: boolean;
};

const LeadInfoActions = (props: LeadInfoActionsProps) => {
  const { onConfirm, onEdit, editMode, canEdit } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { leadInfomationTabState } = useContext(LeadDetailContext);
  const { classes } = useStyles();
  return (
    <div className={classes.flexRow}>
      {leadInfomationTabState?.saving ? null : (
        <Button onClick={onEdit} variant="outlined" className={classes.buttonBorder} disabled={!canEdit}>
          {editMode ? Translation('app.button.cancel') : Translation('app.button.edit')}
        </Button>
      )}
      {editMode && (
        <Button onClick={onConfirm} variant="contained" className={classes.confirmButton}>
          {leadInfomationTabState?.saving ? (
            <CircularProgress size={18} color="inherit" />
          ) : (
            Translation('app.button.confirm')
          )}
        </Button>
      )}
    </div>
  );
};

const AppointmentActions = ({ onClick }: { onClick: () => void }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  return (
    <Button onClick={onClick} variant="contained" className={classes.confirmButton}>
      {Translation('prospect.appoinment.add.appointment')}
    </Button>
  );
};

export const LeadDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { history } = props;
  const leadBasicInfo = get(history.location, 'state.leadBasicInfo', null);
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  // informationTabRef.current && informationTabRef.current.submitForm();
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    leadDetail,
    leadInfoTabState,
    informationTabRef,
    appointmentTabRef,
    onClickEdit,
    onSubmitLeadInfo,
    onClickAddAppointment,
    updateLeadInfoTabState,
    updateLeadDetail,
    canEdit,
    totalAppointmentsLabel,
    onAppointmentLoaded,
    hideStatus,
    leadCreatedAt,
  } = useLeadDetail({
    id: id,
  });
  const goBack = () => {
    history.goBack();
  };
  const [value, setValue] = React.useState(LEAD_DETAIL_TABS.INFORMATION);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const type = leadDetail?.campaign?.type;

  return (
    <LeadDetailContext.Provider
      value={{
        leadInfomationTabState: leadInfoTabState,
        updateLeadInfoTabState: updateLeadInfoTabState,
        updateLeadDetail: updateLeadDetail,
      }}
    >
      <Page>
        <HeaderBreadCrumbs />
        <PageTitle
          title={
            !leadDetail ? '-' : type === 'servicing' ? Translation('customer_detail') : Translation('salesLdTitle')
          }
          rightNode={
            <Button variant="outlined" color="secondary" onClick={goBack}>
              {Translation('app.button.back')}
            </Button>
          }
        />
        {
          <div>
            <LeadBasicInfo detail={leadDetail || leadBasicInfo} />
          </div>
        }
        <div className={styles.tabContainer}>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { backgroundColor: '#E8192C' } }}
            sx={{
              '.Mui-selected': {
                color: '#E8192C !important',
              },
            }}
          >
            <Tab value={LEAD_DETAIL_TABS.INFORMATION} label={Translation('acm_info')} wrapped />
            {leadDetail?.agentStatus === AgentStatusEnum.accepted && (
              <Tab value={LEAD_DETAIL_TABS.APPOINTMENT} label={totalAppointmentsLabel} />
            )}
            {!hideStatus && <Tab value={LEAD_DETAIL_TABS.STATUS_HISTORY} label={Translation('lead.statusHistory')} />}
          </Tabs>
          {value === LEAD_DETAIL_TABS.INFORMATION && (
            <LeadInfoActions
              onConfirm={onSubmitLeadInfo}
              onEdit={onClickEdit}
              editMode={!leadInfoTabState.leadInfoReadOnly}
              canEdit={canEdit}
            />
          )}
          {value === LEAD_DETAIL_TABS.APPOINTMENT && <AppointmentActions onClick={onClickAddAppointment} />}
        </div>
        <div>
          <TabPanel value={value} index={LEAD_DETAIL_TABS.INFORMATION}>
            {leadDetail && (
              <LeadInformationPage
                ref={informationTabRef}
                {...props}
                leadDetail={leadDetail}
                readOnly={leadInfoTabState.leadInfoReadOnly}
                canEdit={canEdit}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={LEAD_DETAIL_TABS.APPOINTMENT}>
            <AppointmentComponent lead={{ _id: id }} ref={appointmentTabRef} onLoaded={onAppointmentLoaded} />
          </TabPanel>
          <TabPanel value={value} index={LEAD_DETAIL_TABS.STATUS_HISTORY}>
            {
              <div className={styles.status}>
                <LeadStatusHistoryComponent
                  followUp={leadDetail?.followUp}
                  leadCategory={LeadCategoryEnum.SALES_LEAD}
                  leadCreatedAt={leadCreatedAt}
                />
              </div>
            }
          </TabPanel>
        </div>
      </Page>
    </LeadDetailContext.Provider>
  );
};
