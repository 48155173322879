import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './appointment.module.css';
import { useAppointment } from './appointment.hook';
import { InsightSectionComponent } from '../../../components/insight-section/insight-section.component';

type ComponentProps = ParamsProps & {
  agentCode?: string;
};

export const AppointmentComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();

  // custom-hook
  const { data, tooltip, handlePress } = useAppointment({
    agentCode: props.agentCode,
  });
  return <InsightSectionComponent onPress={handlePress} title={t('appointment')} data={data} tooltip={tooltip} />;
});
