import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Button, Divider, Stack, ToggleButton, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { BirthdayTabValues, CampaignTabValues, useList } from './list.hook';
import { TabBar } from 'src/app/common/components/tab-bar/tab-bar.component';
import ProFilterTable, { RequestFunction } from 'src/app/common/components/ProTable';
import { getProspectColumns } from '../../utils/list.util';
import {
  DialogueConfigItemType,
  DialogueItemType,
  DialogueKeyEnum,
  TalkBotExportType,
  TalkbotUnreadItem,
} from 'src/app/modules/ai-talkbot/types/talkbot-export-leads-types';
import { getColumns as getMarketingColumns } from 'src/app/modules/Campaign/pages/MarketingCampaign/List/util/list.util';
import { useStyles } from 'src/app/modules/Campaign/pages/MarketingCampaign/List/list.style';
import { getColumns } from 'src/app/modules/Campaign/pages/ServicingCampaign/List/util/list.util';
import { MultiExpoButton } from 'src/app/modules/ai-talkbot/common/components/talkbot-export-button/talkbot-export-button.component';
import { get } from 'lodash';
import { configManager } from 'src/app/common/utils';
import { PROSPECT_BASE_PATH, PROSPECT_DETAIL_PATH } from 'src/app/modules/Prospect/constants';
import { useHistory } from 'react-router-dom';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  curDialogue: DialogueItemType;
  talkbotUnreadList?: TalkbotUnreadItem[];
};

export const ListComponent: React.FC<ComponentProps> = memo(({ curDialogue, talkbotUnreadList }: ComponentProps) => {
  // i18n
  const { t } = useTranslation();
  const history = useHistory();
  const { flowId } = curDialogue ?? {};
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    tabs,
    selectedTab,
    selectedBirthdayTab,
    marketingActionRef,
    servicingActionRef,
    prospectCategories,
    setSelectedTab,
    setSelectedBirthdayTab,
    getProspectData,
    getMarketingCampaignData,
    getServicingCampaignData,
    toDetail,
  } = useList({ curDialogue });

  const { normal = 0, servicing = 0 } = talkbotUnreadList?.find((item) => Number(item.flowId) === flowId)?.count ?? {};

  const birthdayTabs = useMemo(() => {
    return [
      { label: t('talkbot.export_leads.prospects'), value: BirthdayTabValues.PROSPECTS },
      {
        label: <UnReadTabLabel label={t('talkbot.export_leads.campaigns')} count={normal + servicing} />,
        value: BirthdayTabValues.CAMPAIGNS,
      },
    ];
  }, [normal, servicing, t]);

  const unreadTabs = useMemo(() => {
    return [
      {
        label: <UnReadTabLabel label={t('talkbot.campaign.marketing')} count={normal} />,
        value: CampaignTabValues.MARKETING,
      },
      {
        label: <UnReadTabLabel label={t('talkbot.campaign.servicing')} count={servicing} />,
        value: CampaignTabValues.SERVICING,
      },
    ];
  }, [normal, servicing, t]);

  const isBirthdayDialogue = useMemo(() => {
    const dialogConfigs = get(configManager.getConfig('sales-common'), 'Sales.talkbot.dialogues', []);
    const curDialogueKey = dialogConfigs?.find((item: DialogueConfigItemType) => item.flowId === flowId)?.dialogueKey;
    return curDialogueKey === DialogueKeyEnum['birthday-call'];
  }, [flowId]);

  const showMarketingCampaignList = useMemo(() => {
    if (!isBirthdayDialogue) return selectedTab === CampaignTabValues.MARKETING;
    return selectedBirthdayTab === BirthdayTabValues.CAMPAIGNS && selectedTab === CampaignTabValues.MARKETING;
  }, [isBirthdayDialogue, selectedTab, selectedBirthdayTab]);

  const showServicingCampaignList = useMemo(() => {
    if (!isBirthdayDialogue) return selectedTab === CampaignTabValues.SERVICING;
    return selectedBirthdayTab === BirthdayTabValues.CAMPAIGNS && selectedTab === CampaignTabValues.SERVICING;
  }, [isBirthdayDialogue, selectedTab, selectedBirthdayTab]);

  return (
    <>
      <Typography sx={{ fontSize: 24, fontWeight: '600', marginLeft: 3, marginBottom: 2, marginTop: 3 }}>
        {isBirthdayDialogue ? t('talkbot.eligible_prospects_campaigns') : t('talkbot.eligible_campaigns')}
      </Typography>

      {isBirthdayDialogue ? (
        <Box sx={{ marginLeft: 3 }}>
          <TabBar
            tabs={birthdayTabs}
            currentValue={selectedBirthdayTab}
            onChange={(value) => setSelectedBirthdayTab(value)}
            tabStyle={{ fontSize: 14 }}
          />
        </Box>
      ) : (
        <Box sx={{ marginLeft: 3 }}>
          <TabBar
            tabs={unreadTabs}
            currentValue={selectedTab}
            onChange={(value) => setSelectedTab(value)}
            tabStyle={{ fontSize: 14 }}
          />
        </Box>
      )}

      {isBirthdayDialogue && selectedBirthdayTab === BirthdayTabValues.CAMPAIGNS && (
        <Box sx={{ marginLeft: 3, marginTop: 3 }}>
          {tabs?.map(({ label, value }) => {
            return (
              <ToggleButton
                key={value}
                color="secondary"
                sx={{ marginRight: 2 }}
                value="center"
                aria-label="centered"
                selected={selectedTab === value}
                onClick={() => setSelectedTab(value)}
              >
                <Typography>{label}</Typography>
              </ToggleButton>
            );
          })}
        </Box>
      )}

      <Box sx={{ marginTop: 3 }}>
        {isBirthdayDialogue && selectedBirthdayTab === BirthdayTabValues.PROSPECTS && (
          <ProFilterTable
            filterState={{ sorter: { birthDate: 'asc' } }}
            showFilter={false}
            enableRefresh={false}
            rowKey="eventId"
            columns={getProspectColumns({ t, prospectCategories, flowId: curDialogue.flowId })}
            request={getProspectData}
            ActionNode={(props) => (
              <MultiExpoButton {...props} type={TalkBotExportType.prospect} flowId={curDialogue.flowId} />
            )}
            onClickRow={(row: any) => {
              if (row.prospect._id) {
                history.push(`${PROSPECT_BASE_PATH}${PROSPECT_DETAIL_PATH}/${row.prospect._id}`, {
                  prospectBasicInfo: row.prospect,
                });
              }
            }}
          />
        )}
        {showMarketingCampaignList && (
          <ProFilterTable
            showFilter={false}
            showSelect={false}
            enableRefresh={false}
            rowKey="_id"
            columns={getMarketingColumns({ t, styles })}
            request={getMarketingCampaignData}
            actionRef={marketingActionRef}
            onClickRow={toDetail}
          />
        )}
        {showServicingCampaignList && (
          <ProFilterTable
            showFilter={false}
            showSelect={false}
            enableRefresh={false}
            rowKey="_id"
            columns={getColumns({ t, styles })}
            request={getServicingCampaignData}
            onClickRow={toDetail}
            actionRef={servicingActionRef}
          />
        )}
      </Box>
    </>
  );
});

const UnReadTabLabel = ({ label, count }: { label: string; count: number }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {label}
      {count > 0 && (
        <Stack
          sx={{
            width: 16,
            height: 16,
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 1,
            backgroundColor: '#E8192C',
            color: '#ffffff',
            fontSize: 10,
            fontWeight: 700,
            lineHeight: '12px',
          }}
        >
          {count}
        </Stack>
      )}
    </Box>
  );
};
