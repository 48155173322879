import React, { useState, useEffect, useRef } from 'react';
import { CardContent, Typography } from '@mui/material';
import styles from './audio-player.module.css';

interface AudioPlayerProps {
  audio: ArrayBuffer;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audio }) => {
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [error, setError] = useState<string>('');
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (!audio) return;

    try {
      const uint8Array = new Uint8Array(audio);
      // Process the array in chunks to avoid call stack size exceeded
      const chunkSize = 8192;
      let result = '';
      for (let i = 0; i < uint8Array.length; i += chunkSize) {
        const chunk = Array.from(uint8Array.slice(i, i + chunkSize));
        result += String.fromCharCode.apply(null, chunk);
      }
      const base64String = btoa(result);
      const audioSrc = `data:audio/mp3;base64,${base64String}`;
      setAudioUrl(audioSrc);
    } catch (err) {
      console.error('Error creating audio blob:', err);
      setError('Failed to load audio');
    }
  }, [audio]);

  const handleCanPlay = () => {
    const audioElement = audioRef.current;
    if (audioElement) {
      // Set up audio context when user interacts
      audioElement.play().catch((error) => {
        console.log('Playback prevented:', error);
        setError('Click to enable audio playback');
      });
    }
  };

  return (
    <CardContent className={styles.cardContent}>
      {error && (
        <Typography className={styles.errorText} variant="caption" gutterBottom>
          {error}
        </Typography>
      )}
      {audioUrl && (
        <audio
          ref={audioRef}
          controls
          src={audioUrl}
          className={styles.audioPlayer}
          preload="metadata"
          crossOrigin="use-credentials"
          playsInline
          onCanPlay={handleCanPlay}
        ></audio>
      )}
    </CardContent>
  );
};

export default AudioPlayer;
