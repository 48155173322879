import React from 'react';
import QRCode from 'qrcode';
import { pdf } from '@react-pdf/renderer';
import { RegionLocale } from 'src/app/i18n';
import { EventListItem } from '../../../../types';
import { QRCodePDF } from '../components';

export const downloadQRCodePDF = async (qrCodeContent: string, eventListItem: EventListItem) => {
  const qrCodeDataURL = await QRCode.toDataURL(qrCodeContent);
  const blob = await pdf(
    <QRCodePDF qrCodeDataURL={qrCodeDataURL} eventName={eventListItem.name[RegionLocale.ENGLISH]} />,
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${eventListItem.name[RegionLocale.ENGLISH]}_QR.pdf`;
  link.click();
};
