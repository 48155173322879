import React, { FC, memo, useMemo } from 'react';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useLeadListReminder } from './lead-list-reminder.hook';
import { LeadReminderComponent } from './components/lead-reminder/lead-reminder.component';
import { LeadListFullScreenDialogComponent } from '../lead-list-full-screen-dialog/lead-list-full-screen-dialog.component';
import * as H from 'history';
import { AutoDropReminder } from './components/auto-drop-reminder/auto-drop-reminder.component';
import { Box } from '@mui/material';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  PruLeadsHomeScreenGaDataClick,
  PruLeadsHomeScreenGaDataClick1,
  PruLeadsHomeScreenGaDataClick2,
  PruLeadsHomeScreenGaDataClick3,
  PruLeadsHomeScreenGaDataClick4,
  PruLeadsHomeScreenGaDataClick5,
  reassignLeadGaDataClick,
} from 'src/app/common/ga/types/ga-click-lead';
import { OperationContext } from 'src/app/modules/Leads/components/lead-list-item/lead-operation-units/util/operation-context.util';
import { LeadListDialogType } from '../lead-list-full-screen-dialog/types/types';

type ComponentProps = ParamsProps & {
  history: H.History;
};

export const LeadListReminderComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // custom-hook
  const {
    t,
    openFullScreenDialog,
    setOpenFullScreenDialog,
    showContactLeadReminder,
    setShowContactLeadReminder,
    showAgingLeadReminder,
    setShowAgingLeadReminder,
    showNewLeadReminder,
    setShowNewLeadReminder,
    showReassignLeadReminder,
    reassignData,
    dialogType,
    setDialogType,
    skipTodayReminder,
    oppProviderValue,
  } = useLeadListReminder();
  return (
    <>
      {showContactLeadReminder ? (
        <LeadReminderComponent
          type="reminder"
          reminderText={t('lead.contactCheck.header')}
          confirmText={t('lead.reminder.checkBtn')}
          onClose={() => {
            skipTodayReminder(LeadListDialogType.CONTACT_CHECK);
            setShowContactLeadReminder(false);
            takeUIClickEvent(PruLeadsHomeScreenGaDataClick3);
          }}
          onConfirm={() => {
            setDialogType(LeadListDialogType.CONTACT_CHECK);
            setOpenFullScreenDialog(true);
            takeUIClickEvent(PruLeadsHomeScreenGaDataClick2);
          }}
        />
      ) : null}
      {showNewLeadReminder ? (
        <LeadReminderComponent
          type="reminder"
          reminderText={t('lead.newOpportunities.header')}
          confirmText={t('lead.reminder.checkBtn')}
          onClose={() => {
            skipTodayReminder(LeadListDialogType.NEW_OPPORTUNITIES);
            setShowNewLeadReminder(false);
            takeUIClickEvent(PruLeadsHomeScreenGaDataClick);
          }}
          onConfirm={() => {
            setDialogType(LeadListDialogType.NEW_OPPORTUNITIES);
            setOpenFullScreenDialog(true);
            takeUIClickEvent(PruLeadsHomeScreenGaDataClick1);
          }}
        />
      ) : null}
      {showAgingLeadReminder ? (
        <LeadReminderComponent
          type="reminder"
          reminderText={t('lead.coldLeads.header')}
          confirmText={t('lead.reminder.checkBtn')}
          onClose={() => {
            skipTodayReminder(LeadListDialogType.COLD_LEADS);
            setShowAgingLeadReminder(false);
            takeUIClickEvent(PruLeadsHomeScreenGaDataClick5);
          }}
          onConfirm={() => {
            setDialogType(LeadListDialogType.COLD_LEADS);
            setOpenFullScreenDialog(true);
            takeUIClickEvent(PruLeadsHomeScreenGaDataClick4);
          }}
        />
      ) : null}
      <AutoDropReminder />
      {showReassignLeadReminder ? (
        <LeadReminderComponent
          type="warning"
          reminderText={t('lead.reassign.overview', {
            leadCount: reassignData?.reassignLeadsCount,
            groupString: reassignData?.groupsString,
          })}
          confirmText={t('lead.reminder.checkBtn')}
          onConfirm={() => {
            takeUIClickEvent(reassignLeadGaDataClick);
            props.history.push('/leads/list/reassign');
          }}
        />
      ) : null}
      {showContactLeadReminder || showNewLeadReminder || showAgingLeadReminder || showReassignLeadReminder ? (
        <>
          <OperationContext.Provider value={oppProviderValue}>
            <LeadListFullScreenDialogComponent
              dialogType={dialogType}
              open={openFullScreenDialog}
              onClose={() => {
                setOpenFullScreenDialog(false);
              }}
              onLoad={(type, total) => {
                if (total) return;
                if (type === LeadListDialogType.CONTACT_CHECK) {
                  setShowContactLeadReminder(false);
                } else if (type === LeadListDialogType.COLD_LEADS) {
                  setShowAgingLeadReminder(false);
                } else if (type === LeadListDialogType.NEW_OPPORTUNITIES) {
                  setShowNewLeadReminder(false);
                }
              }}
            />
            <Box marginBottom="24px" />
          </OperationContext.Provider>
        </>
      ) : null}
    </>
  );
});
