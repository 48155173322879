import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import {
  getFollowUpFilterConfig,
  getFollowUpsColumns,
  getOpportunitiesColumns,
  getOpportunitiesFilterConfig,
} from 'src/app/modules/Leads/pages/leads/leads-list/util/list.util';
import {
  getFollowUps,
  getOpportunities,
  getTotalFollowUps,
} from 'src/app/modules/Leads/pages/leads/leads-list/util/api.util';
import { Columns, TabValues } from 'src/app/modules/Leads/types/types';
import { displayCount } from 'src/app/common/utils/display.util';
import { getDropListCount } from 'src/app/modules/Leads/pages/leads/leads-list/components/lead-list-reminder/components/auto-drop-reminder/util/api.util';
import { addExportToColumn } from '../../../common/utils/talkbot.utils';
import { FilterComponentsEnum, ListFilterConfig } from 'src/app/common/components/list-filter';
import { getDialogueList } from '../../../network/talkbot-export-leads-crud';
import { setActiveFlowIds } from '../../../redux/talkbot-redux';
import { DialogueItemType } from '../../../types/talkbot-export-leads-types';
import { getEligibleCampaignsFilter } from './utils/api.util';

interface HookProps {
  cacheParams: React.MutableRefObject<Record<string, any> | undefined>;
}

export const useExportLeadsList = ({ cacheParams }: HookProps) => {
  const { t } = useTranslation();
  const locale = useLang();
  const [selectedTab, setSelectedTab] = useState<string>(cacheParams.current?.selectedTab);
  const [oppCount, setOppCount] = useState<number>();
  const [follCount, setFollCount] = useState<number>();

  // redux
  const dispatch = useDispatch();

  const opp = cacheParams.current?.[TabValues.opportunities];
  const foll = cacheParams.current?.[TabValues.followUp];

  // request
  const { runAsync: runAsyncFoll } = useRequest(
    async () =>
      getTotalFollowUps(foll.filterState, foll.sorter, foll.filter, dispatch, {
        isTalkbotLead: true,
        isExportToTalkbot: false,
      }),
    {
      manual: false,
      onSuccess: (res) => {
        setFollCount(res);
      },
    },
  );
  const { data: dialogueList } = useRequest(() => getDialogueList(dispatch), {
    manual: false,
    onSuccess: (data) => {
      const ids = data?.map((item) => item.flowId);
      dispatch(setActiveFlowIds(ids));
    },
  });
  const { data: campaignItems } = useRequest(async () => getEligibleCampaignsFilter(TabValues.opportunities, dispatch));
  const { data: followUpCampaignItems } = useRequest(async () =>
    getEligibleCampaignsFilter(TabValues.followUp, dispatch),
  );
  const { data: autoDropData } = useRequest(
    () => getDropListCount(dispatch, { isTalkbotLead: true, isExportToTalkbot: false }),
    { manual: false },
  );

  const getTalkbotOpportunities = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
      dispatch?: React.Dispatch<any>,
    ) => {
      return getOpportunities(params, sort, filter, dispatch, { isTalkbotLead: true, isExportToTalkbot: false });
    },
    [],
  );

  const getTalkbotFollowUps = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
      dispatch?: React.Dispatch<any>,
    ) => {
      return getFollowUps(params, sort, filter, dispatch, { isTalkbotLead: true, isExportToTalkbot: false });
    },
    [],
  );

  const tabs = useMemo(() => {
    return [
      { label: `${t('salesOpTitle')}${displayCount(oppCount)}`, value: TabValues.opportunities },
      { label: `${t('salesFuTitle')}${displayCount(follCount)}`, value: TabValues.followUp },
    ];
  }, [follCount, oppCount, t]);

  const onTabChange = useCallback(
    (newVal: string) => {
      if (cacheParams?.current && newVal !== selectedTab) {
        cacheParams.current.selectedTab = newVal;
      }
      setSelectedTab(newVal);
    },
    [cacheParams, selectedTab],
  );

  const getTalkbbotOpportunitiesColumns = useCallback(
    ({ keyword }: { keyword: string }) => {
      const columns = getOpportunitiesColumns({
        keyword,
      }).filter((item) => item.dataIndex !== Columns.opportunityOperation);
      return addExportToColumn(columns, t, true);
    },
    [t],
  );

  const opportunitiesFilterConfigs = useMemo(() => {
    const { sourceList, campaignList } = campaignItems ?? {};
    const filters = getOpportunitiesFilterConfig(t, sourceList, campaignList);
    const newFilters = filterExportLeadListFilter({
      filterList: filters,
      t,
      locale,
      campaignList,
      dialogueList,
    });
    return newFilters;
  }, [t, campaignItems, dialogueList, locale]);

  const getTalkbotFollowUpsColumns = useCallback(
    ({ keyword }: { keyword: string }) => {
      const columns = getFollowUpsColumns({
        keyword,
      }).filter((item) => item.dataIndex !== Columns.followUpOperation);
      return addExportToColumn(columns, t);
    },
    [t],
  );

  const followUpFilterConfigs = useMemo(() => {
    const { campaignList, sourceList } = followUpCampaignItems ?? {};
    const filters = getFollowUpFilterConfig(t, sourceList, campaignList)?.filter(
      (item) => item.key !== 'typeOfLeadSource' && item.key !== 'status' && item.key !== 'agentLeadSource',
    );
    const newFilters = filterExportLeadListFilter({
      filterList: filters,
      t,
      locale,
      campaignList,
      dialogueList,
    });
    return newFilters;
  }, [t, followUpCampaignItems, dialogueList, locale]);

  const updatePageInfo = useCallback((updateFunc: (val: number) => void, pageInfo?: { total?: number }) => {
    if (typeof pageInfo?.total === 'number') {
      updateFunc(pageInfo?.total);
    }
  }, []);

  return {
    autoDropData,
    tabs,
    selectedTab,
    getTalkbotOpportunities,
    getTalkbbotOpportunitiesColumns,
    opportunitiesFilterConfigs,
    getTalkbotFollowUps,
    getTalkbotFollowUpsColumns,
    followUpFilterConfigs,
    setSelectedTab,
    onTabChange,
    setOppCount,
    setFollCount,
    updatePageInfo,
  };
};

const filterExportLeadListFilter = ({
  filterList,
  t,
  locale,
  campaignList,
  dialogueList,
}: {
  filterList: ListFilterConfig[];
  t: (val: string) => string;
  locale: any;
  campaignList?: { itemKey: string; itemLabel: string }[];
  dialogueList?: DialogueItemType[];
}) => {
  if (!campaignList?.length) {
    filterList = filterList.filter((item) => item.key !== 'campaign' && item.key !== 'marketingLeadSource');
  }
  filterList.unshift({
    key: 'talkbotFlowIds',
    title: t('talkbot.export_leads.talkbot_filter_label'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: dialogueList
      ? dialogueList.map((item) => ({ itemKey: String(item.flowId), itemLabel: item.name[locale] || '' }))
      : [],
  });

  return filterList;
};
