import React, { useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ListFilterConfig, ListFilterData } from 'src/app/common/components/list-filter/list-filter.component';
import { isEmpty } from 'lodash';
import { isEmptyArray } from 'formik';
import { valueToDisplay } from 'src/app/common/components/list-filter/util/list-filter.util';

interface HookProps {
  configs: Array<ListFilterConfig>;
  value: ListFilterData;
}

export const useInsightFilter = ({ value, configs }: HookProps) => {
  // i18n
  const intl = useIntl();
  const Translation = useCallback((id: string) => intl.formatMessage({ id }), [intl]);

  const [openFilter, setOpenFilter] = useState(false);

  const itemText = useMemo(() => {
    let optionsText: Array<string> = [];
    configs
      .filter((i) => !i.visible || i.visible(value))
      .forEach((config) => {
        if (!isEmpty(value[config.key])) {
          optionsText = [...optionsText, valueToDisplay(value, config)];
        }
      });
    return isEmptyArray(optionsText) ? [Translation('app.input.placeholder.please-select')] : optionsText;
  }, [Translation, configs, value]);

  return {
    itemText,
    openFilter,
    setOpenFilter,
  };
};
