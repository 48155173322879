import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InsightSession } from 'src/app/modules/Insight/types/types';
import { getMassLeadDetails } from 'src/app/modules/Insight/network/insightCrud';
import { getPercentage } from 'src/app/modules/Insight/util/insight.util';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

export type CampaignDetail = {
  name: string;
  startDate: string;
  endDate: string;
  detail: {
    total: number;
    accepted: number;
    rejected: number;
    other: number;
    AcceptedPolicyIssued: number;
    AcceptedNotConverted: number;
  };
};

export const useLeadDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentAgentCode =
    sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode();
  const [summary, setSummary] = useState<any[]>([]);
  const [receivedLead, setReceivedLead] = useState<number>(0);
  const [activeCampaign, setActiveCampaign] = useState<CampaignDetail[]>([]);
  const [showBarChart, setShowBarChart] = useState<boolean>(false);

  useEffect(() => {
    if (currentAgentCode) {
      getMassLeadDetails({ agentCode: currentAgentCode }, dispatch).then((res) => {
        const { campaigns, summary } = res;
        if (campaigns) {
          setActiveCampaign(campaigns);
        }
        if (summary) {
          setReceivedLead(summary.total);
          const sum = summary.accepted + summary.rejected + summary.other;
          setSummary([
            {
              label: t('mass_lead_accepted'),
              value: summary.accepted,
              color: '#24C791',
            },
            {
              label: t('mass_lead_rejected'),
              value: summary.rejected,
              color: '#FF872E',
            },
            {
              label: t('mass_lead_pending'),
              value: summary.other,
              color: '#D9D9D9',
            },
          ]);
          setShowBarChart(sum > 0);
        }
      });
    }
  }, []);

  return { receivedLead, activeCampaign, summary, showBarChart };
};
