import React, { useCallback, useMemo, useState } from 'react';

interface HookProps {
  initSeries: {
    name: string;
    data: {
      x: any;
      y: string | number;
    }[];
  }[];
  initHighlightX?: number[];
}

export const RANG = 9;

export const useChartPagination = ({ initSeries, initHighlightX }: HookProps) => {
  const [page, setPage] = useState(0); // 0 means last page
  const range = RANG + 1; // display 10 dot
  const [displaySeries, start, end] = useMemo(() => {
    let start = initSeries[0].data.length - range * (page + 1);
    let end = initSeries[0].data.length - range * page;
    start = start < 0 ? 0 : start >= initSeries[0].data.length ? initSeries[0].data.length : start;
    end = end > initSeries[0].data.length ? initSeries[0].data.length : end < 0 ? 0 : end;
    return [
      initSeries.map((i) => {
        return { name: i.name, data: i.data.slice(start, end) };
      }),
      start,
      end,
    ];
  }, [initSeries, page, range]);

  const disablePrevious = useMemo(() => start <= 0, [start]);

  const disableNext = useMemo(() => {
    const len = initSeries[0].data.length;
    return end >= len;
  }, [end, initSeries]);

  const maxPage = useMemo(() => Math.ceil(initSeries[0].data.length / range), [initSeries, range]);

  const onPageChange = useCallback(
    (val: 'previous' | 'next') => {
      if (val === 'previous') {
        setPage((p) => {
          const i = p + 1;
          return i > maxPage ? maxPage : i;
        });
      } else {
        setPage((p) => {
          const i = p - 1;
          return i < 0 ? 0 : i;
        });
      }
    },
    [maxPage],
  );

  const highlightX = useMemo(() => {
    if (!initHighlightX) return initHighlightX;
    const hightItem = initHighlightX.map((x) => initSeries?.[0].data?.[x]); // index to data
    return hightItem.map((h) => displaySeries?.[0].data?.findIndex((item) => h === item));
  }, [displaySeries, initHighlightX, initSeries]);

  return {
    displaySeries,
    disablePrevious,
    disableNext,
    onPageChange,
    highlightX,
    maxPage,
    page,
  };
};
