import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Button, Divider, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { DialogueItemType } from 'src/app/modules/ai-talkbot/types/talkbot-export-leads-types';
import { useLang } from 'src/app/i18n';
import { debounce } from 'lodash';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  curDialogue: DialogueItemType;
};

const MAX_CONTENT_HEIGHT = 140;

export const TalkbotDialogueDescComponent: React.FC<ComponentProps> = memo(({ curDialogue }: ComponentProps) => {
  // i18n
  const { t } = useTranslation();
  const locale = useLang();

  const [collapse, setCollapse] = useState(true);
  const [showViewMore, setShowViewMore] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    const contentHeight = contentRef.current?.offsetHeight;
    if (contentHeight && contentHeight > MAX_CONTENT_HEIGHT) {
      setShowViewMore(true);
    } else {
      setShowViewMore(false);
    }
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize, curDialogue]);

  useEffect(() => {
    const handleDebounceResize = debounce(() => {
      handleResize();
    }, 300);

    window.addEventListener('resize', handleDebounceResize);

    return () => {
      window.removeEventListener('resize', handleDebounceResize);
    };
  }, [handleResize]);

  return !!curDialogue?.description?.[locale] ? (
    <>
      <Box sx={{ padding: 3, paddingBottom: 0 }}>
        <Typography className="tw-break-words" sx={{ fontSize: 24, fontWeight: '600', marginBottom: 3 }}>
          {curDialogue?.name[locale]}
        </Typography>
        <Box className={`tw-relative tw-w-full ${showViewMore && collapse ? 'tw-h-[150px] tw-overflow-hidden' : ''}`}>
          <div
            ref={contentRef}
            dangerouslySetInnerHTML={{
              __html: curDialogue?.description?.[locale] || '',
            }}
          />

          {showViewMore && collapse && (
            <Box
              className="tw-w-full tw-h-[100px] tw-absolute tw-bottom-0 tw-left-0 tw-flex tw-flex-row tw-items-end tw-justify-center"
              sx={{
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) -4%, #fff 46.15%)',
                paddingBottom: 1,
              }}
            >
              <Button className="tw-items-center" onClick={() => setCollapse(false)}>
                <Typography sx={{ fontSize: 14, fontWeight: '500' }}>{t('talkbot.view_more')}</Typography>
                <KeyboardArrowDown fontSize="medium" />
              </Button>
            </Box>
          )}

          {showViewMore && !collapse && (
            <Box sx={{ marginTop: 3, paddingBottom: 1 }} className="tw-flex tw-flex-row tw-items-end tw-justify-center">
              <Button className="tw-items-center" onClick={() => setCollapse(true)}>
                <Typography sx={{ fontSize: 14, fontWeight: '500' }}>{t('talkbot.view_less')}</Typography>
                <KeyboardArrowUp fontSize="medium" />
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Divider sx={{ marginTop: 3 }} />
    </>
  ) : null;
});
