import React, { FC, memo, useMemo, useRef } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { FilterTableContainer } from 'src/app/common/components/filter-table-container/filter-table-container.component';
import { ActionItems } from './action-items/action-items.component';
import { getOpportunities } from '../../../../util/api.util';
import { openLeadDetailInNewTab } from 'src/app/modules/Leads/util/leads.util';
import { getOpportunitiesColumns } from '../../../../util/list.util';
import { OperationContext } from 'src/app/modules/Leads/components/lead-list-item/lead-operation-units/util/operation-context.util';
import {
  youGotANewOpportunityGaDataClick,
  youGotANewOpportunityGaDataClick1,
} from 'src/app/common/ga/types/ga-click-lead';

type ComponentProps = ParamsProps & {
  onLoad?: (
    record: any[],
    pageInfo?: {
      page?: number;
      pageSize?: number;
      total?: number;
    },
  ) => void;
};

export const NewOpportunitiesComponent: React.FC<ComponentProps> = memo(({ onLoad }: ComponentProps) => {
  const getColumns = useMemo(() => {
    return () => getOpportunitiesColumns({});
  }, []);

  const cacheParams = useRef({ sorter: { updatedAt: 'desc' } });
  const oppProviderValue = useMemo(() => {
    return {
      gaData: {
        accept: youGotANewOpportunityGaDataClick,
        reject: youGotANewOpportunityGaDataClick1,
      },
    };
  }, []);

  return (
    <OperationContext.Provider value={oppProviderValue}>
      <FilterTableContainer
        rowKey="_id"
        cacheParams={cacheParams}
        getData={getOpportunities}
        getColumns={getColumns}
        showFilter={false}
        ActionNode={ActionItems}
        onClickRow={(record) => openLeadDetailInNewTab(record)}
        onLoad={onLoad}
      />
    </OperationContext.Provider>
  );
});
