import React, { memo, useEffect, useMemo, useState } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './campaign-lead-list.style';
import { useCampaignLeadList } from './campaign-lead-list.hook';
import ProFilterTable, { ActionNodeTypeProps } from 'src/app/common/components/ProTable';
import { CampaignDetail, CampaignLeadItem, CampaignTypeEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { ActionItems } from './action-items/action-items.component';
import { useSelector } from 'react-redux';
import { SelectDownline } from 'src/app/common/components/select-downline/select-downline.component';
import { CategorySelectorComponent } from 'src/app/common/components/category-selector/category-selector.component';
import { FilterProps } from 'src/app/common/components/ProTable/ProFilter';
import { Option } from 'src/app/common/components/cascading-selector/cascading-selector.component';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { useHistory } from 'react-router-dom';
import { LEADS_DETAIL_PATH } from 'src/app/modules/Leads/constants';
import { OperationContext } from 'src/app/modules/Leads/components/lead-list-item/lead-operation-units/util/operation-context.util';
import { Typography } from '@mui/material';
import { Translation } from 'src/app/i18n';
import { AiTalkbotItemComponent } from './ai-talkbot-item/ai-talkbot-item.component';
import { AutoCallDialogComponent } from 'src/app/modules/ai-talkbot/common/components/auto-call-dialog/auto-call-dialog.component';
import { getEligibleLeads } from 'src/app/modules/ai-talkbot/common/utils/talkbot.utils';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  data: CampaignDetail;
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  campaignId: string;
};

const TabLeadFilterComponent: React.FC<any> = memo(
  ({
    cacheParams,
    campaignId,
    agentCode,
    filterState,
    setFilterState,
    onExtraFilterChange,
    onSave,
    setDisabledOp,
    data,
  }: any) => {
    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);
    const [agentCodes, setAgentCodes] = React.useState<string[]>([agentCode]);
    useEffect(() => {
      if (filterState.agentCodes) {
        setAgentCodes(filterState.agentCodes);
      }
    }, []);
    useEffect(() => {
      if (agentCodes[0] === globalStore.getAgentCode() && agentCodes.length === 1) setDisabledOp(false);
      else setDisabledOp(true);
    }, [agentCodes, setDisabledOp]);

    return (
      <div className={styles.filterContainer}>
        <div className={styles.selectorConatiner}>
          <SelectDownline
            campaignId={campaignId}
            currentOption={
              cacheParams?.current?.filterState.downline ?? [
                { label: '', value: '' },
                { label: '', value: globalStore.getAgentCode() },
              ]
            }
            onChange={(option: Array<Option>, codeArr: Array<string>) => {
              const updatedLeadState = { ...filterState };
              updatedLeadState.downline = option;
              updatedLeadState.agentCodes = codeArr;
              setAgentCodes(codeArr);
              setFilterState(updatedLeadState);
              onSave(updatedLeadState);
            }}
            campaignType={CampaignTypeEnum.normal}
          />
        </div>
        <Typography fontSize={16} fontWeight={600} marginBottom={'24px'}>
          {Translation('campaign.detail.leadOverview')}
        </Typography>

        <div className={styles.selectorConatiner}>
          <CategorySelectorComponent
            campaignId={campaignId}
            agentCodes={agentCodes}
            onChange={(e) => {
              const updatedLeadState = { ...filterState };
              updatedLeadState.leadState = e;
              setFilterState(updatedLeadState);
              onSave(updatedLeadState);
            }}
            value={cacheParams?.current?.filterState?.leadState || 'total'}
          />
        </div>

        {data?.talkbotRule?.enabled && (
          <AiTalkbotItemComponent
            flowId={data?.talkbotRule?.flowId}
            exportedCount={data.talkbotExportedCount}
            calledCount={data.talkbotCalledCount}
          />
        )}

        <Typography fontSize={16} fontWeight={600} marginBottom={'24px'}>
          {Translation('details')}
        </Typography>
      </div>
    );
  },
);

export const MarketingCampaignLeadListComponent: React.FC<ComponentProps> = memo(
  ({ data, cacheParams, campaignId }) => {
    const {
      columns,
      getData,
      rowSelection,
      onFilterChange,
      oppProviderValue,
      setDisabledOp,
      disabledOp,
      exportData,
      setExportData,
      activeFlowIds,
    } = useCampaignLeadList({
      data,
      cacheParams,
      campaignId,
    });
    const history = useHistory();
    const toLead = (i: any) => {
      if (!disabledOp) history.push(`${LEADS_DETAIL_PATH}/${i._id}`);
    };
    const user: any = useSelector<any>((state) => state.auth.user);
    const Filter = useMemo(() => {
      return (props: FilterProps<any>) => (
        <TabLeadFilterComponent
          {...props}
          campaignId={campaignId}
          agentCode={user?.agentCode ?? ''}
          cacheParams={cacheParams}
          setDisabledOp={setDisabledOp}
          data={data}
        />
      );
    }, [cacheParams, user.agentCode, campaignId]);

    useEffect(() => {
      return () => {
        cacheParams?.current && (cacheParams.current = undefined);
      };
    }, []);

    const ActionNode = useMemo(() => {
      return (props: ActionNodeTypeProps<CampaignLeadItem>) => (
        <ActionItems
          {...props}
          onAutoCall={(selectedRows) => setExportData(getEligibleLeads(selectedRows, activeFlowIds))}
        />
      );
    }, [activeFlowIds, setExportData]);

    return (
      <OperationContext.Provider value={oppProviderValue}>
        <ProFilterTable
          Filter={Filter}
          filterState={cacheParams?.current}
          rowKey="_id"
          rowSelection={rowSelection}
          columns={columns}
          request={getData}
          enableRefresh={false}
          ActionNode={ActionNode}
          showSelect={true}
          onFilterChange={onFilterChange}
          onClickRow={toLead}
        />
        {exportData && exportData?.length > 0 && (
          <AutoCallDialogComponent items={exportData} onCancel={() => setExportData(undefined)} />
        )}
      </OperationContext.Provider>
    );
  },
);
