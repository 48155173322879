import React, { useCallback, useMemo } from 'react';
import { calcPercentage, sum } from './util/percentage-bar-chart.util';

export interface PercentageBarChartHookProps {
  data: { label?: string; value: number; color: string }[];
  valueToLabel?: (value: number) => string;
}

export const usePercentageBarChart = ({ data, valueToLabel }: PercentageBarChartHookProps) => {
  const total = useMemo(() => sum(data), [data]);

  const formatLabel = useCallback(
    (per: number, label?: string) => {
      if (label) {
        return label + ' ' + per + '%';
      }
      return valueToLabel ? valueToLabel(per) : per + '%';
    },
    [valueToLabel],
  );
  const barOptions = useMemo(
    () =>
      data.map(({ value, label, color: bgColor }) => {
        const percentage = calcPercentage(value * 100, total);
        const flex = calcPercentage(value * 100, total);
        return {
          bgColor,
          label: label ? formatLabel(percentage, label) : formatLabel(percentage),
          flex,
        };
      }),
    [data, total, formatLabel],
  );

  return {
    total,
    barOptions,
    formatLabel,
  };
};
