import { useRequest } from 'ahooks';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FilterComponentsEnum } from 'src/app/common/components/list-filter/filter-components.enum';
import { ListFilterConfig, ListFilterData } from 'src/app/common/components/list-filter/list-filter.component';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { RootState } from 'src/redux/store';
import { getParticipatedType } from '../../network/insightCrud';
import { insightAction } from '../../redux/insight.slice';
import { leadStatusDetailFilter } from '../../redux/insight.type';
import { CampaignType, InsightSession, LeadSourceEnum } from '../../types/types';

export enum TabEnum {
  details = 'details',
  graphs = 'graphs',
}

export const useLeadStatusDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = useSelector<RootState>((state) => state.insight?.leadStatusDetailFilter) as leadStatusDetailFilter;
  const defaultFilterValue = useMemo(() => {
    return {
      source: LeadSourceEnum.all,
      campaignTypeId: LeadSourceEnum.all,
      dateRange: {
        key: 'ALL',
        value: {
          startDate: '',
          endDate: '',
          label: t('filter.date.all'),
        },
      },
    };
  }, [t]);
  const defaultValues = defaultFilterValue;
  const tabs = [
    { label: t('appointment_details'), value: TabEnum.details },
    { label: t('graphs'), value: TabEnum.graphs },
  ];
  const [tabValue, setTabValue] = useState<string>(TabEnum.details);

  const handleTabChange = (value: string) => {
    setTabValue(value);
  };

  const [filterValues, setFilterValues] = useState<ListFilterData>(filter?.filterValues || defaultFilterValue);

  // useRequest to wrap api request.
  const { data, error, loading, run, runAsync } = useRequest(() => {
    const agentCode = sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode();
    const body = { agentCode: agentCode };
    return getParticipatedType(body).then((response) => {
      const arr = response.data;
      if (!arr || arr.length < 1) {
        return;
      }

      arr.sort((a: CampaignType, b: CampaignType) => a?.campaignTypeName?.localeCompare(b?.campaignTypeName));
      // campaign type list
      let options = [
        {
          itemLabel: t('insights_all'),
          itemKey: 'all',
        },
      ];
      arr.forEach((item: CampaignType) => {
        options.push({
          itemLabel: item.campaignTypeName + '',
          itemKey: item._id + '',
        });
      });
      options.push({
        itemLabel: t('none_acm_type'),
        itemKey: 'None',
      });

      const campaignTypeConfig: ListFilterConfig = {
        title: t('acm_type'),
        key: 'campaignTypeId',
        type: FilterComponentsEnum.PURE_RADIO_GROUP,
        items: options,
      };
      return campaignTypeConfig;
    });
  });

  // filter config: use default-config if no redux data
  const config = useMemo(() => {
    const defaultFilter = [
      ...(filter?.filterConfig || [
        {
          title: t('insights_new_added_date'),
          items: [],
          key: 'dateRange',
          type: FilterComponentsEnum.RADIO_GROUP, // select date range
        },
        {
          title: t('lead_source'),
          key: 'source',
          type: FilterComponentsEnum.PURE_RADIO_GROUP,
          items: [
            { itemKey: LeadSourceEnum.all, itemLabel: t('leads_status_all_source') },
            { itemKey: LeadSourceEnum.marketingSource, itemLabel: t('leads_status_marketing_source') },
            { itemKey: LeadSourceEnum.agentSource, itemLabel: t('leads_status_agent_source') },
          ],
        },
      ]),
    ].filter((i) => i.key !== 'campaignTypeId');
    return data
      ? [
          ...defaultFilter,
          {
            ...data,
            visible: (val: ListFilterData) => val.source === LeadSourceEnum.agentSource,
          },
        ]
      : defaultFilter;
  }, [data, filter?.filterConfig, t]);

  const onFilterConfirm = useCallback(
    (value: ListFilterData) => {
      const val = value;
      if (val && value.source !== LeadSourceEnum.agentSource) {
        val['campaignTypeId'] = 'all'; // reset default val
      }

      dispatch(
        insightAction.setLeadStatusDetailFilter({
          filterConfig: config,
          filterValues: val,
        }),
      );
      setFilterValues(val);
    },
    [config, dispatch],
  );

  const onFilterSelect = useCallback((value: ListFilterData) => {}, []);

  return {
    loading,
    data,
    filterConfig: config,
    filterValues,
    tabs,
    tabValue,
    setFilterValues,
    onFilterConfirm,
    onFilterSelect,
    defaultValues,
    handleTabChange,
  };
};
