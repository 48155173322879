import React, { FC, memo, useMemo } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { useTranslation } from 'src/app/common/hooks/translation.hook';
import styles from './lead-status-history.module.css';
import { FollowUp, LeadCategoryEnum } from '../../util/lead-status/lead-status.enum';
import { StepIconComponent } from '../lead-status-stepper/step-icon/step-icon.component';
import { convertStatusHistory, formatDeadReason } from '../../util/lead-status/lead-status.util';
import { nth } from 'lodash';
import moment from 'moment';
import { DateFormat } from 'src/app/common/utils/date.util';
import { FollowUpStatusEnum } from 'src/app/modules/Campaign/types/campaign-types';

type ComponentProps = {
  followUp: Array<FollowUp>; // exactly the same as DB field:lead-coe-dev-encrypt.lead.followUp
  leadCategory: LeadCategoryEnum; //'RecruitLead' | 'SalesLead';
  leadCreatedAt: string; // date string
};

// leas-status vertical
export const LeadStatusHistoryComponent: React.FC<ComponentProps> = memo(
  ({ leadCreatedAt, followUp }: ComponentProps) => {
    // i18n
    const { t: Translation } = useTranslation();

    const { stageBarList, isClosed } = convertStatusHistory(followUp, leadCreatedAt);
    const labelDay = Translation(`campaign.lead.followUp.day`);
    const labelDays = Translation(`campaign.lead.followUp.days`);
    const labelDuration = Translation(`campaign.lead.followUp.druation`);

    return (
      <Timeline className={styles.wrap}>
        {stageBarList.map((item, index) => {
          const connectorClassName = item.isActive ? styles.connectorActive : '';
          const labelClassName = item.isActive ? styles.labelActive : styles.labelInactive;
          // status label
          const label = Translation(`campaign.lead.followUp.${item.status}`);
          let duration = '';
          const startDate = index === 0 ? leadCreatedAt : nth(followUp, index - 1)?.createdAt;
          const endDate = item.createdAt;
          const date = endDate ? moment(endDate).format(DateFormat.datetime) : '';
          if (endDate && startDate) {
            // Round down. Example: 3d14h => 3d, 12h => <1d
            const daysDiff = moment(endDate).diff(moment(startDate), 'days') || 0;
            if (daysDiff === 1) {
              duration = `${labelDuration}: 1 ${labelDay}`;
            } else if (daysDiff > 1) {
              duration = `${labelDuration}: ${daysDiff} ${labelDays}`;
            } else {
              duration = `${labelDuration}: <1 ${labelDay}`;
            }
          }
          let deadReasons = '';
          if (item.icon === FollowUpStatusEnum.dead) {
            deadReasons = formatDeadReason(Translation, item.reasons, item.reason);
          }
          let policyNumber = '';
          if (item.status === FollowUpStatusEnum.policy) {
            policyNumber = ` / ${Translation('policy_number')}: `;
            if (item.issuedPolicy && item.issuedPolicy.length > 0) {
              policyNumber += item.issuedPolicy.map((policy) => policy.policyNumber).join(';');
            }
          }

          return (
            <TimelineItem key={index}>
              <TimelineOppositeContent className={styles.content}>{date}</TimelineOppositeContent>
              <TimelineSeparator className={connectorClassName}>
                <StepIconComponent icon={item.icon} isClosed={isClosed} />
                {!item.isFirst && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent className={styles.content}>
                <div className={labelClassName}>
                  <div>
                    {label}
                    {policyNumber}
                  </div>
                  <div className={styles.reason}>{duration}</div>
                  {deadReasons ? <div className={styles.reason}>{deadReasons}</div> : null}
                </div>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  },
);
