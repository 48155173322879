import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { last_30_days_rages } from 'src/app/common/components/list-filter/components/list-filter-date-radio-group/util/list-filter-date-radio.util';
import { getLeadsStatus } from '../../../network/insightCrud';
import { ListFilterConfig, ListFilterData } from 'src/app/common/components/list-filter/list-filter.component';
import { FilterComponentsEnum } from 'src/app/common/components/list-filter/filter-components.enum';
import { insightAction } from '../../../redux/insight.slice';
import { RootState } from 'src/redux/store';

interface HookProps {
  // agent-code string, split by comma,
  // example: "00010001" or "00010001,00010002,00010003"
  agentCode?: string;
}

export const useLeadStatus = ({ agentCode }: HookProps) => {
  // i18n
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultRange = last_30_days_rages();

  const filterData = useSelector<RootState>((state) => state.insight?.overviewLeadStatusFilter) as ListFilterData;

  // const saleskit = useSelector<RootState>((state) => state.saleskit);
  const agentCodeList = agentCode || globalStore.getAgentCode();
  const defaultValues = {
    dateRange: {
      key: 'last_30_days',
      value: {
        startDate: defaultRange.startDate,
        endDate: defaultRange.endDate,
        label: t('last_30_days'),
      },
    },
  };
  const [filterValues, setFilterValues] = useState<ListFilterData>(filterData || defaultValues);
  const filterConfig: ListFilterConfig[] = [
    {
      title: t('insights_new_added_date'),
      items: [],
      key: 'dateRange',
      type: FilterComponentsEnum.RADIO_GROUP, // select date range
    },
  ];

  const onFilterConfirm = useCallback((filterData: ListFilterData) => {
    setFilterValues(filterData);
    dispatch(insightAction.setOverviewLeadStatusFilter(filterData));
  }, []);

  const { data, error, loading, run } = useRequest(
    () => {
      const body = {
        agentCode: agentCodeList,
        startDate: get(filterValues, 'dateRange.value.startDate') as string,
        endDate: get(filterValues, 'dateRange.value.endDate') as string,
      };
      return getLeadsStatus(body).then((response) => {
        const { followUp, agingLeads, contacted, policyIssued } = response.data;
        const result = [
          {
            value: followUp ?? '-',
            label: t('follow_up'),
          },
          {
            value: agingLeads ?? '-',
            label: t('aging_leads'),
          },
          {
            value: contacted ?? '-',
            label: t('contacted'),
          },
          {
            value: policyIssued ?? '-',
            label: t('policy_issued'),
          },
        ];
        return result;
      });
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    // manual call api request
    if (agentCodeList) {
      run();
    }
  }, [agentCodeList, filterValues]);

  const handlePress = useCallback(() => {
    history.push('./lead-status');
  }, []);

  const defaultData = useMemo(() => {
    const result = [
      {
        value: '-',
        label: t('follow_up'),
      },
      {
        value: '-',
        label: t('aging_leads'),
      },
      {
        value: '-',
        label: t('contacted'),
      },
      {
        value: '-',
        label: t('policy_issued'),
      },
    ];
    return result;
  }, []);

  const tooltip = useMemo(() => {
    const tooltip = [
      {
        title: t('follow_up'),
        content: t('lead_des1'),
      },
      {
        title: t('aging_leads'),
        content: t('lead_des2'),
      },
      {
        title: t('contacted'),
        content: t('lead_des3'),
      },
      {
        title: t('policy_issued'),
        content: t('lead_des4'),
      },
    ];
    return tooltip;
  }, []);

  return {
    tooltip,
    data: loading ? defaultData : data || defaultData,
    filterValues,
    defaultValues,
    filterConfig,
    run,
    handlePress,
    onFilterConfirm,
  };
};
