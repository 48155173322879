import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { getServicingCampaignSummary } from '../../../network/insightCrud';

interface HookProps {
  // agent-code string, split by comma,
  // example: "00010001" or "00010001,00010002,00010003"
  agentCode?: string;
}

export const useServicingCampaign = ({ agentCode }: HookProps) => {
  // i18n
  const history = useHistory();
  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  // useRequest to wrap api request.
  const agentCodeList = agentCode || globalStore.getAgentCode();
  const { data, error, loading, run, runAsync } = useRequest(
    () => {
      return getServicingCampaignSummary({ agentCode: agentCodeList }).then((response) => {
        const { totalFeedback, activeCampaign } = response.data;
        const result = [
          {
            value: totalFeedback ?? '-',
            label: t('insights_servicing_campaign_customers'),
          },
          {
            value: activeCampaign ?? '-',
            label: t('insights_servicing_campaign_active'),
          },
        ];
        return result;
      });
    },
    {
      manual: true,
    },
  );
  const handlePress = useCallback(() => {
    history.push('./servicing-campaign');
  }, []);

  useEffect(() => {
    // manual call api request
    if (agentCodeList) {
      run();
    }
  }, [agentCodeList]);

  const defaultData = useMemo(() => {
    const result = [
      {
        value: '-',
        label: t('insights_servicing_campaign_customers'),
      },
      {
        value: '-',
        label: t('insights_servicing_campaign_active'),
      },
    ];
    return result;
  }, []);

  const tooltip = useMemo(() => {
    const tooltip = [
      {
        title: t('insights_servicing_campaign_customers'),
        content: t('insights_servicing_campaign_customers_des'),
      },
      {
        title: t('insights_servicing_campaign_active'),
        content: t('insights_servicing_campaign_active_des'),
      },
    ];
    return tooltip;
  }, []);

  return {
    loading,
    data: loading ? defaultData : data || defaultData,
    tooltip,
    run,
    handlePress,
  };
};
