import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Card } from 'src/app/common/components/card/card.component';
import { ParticipantOverview, SessionOverview } from 'src/app/modules/event-v2/types';
import { renderValue } from 'src/app/modules/event-v2/util/event-v2.util';
import { sessionKeyToDisplayName } from '../../../../common';
import { useLang } from 'src/app/i18n';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  data?: ParticipantOverview | SessionOverview;
  walkInAllowed: boolean;
};

export const OverviewComponent: React.FC<ComponentProps> = memo(({ data, walkInAllowed }: ComponentProps) => {
  // i18n
  const { t } = useTranslation();
  const locale = useLang();

  const overviewItems = useMemo(() => {
    const { quota, registered, attended, attendanceRate, walkin } = data ?? {};
    const config = [
      { label: t('event.form.quota'), value: renderValue(quota) },
      { label: t('event.form.total_registered'), value: renderValue(registered) },
      { label: t('event.form.attended'), value: renderValue(attended) },
      { label: t('event.form.attendance_rate'), value: renderValue(attendanceRate) },
    ];
    if (walkInAllowed) {
      config.push({ label: t('event.form.walk_ins'), value: renderValue(walkin) });
    }
    return config;
  }, [data]);

  return (
    <Card sx={{ p: 3 }}>
      <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
        {t('event.form.overview')}
        {(data as SessionOverview)?.key &&
          `(${sessionKeyToDisplayName((data as SessionOverview)?.key)} - ${(data as SessionOverview)?.name[locale]})`}
      </Typography>
      <Stack
        sx={{ mt: 3 }}
        onClick={() => null}
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={5}
      >
        {overviewItems.map(({ label, value }) => {
          return (
            <Stack key={label}>
              <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#999' }}>{label}</Typography>
              <Typography sx={{ fontSize: 30, fontWeight: 700 }}>{value}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
});
