import dayjs, { Dayjs } from 'dayjs';
import { MobileTimePicker, MobileTimePickerProps } from '@mui/x-date-pickers';
import { InputAdornment, IconButton, TextFieldProps } from '@mui/material';
import PruIcon from 'src/app/common/components/PruIcon';
import { useStyles } from './date-picker.style';

type PruTimePickerProps = {
  value: Date | null;
  minTime?: Date | null;
  maxTime?: Date | null;
  onChange?: (value: Date | null) => void;
} & Omit<MobileTimePickerProps<Dayjs>, 'value' | 'minTime' | 'maxTime' | 'onChange'>;

const PruTimePicker = ({ disabled, value, minTime, maxTime, onChange, slotProps, ...props }: PruTimePickerProps) => {
  const { classes } = useStyles();
  const textFieldProps = (slotProps?.textField as TextFieldProps) || {};
  return (
    <MobileTimePicker
      disabled={disabled}
      value={value ? dayjs(value) : null}
      minTime={minTime ? dayjs(minTime) : undefined}
      maxTime={maxTime ? dayjs(maxTime) : undefined}
      onChange={(newValue) => onChange && onChange(newValue ? newValue.toDate() : null)}
      slotProps={{
        ...slotProps,
        actionBar: slotProps?.actionBar
          ? slotProps.actionBar
          : {
              actions: ['clear', 'cancel', 'accept'],
            },
        textField: {
          ...textFieldProps,
          variant: textFieldProps.variant ? textFieldProps.variant : 'outlined',
          InputProps: {
            ...textFieldProps.InputProps,
            endAdornment: textFieldProps.InputProps?.endAdornment ? (
              textFieldProps.InputProps?.endAdornment
            ) : (
              <InputAdornment position="end">
                <IconButton
                  disabled={disabled}
                  className={
                    textFieldProps.variant === 'standard'
                      ? classes.standardDateTimePickerIcon
                      : classes.dateTimePickerIcon
                  }
                >
                  <PruIcon icon="time" style={{ fontSize: 20 }} />
                </IconButton>
              </InputAdornment>
            ),
          },
        },
      }}
      {...props}
    />
  );
};

export default PruTimePicker;
