import React, { FC, memo, useMemo } from 'react';
import styles from './tooltip.module.css';
import { TooltipComponent as CustomTooltip } from 'src/app/common/components/tooltip/tooltip.component';

type ComponentProps = {
  tooltip?: Array<{ title: string; content: string }>;
};

export const TooltipComponent: React.FC<ComponentProps> = memo(({ tooltip }: ComponentProps) => {
  if (!tooltip) {
    return null;
  }
  return (
    <CustomTooltip
      title={
        <div className={styles.wrap}>
          {tooltip.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <h2>{item.title}</h2>
                <span>{item.content}</span>
              </React.Fragment>
            );
          })}
        </div>
      }
    />
  );
});
