import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Button } from '@mui/material';

import { useLeadStatusDetailList } from './lead-status-detail-list.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import ProFilterTable from 'src/app/common/components/ProTable';
import { OperationContext } from 'src/app/modules/Leads/components/lead-list-item/lead-operation-units/util/operation-context.util';
import { LEADS_DETAIL_PATH } from 'src/app/modules/Leads/constants';

export const LeadStatusDetailListPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  // custom-hook
  const { title, tableColumns, getData, defaultParams, oppProviderValue } = useLeadStatusDetailList();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={title}
        rightNode={
          <Button variant="outlined" color="info" onClick={history.goBack}>
            {t('app.button.back')}
          </Button>
        }
      />
      <OperationContext.Provider value={oppProviderValue}>
        <Box marginBottom="2rem" />
        <ProFilterTable
          filterState={defaultParams}
          showFilter={false}
          showSelect={false}
          rowKey="agentCode"
          columns={tableColumns}
          request={getData}
          enableRefresh={false}
          formatTitle={t}
          onClickRow={(i) => {
            history.push(`${LEADS_DETAIL_PATH}/${i._id}`);
          }}
        />
      </OperationContext.Provider>
    </Page>
  );
};
