import React, { memo } from 'react';
import { RejectOpportunityButton } from '../components/reject-opportunity-button/reject-opportunity-button.component';
import { AcceptOpportunityButton } from '../components/accept-opportunity-button/accept-opportunity-button.component';
import { Lead } from 'src/app/modules/Leads/types/types';
import { TalkbotOperationType } from 'src/app/modules/Leads/util/table-columns-render.util';

type ComponentProps = {
  item: Lead;
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
  operations?: TalkbotOperationType;
};

export const LeadOpportunityOperation: React.FC<ComponentProps> = memo(
  ({ item, actions, operations }: ComponentProps) => {
    return (
      <>
        <RejectOpportunityButton item={item} actions={actions} />
        <AcceptOpportunityButton item={item} actions={actions} operations={operations} />
      </>
    );
  },
);
