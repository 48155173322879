import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Alert } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './tab-document.style';
import { useTabDocument } from './tab-document.hook';
import { CampaignStructureItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { AgencyCampaignTypeStatusEnum } from 'src/app/modules/AgencyCampaign/constants';
import moment from 'moment';
import { toAbsoluteUrl } from 'src/app/common/utils/path-utils';
import { EmptyIcon } from 'src/app/common/components/svg-icon/svg-icon.component';

type ComponentProps = ParamsProps & {
  campaignTypeStructureData: CampaignStructureItem;
  campaign: any;
  formDispatch: (data: any) => void;
  formState: any;
  sectionKey: string;
  errorState: any;
  disabledEdit?: boolean;
  validationHandler: any;
  onSubmitDocuments: Function;
  disabled?: boolean;
  toggleSubmit: (val: boolean) => void;
  noPostDoc: boolean;
};

export const TabDocumentComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { cachedRenderEventSection } = useTabDocument(props);
  const { campaign, noPostDoc } = props;
  if (noPostDoc) {
    return (
      <div className={styles.noRequiredBox}>
        <div>
          <EmptyIcon sx={{ width: '180px', height: '172px' }} />
          <div>{Translation('agencyCampaign.create.noEventDocumentRequired')}</div>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <>
        {![
          AgencyCampaignTypeStatusEnum.ACTIVE + '',
          AgencyCampaignTypeStatusEnum.COMPLETED + '',
          AgencyCampaignTypeStatusEnum.APPROVED + '',
        ].includes(campaign.campaignStatus) && (
          <Alert className="tw-mb-2" severity="info" color="warning">
            {Translation('campaign_should_be_approved_to_submit_the_section')}
          </Alert>
        )}
        {moment(campaign.startDate).isAfter(moment(new Date())) && (
          <Alert className="tw-mb-2" severity="info" color="warning">
            {Translation('should_pass_campaign_start_date')}
          </Alert>
        )}
      </>

      <div className={classes.container} style={{ background: '#fff' }}>
        {cachedRenderEventSection}
      </div>
    </div>
  );
});
