import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteParticipantItem,
  fetchParticipantList,
  fetchParticipantOverview,
} from 'src/app/modules/event-v2/network/participant-crud';
import { RADIO_ALL, formatParticipantFilter } from '../util/participant.util';
import { useDataProvider } from 'src/app/common/components/ProTable/hook';
import { EventDetailPageTabEnum, RegistrationTypeEnum, SessionOverview } from 'src/app/modules/event-v2/types';
import { useRequest } from 'ahooks';
import { eventListPath } from '../../../../event-list-routes';
import { useHistory } from 'react-router-dom';

interface HookProps {
  eventId: string;
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  // other params
}

export const defaultFilterState = {
  role: RADIO_ALL,
  requestAttendanceStatus: RADIO_ALL,
  page: 0,
  pageSize: 50,
  registrationType: RegistrationTypeEnum.RSVP,
};

export const useParticipantList = ({ eventId, cacheParams }: HookProps) => {
  const history = useHistory();
  const filterState = cacheParams?.current?.[EventDetailPageTabEnum.PARTICIPANT]?.filter || defaultFilterState;
  const [curRegistrationId, setCurRegistrationId] = useState<string>();

  // redux
  const dispatch = useDispatch();

  const {
    data: overviewData,
    error,
    run,
  } = useRequest(() => fetchParticipantOverview(eventId, dispatch), { ready: !!eventId });

  const getData = useCallback(
    async (params: Record<string, any>) => {
      if (eventId) {
        const formatFilter = formatParticipantFilter(params);
        const res = await fetchParticipantList(eventId, formatFilter);
        if (res) {
          const { docs, totalDocs } = res;
          return {
            success: true,
            data: docs ?? [],
            total: totalDocs ?? 0,
          };
        }
        return { success: false, data: [], total: 0 };
      }
      return { success: false, data: [], total: 0 };
    },
    [eventId],
  );
  const { loading, reload, dataSource, total } = useDataProvider(async () => {
    return await getData(filterState);
  });

  useEffect(() => {
    reload();
  }, []);

  const onSelect = useCallback(
    (e: any) => {
      const newSelectedSession = overviewData?.sessions?.find((session) => session.key === e.target.value);
      changeParticipantCacheParams({ sessionId: newSelectedSession?._id }, cacheParams);
      reload();
    },
    [cacheParams, overviewData?.sessions, reload],
  );

  const onFilterChanged = useCallback(
    (value: any, cb: () => void) => {
      const oldSessionId = cacheParams?.current?.[EventDetailPageTabEnum.PARTICIPANT]?.filter?.sessionId;
      changeParticipantCacheParams({ ...value, page: 0, sessionId: oldSessionId }, cacheParams, true);
      cb?.();
      reload();
    },
    [cacheParams, reload],
  );

  const onRemove = useCallback(
    async (id: string) => {
      try {
        await deleteParticipantItem(eventId, id, dispatch);
        reload();
        run();
      } catch (err) {}
    },
    [dispatch, eventId, reload, run],
  );

  // table row operation
  const onClickAttendance = useCallback(
    (registrationId: string) => {
      history.push(`${eventListPath}/view/${eventId}/participant-details/${registrationId}`);
    },
    [eventId, history],
  );

  // table row operation
  const onClickRemove = useCallback((registrationId: string) => {
    setCurRegistrationId(registrationId);
  }, []);

  return {
    overviewData,
    filterState,
    dataSource,
    total,
    loading,
    curRegistrationId,
    onSelect,
    getData,
    onFilterChanged,
    reload,
    onRemove,
    onClickRemove,
    onClickAttendance,
    setCurRegistrationId,
  };
};

export const changeParticipantCacheParams = (
  newFilterState: Record<string, any>,
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>,
  reset?: boolean,
) => {
  if (cacheParams) {
    cacheParams.current = {
      ...cacheParams.current,
      [EventDetailPageTabEnum.PARTICIPANT]: {
        filter: reset
          ? newFilterState
          : {
              ...cacheParams.current?.[EventDetailPageTabEnum.PARTICIPANT]?.filter,
              ...newFilterState,
            },
      },
    };
  }
};
