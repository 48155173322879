import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { last_30_days_rages } from 'src/app/common/components/list-filter/components/list-filter-date-radio-group/util/list-filter-date-radio.util';
import { getAssistedLeadSummary } from '../../../network/insightCrud';

interface HookProps {
  // agent-code string, split by comma,
  // example: "00010001" or "00010001,00010002,00010003"
  agentCode?: string;
}

export const useCompanyAssistedLead = ({ agentCode }: HookProps) => {
  // i18n
  const history = useHistory();
  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  const agentCodeList = agentCode || globalStore.getAgentCode();

  // useRequest to wrap api request.
  const { data, error, loading, run, runAsync } = useRequest(
    () => {
      const date = last_30_days_rages();
      const param = { agentCode: agentCodeList, startDate: date.startDate, endDate: date.endDate };
      return getAssistedLeadSummary(param).then((response) => {
        const { leadsAdded, campaignsParticipated } = response.data;
        const result = [
          {
            value: leadsAdded ?? '-',
            label: t('company_assisted_leads_added'),
          },
          {
            value: campaignsParticipated ?? '-',
            label: t('company_assisted_camgaign_participated'),
          },
        ];
        return result;
      });
    },
    {
      manual: true,
    },
  );
  const handlePress = useCallback(() => {
    history.push('./company-assisted-lead');
  }, []);
  useEffect(() => {
    // manual call api request
    if (agentCodeList) {
      run();
    }
  }, [agentCodeList]);
  const defaultData = useMemo(() => {
    const result = [
      {
        value: '-',
        label: t('company_assisted_leads_added'),
      },
      {
        value: '-',
        label: t('company_assisted_camgaign_participated'),
      },
    ];
    return result;
  }, []);

  const tooltip = useMemo(() => {
    const tooltip = [
      {
        title: t('company_assisted_leads_added'),
        content: t('assisted_lead_des1'),
      },
      {
        title: t('company_assisted_camgaign_participated'),
        content: t('assisted_lead_des2'),
      },
    ];
    return tooltip;
  }, []);

  return {
    loading,
    tooltip,
    data: loading ? defaultData : data || defaultData,
    handlePress,
  };
};
