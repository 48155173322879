import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { preFillZero } from 'src/app/common/utils';
import { AttachmentDef } from 'src/app/common/types';
import { ApiErrorResponse } from 'src/app/common/network';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { validateAgentList } from 'src/app/modules/event-v2/network';

export type HookProps = {
  onClose: () => void;
  onImportAgent: (agentCodeList: string[], agentListFile: AttachmentDef) => void;
};

type ImportFileFormState = {
  agentListFile?: AttachmentDef;
  originalFile?: File;
};

export const useImportAgentFromFileDialog = ({ onClose, onImportAgent }: HookProps) => {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState<ImportFileFormState>({});
  const [errors, setErrors] = useState<string[]>([]);

  const handleUploadFile = (agentListFile: AttachmentDef, originalFile: File) => {
    setFormState({ agentListFile, originalFile });
  };

  const handleRemoveFile = () => {
    setFormState({});
    setErrors([]);
  };

  const handleImport = async () => {
    const { agentListFile, originalFile } = formState;
    if (agentListFile && originalFile) {
      try {
        await validateAgentList({ blobId: agentListFile.blobId });
        const reader = new FileReader();
        reader.onload = () => {
          const agentCodeList: string[] = [];
          const csvData = String(reader.result);
          const rows = csvData.split('\n');
          rows.shift();
          rows.forEach((row) => {
            const rowItems = row.split(',');
            if (rowItems[0] && rowItems[0].trim()) {
              agentCodeList.push(preFillZero(rowItems[0].trim()));
            }
          });
          onImportAgent(agentCodeList, agentListFile);
          onClose();
        };
        reader.readAsBinaryString(originalFile);
      } catch (err) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              content: `Import file failed!`,
            },
          ]),
        );
        const error = err as ApiErrorResponse;
        setErrors(error.errors[0].message.split('\n').filter((str) => str));
      }
    }
  };

  return {
    formState,
    errors,
    handleUploadFile,
    handleRemoveFile,
    handleImport,
  };
};
