import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { ListFilterData } from 'src/app/common/components/list-filter/list-filter.component';
import { FilterComponentsEnum, ListFilterConfig } from 'src/app/common/components/list-filter';
import { InsightSession, LeadSourceEnum } from '../../types/types';
import { ProspectSourceTypes } from 'src/app/modules/Prospect/types/prospect-types';
import { dateType } from 'src/app/common/components/list-filter/components/list-filter-date-radio-group/util/list-filter-date-radio.util';
import moment from 'moment';

export enum TAB_TYPE {
  ALL = 'all',
  NEW_ADD = 'newAdd',
}

export const useProspectDetail = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<string>('all');
  const [startDate, endDate] = useMemo<string[]>(() => {
    const endDate = new Date().toISOString();
    const startDate = moment().subtract(7, 'days').toDate().toISOString();
    return [startDate, endDate];
  }, []);

  const leadSourceFilter: ListFilterConfig = useMemo(() => {
    return {
      title: t('insights_filter_source'),
      key: 'source',
      type: FilterComponentsEnum.PURE_RADIO_GROUP,
      items: [
        { itemKey: LeadSourceEnum.all, itemLabel: t('leads_status_all_source') },
        { itemKey: ProspectSourceTypes.prospect_source_marketing, itemLabel: t('leads_status_marketing_source') },
        { itemKey: ProspectSourceTypes.prospect_source_agent, itemLabel: t('leads_status_agent_source') },
      ],
    };
  }, []);

  const dateRangeFilter: ListFilterConfig = useMemo(() => {
    return {
      title: t('insights_new_added_date'),
      items: [],
      key: 'dateRange',
      type: FilterComponentsEnum.RADIO_GROUP, // select date range
    };
  }, []);

  const filterConfig = useMemo(() => {
    return currentTab === TAB_TYPE.ALL ? [leadSourceFilter] : [dateRangeFilter, leadSourceFilter];
  }, [currentTab]);

  const defaultFilterValues = {
    source: LeadSourceEnum.all,
    campaignTypeId: LeadSourceEnum.all,
    dateRange: {
      key: dateType.last_7_days,
      value: {
        startDate: startDate,
        endDate: endDate,
        label: t('filter.date.last_7_days'),
      },
    },
  };

  // const [filterValues, setFilterValues] = useState<ListFilterData>(defaultFilterValues);
  const [tabFilterValues, setTabFilterValues] = useState<{ [key: string]: ListFilterData }>({}); // filter values for each tab

  const filterValues = useMemo(() => {
    return tabFilterValues[currentTab] || defaultFilterValues;
  }, [currentTab, tabFilterValues]);

  const onFilterConfirm = useCallback(
    (value: ListFilterData) => {
      setTabFilterValues((prev) => {
        const newFilterValues = { ...prev, [currentTab]: value };
        sessionStorage.setItem(InsightSession.ProspectDetailFilterValues, JSON.stringify(newFilterValues));
        return newFilterValues;
      });
    },
    [currentTab],
  );

  const tabs = [
    {
      label: t('insights_all'),
      value: TAB_TYPE.ALL,
    },
    {
      label: t('insights_new_added'),
      value: TAB_TYPE.NEW_ADD,
    },
  ];

  const onTabChange = useCallback((value: string) => {
    setCurrentTab(value);
  }, []);

  useEffect(() => {
    const cachedFilterValues = JSON.parse(sessionStorage.getItem(InsightSession.ProspectDetailFilterValues) || '{}');
    setTabFilterValues({ ...tabFilterValues, ...cachedFilterValues });
  }, []);

  useEffect(() => {
    sessionStorage.setItem(
      InsightSession.ProspectDetailFilterValues,
      JSON.stringify({
        [TAB_TYPE.ALL]: defaultFilterValues,
        [TAB_TYPE.NEW_ADD]: defaultFilterValues,
      }),
    );
  }, []);

  return { tabs, currentTab, filterConfig, filterValues, defaultFilterValues, onTabChange, onFilterConfirm };
};
