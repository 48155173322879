import React, { memo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LeadReminderComponent } from '../lead-reminder/lead-reminder.component';
import { useRequest } from 'ahooks';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LEADS_BASE_PATH } from 'src/app/modules/Leads/constants';
import { getDropListCount } from './util/api.util';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { pRULeadsHomeScreenGaDataClick17 } from 'src/app/common/ga/types/ga-click-lead';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
};

export const AutoDropReminder: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: autoDropData } = useRequest(() => getDropListCount(dispatch), { manual: false });
  const history = useHistory();

  return (
    <>
      {typeof autoDropData?.leadCount === 'number' && autoDropData?.leadCount > 0 ? (
        <LeadReminderComponent
          type="warning"
          reminderText={t('sales_lead_autoDrop_remind', {
            autoDropLeadCount: autoDropData?.leadCount,
            autoDropLeadDay: autoDropData?.reminderAutoDropLeadDay ?? 7,
          })}
          confirmText={t('lead.reminder.checkBtn')}
          onConfirm={() => {
            takeUIClickEvent(pRULeadsHomeScreenGaDataClick17);
            history.push(`${LEADS_BASE_PATH}/list/auto-drop`);
          }}
        />
      ) : null}
    </>
  );
});
