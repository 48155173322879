import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, set } from 'lodash';
import { useLang } from 'src/app/i18n';

import { useTranslation } from 'src/app/common/hooks/translation.hook';

import { InsightExportSetting, InsightSectionEnum, InsightSession, InsightSourceRequestEnum } from '../../types/types';
import moment from 'moment';
import { DateFormat } from 'src/app/common/utils';
import { exportInsight, getAgentCodeListByGroup, getDivisions } from '../../network/insightCrud';
import { getAgentName } from 'src/app/common/utils/i18n.util';
import { createFilterOptions } from '@mui/material';
import dayjs from 'dayjs';
import { ProColumns } from 'src/app/common/components/ProTable';
import { FilterComponentsEnum, ListFilterConfig } from 'src/app/common/components/list-filter';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { useHistory } from 'react-router-dom';
import { PermissionRole } from 'src/app/modules/Auth/types/auth-types';

interface ReportForm {
  name: string;
  email: string;
  startDate: Date;
  endDate: Date;
  designation: string;
  agentCodes: string[];
  sections: InsightSectionEnum[];
  exportSetting: InsightExportSetting;
  reporterCode: string;
  lang: string;
}

const MONTH_NAME = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const getSections = (t: (id: string) => string) => [
  // { name: t('daily_point'), value: InsightSectionEnum.DAILY_POINT },
  { name: t('lead_status'), value: InsightSectionEnum.LEAD_STATUS },
  { name: t('marketing_lead'), value: InsightSectionEnum.MARKETING_LEAD },
  { name: t('mass_lead_header_title'), value: InsightSectionEnum.MASS_LEAD },
  { name: t('company_assisted_lead'), value: InsightSectionEnum.AGENCY_CAMPAIGN_LEAD },
  { name: t('prospect'), value: InsightSectionEnum.PROSPECT },
  // { name: t('engagement'), value: InsightSectionEnum.ENGAGEMENT },
  { name: t('appointment'), value: InsightSectionEnum.APPOINTMENT },
  { name: t('insights_servicing_campaign_title'), value: InsightSectionEnum.SERVICING_CAMPAIGN },
  // { name: t('insurance_summary'), value: InsightSectionEnum.INSURANCE_SUMMARY },
];

export const useExportReport = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const locale = useLang();
  const { user, agentInfo } = useSelector((state: any) => state.auth);

  const isManager = user.role === PermissionRole.MANAGER;
  const allAgentOption = { label: t('salesSelectAll'), agentCode: 'allAgent' };
  const currentAgentCode = user.agentCode;
  const [designation, setDesignation] = useState<Array<any>>([]);
  const [agents, setAgents] = useState<Array<any>>([]);
  const [selectedDesignation, setSelectedDesignation] = useState<string>('');
  const [selectedAgents, setSelectedAgents] = useState<string[]>(() => {
    return [currentAgentCode];
  });
  const [sections, setSections] = useState<any[]>(getSections(t).map((section) => section.value));
  const [startDate, endDate] = useMemo(() => [moment().subtract(29, 'days').startOf('day').toDate(), new Date()], []);

  const [showDialog, setShowDialog] = useState(false);

  const [showFilter, setShowFilter] = useState(false);

  const initState: Partial<ReportForm> = {
    name: `PRULeads Insight ${MONTH_NAME[new Date().getMonth()]} ${moment(new Date()).format('DD ' + DateFormat.time)}`,
    exportSetting: { leadSource: InsightSourceRequestEnum.ALL, prospectSource: InsightSourceRequestEnum.ALL },
    startDate: startDate,
    endDate: endDate,
    agentCodes: [currentAgentCode],
    reporterCode: currentAgentCode,
    lang: locale,
    email:
      get(JSON.parse(sessionStorage.getItem(InsightSession.EmailToReceiveReport) ?? '{}'), currentAgentCode) ??
      agentInfo.email,
  };

  const [form, setForm] = useState<Partial<ReportForm>>(initState);

  const leadSourceFilter: ListFilterConfig = useMemo(() => {
    return {
      title: t('lead_source'),
      key: 'leadSource',
      type: FilterComponentsEnum.PURE_RADIO_GROUP,
      items: [
        { itemKey: InsightSourceRequestEnum.ALL, itemLabel: t('leads_status_all_source') },
        { itemKey: InsightSourceRequestEnum.MARKETING_SOURCE, itemLabel: t('leads_status_marketing_source') },
        { itemKey: InsightSourceRequestEnum.AGENT_SOURCE, itemLabel: t('leads_status_agent_source') },
      ],
    };
  }, []);

  const prospectSourceFilter: ListFilterConfig = useMemo(() => {
    return {
      title: t('insights_filter_source'),
      key: 'prospectSource',
      type: FilterComponentsEnum.PURE_RADIO_GROUP,
      items: [
        { itemKey: InsightSourceRequestEnum.ALL, itemLabel: t('leads_status_all_source') },
        { itemKey: InsightSourceRequestEnum.MARKETING_SOURCE, itemLabel: t('leads_status_marketing_source') },
        { itemKey: InsightSourceRequestEnum.AGENT_SOURCE, itemLabel: t('leads_status_agent_source') },
      ],
    };
  }, []);

  const filterConfig = useMemo(() => {
    return [leadSourceFilter, prospectSourceFilter];
  }, []);

  const defaultFilterValues = useMemo(() => {
    return {
      leadSource: InsightSourceRequestEnum.ALL,
      prospectSource: InsightSourceRequestEnum.ALL,
    };
  }, []);

  const [filterValues, setFilterValues] = useState<any>(defaultFilterValues);

  const getAgents = useCallback(
    (groupCode: string) => {
      getAgentCodeListByGroup(user.agentCode, groupCode).then((res) => {
        if (res && res.data) {
          res.data.sort(function (a: any, b: any) {
            return a.agentCode - b.agentCode;
          });
          setAgents([allAgentOption, ...res.data]);
        }
      });
    },
    [user.agentCode],
  );

  const onSelectedDesignation = useCallback(
    (groupCode: string) => {
      setSelectedDesignation(groupCode);
      getAgents(groupCode);
      setSelectedAgents([]);
    },
    [getAgents],
  );

  const onSelectedAgents = useCallback(
    (agentCodes: string[]) => {
      // if (agentCodes.includes('allAgent')) {
      //   setSelectedAgents(agents.map((agent) => agent.agentCode));
      //   return;
      // }
      setSelectedAgents(agentCodes);
    },
    [agents],
  );

  const onSelectAllAgents = useCallback(
    (selected: boolean) => {
      if (selected) {
        setSelectedAgents([]);
      } else {
        setSelectedAgents(agents.map((agent) => agent.agentCode));
      }
    },
    [agents],
  );

  const onChangeDate = useCallback((value: dayjs.Dayjs | null, type: 'startDate' | 'endDate') => {
    if (!value) return;
    setForm((prev) => {
      return {
        ...prev,
        [type]: value.startOf('day').toDate(),
      };
    });
  }, []);

  const onInputChanged = useCallback((field: string, value: string) => {
    setForm((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }, []);

  const onFilterChanged = useCallback((value: any) => {
    if (!isEmpty(value)) {
      setFilterValues(value);
      setForm((prev) => {
        return {
          ...prev,
          exportSetting: value,
        };
      });
    }
    setShowFilter(false);
  }, []);

  const getData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        current?: number | undefined;
        keyword?: string | undefined;
      },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<any> => {
      const data = getSections(t);
      return {
        success: true,
        data: data ?? [],
        total: data.length,
      };
    },
    [],
  );

  const rowSelection = useMemo(
    () => ({
      onChange: (rows: any) => {
        setSections(rows.map((row: any) => row.value));
      },
      getCheckboxProps: (row: any, rows: any[]) => {
        return {};
      },
    }),
    [],
  );

  const columns: ProColumns<any>[] = useMemo(
    () => [
      {
        title: t('Section'),
        dataIndex: 'name',
        width: '290px',
        render: (currValue: string) => {
          return currValue;
        },
      },
    ],
    [],
  );

  const disableSubmit = useMemo(() => {
    return (
      selectedAgents.length === 0 ||
      (isManager && selectedDesignation === '') ||
      sections.length === 0 ||
      !form.email ||
      !form.name
    );
  }, [selectedAgents, selectedDesignation, sections, form]);

  useEffect(() => {
    if (isManager) {
      getDivisions(user.agentCode).then((res) => {
        if (res && res.data && res.data.length > 0) {
          setDesignation(res.data);
          const groupCode = res.data[0].groupCode;
          setSelectedDesignation(groupCode);
          getAgents(groupCode);
        }
      });
    }
  }, []);

  const [displayAgentName, restCount] = useMemo(() => {
    let _selectedAgents = [...selectedAgents];
    _selectedAgents = _selectedAgents.filter((item) => item !== 'allAgent');
    const toShow = _selectedAgents.splice(0, 3);
    const name = toShow
      .map((item: any) => {
        const agent = agents.find((agent) => agent.agentCode === item);
        if (!agent) return '';
        return getAgentName(agent.agentName, locale);
      })
      .filter((item) => item !== '')
      .join(', ');
    const restCount = _selectedAgents.length;
    return [name, restCount];
  }, [selectedAgents, agents]);

  const filterOptions = createFilterOptions({
    stringify: (option: any) => `${option.agentCode} ${getAgentName(option.agentName, locale)}`,
  });

  const checkForm = () => {
    const startDateTs = form.startDate!.getTime();
    const endDateTs = form.endDate!.getTime();
    const todayTs = moment().endOf('day').toDate().getTime();
    const daysBetweenStartEndDay = moment(form.endDate).diff(form.startDate, 'day');
    let errorMsg = null;
    if (endDateTs > todayTs) {
      errorMsg = t('end_date_should_not_be_later_today');
    } else if (startDateTs >= endDateTs) {
      errorMsg = t('end_date_should_be_later_or_equal_start_date');
    } else if (daysBetweenStartEndDay > 365) {
      errorMsg = t('time_range_should_less_than_a_year');
    }
    const emailRegex = /^[\w.+\-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(form.email ?? '')) {
      errorMsg = t('prospect.invalid_email_address');
    }
    if (errorMsg) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: '',
            content: errorMsg,
          },
        ]),
      );
      return;
    }
    setShowDialog(true);
  };

  const submit = () => {
    setShowDialog(false);
    form.agentCodes = selectedAgents.filter((agent) => agent !== 'allAgent');
    form.designation = selectedDesignation;
    form.sections = sections;

    exportInsight(form)
      .then((res) => {
        history.goBack();
        sessionStorage.setItem(
          InsightSession.EmailToReceiveReport,
          JSON.stringify({ [currentAgentCode]: form.email ?? '' }),
        );
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: t('export_report_successfully'),
              content: t('export_report_success_description', { email: form.email }),
            },
          ]),
        );
      })
      .catch((err) => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: t('common.serverError'),
              content: t('export_report_failed_description'),
            },
          ]),
        );
      });
  };

  return {
    form,
    startDate,
    endDate,
    designation,
    selectedDesignation,
    agents,
    selectedAgents,
    displayAgentName,
    allAgentOption,
    restCount,
    columns,
    rowSelection,
    defaultSelection: getSections(t),
    showFilter,
    filterValues,
    filterConfig,
    disableSubmit,
    showDialog,
    isManager,
    defaultFilterValues,
    setShowDialog,
    setShowFilter,
    getData,
    onSelectedAgents,
    onSelectAllAgents,
    onSelectedDesignation,
    onChangeDate,
    onInputChanged,
    onFilterChanged,
    filterOptions,
    checkForm,
    submit,
  };
};
