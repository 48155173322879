import { makeStyles } from 'tss-react/mui';
import { FIELD_COLUMN_GAP } from '../../../common';

export const useBasicsStyles = makeStyles()(() => ({
  info: {
    padding: '24px 20px',
    backgroundColor: '#FAFAFA',
    boxShadow: 'none',
  },
  publishStatusContainer: {
    marginBottom: 24,
  },
  publishStatusLabel: {
    fontSize: 14,
    fontWeight: 600,
    marginRight: 8,
  },
  categoryInfoContainer: {
    display: 'flex',
    columnGap: 40,
  },
  categoryInfo: {
    minWidth: 140,
  },
  categoryLabel: {
    color: '#999999',
    marginBottom: 12,
  },
  categoryText: {
    fontSize: 16,
    fontWeight: 500,
    overflowWrap: 'break-word',
  },
  dateTimePickerContainer: {
    display: 'flex',
    columnGap: FIELD_COLUMN_GAP,
    flexWrap: 'wrap',
  },
}));
