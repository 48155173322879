import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Card, CircularProgress, Typography } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './graphs-view.style';
import { useGraphsView } from './graphs-view.hook';
import { ChipTabsComponent } from 'src/app/common/components/chip-tabs/chip-tabs.component';
import { AreaChart } from 'src/app/common/components/charts/area-chart/area-chart.component';
import {
  SwitchButton,
  TimeRangeButton,
  ChartType,
} from 'src/app/common/components/charts/chart-button/chart-button.component';
import { BarChart } from 'src/app/common/components/charts/bar-chart/bar-chart.component';

type ComponentProps = ParamsProps & {
  agentCode: string;
  /* otherProp: string */
};

export const GraphsViewComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { agentCode } = props;
  const {
    loading,
    chipTabsConfig,
    selectedChipTab,
    setSelectedChipTab,
    chartType,
    setChartType,
    timeRange,
    setTimeRange,
    chartData,
    formatter,
    highlightX,
  } = useGraphsView({ agentCode });

  return (
    <>
      <ChipTabsComponent
        config={chipTabsConfig}
        value={selectedChipTab}
        onChange={(tabKey: string) => setSelectedChipTab(tabKey)}
      />

      {loading ? (
        <Box display="flex" flex={1} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <Box marginTop={'24px'}>
          <Card>
            <Box margin="24px">
              <Box display="flex" justifyContent={'space-between'} marginBottom={'24px'} color={'#F0F0F0'}>
                <Box display="flex" flexDirection={'row'}>
                  <Box>
                    <SwitchButton value={chartType} onChange={setChartType} />
                  </Box>
                  <Box marginLeft={'16px'}>
                    <TimeRangeButton value={timeRange} onChange={setTimeRange} />
                  </Box>
                </Box>
              </Box>
              <Box>
                {chartType === ChartType.area ? (
                  <AreaChart series={chartData} labelsFormatter={formatter} highlightX={highlightX} />
                ) : (
                  <BarChart series={chartData} labelsFormatter={formatter} highlightX={highlightX} />
                )}
              </Box>
            </Box>
          </Card>
        </Box>
      )}
    </>
  );
});
