import React, { ReactNode, memo, useMemo, useState } from 'react';
import { Box, Button, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { useStyles } from './action-items.style';
import { ActionNodeProps, useActionItems } from './action-items.hook';
import DoneIcon from '@mui/icons-material/Done';
import { Dialog } from 'src/app/common/components/Dialog/dialog/dialog.component';

export const ActionItems: React.FC<ActionNodeProps> = memo((props: ActionNodeProps) => {
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const { accept, acceptLoading, t } = useActionItems(props);
  const [showImportNotice, setShowImportNotice] = useState(false);

  return (
    <Box paddingRight={'16px'}>
      <Button
        disabled={acceptLoading}
        onClick={() => setShowImportNotice(true)}
        variant="text"
        className={styles.enable}
        startIcon={<DoneIcon />}
      >
        <Typography className={styles.iconButtonText}>{t('campaign.lead.accept.selected')}</Typography>
      </Button>
      <Dialog
        title={t('important_notice')}
        content={t('accept_tips')}
        confirmBtnText={t('global.text.yes')}
        cancelBtnText={t('global.text.no')}
        open={showImportNotice}
        onConfirm={() => {
          setShowImportNotice(true);
          accept();
        }}
        onClose={() => {
          setShowImportNotice(false);
        }}
      />
    </Box>
  );
});
