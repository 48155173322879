import { isEmpty, cloneDeep } from 'lodash';
import { supportedLocales } from './survey-creator';
import { surveyLocalization } from 'survey-core';
import { Serializer } from 'survey-react';

export const panelProperty = {
  name: 'fieldType',
  displayName: 'Field Type',
  type: 'shorttext',
  category: 'general',
  readOnly: true,
  default: 'Custom',
  isSerializable: true,
  visible: true,
};

export const questionProperty = {
  name: 'fieldType',
  displayName: 'Field Type',
  type: 'shorttext',
  category: 'general',
  readOnly: true,
  default: 'Custom',
  isSerializable: true,
  visible: true,
};

export const pageProperty = {
  name: 'fieldType',
  displayName: 'Field Type',
  type: 'shorttext',
  category: 'general',
  readOnly: true,
  default: 'Custom',
  isSerializable: true,
  visible: true,
};

export const isPIIProperty = {
  name: 'isPII',
  type: 'boolean',
  default: false,
  isRequired: false,
  category: 'general',
  visibleIndex: 3,
  readOnly: false,
};

export const presetProperty = {
  name: 'preset',
  type: 'shorttext',
  default: '',
  isRequired: false,
  category: 'general',
  visibleIndex: 1,
  readOnly: true,
};

export const disableChoicesProperty = {
  name: 'disableChoices',
  type: 'boolean',
  default: false,
  isRequired: false,
  category: 'general',
  visibleIndex: 2,
  readOnly: false,
};

export const enableChoicesLazyLoadProperty = {
  name: 'enableChoicesLazyLoad',
  type: 'boolean',
  default: false,
  isSerializable: false,
  category: 'choicesByUrl',
  onSetValue: (question: any, value: any) => {
    question.setPropertyValue('choicesLazyLoadEnabled', value);
  },
  onGetValue: function (question: any) {
    // Do not serialize the property to JSON
    return question?.choicesLazyLoadEnabled;
  },
};

export const isChoicesByProtectedUrlProperty = {
  name: 'isChoicesByProtectedUrl',
  type: 'boolean',
  default: false,
  category: 'choicesByUrl',
};

export const choicesLazyLoadEnabledProperty = {
  name: 'choicesLazyLoadEnabled',
  type: 'boolean',
  visible: false,
};

export const isAutoCapitalizeProperty = {
  name: 'isAutoCapitalize',
  type: 'boolean',
  default: false,
  isRequired: false,
  category: 'general',
  visible: false,
  readOnly: true,
};

export const surveyRequiredErrorTextProperty = {
  name: 'requiredErrorText',
  type: 'shorttext',
  default: '',
  isRequired: false,
  category: 'general',
  visible: true,
  readOnly: false,
  isLocalizable: true,
};

export const surveyInvalidNumberErrorTextProperty = {
  name: 'invalidNumberErrorText',
  type: 'shorttext',
  default: '',
  isRequired: false,
  category: 'general',
  visible: true,
  readOnly: false,
  isLocalizable: true,
};

export const contractMonthProperty = {
  name: 'contractMonth',
  type: 'number',
  default: null,
  isRequired: false,
  category: 'general',
  visible: true,
  readOnly: false,
  dependsOn: ['inputType'],
  visibleIf: function (obj: any) {
    return obj.name === 'contractMonth';
  },
};

export const triggerPopulateProperty = {
  name: 'triggerPopulate',
  type: 'itemvalues',
  default: {
    path: '',
    triggerKey: '',
    type: '',
  },
  isRequired: false,
  category: 'general',
  visible: false,
  readOnly: true,
};

export const tilNowProperty = {
  name: 'tilNow',
  type: 'boolean',
  default: false,
  isRequired: false,
  category: 'general',
  visible: false,
  readOnly: true,
};

export const TnCUploaderProperty = {
  name: 'TnCUploader',
  displayName: 'TnC Uploader',
  type: 'TnCUploader',
  category: 'TnC Config',
  visibleIndex: 4,
  visible: true,
  default: {},
  isSerializable: false,
  onSetValue: (question: any, value: any) => {
    if (!question || isEmpty(question) || !value?.url) return;
    question.setPropertyValue('TnCUploader', value);
    let locale = question.getPropertyValue('TnCLocale');
    if (locale === 'en') locale = 'default';
    const url = value.url;
    const localeTemp = cloneDeep(question.localizableStrings.attachmentUrl);
    localeTemp.values[locale] = url;
    question.setPropertyValue('attachmentUrl', url);
    question.attachmentUrl = url;
    question.localizableStrings.attachmentUrl = localeTemp;
  },
};

export const attachmentUrlProperty = {
  name: 'attachmentUrl',
  displayName: 'AttachmentUrl',
  visible: true,
  type: 'text',
  category: 'TnC Config',
  readOnly: true,
  isLocalizable: true,
  visibleIndex: 1,
};

export const disallowedActionsProperty = {
  name: 'disallowedActions',
  displayName: 'Disallowed Actions',
  visible: true,
  type: 'string',
  default: '',
  category: 'general',
  readOnly: true,
  isSerializable: true,
};

export const TnCLocaleProperty = {
  name: 'TnCLocale',
  default: surveyLocalization.defaultLocale,
  choices: () => supportedLocales,
  onSetValue: (question: any, value: any) => {
    question.setPropertyValue('TnCUploader', {});
    question.setPropertyValue('TnCLocale', value);
    let locale = question.getPropertyValue('TnCLocale');
    if (locale === 'en') locale = 'default';
    const localeTemp = cloneDeep(question.localizableStrings.attachmentUrl);
    const attachmentUrl = question.localizableStrings.attachmentUrl?.values?.[locale] || '';
    question.setPropertyValue('attachmentUrl', attachmentUrl);
    question.attachmentUrl = attachmentUrl;
    localeTemp.values[locale] = attachmentUrl;
    question.localizableStrings.attachmentUrl = localeTemp;
  },
  category: 'TnC Config',
  visibleIndex: 2,
  isSerializable: false,
};

export const maxFileNumberProperty = {
  name: 'maxFileNumber',
  type: 'number',
  default: 5,
  isRequired: true,
  category: 'general',
  visible: false,
  isSerializable: true,
};

export const watermarkProperty = {
  name: 'takePhotoWithWatermark',
  type: 'boolean',
  default: false,
  isRequired: true,
  category: 'general',
  visible: true,
  isSerializable: true,
};

export const moduleOrderProperty = {
  name: 'moduleOrder',
  type: 'number',
  default: 0,
  isRequired: true,
  category: 'general',
  visible: true,
  isSerializable: true,
};

export const maxFileNumberErrorTextProperty = {
  name: 'maxFileNumberErrorText',
  type: 'shorttext',
  default: '',
  category: 'validation',
  visible: true,
  isLocalizable: true,
  isSerializable: true,
};

export const acceptedTypesErrorTextProperty = {
  name: 'acceptedTypesErrorText',
  type: 'shorttext',
  default: '',
  category: 'validation',
  visible: true,
  isLocalizable: true,
  isSerializable: true,
};

export const convertImageToPdfProperty = {
  name: 'convertImageToPdf',
  type: 'boolean',
  category: 'general',
  default: 0,
  visibleIndex: 7,
  visible: true,
  isRequired: false,
  isSerializable: true,
};

export const validateDimensionProperty = {
  name: 'imageDimension',
  displayName: 'Image dimension',
  type: 'dropdown',

  choices: [
    {
      value: { width: 413, height: 600 },
      text: '413 x 600',
    },
    {
      value: { width: 800, height: 600 },
      text: '800 x 600',
    },
  ], // hardcoded for now
  category: 'general',
  visibleIndex: 8,
  visible: true,
  isRequired: false,
  isSerializable: true,
};

export const inputTypeList = [
  'number',
  'month',
  'date',
  'tel',
  'year',
  'email',
  'date',
  'datetime-local',
  'year',
  'time',
  'url',
  'week',
];

export const enabledQuestionTypes = [
  'panel',
  'boolean',
  'radiogroup',
  'checkbox',
  'imagepicker',
  'text',
  'multipletext',
  'paneldynamic',
  'file',
  'dropdown',
];
