import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  menuListContainer: {
    minWidth: 440,
    overflowY: 'auto',
  },
  btnContainer: {
    paddingTop: 20,
  },
  menuList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },
  menuItem: {
    padding: '18px 24px',
    border: '1px solid #F0F0F0',
    borderRadius: 8,
    whiteSpace: 'normal',
    overflowWrap: 'anywhere',
    '&:hover': {
      backgroundColor: '#FEF6F7',
    },
    '&.Mui-selected': {
      color: '#E8192C',
      backgroundColor: '#FFE4E6',
      borderColor: '#E8192C',
      '&:hover': {
        backgroundColor: '#FFE4E6',
      },
    },
  },
}));
