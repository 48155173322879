import { FC, useMemo } from 'react';
import { Tag } from 'src/app/common/components/tag';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { EventPublishStatusEnum } from 'src/app/modules/event-v2/types';

type PublishStatusTagProps = {
  publishStatus?: EventPublishStatusEnum;
};

const getTagConfig = (Translation: (id: string) => string) => ({
  [EventPublishStatusEnum.DRAFT]: {
    color: '#24B1C7',
    bgColor: '#E0FBFF',
    text: Translation(`event.common.publish_status.${EventPublishStatusEnum.DRAFT.toLowerCase()}`),
  },
  [EventPublishStatusEnum.PUBLISHED]: {
    color: '#333333',
    bgColor: '#F5F5F5',
    text: Translation(`event.common.publish_status.${EventPublishStatusEnum.PUBLISHED.toLowerCase()}`),
  },
  [EventPublishStatusEnum.UNPUBLISHED]: {
    color: '#EDA62B',
    bgColor: '#FFF5E0',
    text: Translation(`event.common.publish_status.${EventPublishStatusEnum.UNPUBLISHED.toLowerCase()}`),
  },
});

export const PublishStatusTag: FC<PublishStatusTagProps> = ({ publishStatus = EventPublishStatusEnum.DRAFT }) => {
  const { t: Translation } = useTranslation();
  const configs = useMemo(() => getTagConfig(Translation), [Translation]);

  return (
    <div style={{ display: 'inline-block' }}>
      <Tag<string> value={publishStatus} configs={configs} />
    </div>
  );
};
