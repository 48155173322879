import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { getLeadStatusGraph } from '../../../network/insightCrud';
import { DisplayCategoryEnum, InsightSession, StatusTypeEnum } from '../../../types/types';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { ChartType, TimeRange } from 'src/app/common/components/charts/chart-button/chart-button.component';
import { formatLabel } from 'src/app/common/components/charts/util/format-response.util';

interface HookProps {
  // other params
}

export const useGraphTab = () => {
  // i18n
  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  const [selectedLead, setSelectedLead] = useState<StatusTypeEnum>(StatusTypeEnum.newLead);
  const [displayCategory, setDisplayCategory] = useState<DisplayCategoryEnum>(DisplayCategoryEnum.all);
  const [timeRange, setTimeRange] = useState(TimeRange.day);
  const [chartType, setChartType] = useState(ChartType.bar);

  // useRequest to wrap api request.

  const { data, error, loading, run } = useRequest(
    () => {
      const agentCode = sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode();
      const body = {
        agentCode, // format: '111,222,333'
        dateType: timeRange,
        chartType: chartType,
        statusType: selectedLead,
      };
      return getLeadStatusGraph(body);
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    // manual call api request
    run();
  }, [selectedLead, timeRange, chartType]);

  const handlePress = useCallback((lead: StatusTypeEnum) => {
    setSelectedLead(lead);
  }, []);

  const formatter = useCallback(
    (val: any) => {
      if (!val) return '';
      return formatLabel(val, timeRange);
    },
    [timeRange],
  );

  return {
    loading,
    data,
    selectedLead,
    timeRange,
    chartType,
    displayCategory,
    setSelectedLead,
    setDisplayCategory,
    setChartType,
    setTimeRange,
    handlePress,
    formatter,
  };
};
