import { Stepper, Step, StepButton, StepLabel } from '@mui/material';
import { Error } from '@mui/icons-material';
import { StepStatusEnum, FormCommonProps, StepItem, StepIndicator } from '../types/pru-stepped-form-types';
import { useStyles } from './pru-stepper.style';

type PruStepperProps<T extends FormCommonProps> = {
  steps: StepItem<T>[];
  activeStep?: StepIndicator;
  formCommonProps: T;
  onStepChange: (newActiveStep: StepIndicator) => void;
};

const ErrorIcon = () => {
  const { classes } = useStyles();
  return <Error className={classes.errorIcon} />;
};

export const PruStepper = <T extends FormCommonProps>({
  steps,
  activeStep,
  formCommonProps,
  onStepChange,
}: PruStepperProps<T>) => {
  const { classes } = useStyles();

  return (
    <Stepper nonLinear alternativeLabel className={classes.stepper} activeStep={activeStep?.stepIndex ?? -1}>
      {steps.map((stepItem, stepIndex) => {
        if (!stepItem.hidden || (stepItem.hidden && !stepItem.hidden(formCommonProps))) {
          const disabled = stepItem.disabled ? stepItem.disabled(formCommonProps) : false;
          return (
            <Step
              key={stepIndex}
              disabled={disabled}
              active={stepIndex === activeStep?.stepIndex}
              completed={stepItem.status === StepStatusEnum.FINISHED}
              className={classes.step}
            >
              <StepButton onClick={() => onStepChange({ stepIndex })}>
                <StepLabel StepIconComponent={stepItem.status === StepStatusEnum.WARNING ? ErrorIcon : undefined}>
                  {stepItem.label}
                </StepLabel>
              </StepButton>
            </Step>
          );
        }
      })}
    </Stepper>
  );
};
