import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './all-tab.module.css';
import { useAllTab } from './all-tab.hook';
import { Card } from 'src/app/common/components/card/card.component';
import { PercentageBarChart } from 'src/app/common/components/charts/percentage-bar-chart/percentage-bar-chart.component';
import { ProspectTypeList } from '../prospect-type-list/prospect-type-list.component';

type ComponentProps = ParamsProps & {
  filterValues: { source?: string };
};

export const AllTab: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();

  // custom-hook
  const { summary, summaryDetail, totalProspects, totalNewAdded } = useAllTab(props);
  return (
    <>
      <div className={styles.wrap}>
        <Card className={styles.wrapChart}>
          <div className={styles.flexRow}>
            <div className={styles.workingLeads}>
              {t('total_prospects')}
              <div className={styles.num}>{totalProspects}</div>
            </div>
            <div className={styles.workingLeads}>
              {t('added_in_last_7_days')}
              <div className={styles.num}>{`+${totalNewAdded}`}</div>
            </div>
          </div>
          {totalProspects > 0 && <PercentageBarChart data={summary} />}
        </Card>
      </div>
      <ProspectTypeList data={summaryDetail} />
    </>
  );
});
