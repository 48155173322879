import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ParticipantListParam,
  exportParticipantList,
  exportRegistrationFormResult,
} from 'src/app/modules/event-v2/network/participant-crud';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { EventItem, RegistrationTypeEnum } from 'src/app/modules/event-v2/types';
import { formatParticipantFilter } from '../../util/participant.util';
import { useLang } from 'src/app/i18n';

interface HookProps {
  eventId: string;
  eventItem?: EventItem;
  filterState: Record<string, any>;
  walkInAllowed?: boolean;
}

export const useParticipantTableBar = ({ eventId, filterState, eventItem, walkInAllowed }: HookProps) => {
  const { t } = useTranslation();
  const locale = useLang();
  const [tabValue, setTabValue] = useState<string>(RegistrationTypeEnum.RSVP);
  const [showFilter, setShowFilter] = useState(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isExportingFormResult, setIsExportingFormResult] = useState<boolean>(false);

  // redux
  const dispatch = useDispatch();

  const tabs = useMemo(
    () =>
      walkInAllowed
        ? [
            {
              value: RegistrationTypeEnum.RSVP,
              label: t('event.form.participant_rsvp_list'),
            },
            {
              value: RegistrationTypeEnum.WALKIN,
              label: t('event.form.walk_ins_list'),
            },
          ]
        : [
            {
              value: RegistrationTypeEnum.RSVP,
              label: t('event.form.participant_rsvp_list'),
            },
          ],
    [t, walkInAllowed],
  );

  const onExportFormResult = useCallback(async () => {
    setIsExportingFormResult(true);
    try {
      const formatFilter = formatParticipantFilter(filterState);
      await exportRegistrationFormResult(
        eventId,
        formatFilter as ParticipantListParam,
        eventItem?.name[locale] as string,
        dispatch,
      );
    } catch (err) {
    } finally {
      setIsExportingFormResult(false);
    }
  }, [dispatch, eventId, filterState, eventItem?.name, locale]);

  const onExport = useCallback(async () => {
    setIsExporting(true);
    try {
      const formatFilter = formatParticipantFilter(filterState);
      await exportParticipantList(eventId, formatFilter as ParticipantListParam, dispatch);
    } catch (err) {
    } finally {
      setIsExporting(false);
    }
  }, [eventId, filterState]);

  return {
    tabs,
    tabValue,
    showFilter,
    isExporting,
    isExportingFormResult,
    setTabValue,
    setShowFilter,
    onExportFormResult,
    onExport,
  };
};
