import React, { memo, useMemo } from 'react';
import { Chip, Stack } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './chip-tabs.style';

export type ChipTabsConfig = {
  label: string;
  value: string;
};

type ComponentProps = ParamsProps & {
  config: Array<ChipTabsConfig>;
  value: string;
  onChange: (key: string) => void;
};

export const ChipTabsComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { config, value, onChange } = props;

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <Stack direction="row" spacing={1}>
      {config.map((item) => (
        <Chip
          key={item.value}
          label={item.label}
          onClick={() => onChange(item.value)}
          variant={value === item.value ? 'filled' : 'outlined'}
          className={value === item.value ? styles.selected : styles.unselected}
        />
      ))}
    </Stack>
  );
});
