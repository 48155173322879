import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  page: {
    height: '100%',
    '& .react-pdf__Page__canvas': {
      width: 'auto !important',
      height: '100% !important',
    },
  },
}));
