import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { get, set, sum } from 'lodash';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { getAssistedLeadDetails, getAssistedLeadSummary } from '../../network/insightCrud';
import moment from 'moment';
import { InsightSession } from '../../types/types';
import { getPercentage } from '../../util/insight.util';
import { ListFilterConfig, ListFilterData } from 'src/app/common/components/list-filter/list-filter.component';
import { FilterComponentsEnum } from 'src/app/common/components/list-filter/filter-components.enum';
import { DateRadioGroupType } from 'src/app/common/components/list-filter/components/list-filter-date-radio-group/list-filter-date-radio-group.component';
import { AccordionProps } from '../../components/insight-accordion/insight-accordion.component';
import { globalStore } from 'src/app/common/helpers/global-store.util';

const colorList = ['#FF872E', '#24B1C7', '#9983dc', '#4386E5', '#CCCCCC'];

const formatSummaryData = (summary: any) => {
  const length = colorList.length;
  const getOthersDate = (list: any[]) => {
    let others = sum(
      list.slice(length - 1).map((item) => sum(item.campaignList?.map((subItem: any) => subItem.leadAdded))),
    );
    const othersData = {
      label: 'others',
      value: others,
      color: '#CCCCCC',
    };
    return list
      .slice(0, length - 1)
      .map((item, index) => {
        return {
          label: item.campaignTypeName,
          value: sum(item.campaignList?.map((subItem: any) => subItem.leadAdded)),
          color: colorList[index],
        };
      })
      .concat(othersData);
  };
  let formatData = [];
  if (summary.length < length) {
    formatData = summary.map((item: any, index: number) => {
      return {
        label: item.campaignTypeName,
        value: sum(item.campaignList?.map((subItem: any) => subItem.leadAdded)),
        color: colorList[index],
      };
    });
  } else {
    formatData = getOthersDate(summary);
  }
  return formatData;
};

const formateDetailData = (data: any[], t: (id: string) => string) => {
  return data.map((detail: any, index: number) => {
    const { campaignTypeName, campaignList, sumOfLeads } = detail;
    const title = {
      dotColor: colorList[index] ?? '#CCCCCC',
      name: campaignTypeName,
      nameCount: campaignList.length,
      totalCount: sumOfLeads,
    };

    const body = campaignList.map((campaign: any) => {
      const { campaignName, leadAdded } = campaign;
      return {
        parent: {
          value: [campaignName, leadAdded],
          // link: `${link}&status=${stage}`,
        },
      };
    });

    const data: AccordionProps = {
      title: title,
      data: {
        header: [{ value: t('salesCaTitle') }, { value: t('company_assisted_leads_added') }],
        body: body,
      },
    };

    return data;
  });
};

export const useCompanyAssistedLeadDetail = () => {
  const { t } = useTranslation();

  const currentAgentCode =
    sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode();

  const [dateRange, setDateRange] = useState<string[]>(() => {
    const endDate = new Date().toISOString();
    const startDate = moment().subtract(1, 'month').toDate().toISOString();
    return [startDate, endDate];
  });

  const [leadsAdded, setLeadsAdded] = useState(0);
  const [campaignsParticipated, setCampaignsParticipated] = useState(0);
  const [summary, setSummary] = useState<any[]>([]);
  const [detail, setDetail] = useState<AccordionProps[]>([]);

  const defaultFilterValues = {
    dateRange: {
      key: 'last_30_days',
      value: {
        startDate: dateRange[0],
        endDate: dateRange[1],
        label: t('last_30_days'),
      },
    },
  };

  const [filterValues, setFilterValues] = useState<any>(defaultFilterValues);

  const filterConfig: Array<ListFilterConfig> = [
    {
      items: [],
      key: 'dateRange',
      title: t('insights_new_added_date'),
      type: FilterComponentsEnum.RADIO_GROUP,
    },
  ];

  const getData = useCallback(
    (dateRange: string[] | null) => {
      const body = {
        agentCode: currentAgentCode ?? '',
      };
      if (dateRange) {
        const [startDate, endDate] = dateRange;
        set(body, 'startDate', startDate);
        set(body, 'endDate', endDate);
      }
      getAssistedLeadSummary(body).then((res) => {
        if (res && res.data) {
          const { campaignsParticipated, leadsAdded } = res.data;
          setCampaignsParticipated(campaignsParticipated);
          setLeadsAdded(leadsAdded);
        }
      });
      getAssistedLeadDetails(body).then((res) => {
        if (res && res.data) {
          const summaryData = formatSummaryData(res.data);
          const detailData = formateDetailData(res.data, t);
          setDetail(detailData);
          setSummary(
            summaryData.map((item: any) => {
              return {
                ...item,
                label: `${item.label}`,
              };
            }),
          );
        }
      });
    },
    [dateRange],
  );

  const onFilterChange = useCallback((value: ListFilterData) => {
    const _dateRange = value['dateRange'] as DateRadioGroupType;
    if (_dateRange && _dateRange.value) {
      const { startDate, endDate } = _dateRange.value;

      if (_dateRange.key === 'ALL') {
        getData(null);
      } else {
        getData([startDate, endDate]);
      }
    } else {
      getData(dateRange);
    }
    setFilterValues(value);
  }, []);

  useEffect(() => {
    getData(dateRange);
  }, []);

  return {
    leadsAdded,
    campaignsParticipated,
    summary,
    detail,
    filterValues,
    filterConfig,
    defaultFilterValues,
    onFilterChange,
  };
};
