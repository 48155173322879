import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  session: {
    color: '#666666',
    fontWeight: 600,
    marginRight: 4,
  },
  enrollQuotaValue: {
    color: '#666666',
  },
}));
