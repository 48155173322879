import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useServicingCampaign } from './servicing-campaign.hook';
import { InsightSectionComponent } from '../../../components/insight-section/insight-section.component';

type ComponentProps = ParamsProps & {
  agentCode?: string;
};

export const ServicingCampaignComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();
  // custom-hook
  const { data, tooltip, handlePress } = useServicingCampaign({
    agentCode: props.agentCode,
  });
  return (
    <InsightSectionComponent onPress={handlePress} title={t('servicing_campaign')} data={data} tooltip={tooltip} />
  );
});
