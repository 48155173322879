import React, { CSSProperties, FC, memo } from 'react';
import styles from './error.module.css';

type ComponentProps = {
  title?: string;
  content?: string;
  imageSrc?: string;
  style?: CSSProperties;
};

export const ErrorComponent: FC<ComponentProps> = memo(
  ({ title, content, style, imageSrc = '/media/svg/icons/Error/file-flies.svg' }: ComponentProps) => {
    return (
      <div style={style} className={styles.wrap}>
        <img src={imageSrc} alt="error" width={180} />
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{content}</div>
      </div>
    );
  },
);
