import React, { useMemo, ComponentProps, useImperativeHandle } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Grid, MenuItem, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './lead-information.style';
import Form, { Item } from 'src/app/common/components/Form';
import { useLeadInformation } from './lead-information.hook';
import moment from 'moment';
import { constants } from 'src/app/common/constants';
import { DeleteLeadDialog } from '../delete-lead-dialog/delete-lead-dialog.component';
import { LeadReferredComponent } from 'src/app/modules/Leads/components/lead-referred/lead-referred.component';
import { LeadSourceEnum } from 'src/app/modules/Leads/types/types';

type PageProps = ParamsProps &
  RouteComponentProps & {
    leadDetail: any;
    readOnly?: boolean;
    canEdit?: boolean;
  } & ComponentProps<'base'>;

type InputItemProps = {
  name: string;
  label: string;
  defaultValue?: string | number | boolean;
  required?: boolean;
  isSelect?: boolean;
  selectOptions?: { label: string; value: string | boolean }[];
  readonly?: boolean;
  filedError?: {
    error?: string;
  };
};

const InputItem = (props: InputItemProps) => {
  const { name, label, defaultValue, required, isSelect, selectOptions, readonly, filedError } = props;
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const disabled = useMemo(() => {
    const editAccessList = [
      'description',
      'contactDay',
      'contactTime',
      'otherLeadComments',
      'contactMethod',
      'interestedProduct',
      'qualification',
      'financialNeeds',
      'expenses',
      'insuranceProducts',
    ];
    if (readonly) {
      return false;
    } else {
      return !editAccessList.includes(name);
    }
  }, [readonly, name]);
  return (
    <Item name={name} hideError>
      <TextField
        label={label}
        variant="outlined"
        defaultValue={defaultValue}
        required={required}
        className={styles.input}
        size="medium"
        select={isSelect}
        SelectProps={{
          size: 'medium',
        }}
        inputProps={{
          readOnly: readonly,
        }}
        error={Boolean(filedError)}
        helperText={filedError?.error ?? ''}
        disabled={disabled}
      >
        {isSelect &&
          selectOptions &&
          selectOptions.map((item, index) => (
            <MenuItem key={`${item.value}_${index}`} value={item.value.toString()}>{`${item.label}`}</MenuItem>
          ))}
      </TextField>
    </Item>
  );
};

export const LeadInformationPage = React.forwardRef((props: PageProps, ref) => {
  const { leadDetail, readOnly } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  // custom-hook
  const {
    form,
    formModel,
    showReferrby,
    selectedReferrBy,
    showDeleteDialog,
    onSelectReferrBy,
    setShowReferrby,
    onFiledChange,
    onDeleteDialogClose,
    deleteLead,
    submit,
  } = useLeadInformation({
    leadDetail: leadDetail,
  });
  const isVisible = (defaultValue: string | undefined, readOnly: boolean = true) => {
    if (!defaultValue && readOnly) {
      return false;
    } else {
      return true;
    }
  };

  const submitForm = () => {
    submit();
  };

  const resetForm = () => {
    form.resetFields();
  };

  useImperativeHandle(ref, () => ({
    submitForm: submitForm,
    resetForm: resetForm,
  }));
  return (
    <div>
      <Form initialValues={formModel} form={form} onFieldsChange={onFiledChange}>
        <div className={styles.sectionBox}>
          <div className={styles.sectionTitle}>
            <span>{Translation('lead.info.leadInformation')}</span>
          </div>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {isVisible(formModel?.leadDate, readOnly) && (
              <Grid item xs={6}>
                <InputItem
                  name={'leadDate'}
                  label={Translation('lead.info.subtitle.leadDate')}
                  defaultValue={moment(formModel?.leadDate).format(constants.Default_Date_Format)}
                  readonly={readOnly}
                />
              </Grid>
            )}
            {isVisible(formModel?.interestedProduct, readOnly) && (
              <Grid item xs={6}>
                <InputItem
                  name={'interestedProduct'}
                  label={Translation('lead.info.subtitle.interestedProduct')}
                  defaultValue={formModel.interestedProduct}
                  readonly={readOnly}
                />
              </Grid>
            )}
            {isVisible(formModel?.leadQualification, readOnly) && (
              <Grid item xs={6}>
                <InputItem
                  name="qualification"
                  label={Translation('lead.info.subtitle.qualification')}
                  readonly={readOnly}
                  defaultValue={formModel.leadQualification}
                />
              </Grid>
            )}
            {isVisible(formModel?.financialNeeds, readOnly) && (
              <Grid item xs={6}>
                <InputItem
                  name={'financialNeeds'}
                  label={Translation('lead.info.subtitle.financialNeeds')}
                  defaultValue={formModel.financialNeeds}
                  readonly={readOnly}
                />
              </Grid>
            )}
            {isVisible(formModel?.expenses, readOnly) && (
              <Grid item xs={6}>
                <InputItem
                  name={'expenses'}
                  label={Translation('lead.info.subtitle.expenses')}
                  defaultValue={formModel.expenses}
                  readonly={readOnly}
                />
              </Grid>
            )}
            {isVisible(formModel?.insuranceProducts, readOnly) && (
              <Grid item xs={6}>
                <InputItem
                  name={'insuranceProducts'}
                  label={Translation('lead.info.subtitle.insuranceProducts')}
                  readonly={readOnly}
                  defaultValue={formModel.insuranceProducts}
                />
              </Grid>
            )}
            {isVisible(formModel?.contactMethod, readOnly) && (
              <Grid item xs={6}>
                <InputItem
                  name={'contactMethod'}
                  label={Translation('lead.info.subtitle.contactMethod')}
                  readonly={readOnly}
                  defaultValue={formModel.contactMethod}
                />
              </Grid>
            )}
            {isVisible(formModel?.contactTime, readOnly) && (
              <Grid item xs={6}>
                <InputItem
                  name={'contactTime'}
                  label={Translation('lead.info.subtitle.contactTime')}
                  readonly={readOnly}
                  defaultValue={formModel.contactTime}
                />
              </Grid>
            )}
            {isVisible(formModel?.otherLeadComments, readOnly) && (
              <Grid item xs={6}>
                <InputItem
                  name={'otherLeadComments'}
                  label={Translation('lead.info.subtitle.otherLeadComments')}
                  readonly={readOnly}
                  defaultValue={formModel.otherLeadComments}
                />
              </Grid>
            )}
            {isVisible(formModel?.contactDay, readOnly) && (
              <Grid item xs={6}>
                <InputItem
                  name={'contactDay'}
                  label={Translation('lead.info.subtitle.contactDay')}
                  readonly={readOnly}
                  defaultValue={formModel.contactDay}
                />
              </Grid>
            )}
            {isVisible(formModel?.description, readOnly) && (
              <Grid item xs={6}>
                <InputItem
                  name={'description'}
                  label={Translation('lead.info.subtitle.description')}
                  readonly={readOnly}
                  defaultValue={formModel.description}
                />
              </Grid>
            )}
            {isVisible(selectedReferrBy?.displayName, readOnly) && (
              <Grid item xs={6}>
                <TextField
                  label={Translation('lead.info.subtitle.referrBy')}
                  variant="outlined"
                  className={styles.input}
                  size="medium"
                  onClick={() => {
                    if (readOnly) return;
                    setShowReferrby(true);
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={selectedReferrBy?.displayName ?? ''}
                ></TextField>
              </Grid>
            )}
          </Grid>
        </div>
        {(formModel?.policy || !readOnly) && (
          <div className={styles.sectionBox}>
            <div className={styles.sectionTitle}>
              <span>{Translation('lead.info.policyInformation')}</span>
            </div>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {isVisible(formModel?.policy?.policyNumber, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="policyNumber"
                    label={Translation('lead.info.subtitle.policyNumber')}
                    defaultValue={formModel?.policy?.policyNumber}
                    readonly={readOnly}
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.clientCode, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="notes"
                    label={Translation('lead.info.subtitle.clientCode')}
                    defaultValue={formModel?.policy?.clientCode}
                    readonly={readOnly}
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.effectiveDate, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="effectiveDate"
                    label={Translation('lead.info.subtitle.effectiveDate')}
                    defaultValue={
                      formModel?.policy?.effectiveDate
                        ? moment(formModel?.policy?.effectiveDate).format(constants.Default_Date_Format)
                        : ''
                    }
                    readonly={readOnly}
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.expiredDate, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="expiredDate"
                    label={Translation('lead.info.subtitle.expiredDate')}
                    defaultValue={
                      formModel?.policy?.expiredDate
                        ? moment(formModel?.policy?.expiredDate).format(constants.Default_Date_Format)
                        : ''
                    }
                    readonly={readOnly}
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.eligibleBenefit, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="eligibleBenefit"
                    label={Translation('lead.info.subtitle.eligibleBenefit')}
                    defaultValue={formModel?.policy?.eligibleBenefit}
                    readonly={readOnly}
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.paymentMethod, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="paymentMethod"
                    label={Translation('lead.info.subtitle.paymentMethod')}
                    defaultValue={formModel?.policy?.paymentMethod}
                    readonly={readOnly}
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.paidUpDate, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="paidUpDate"
                    label={Translation('lead.info.subtitle.paidUpDate')}
                    readonly={readOnly}
                    defaultValue={
                      formModel?.policy?.paidUpDate
                        ? moment(formModel?.policy?.paidUpDate).format(constants.Default_Date_Format)
                        : ''
                    }
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.productDescription, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="productDescription"
                    label={Translation('lead.info.subtitle.productDescription')}
                    defaultValue={formModel.policy?.productDescription}
                    readonly={readOnly}
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.insuredName, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="insuredName"
                    label={Translation('lead.info.subtitle.insuredName')}
                    defaultValue={formModel?.policy?.insuredName}
                    readonly={readOnly}
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.insuredClientCode, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="insuredClientCode"
                    label={Translation('lead.info.subtitle.insuredClientCode')}
                    defaultValue={formModel?.policy?.insuredClientCode}
                    readonly={readOnly}
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.insuredGender, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="insuredGender"
                    label={Translation('lead.info.subtitle.insuredGender')}
                    defaultValue={formModel?.policy?.insuredGender}
                    readonly={readOnly}
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.insuredPhoneNumber, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="insuredPhoneNumber"
                    label={Translation('lead.info.subtitle.insuredPhoneNumber')}
                    defaultValue={formModel?.policy?.insuredPhoneNumber}
                    readonly={readOnly}
                  />
                </Grid>
              )}
              {isVisible(formModel?.policy?.notes, readOnly) && (
                <Grid item xs={6}>
                  <InputItem
                    name="notes"
                    label={Translation('lead.info.subtitle.notes')}
                    defaultValue={formModel?.policy?.notes}
                    readonly={readOnly}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        )}

        <Button
          onClick={deleteLead}
          variant="outlined"
          disabled={leadDetail.leadSource !== LeadSourceEnum.agentSourced || !props.canEdit}
        >
          {Translation('lead.info.deleteLead').toUpperCase()}
        </Button>
      </Form>

      <LeadReferredComponent
        defaultSelectedRows={selectedReferrBy}
        open={showReferrby}
        onClose={() => setShowReferrby(false)}
        onConfirm={onSelectReferrBy}
      />
      <DeleteLeadDialog leadDetail={leadDetail} onClose={onDeleteDialogClose} open={showDeleteDialog} />
    </div>
  );
});
