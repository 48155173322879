import { useRef, useEffect } from 'react';
import { UpdateCheckerProps } from '../types/pru-stepped-form-types';

export const useUpdateChecker = ({
  disabled,
  formState,
  isReset,
  setChangesUnsaved,
  setIsReset,
}: UpdateCheckerProps) => {
  const initialRender = useRef(true);

  useEffect(() => {
    if (!disabled) {
      if (initialRender.current) {
        initialRender.current = false;
      } else if (isReset) {
        setIsReset(false);
      } else {
        setChangesUnsaved(true);
      }
    }
  }, [formState]);
};
