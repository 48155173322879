import React, { memo } from 'react';
import { styled, Tooltip, Typography, TooltipProps, tooltipClasses } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import styles from './tooltip.module.css';

type ComponentProps = {
  title?: string | JSX.Element;
};

export const TooltipComponent: React.FC<ComponentProps> = memo(({ title }: ComponentProps) => {
  if (!title) {
    return null;
  }
  return (
    <>
      <HtmlTooltip className={styles.wrap} title={title}>
        <InfoIcon className={styles.icon} />
      </HtmlTooltip>
    </>
  );
});

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(50, 50, 50, 0.8)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: '#fff',
    backgroundColor: 'rgba(50, 50, 50, 0.8)',
    fontSize: '0.9rem',
    wordBreak: 'break-word',
    overflowWrap: 'anywhere',
    textWrap: 'wrap',
  },
}));
