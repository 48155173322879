import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';
import { getOverview } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { APP_CONFIG } from '../../utils';
import { configManager } from '../../utils/app-configs.util';
import { OperationContext } from 'src/app/modules/Leads/components/lead-list-item/lead-operation-units/util/operation-context.util';

interface HookProps {
  campaignId: string;
  agentCodes: string[];
}

export const useCategorySelector = ({ campaignId, agentCodes }: HookProps) => {
  // redux
  const dispatch = useDispatch();
  const [overviewConfig, setOverviewConfig] = useState<Array<string>>([
    'total',
    'pendingAccept',
    'pendingContact',
    'contactSuccess',
    'met',
    'proposal',
    'quote',
    'policy',
    'dead',
  ]);

  const { data, error, loading, run, runAsync } = useRequest(() => getOverview({ campaignId, agentCodes }, dispatch), {
    manual: true,
  });
  useEffect(() => {
    run();
    const appConfig = configManager.getConfig(APP_CONFIG.SALES_COMMON);
    if (appConfig?.Sales?.lead.defaultOverview) {
      setOverviewConfig(['total', ...appConfig.Sales.lead.defaultOverview]);
    }
  }, [agentCodes]);

  const { event } = useContext(OperationContext);
  event &&
    event?.useSubscription(() => {
      run();
    });

  const list = useMemo(() => {
    if (data) {
      let res: { [key: string]: number } = {};
      res.total = data.total;
      if (data.overview) {
        res = { ...res, ...data.overview };
      }
      return res;
    }
    return undefined;
  }, [data]);

  return {
    loading,
    list,
    run,
    overviewConfig,
  };
};
