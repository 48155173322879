import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useMassLead } from './mass-lead.hook';
import { InsightSectionComponent } from '../../../components/insight-section/insight-section.component';

type ComponentProps = ParamsProps & {
  // agent-code string, split by comma,
  // example: "00010001" or "00010001,00010002,00010003"
  agentCode?: string;
};

export const MassLeadComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();

  // custom-hook
  const { data, tooltip, handlePress } = useMassLead({
    agentCode: props.agentCode,
  });
  return (
    <InsightSectionComponent onPress={handlePress} title={t('mass_lead_header_title')} data={data} tooltip={tooltip} />
  );
});
