interface Params {
  id: number;
  name: string;
}

export function insight({ id, name }: Params): string {
  // todo
  return name;
}
export const formatNumber = (value: string | number, toFixed: number = -1): string => {
  value = value ?? '-';

  if (toFixed !== -1 && value !== '-') {
    return (value as Number).toFixed(toFixed);
  }

  return value as string;
};

export const getPercentage = (value: number, sum: number, toFix: number = 0) => {
  if (sum === 0) {
    return '0%';
  }
  return ` ${Number(((value / sum) * 100).toFixed(toFix))}%`;
};
