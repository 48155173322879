import React, { memo } from 'react';
import Chart from 'react-apexcharts';
import { AreaChartHookProps, useAreaChart } from './area-chart.hook';
import { Box } from '@mui/material';
import { PaginationButton } from '../chart-button/chart-button.component';

export interface AreaChartProps extends AreaChartHookProps {}

export const AreaChart: React.FC<AreaChartProps> = memo((props: AreaChartProps) => {
  const { options, series, disablePrevious, disableNext, onPageChange } = useAreaChart(props);

  return (
    <Box>
      <Box display={'flex'} justifyContent={'flex-end'} margin={'16px'} marginRight={0}>
        <PaginationButton disablePrevious={disablePrevious} disableNext={disableNext} onChange={onPageChange} />
      </Box>
      <Chart options={options} series={series} type="area" width={'100%'} height={205} />
    </Box>
  );
});
