import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import plugins from './plugins';
import toolbar from './toolbar';
import menu from './menu';
import menubar from './menubar';
import { makeStyles, withStyles } from 'tss-react/mui';
import { Button, CircularProgress, Dialog } from '@mui/material';
import PreviewDialog from 'src/app/common/components/Preview';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { createBlobAsAgent, getBlobAsAgent } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { forEach } from 'lodash';
import { useStyles } from './style';

// interface DpEditorValueState {
//   html: string | '';
// }

interface DpEditorProps {
  // EditorValue: string;
  value?: string;
  onChange?: (EditorValue: string) => void;
  onChangeWordCount?: (EditorValueCount: string) => void;
  disabled: boolean;
  module?: string;
  accessLevel?: 'public' | 'private' | 'anonymous';
}

const StyledDialog = withStyles(Dialog, {
  root: {
    'z-index': '9999999999 !important',
  },
  paper: {
    background: 'rgba(0, 0, 0, 0)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const DpEditor: React.FC<DpEditorProps> = ({
  onChange,
  onChangeWordCount,
  value,
  disabled,
  module,
  accessLevel = 'public',
}) => {
  const { classes } = useStyles();
  // const [html, setContent] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [previewValue, setPreviewValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  let tinyEditor: any;

  useEffect(() => {
    setPreviewValue(value || '');
  }, [value]);

  const triggerChange = (changedValue: string, editor: any) => {
    if (onChange) {
      // const imgTagMatch = changedValue.match(/<img(.+)[a-zA-Z]+(.+)\/>/);
      // const imgReg = /<img.*?(?:>|\/>)/gi;
      const imgReg = new RegExp(/<img.*?(?:>|\/>)/, 'gi');
      const imgTagMatch = changedValue.match(imgReg);
      forEach(imgTagMatch, (imgTag) => {
        const oldString = imgTag;
        const blobId = oldString.match(/blobId=(.+?)"/);
        var blobIdString = '';
        if (blobId && blobId.length > 0) {
          blobIdString = blobId[0].split('blobId=')[1].split('"')[0];
        }
        const newString =
          oldString.indexOf('data-blob-id') < 0
            ? oldString.split('/>')[0] + ' data-blob-id="' + blobIdString + '"> </img>'
            : oldString.split('/>')[0] + '> </img>';
        changedValue = changedValue.replace(oldString, newString);
      });
      if (onChangeWordCount) {
        onChangeWordCount(editor.plugins.wordcount.getCount());
      }
      onChange(changedValue);
    }
  };

  const onEditorChange = (newContent: string, editor: any) => {
    triggerChange(newContent, editor);
  };

  const getWordCountInitial = (editor: any) => {
    if (editor.isNotDirty && onChangeWordCount) {
      onChangeWordCount(editor.plugins.wordcount.getCount());
    }
  };

  const onUploadFile = async (
    {
      file,
      fileName,
      fileType,
      success,
      failure,
    }: { file: any; fileName: string; fileType: string; success: Function; failure: Function },
    lang: string = 'en-US',
  ) => {
    const TranslationWithVariable = (key: string, count: number | string) =>
      intl.formatMessage({ id: key }, { num: count });
    const errMsg = TranslationWithVariable('global.max.file.size.allow', 5);
    const fileSize = file.size / 1024 / 1024;

    try {
      if (fileSize > 5) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsg,
            },
          ]),
        );
        failure(errMsg);
      } else {
        const createBlobRes = await createBlobAsAgent({ mimeType: file.type, accessLevel, module }, dispatch);
        await fileUpload(createBlobRes[0].url, file, setUploadProgress);
        const blobDetail = await getBlobAsAgent({ resourceIds: createBlobRes[0].blobId }, dispatch);
        const result = blobDetail[0];
        success(result.url);
      }
    } catch (err) {
      failure(err);
      console.log(uploadProgress);
    }
  };

  const onPreview = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const uploadVideo = (cb: any, value: any, meta: any) => {
    var input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'video/*');
    const TranslationWithVariable = (key: string, count: number | string) =>
      intl.formatMessage({ id: key }, { num: count });
    const errMsg = TranslationWithVariable('global.max.file.size.allow', 300);

    input.onchange = async (e: any) => {
      const file = (e.target.files || [])[0];
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 300) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsg,
            },
          ]),
        );
        return;
      }
      setIsLoading(true);
      try {
        const createBlobRes = await createBlobAsAgent({ mimeType: file.type, accessLevel, module }, dispatch);
        await fileUpload(createBlobRes[0].url, file, setUploadProgress);
        const blobDetail = await getBlobAsAgent({ resourceIds: createBlobRes[0].blobId }, dispatch);
        const result = blobDetail[0];
        setIsLoading(false);
        cb(`${result.url}#t=0.1&blobId=${result.blobId}`, { sourcemime: result.blobId });
      } catch (err) {
        console.log('time out');
        console.log(uploadProgress);
        setIsLoading(false);
      }
    };

    input.click();
  };

  return (
    <div className={classes.container}>
      <Editor
        licenseKey="gpl"
        disabled={disabled}
        tinymceScriptSrc={window.location.origin + '/scripts/tinymce/tinymce.min.js'}
        initialValue={value}
        //value={value}
        //onEditorChange={defaultEditorOnChange}
        init={{
          setup: (editor) => {
            // eslint-disable-next-line
            tinyEditor = editor;
            // tinyEditor.setContent(value);
            editor.on('blur', function (e) {
              if (disabled) return;
              onEditorChange(tinyEditor.getContent(), tinyEditor);
              setPreviewValue(tinyEditor.getContent());
            });
          },
          init_instance_callback: function (editor) {
            getWordCountInitial(editor);
          },
          plugins,
          toolbar,
          menu,
          menubar,
          branding: false,
          promotion: false,
          // language_url: '/tinymce/langs/en_US.js',
          // language: ' en_US',
          font_formats:
            '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif',
          min_height: 300,
          images_upload_handler: (blobInfo) => {
            return new Promise((resolve, reject) => {
              // const formData = new FormData();
              // formData.append('file', blobInfo.blob(), blobInfo.filename());
              const file = blobInfo.blob();
              const fileName = blobInfo.filename();
              const type = fileName.split('.').pop();
              const fileType = `image/${type}`;
              onUploadFile({ file, fileName, fileType, success: resolve, failure: reject });
            });
          },
          file_picker_types: 'media',
          file_picker_callback: uploadVideo,
          video_template_callback: function (data: any) {
            // eslint-disable-next-line
            const blobId = data.source.split('blobId=')[1];
            const videoHtml = `<video width="100%" height="100%" ${
              data.poster ? ` poster="${data.poster}"` : ``
            } controls="controls" preload="metadata" data-blob-id="${blobId}">\n  <source src="${data.source}" ${
              data.sourcemime ? ` type="${data.sourcemime}"` : ``
            } />\n ${
              data.altsource
                ? `<source src="${data.altsource}"` +
                  (data.altsourcemime ? ` type="${data.altsourcemime}"` : ``) +
                  ' />\n'
                : ''
            } </video>`;
            return videoHtml;
          },
          relative_urls: false,
          remove_script_host: false,
        }}
      />
      {onPreview && (
        <Button color="secondary" className={classes.btnContainer} onClick={onPreview}>
          {Translation('global.text.preview')}
        </Button>
      )}
      <PreviewDialog html={previewValue} open={open} onClose={onClose} />

      <StyledDialog open={isLoading} onClose={onClose} fullScreen={true}>
        <CircularProgress color="secondary" />
      </StyledDialog>
    </div>
  );
};

export default DpEditor;
