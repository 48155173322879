import { RegionLocale } from 'src/app/i18n';
import { Agent } from 'src/app/common/types';
import { getDisplayName } from 'src/app/common/utils';
import { ProColumns } from 'src/app/common/components/ProTable';
import { HighlightMatchedText } from 'src/app/common/components/highlight-matched-text/highlight-matched-text.component';

export const getColumns: (
  Translation: (id: string) => string,
  locale: RegionLocale,
  keyword: string,
) => ProColumns<Agent>[] = (Translation, locale, keyword) => [
  {
    dataIndex: 'unit',
    title: Translation('common.agent.unit'),
    width: '290px',
    sorter: true,
    render: (_, agent) => <HighlightMatchedText highLightText={keyword}>{agent.unit}</HighlightMatchedText>,
  },
  {
    dataIndex: 'name',
    title: Translation('common.agent.business_name'),
    width: '290px',
    render: (_, agent) => getDisplayName(agent, locale),
  },
  {
    dataIndex: 'agentCode',
    title: Translation('common.agent.agent_code'),
    width: '290px',
    render: (_, agent) => <HighlightMatchedText highLightText={keyword}>{agent.agentCode}</HighlightMatchedText>,
    sorter: true,
  },
  {
    dataIndex: 'designation',
    title: Translation('common.agent.designation_code'),
    width: '290px',
    render: (_, agent) => <HighlightMatchedText highLightText={keyword}>{agent.designation.code}</HighlightMatchedText>,
    sorter: true,
  },
];
