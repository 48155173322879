import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { sum } from 'lodash';

import { InsightSession } from '../../types/types';
import { getServicingCampaignDetail } from '../../network/insightCrud';
import { getPercentage } from '../../util/insight.util';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

const colorList = ['#FF872E', '#24B1C7', '#4386E5', '#9983dc', '#CCCCCC'];
export type CampaignDetail = {
  name: string;
  startDate: string;
  endDate: string;
  feedbacks: Array<{
    type: string;
    count: number;
  }>;
};

export const useServicingCampaignDetail = () => {
  const { t } = useTranslation();
  const currentAgentCode =
    sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode();
  const [totalFeedback, setTotalFeedback] = useState<number>(0);
  const [activeCampaignCount, setActiveCampaignCount] = useState<number>(0);

  const [summary, setSummary] = useState<any[]>([]);
  const [campaignDetails, setCampaignDetails] = useState<CampaignDetail[]>([]);

  function handleFeedbackOthers(arr: any[], limit: number) {
    arr = arr.sort((a, b) => b.count - a.count);
    const otherCount = sum(arr.slice(limit).map((item) => item.count));

    const othersData = {
      label: t('others'),
      value: otherCount,
      color: '#CCCCCC',
    };
    const formatData = arr.slice(0, limit - 1).map((item, index) => {
      return {
        label: `${item.type}`,
        value: item.count,
        color: colorList[index],
      };
    });
    if (arr.length > limit) {
      formatData.push(othersData);
    }

    return formatData;
  }

  useEffect(() => {
    getServicingCampaignDetail({
      agentCode: currentAgentCode ?? '',
    }).then((res) => {
      if (res && res.data) {
        const { activeCampaign, totalFeedback, summaryFeedback, campaignDetails } = res.data;
        const summaryData = handleFeedbackOthers(summaryFeedback, colorList.length);
        setActiveCampaignCount(activeCampaign);
        setTotalFeedback(totalFeedback);
        setCampaignDetails(campaignDetails);
        setSummary(summaryData);
      }
    });
  }, []);

  return {
    summary,
    totalFeedback,
    activeCampaignCount,
    campaignDetails,
  };
};
