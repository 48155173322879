import React from 'react';
import { DateFormat, formatDate } from '../../../common/utils/date.util';
import { SlaTimeCounterComponent } from '../components/lead-list-item/sla-time-counter/sla-time-counter.component';
import { LeadProspectNameComponent } from '../components/lead-list-item/lead-prospect-name/lead-prospect-name.component';
import { LeadRemarkComponent } from '../components/lead-list-item/lead-remark/lead-remark.component';
import {
  LeadCampaignNameComponent,
  LeadSource,
} from '../components/lead-list-item/lead-campaign-name/lead-campaign-name.component';
import { LeadTypesTagComponent } from '../components/lead-list-item/lead-types-tag/lead-types-tag.component';
import { LeadTimeDisplayComponent } from '../components/lead-list-item/lead-time-display/lead-time-display.component';
import { LeadTextDisplayComponent } from '../components/lead-list-item/lead-text-display/lead-text-display.component';
import { LeadOperationComponent } from '../components/lead-list-item/lead-operation/lead-operation.component';
import { LeadDropDateComponent } from '../components/lead-list-item/lead-drop-date/lead-drop-date.component';
import { FollowUpStatusEnum, Lead, TabValues } from '../types/types';
import { LeadStatusStepperComponent } from '../components/lead-status-stepper/lead-status-stepper.component';
import { LeadOpportunityOperation } from '../components/lead-list-item/lead-operation-units/lead-opportunity-operation/lead-opportunity-operation.component';
import { ProspectLeadOperation } from '../components/lead-list-item/lead-operation-units/prospect-lead-operation/prospect-lead-operation.component';
import { LeadFollowUpOperation } from '../components/lead-list-item/lead-operation-units/lead-follow-up-operation/lead-follow-up-operation.component';

export type OperationType = {
  tab?: TabValues;
  onReject?: (item: Lead) => void;
  onAccept?: (item: Lead) => void;
  onAppt?: (item: Lead) => void;
  onUpdate?: (item: Lead) => void;
};

export type TalkbotOperationType = {
  onAutoCall?: (item: Lead) => void;
};

export const renderDate = (currValue: string) => formatDate(currValue, DateFormat.date);

export const renderDateTime = (currValue: string) => formatDate(currValue);

export const renderSLATime = (currValue: string, item: any) => (
  <SlaTimeCounterComponent slaDate={currValue} item={item} />
);

export const renderLeadProspectName = (currValue: string, item: Lead, keyword?: string) => (
  <LeadProspectNameComponent displayName={item.prospect?.displayName} item={item} keyword={keyword} />
);

export const renderLeadRemark = (currValue: string, item: Lead) => <LeadRemarkComponent item={item} />;

export const renderLeadCampaignName = (currValue: string, item: Lead) => <LeadCampaignNameComponent item={item} />;
export const renderLeadSource = (currValue: string, item: Lead) => <LeadSource item={item} />;

export const renderLeadTypeOfLead = (currValue: string, item: Lead) => <LeadTypesTagComponent item={item} />;

export const renderLeadTime = (currValue: string) => <LeadTimeDisplayComponent currValue={currValue} />;

export const renderLeadText = (currValue: string) => <LeadTextDisplayComponent currValue={currValue} />;

export const renderLeadOperation = (
  item: Lead,
  { onReject, onAccept, onAppt, onUpdate, tab }: OperationType,
  actions: (actions: ('clearRow' | 'refresh')[]) => void,
  status?: FollowUpStatusEnum,
) => (
  <LeadOperationComponent
    item={item}
    onAccept={onAccept}
    onAppt={onAppt}
    onReject={onReject}
    onUpdate={onUpdate}
    tab={tab}
    actions={actions}
    status={status}
  />
);

export const renderLeadStatus = (currValue: string, item: Lead) => (
  <LeadStatusStepperComponent followUp={item.followUp} agentStatus={item.agentStatus} />
);

export const renderLeadDropDate = (currValue: string) => <LeadDropDateComponent currValue={currValue} />;
export const renderOpportunityLeadOperation = (
  item: Lead,
  actions: (actions: ('clearRow' | 'refresh')[]) => void,
  operations?: TalkbotOperationType,
) => <LeadOpportunityOperation item={item} actions={actions} operations={operations} />;

export const renderFollowUpLeadOperation = (item: Lead, actions: (actions: ('clearRow' | 'refresh')[]) => void) => (
  <LeadFollowUpOperation item={item} actions={actions} />
);

export const renderProspectLeadOperation = (item: Lead, actions: (actions: ('clearRow' | 'refresh')[]) => void) => (
  <ProspectLeadOperation item={item} actions={actions} />
);
