import { AuthApiRes, IntrospectRes, TokenInfo } from '../types/auth-types';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, CORE_URL, PUBLIC_URL, AGENT_URL } from 'src/app/common/network';
import { convertServerLocale, RegionLocale } from 'src/app/i18n';
import { stringify } from 'query-string';
import { AxiosRequestConfig } from 'axios';
import * as jose from 'jose';
import axios from 'axios';
import { Agent } from 'src/app/common/types/common-types';

export const getToken = async (authorizationCode: string, dispatch?: Dispatch<any>): Promise<AuthApiRes> => {
  return apiClient
    .get<AuthApiRes>(`${CORE_URL}/auth/token?grantType=authorizationCode&code=${authorizationCode}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const agentLogin = async (username: string, password: string, dispatch?: Dispatch<any>): Promise<AuthApiRes> => {
  return apiClient
    .post<AuthApiRes>(
      `${CORE_URL}/auth/token?grantType=password`,
      {
        username,
        password,
      },
      {
        headers: {
          Application: 'Agent Portal',
          'Cache-Control': 'no-cache', // Prevent caching to avoid login-cors-error
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

// const getJWTConfig = axios.get(`${PUBLIC_URL}/configs/system-appconfig`);
/**
 * get jwt config, session cache first
 */
const getJWTConfig = async (): Promise<{
  kid: string; // Key ID
  kty: string; // Key Type
  alg: string; // Algorithm
  use: string; // Public Key Use
  n: string; // Modulus
  e: string; // Exponent
}> => {
  const jwtStorageKey = 'JWT_CONFIG';
  // Check if the data is already in session storage
  const cachedData = sessionStorage.getItem(jwtStorageKey);

  if (cachedData) {
    // Parse and return the cached data
    return JSON.parse(cachedData);
  } else {
    try {
      // Make the API call
      const response = await axios.get(`${PUBLIC_URL}/configs/system-appconfig`);
      const jwk = response.data?.content?.jwks?.[0] || {};
      // Cache the response in session storage
      sessionStorage.setItem(jwtStorageKey, JSON.stringify(jwk));
      return jwk;
    } catch (error) {
      console.error('Error fetching app config:', error);
      throw error; // Re-throw the error for further handling
    }
  }
};

export const decodeJWT = async (jwtToken: string): Promise<TokenInfo> => {
  try {
    // const config = await getJWTConfig;
    // const jwk = config.data?.content?.jwks?.[0] || {};
    const jwk = await getJWTConfig();
    const data = await jose.jwtVerify(jwtToken, await jose.importJWK(jwk), {
      algorithms: ['RS256'],
    });

    return data.payload as TokenInfo;
  } catch (e) {
    console.log('jwt token validate error');
    return jose['decodeJwt'](jwtToken) as TokenInfo;
  }
};

export const introspect = async (dispatch?: Dispatch<any>): Promise<IntrospectRes> => {
  return apiClient
    .get<IntrospectRes>(`${CORE_URL}/auth/introspect`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const refreshToken = async (refreshToken: string, dispatch?: Dispatch<any>): Promise<AuthApiRes> => {
  return apiClient
    .get<AuthApiRes>(`${CORE_URL}/auth/token?grantType=refreshToken&code=${refreshToken}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const revokeRefreshToken = async (refreshToken: string): Promise<boolean> => {
  return apiClient
    .delete(`${CORE_URL}/auth/token?refreshToken=${refreshToken}`)
    .then((response) => response.status === 204)
    .catch((err) => {
      return false;
    });
};

export const getOTP = async (data: any, dispatch?: Dispatch<any>, config?: AxiosRequestConfig): Promise<AuthApiRes> => {
  // return apiClient.get<AuthApiRes>(`${CORE_URL}/auth/getOtp/auth/getOtp?agentCode=${data.agentCode}&phoneNum=${data.phoneNum}&lang=${data.lang}&enableVerificationTrials=${data.enableVerificationTrials ? true : false}`)
  const url = `${CORE_URL}/auth/getOtp?${stringify(data)}`;
  return apiClient
    .get<AuthApiRes>(url, config)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const verifyOTP = async (
  data: any,
  dispatch?: Dispatch<any>,
  config?: AxiosRequestConfig,
): Promise<AuthApiRes> => {
  return apiClient
    .get<AuthApiRes>(
      `${CORE_URL}/auth/validateOtp?agentCode=${data.agentCode}&token=${data.otpToken}&enableVerificationTrials=${
        data.enableVerificationTrials ? true : false
      }`,
      config,
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCurrentAgentInfo = async (
  lang: string,
  dispatch?: Dispatch<any>,
  config?: AxiosRequestConfig,
): Promise<Agent> => {
  const queryPath = `${AGENT_URL}/agents/me?lang=${convertServerLocale(lang as RegionLocale)}`;

  return apiClient
    .get<any>(queryPath, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
