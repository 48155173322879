import { get, set } from 'lodash';
import { ProColumns } from 'src/app/common/components/ProTable';
import { DateFormat, formatDate } from 'src/app/common/utils';
import { ListType } from '../prospect-type-list/prospect-type-list.component';

type ProspectType = {
  name: string;
  type: string;
  count: number | string;
  targetNumber: number;
  backgroundColorLabel: string;
  colorLabel: string;
};

export const getColumns = ({ t, styles }: any): ProColumns<any>[] => [
  {
    title: t('prospect_name'),
    dataIndex: 'displayName',
    width: '290px',
    render: (currValue: string) => {
      return <div style={{ wordBreak: 'break-all', color: '#333333', fontWeight: '500' }}>{currValue}</div>;
    },
  },
  {
    title: t('create_date'),
    dataIndex: 'createdAt',
    sorter: true,
    width: '176px',
    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.date);
    },
  },
];

export const getDisplayValue = (prospect: ProspectType, insightType: string) => {
  if (insightType === ListType.ALL) {
    return `${prospect.count}${prospect.targetNumber ? '/' + prospect.targetNumber : ''}`;
  }
  const countNumber = Number(String(prospect.count).replace('+', ''));
  return countNumber ? prospect.count : '-';
};
