import React, { FC, memo, useMemo, useRef } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { FilterTableContainer } from 'src/app/common/components/filter-table-container/filter-table-container.component';
import { getContactedLeadsList } from '../../../../util/api.util';
import { openLeadDetailInNewTab } from 'src/app/modules/Leads/util/leads.util';
import { getFollowUpsColumns } from '../../../../util/list.util';

type ComponentProps = ParamsProps & {
  onLoad?: (
    record: any[],
    pageInfo?: {
      page?: number;
      pageSize?: number;
      total?: number;
    },
  ) => void;
};

export const ContactedLeadsComponent: React.FC<ComponentProps> = memo(({ onLoad }: ComponentProps) => {
  const cacheParams = useRef({ sorter: { updatedAt: 'asc' } });
  const getColumns = useMemo(() => {
    return () => getFollowUpsColumns({});
  }, []);
  return (
    <FilterTableContainer
      rowKey="_id"
      cacheParams={cacheParams}
      getData={getContactedLeadsList}
      getColumns={getColumns}
      showFilter={false}
      showSelect={false}
      onClickRow={(record) => openLeadDetailInNewTab(record)}
      onLoad={onLoad}
    />
  );
});
