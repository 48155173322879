import { useEffect, forwardRef, useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { SurveyCreator as SurveyJSCreator, SurveyCreatorComponent } from 'survey-creator-react';
import 'survey-core/defaultV2.css';
import 'survey-creator-core/survey-creator-core.css';
import 'survey-core/survey.i18n';
import './survey-patch-style.css';
import { surveyLocalization, Serializer, settings } from 'survey-core';
import { ICreatorOptions, settings as SurveyCreatorSetting } from 'survey-creator-core';
import { initJson } from './survey-json';
import {
  pageProperty,
  panelProperty,
  questionProperty,
  enabledQuestionTypes,
  isPIIProperty,
  presetProperty,
  isAutoCapitalizeProperty,
  disableChoicesProperty,
  contractMonthProperty,
  inputTypeList,
  triggerPopulateProperty,
  tilNowProperty,
  surveyRequiredErrorTextProperty,
  surveyInvalidNumberErrorTextProperty,
  TnCUploaderProperty,
  TnCLocaleProperty,
  maxFileNumberProperty,
  attachmentUrlProperty,
  acceptedTypesErrorTextProperty,
  maxFileNumberErrorTextProperty,
  moduleOrderProperty,
  watermarkProperty,
  disallowedActionsProperty,
  choicesLazyLoadEnabledProperty,
  enableChoicesLazyLoadProperty,
  isChoicesByProtectedUrlProperty,
  convertImageToPdfProperty,
  validateDimensionProperty,
} from './survey-config';
import { registerYearPicker } from './custom-components';
import { handleFile } from './survey-utils';
import { regionLocale } from 'src/app/i18n';

interface localeInterface {
  [key: string]: string;
}

const localeMap: localeInterface = {
  en: 'default',
  'zh-Hans': 'zh-cn',
  'zh-Hant': 'zh-tw',
};

const localeList = regionLocale;

//format locale to surveyjs format
export const getLocale = (locale: string) => {
  if (localeMap[locale]) {
    return localeMap[locale];
  }
  return locale;
};
settings.storeDuplicatedTranslations = true;
export const supportedLocales = localeList.map((locale) => getLocale(locale));

const initSurveyCreator = (disableLogo?: boolean) => {
  registerYearPicker();
  //set property config
  // Serializer.findProperty('checkbox', 'hasNone').visible = false;
  // Serializer.findProperty('checkbox', 'hasOther').visible = false;
  // Serializer.findProperty('checkbox', 'hasSelectAll').visible = false;
  // Serializer.findProperty('question', 'name').readOnly = true;

  // Serializer.findProperty('panel', 'name').readOnly = true;
  Serializer.findProperty('page', 'title').isRequired = true;

  // Serializer.findProperty('panel', 'title').readOnly = true;
  // Serializer.findProperty('page', 'title').readOnly = true;
  // Serializer.findProperty('text', 'inputType').visible = false;
  // Serializer.getProperty('dropdown', 'choices').visible = false;
  // Serializer.getProperty('checkbox', 'choices').visible = false;
  // Serializer.getProperty('paneldynamic', 'visible').visible = false;
  // Serializer.getProperty('boolean', 'title').maxLength = 1000;
  // Serializer.getProperty('file', 'maxSize').defaultValue = 4134320;
  Serializer.findProperty('text', 'inputType').setChoices(inputTypeList);

  //add property for panel/page/question
  Serializer.addProperty('panel', panelProperty);
  Serializer.addProperty('question', questionProperty);
  Serializer.addProperty('question', disallowedActionsProperty);
  Serializer.addProperty('panel', disallowedActionsProperty);
  Serializer.addProperty('page', disallowedActionsProperty);

  Serializer.addProperty('page', pageProperty);
  Serializer.addProperty('checkbox', presetProperty);
  Serializer.addProperty('text', presetProperty);
  Serializer.addProperty('boolean', presetProperty);
  Serializer.addProperty('question', isPIIProperty);
  Serializer.addProperty('radiogroup', presetProperty);
  Serializer.addProperty('text', isAutoCapitalizeProperty);
  Serializer.addProperty('dropdown', disableChoicesProperty);
  Serializer.addProperty('dropdown', enableChoicesLazyLoadProperty);
  Serializer.addProperty('dropdown', isChoicesByProtectedUrlProperty);
  Serializer.addProperty('checkbox', enableChoicesLazyLoadProperty);
  Serializer.addProperty('checkbox', isChoicesByProtectedUrlProperty);
  Serializer.addProperty('choicesByUrl', choicesLazyLoadEnabledProperty);
  Serializer.addProperty('text', contractMonthProperty);
  Serializer.addProperty('file', maxFileNumberErrorTextProperty);
  Serializer.addProperty('file', acceptedTypesErrorTextProperty);
  Serializer.addProperty('file', watermarkProperty);
  Serializer.addProperty('page', moduleOrderProperty);

  Serializer.addProperty('panel', 'titleLocation');

  Serializer.addProperty('text', triggerPopulateProperty);
  Serializer.addProperty('text', tilNowProperty);
  Serializer.addProperty('survey', surveyInvalidNumberErrorTextProperty);
  Serializer.addProperty('survey', surveyRequiredErrorTextProperty);
  Serializer.addProperty('boolean', TnCUploaderProperty);
  Serializer.addProperty('boolean', TnCLocaleProperty);
  Serializer.addProperty('boolean', attachmentUrlProperty);
  Serializer.addProperty('file', maxFileNumberProperty);
  Serializer.addProperty('file', validateDimensionProperty);
  Serializer.addProperty('file', convertImageToPdfProperty);

  if (disableLogo) {
    Serializer.removeProperty('survey', 'logo');
  }

  surveyLocalization.supportedLocales = supportedLocales;
  surveyLocalization.defaultLocale = 'en';
  surveyLocalization.currentLocale = 'en';
};

let pageCounter = 1;

interface I18nData {
  default: string;
  [key: string]: string;
}

interface Pages {
  name: string;
  title: I18nData;
  elements: any[];
  fieldType?: string;
  state: string;
}

interface Content {
  pages: Pages[];
  templateId: string;
  version: string;
  type?: string;
}

interface Data {
  content: Content;
  version?: string;
  templateId?: string;
  type?: string;
}

type SurveyCreatorProps = {
  data: Data;
  onChangeContent?: (data: any) => void; // The event is called when a survey is saved(user click save) in the designer
  onModifiedContent?: (surveyJson: any, extraData: any) => void; // The event is called when a survey is changed in the designer
  module: string;
  type?: string;
  customOptions?: ICreatorOptions;
  disableLogo?: boolean;
  disableAddQuestionButton?: boolean;
  disableFileTypeQuestion?: boolean;
  readOnly?: boolean; // whole creator readOnly
  customOnPageAdded?: (options: any, pageCounter: number) => void;
  customOnQuestionAdded?: (options: any) => void;
  customOnDragDropAllow?: (options: any) => void;
  customOnElementAllowOperations?: (options: any, originQuestionNames: string[]) => void;
  customOnGetPropertyReadOnly?: (options: any, originQuestionNames: string[]) => void;
};

type SurveyCreatorRef = {
  handleSave: () => void;
};

const defaultOptions: ICreatorOptions = {
  showJSONEditorTab: true,
  showTranslationTab: true,
  showLogicTab: false,
  questionTypes: enabledQuestionTypes,
  pageEditMode: 'bypage',
  isAutoSave: false,
  haveCommercialLicense: true,
  allowEditExpressionsInTextEditor: true,
  showErrorOnFailedSave: true,
};

const SurveyCreator = forwardRef<SurveyCreatorRef, SurveyCreatorProps>(
  (
    {
      data,
      onChangeContent,
      type,
      module,
      customOptions,
      disableLogo,
      disableAddQuestionButton,
      disableFileTypeQuestion,
      readOnly,
      customOnPageAdded,
      customOnQuestionAdded,
      customOnDragDropAllow,
      customOnElementAllowOperations,
      customOnGetPropertyReadOnly,
      onModifiedContent,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const [creator, setCreator] = useState(new SurveyJSCreator(customOptions ?? defaultOptions));
    const content = data?.content;
    const handleSave = () => {
      const json = creator.JSON;
      const saveData = {
        content: json,
        version: data.version,
        templateId: data.templateId,
        type: data.type,
      };
      onChangeContent && onChangeContent(saveData);
    };
    useImperativeHandle(ref, () => ({
      handleSave: handleSave,
    }));
    useEffect(() => {
      initSurveyCreator(disableLogo);
    }, [type]);

    creator.showSaveButton = false;

    function extractNames(obj: any) {
      const names: string[] = [];

      function processItem(item: any) {
        if (item.hasOwnProperty('name')) {
          names.push(item.name);
        }
        if (item.hasOwnProperty('pages') && Array.isArray(item.pages)) {
          item.pages.forEach((page: any) => {
            processItem(page);
            if (page.hasOwnProperty('elements') && Array.isArray(page.elements)) {
              page.elements.forEach((element: any) => {
                processItem(element);
                if (element.hasOwnProperty('elements') && Array.isArray(element.elements)) {
                  element.elements.forEach((innerItem: any) => {
                    processItem(innerItem);
                    if (innerItem.hasOwnProperty('templateElements') && Array.isArray(innerItem.templateElements)) {
                      innerItem.templateElements.forEach((item: any) => {
                        processItem(item);
                      });
                    }
                  });
                }
              });
            }
          });
        }
      }
      if (obj) {
        processItem(obj);
      }

      return names;
    }
    const originQuestionNames = extractNames(content);

    function setPanelQuestionsFieldType(obj: any, type: string) {
      switch (type) {
        case 'panel':
          if (obj?.fieldType) {
            obj.fieldType = 'Custom';
          }
          obj.disallowedActions = '';
          if (obj.elementsValue) {
            const questions = obj.elementsValue;
            questions.map((question: any) => {
              question.fieldType = 'Custom';
              question.disallowedActions = '';
            });
          }
          break;
        case 'question':
          if (obj?.fieldType) {
            obj.fieldType = 'Custom';
          }
          obj.disallowedActions = '';
          break;
        case 'page':
          if (obj?.fieldType) {
            obj.fieldType = 'Custom';
          }
          obj.disallowedActions = '';
          if (obj.elementsValue) {
            const panels = obj.elementsValue;
            panels.map((panel: any) => {
              setPanelQuestionsFieldType(panel, 'panel');
            });
          }
      }
    }

    useEffect(() => {
      const questionPropWhitelist = [
        'name',
        'title',
        'description',
        'fieldType',
        'isPII',
        'placeholder',
        'choices',
        'labelTrue',
        'text',
        'labelFalse',
        'visible',
        'keyDuplicationError',
        'panelAddText',
        'addRowText',
        'contractMonth',
        'defaultValue',
        'requiredErrorText',
        'expression',
        'visibleIf',
        'enableIf',
        'requiredIf',
        'isRequired',
        'moduleOrder',
        'maxLength',
        'choicesByUrl',
        'url',
        'path',
        'valueName',
        'titleName',
        'disallowedActions',
        'takePhotoWithWatermark',
        'preset',
        'acceptedTypesErrorText',
        'maxFileNumberErrorText',
        'readOnly',
        'panelCount',
        'minPanelCount',
        'maxPanelCount',
        'panelAddText',
        'panelRemoveText',
        'enableChoicesLazyLoad',
        'choicesLazyLoadEnabled',
        'isChoicesByProtectedUrl',
        'convertImageToPdf',
        'imageDimension',
      ];
      const surveyWhitelist = ['locale', 'invalidNumberErrorText', 'requiredErrorText'];
      const validationProperties = [
        'regex',
        'validators',
        'minCount',
        'maxCount',
        'minLength',
        'maxLength',
        'allowDigits',
        'minValue',
        'maxValue',
      ];

      const constantModule = ['termsNConditionsModule', 'eSignatureModule'];

      if (disableFileTypeQuestion) {
        creator.toolbox.removeItem('file');
      }

      creator.onShowingProperty.add(function (_, options) {
        const type = options.obj.getType();
        const propertyName = options?.property?.name;
        const name = options?.obj?.name;
        let questionWhiteList = [...questionPropWhitelist, ...validationProperties];

        // get permission for question
        if (options.obj.disallowedActions) {
          // get permission array for question
          if (options.obj.disallowedActions.includes('hide') || options.obj?.fieldType === 'Standard') {
            questionWhiteList = questionWhiteList.filter((item) => item !== 'visible' && item !== 'visibleIf');
          }
        }
        if (options.obj.getPropertyValue('preset') === 'confirm_checkbox_with_detail') {
          questionWhiteList.push('attachmentUrl', 'TnCUploader', 'TnCLocale');
        }
        if (constantModule.includes(name)) {
          questionWhiteList = questionWhiteList.filter((item) => item !== 'moduleOrder');
        }
        options.canShow = questionWhiteList.includes(propertyName);

        //   // add property for survey level
        if (type === 'survey') {
          options.canShow = surveyWhitelist.includes(propertyName);
        }
      });

      // module duplicate
      creator.onPageAdded.add(function (_, options) {
        const json = creator.JSON;
        pageCounter = json.pages.length - 1;
        const page = options.page;
        page.name = 'Module' + pageCounter;
        page.title = 'Module' + pageCounter;
        page.moduleOrder = pageCounter - 2;
        if (customOnPageAdded) {
          customOnPageAdded(options, json.pages.length);
        }
        setPanelQuestionsFieldType(page, 'page');
        setTimeout(() => {
          // when add page move above constant module
          const newPages = [];
          const constantPages = [];
          const newJson = creator.JSON;
          const pages = newJson.pages;
          for (const page of pages) {
            if (!constantModule.includes(page.name)) {
              newPages.push(page);
            }
            if (constantModule.includes(page.name)) {
              constantPages.push(page);
            }
          }
          json.pages = newPages.concat(constantPages);
          creator.JSON = json;
        }, 100);
      });
      // add question set fieldType (duplicate)
      creator.onQuestionAdded.add(function (_, options) {
        if (options.reason === 'ELEMENT_COPIED') {
          const question = options.question;
          setPanelQuestionsFieldType(question, 'question');
        } else {
          options.question.title = options.question.name;
        }
        if (customOnQuestionAdded) {
          customOnQuestionAdded(options);
        }
      });
      // panel duplicate
      creator.onPanelAdded.add(function (_, options) {
        if (options.reason === 'ELEMENT_COPIED') {
          const panel = options.panel;
          setPanelQuestionsFieldType(panel, 'panel');
        } else {
          options.panel.title = options.panel.name;
        }
      });

      creator.onDragDropAllow.add((sender, options) => {
        // disallowed question drag out of panel
        if (options.parent?.getType() === 'page' && options.source.getType() !== 'panel') {
          options.allow = false;
        }
        if (customOnDragDropAllow) {
          customOnDragDropAllow(options);
        }
      });

      creator.onElementAllowOperations.add(function (_, options) {
        if (constantModule.includes(options.obj.name)) {
          options.allowCopy = false;
        }
        if (options.obj.getType() === 'panel' && constantModule.includes(options.obj?.parent?.name)) {
          options.allowCopy = false;
        }
        if (options.obj?.disallowedActions?.includes('delete')) {
          options.allowDelete = false;
        }
        if (customOnElementAllowOperations) {
          customOnElementAllowOperations(options, originQuestionNames);
        }
      });

      // set origin questions name readonly
      creator.onGetPropertyReadOnly.add((sender, options) => {
        if (options.propertyName === 'name' && originQuestionNames.includes(options.obj.name)) {
          options.readOnly = true;
        }
        if (customOnGetPropertyReadOnly) {
          customOnGetPropertyReadOnly(options, originQuestionNames);
        }
      });

      creator.onSurveyPropertyValueChanged.add(function (_, options) {
        const obj = options.obj;
        const json = creator.JSON;
        const constantModules = json.pages.splice(-2);

        // change order
        if (obj.isPage && options.name === 'moduleOrder') {
          const selectedObjIndex = options.oldValue;
          const newModuleOrder = options.newValue;
          // Validate newModuleOrder
          if (newModuleOrder < 0 || newModuleOrder >= json.pages.length) {
            // Invalid moduleOrder, ignore the change
            return;
          }
          // Remove the selected object from the array
          const selectedObj = json.pages.splice(selectedObjIndex, 1)[0] as any;
          // Update moduleOrder for other objects
          // Moving the object to a lower index
          if (newModuleOrder < selectedObjIndex) {
            json.pages.forEach((item: any) => {
              if (item.moduleOrder >= newModuleOrder && item.moduleOrder < selectedObjIndex) {
                item.moduleOrder++;
              }
            });
          } else {
            // Moving the object to a higher index
            json.pages.forEach((item: any) => {
              if (item.moduleOrder > selectedObjIndex && item.moduleOrder <= newModuleOrder) {
                item.moduleOrder--;
              }
            });
          }
          // Update moduleOrder for the selected object
          selectedObj.moduleOrder = newModuleOrder;
          // Insert the selected object at the new index
          json.pages.splice(newModuleOrder, 0, selectedObj);
          json.pages = json.pages.concat(constantModules);
          creator.text = JSON.stringify(json);
        }
      });

      // handle delete module logic
      creator.onElementDeleting.add((sender, options) => {
        // if deleting module
        const disAllowDelete =
          options.element.disallowedActions?.includes('delete') || options.element?.fieldType === 'Standard';
        if (disAllowDelete) {
          options.allowing = false;
          return;
        }
        if (options.elementType === 2) {
          const json = creator.JSON;
          // delete page json by module order
          json.pages.splice(options.element.moduleOrder, 1);
          // reset moduleOrder except constant module
          const pages = json.pages;
          for (let i = 0; i < pages.length; i++) {
            if (!constantModule.includes(pages[i].name)) {
              pages[i].moduleOrder = i;
            }
          }
          creator.JSON = json;
        }
      });

      creator.onPropertyValidationCustomError.add((sender, options) => {
        const type = options.propertyName;
        if (type === 'name') {
          // disallow blank or special characters
          const pattern = /^[^\s\W]+$/;
          if (options.value && !pattern.test(options.value)) {
            options.error = 'Please enter a value without spaces or special characters.';
            return;
          }
        }
        if (type === 'moduleOrder') {
          // get the length of pages except constant module
          const pages = creator.JSON.pages;
          const length = pages.filter((item: any) => !constantModule.includes(item.name)).length;
          if (options.value < 0 || options.value >= length) {
            options.error = 'Please enter a value between 0 and ' + (length - 1);
            return;
          }
        }
        if (type === 'title') {
          // disallow title is empty
          if (!options.value) {
            options.error = 'Please enter a value.';
            return;
          }
        }
      });

      creator.onUploadFile.add((_, option) => {
        option.files.forEach(async (file: any) => {
          const result = await handleFile(file, module, dispatch);
          option.callback('success', result?.url);
        });
      });

      creator.onModified.add(function (_, options) {
        if (typeof onModifiedContent === 'function') {
          const extraData = {
            version: data.version,
            templateId: data.templateId,
            type: data.type,
          };
          onModifiedContent(creator.JSON, extraData);
        }
      });

      // add order to module
      content.pages.map((item: any, index: number) => {
        item.moduleOrder = index;
      });

      if (disableAddQuestionButton) {
        SurveyCreatorSetting.designer.showAddQuestionButton = false;
      } else {
        SurveyCreatorSetting.designer.showAddQuestionButton = true;
      }

      if (content) {
        creator.text = JSON.stringify(content);
      } else {
        creator.JSON = initJson;
      }

      creator.saveSurveyFunc = handleSave;
      creator.readOnly = readOnly ? true : false;
      setCreator(creator);
    }, [creator]);

    return <SurveyCreatorComponent creator={creator} key={module} />;
  },
);

export default SurveyCreator;
