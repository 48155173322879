export type EventAssistantFormState = {
  name?: string;
  email?: string;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD' | 'RESET';
  payload: {
    field: keyof EventAssistantFormState;
    value: any;
  };
};

type InitFieldAction = {
  type: 'INIT_FORM_DATA';
  payload: {
    value: any;
  };
};

export type EventAssistantFormAction = ModifyFieldAction | InitFieldAction;

export const eventAssistantFormReducer = (
  state: EventAssistantFormState,
  action: EventAssistantFormAction,
): EventAssistantFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'INIT_FORM_DATA':
      return { ...state, ...action.payload.value };
    default:
      return state;
  }
};
