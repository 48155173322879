import React, { useState, useEffect, ChangeEvent } from 'react';
// import { Button, Progress } from 'antd';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import { ButtonType } from 'antd/lib/button/button.d';
import { LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from "react-intl";
import { appendAlertItem, AlertType } from '../../../../redux/common/commonSlice';
import { useDispatch } from 'react-redux';
import Preview from '../Preview';

import * as imageConversion from 'image-conversion';

const compressFileSize = 150;

export interface FileObj {
  url?: string;
  importId: string;
  fileName: string;
  inclusive?: boolean,
  receiverType?: string,
  contentType?:string
}

export interface FileUploaderProps {
  showAllowText?: any;
  // btnType: ButtonType;
  btnTxt?: string;
  disabled?: boolean;
  onChange?: (value?: FileObj | null) => void;
  value?: FileObj;
  // upload: (params: { file: Blob, fileName: string, fileType: string }) => Promise<{ id: string, fileName?: string, url?: string }>
  upload: any;
  color?: any;
  maxFileSize?: number; // in MB
  allowedFileTypes?: string[];
  download?: any;
  contentType?: string;
  showPreview?: boolean;
  needCompressImage?: boolean;
  inclusive?: boolean;
  receiverType?: string,
}

const useStyles = makeStyles()((theme) => ({
  closeIcon: {
    marginLeft: '5px',
    verticalAlign: 'middle'
  }
}));

const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const [loadPercentage, setLoadPercentage] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [openPreview, handleOpenPreview] = useState<boolean>(false);
  const [previewContent, handlePreviewContent] = useState<any>();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) => intl.formatMessage({ id: key }, { num: count });
  const { classes } = useStyles();

  let flag: any = null;
  const {
    showAllowText,
    // btnType,
    btnTxt = Translation('app.button.upload'),
    disabled,
    value,
    onChange,
    upload,
    color = 'primary',
    maxFileSize,
    allowedFileTypes,
    download,
    contentType,
    showPreview,
    needCompressImage,
    inclusive,
    receiverType,
  } = props;
  const inputRef = React.createRef<HTMLInputElement>();

  const onSelect = () => {
    inputRef.current!.click();
  };

  const triggerChange = (changedValue: FileObj | null) => {
    if (onChange) {
      if (changedValue) {
        onChange({
          ...value,
          ...changedValue,
        });
      } else {
        onChange(null);
      }
    }
  };

  const simulatorPercentage = () => {
    flag = setInterval(() => {
      setLoadPercentage(v => {
        if (v < 90) {
          return v + 10
        }
        clearInterval(flag)
        return v
      })
    }, 1000);
  };

  const updateUploadFlag = (type = 'add') => {
    const curr = sessionStorage.getItem('isUploadFile') || 0;
    sessionStorage.setItem('isUploadFile', String(Number(curr) + (type === 'add' ? 1 : -1)));
  }

  const compressFile = async (file: any, fileType:any) => {
    const fileSize = file.size / 1024;
    const needCompress = needCompressImage && (fileSize > compressFileSize)

    if (needCompress) {
      const blob = await imageConversion.compressAccurately(file, compressFileSize);
      return blob;
    } else {
      return new Blob([file],{type:fileType} );
    }
  }

  const onUploadFile =  async (e: ChangeEvent<HTMLInputElement>) => {
    console.log(inclusive);
    const file = (e.target.files || [])[0];
    inputRef.current!.value = '';
    const { name: fileName, type: fileType } = file || {};
    simulatorPercentage();
    setIsUploading(true);
    updateUploadFlag('add')
    const newFileBlob = await compressFile(file, fileType);

    upload({ file: newFileBlob, fileName, fileType, inclusive, setLoadPercentage })
      .then((res:any) => {
      // .then(({ id, url } : { id: string, url?: string }) => {
        let url = res.url
        const httpUrl = url?.includes('http') ? url : `https:${url}`;
        triggerChange({
          ...res,
          url: url?.includes('/messages/variable-contents') ? `${url}?contentType=${contentType}` : httpUrl, importId: res.id,
          fileName,
          inclusive,
          receiverType,
          contentType
        });
        setLoadPercentage(100);
        setIsUploading(false);
        clearInterval(flag);
        updateUploadFlag('minus')
      })
      .catch(() => {
        dispatch(appendAlertItem([{
          severity: AlertType.ERROR,
          title: Translation('global.submit.fail'),
          content: Translation('global.submit.fail')
        }]));
        triggerChange(null);
        setIsUploading(false);
        setLoadPercentage(0);
        clearInterval(flag);
        updateUploadFlag('minus')
      });
  };

  const change = (e: ChangeEvent<HTMLInputElement>) => {
    const file = (e.target.files || [])[0];
    if (!checkFile(file, maxFileSize, allowedFileTypes)) {
      return
    }
    onUploadFile(e);
  };

  const deleteFile = () => {
    triggerChange(null);
    setLoadPercentage(0);
  };

  const checkFile = (file: Blob, maxSize?: number, fileTypes?: string[]) => {
    if (!file) return false
    if (fileTypes && !fileTypes.some(item => file.type.toLowerCase().endsWith(item))) {
      dispatch(appendAlertItem([{
        severity: AlertType.WARNING,
        title: Translation('global.submit.fail'),
        content: TranslationWithVariable('global.max.file.type.allow', fileTypes.join('/'))
      }]));
      return false
    }

    if (maxSize && file.size > maxSize*1024*1024){
      dispatch(appendAlertItem([{
        severity: AlertType.WARNING,
        title: Translation('global.submit.fail'),
        content: TranslationWithVariable('global.max.file.size.allow', maxSize)
      }]));
      return false
    }
    return true
  }

  const onPreview = () => {
    if (!value) return
    const img: string = `<img src="${value.url}" width="100%" />`
    handlePreviewContent(img)
    handleOpenPreview(true)
  }

  useEffect(() => {
    return () => {
      clearInterval(flag);
      sessionStorage.setItem('isUploadFile', '0');
    };
  // eslint-disable-next-line
  }, []);


  return (
    <>
      {!isUploading && !value && (
        <>
          <Button /*type={btnType}*/ disabled={disabled} onClick={onSelect} variant="contained" color={color} component="span" style={{ marginRight: 20 }}>
            <AddIcon />
            {btnTxt}
          </Button>
          {showAllowText && <span className="ml10">{showAllowText}</span>}
        </>
      )}
      {/* {isUploading && <Progress percent={loadPercentage} />} */}
      {isUploading && <div style={{ width: '100% '}}>
          <LinearProgress variant="determinate" value={loadPercentage} />
        </div>}
      {/* <LinearProgress variant="determinate" value={50} /> */}
      {value && !isUploading && (
        <div>
          {/* eslint-disable-next-line */}
          <a href={value.url} target="_blank" onClick={(download && download(value.fileName, value.url)) || (() => {})}>
            {value.fileName}
          </a>
          {!disabled && <CloseIcon className={classes.closeIcon} onClick={deleteFile} />}
          {
            showPreview ? <Button onClick={onPreview} color="inherit" component="span" style={{ marginLeft: 50 }}>
              {Translation('global.text.preview')}
            </Button> : null
          }
        </div>
      )}
      <input
        style={{ display: 'none' }}
        type="file"
        id="input"
        ref={inputRef}
        multiple
        onChange={change}
      />
      <Preview html={previewContent} open={openPreview} onClose={() => handleOpenPreview(false)} />
    </>
  );
}

export default FileUploader;
