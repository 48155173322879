import React, { useState } from 'react';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDetail } from './detail.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import ProFilterTable from 'src/app/common/components/ProTable';
import { getColumns, getFilterConfig, tagConfig } from './utils/detail.util';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components';
import { Tag } from 'src/app/common/components/tag';
import {
  ExportDetailItemType,
  TalkBotCallInterestTags,
  TalkBotCallStatus,
} from '../../../types/talkbot-export-history-types';
import { PlayArrow } from '@mui/icons-material';
import styles from './detail.screen.module.css';
import { ViewLogComponent } from './view-log/view-log.component';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const DetailPage: React.FC<PageProps> = (props: PageProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { date } = useParams<{ date: string }>();
  const [showTranscript, setShowTransscript] = useState(false);
  const [selectedCallId, setSelectedCallId] = useState<number>();

  // custom-hook
  const { getData, toDetail } = useDetail({ date: decodeURIComponent(date) });

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={t('title.talkbot.export_details')}
        rightNode={
          <Button variant="outlined" color="info" onClick={history.goBack}>
            {t('app.button.back')}
          </Button>
        }
      />
      <ProFilterTable
        Filter={ProSearchFilter}
        showSearchInput={false}
        filterConfigs={getFilterConfig(t)}
        showSelect={false}
        rowKey="_id"
        columns={getColumns({ t, setShowTransscript, setSelectedCallId, toDetail })}
        request={getData}
        enableRefresh={false}
      />
      {selectedCallId && (
        <ViewLogComponent open={showTranscript} onClose={() => setShowTransscript(false)} id={selectedCallId} />
      )}
    </Page>
  );
};

export const renderTag = (value: string, t: (val: string) => string, type?: 'status' | 'tag') => {
  const isGreenKey = value === TalkBotCallInterestTags.highInterest || value === TalkBotCallStatus.connected;
  const configs: any = Object.fromEntries(
    Object.entries(tagConfig).map(([key, val]) => [
      key,
      {
        ...val,
        text: t(
          type === 'tag'
            ? `talkbot.export_history.contact_tag.${value?.toLocaleLowerCase()}`
            : `talkbot.export_history.call_status.${value?.toLocaleLowerCase()}`,
        ),
      },
    ]),
  );

  return <Tag value={isGreenKey ? 'green' : 'blue'} configs={configs} />;
};

export const renderValue = (value?: string) => {
  return <Typography className={styles.textValue}>{value || 'N/A'}</Typography>;
};

export const renderName = (value: ExportDetailItemType, toDetail?: (id: string) => void) => {
  return (
    <Typography className={styles.nameValue} onClick={() => toDetail?.(value.contactId)}>
      {value?.displayName}
    </Typography>
  );
};

export const formatTime = (time: number): string => {
  const minutes: number = Math.floor(time / 60);
  const seconds: number = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const renderRecording = (
  item: ExportDetailItemType,
  setShowTransscript?: (show: boolean) => void,
  setSelectedCallId?: (id: number) => void,
) => {
  if (!item?.callId || item.callStatus !== TalkBotCallStatus.connected) return null;

  return (
    <Box className={styles.recordingContainer}>
      <IconButton
        size="small"
        onClick={() => {
          if (item.callId) {
            setSelectedCallId?.(item.callId);
            setShowTransscript?.(true);
          }
        }}
        className={styles.playButton}
      >
        <PlayArrow fontSize="small" />
      </IconButton>
    </Box>
  );
};
