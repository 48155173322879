import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InsightSession } from '../../../types/types';
import { getInsightsProspectsAllList, getInsightsProspectsNewAddedList } from '../../../network/insightCrud';
import { getPercentage } from '../../../util/insight.util';
import moment from 'moment';
import { sum } from 'lodash';
import { globalStore } from 'src/app/common/helpers/global-store.util';

interface HookProps {
  filterValues: any;
  // other params
}

export const useAllTab = ({ filterValues }: HookProps) => {
  const currentAgentCode =
    sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode();

  const [totalProspects, setTotalProspects] = useState<number>(0);
  const [totalNewAdded, setTotalNewAdded] = useState<number>(0);
  const [summary, setSummary] = useState<any[]>([]);
  const [summaryDetail, setSummaryDetail] = useState<any[]>([]);
  const [startDate, endDate] = useMemo<string[]>(() => {
    const endDate = new Date().toISOString();
    const startDate = moment().subtract(7, 'days').toDate().toISOString();
    return [startDate, endDate];
  }, []);

  useEffect(() => {
    const { source } = filterValues;
    const sourceType = source ? source.replace('all', '') : '';
    getInsightsProspectsAllList(currentAgentCode ?? '', sourceType).then((res) => {
      if (res && res.data) {
        const total = sum(res.data.map((item: any) => item.count));
        const summaryData = res.data
          .sort((a: any, b: any) => {
            if (!a.type) {
              return 1;
            } else if (!b.type) {
              return -1;
            } else {
              return a.type < b.type ? -1 : 1;
            }
          })
          .map((item: any, index: number) => {
            return {
              label: `${item.type}`,
              value: item.count,
              color: item.colorLabel,
            };
          });
        setSummaryDetail(res.data);
        setSummary(summaryData);
        setTotalProspects(total);
      }
    });
    let body: any = {
      agentCode: currentAgentCode ?? '',
      sourceType: sourceType,
      startDate: startDate,
      endDate: endDate,
    };
    getInsightsProspectsNewAddedList(body).then((res) => {
      if (res && res.data) {
        const total = sum(res.data.map((item: any) => Number(item.count.replace('+', ''))));
        setTotalNewAdded(total);
      }
    });
  }, [filterValues]);

  return { summary, summaryDetail, totalProspects, totalNewAdded };
};
