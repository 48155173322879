import React, { memo } from 'react';
import { BarChartHookProps, useBarChart } from './bar-chart.hook';
import Chart from 'react-apexcharts';
import { Box } from '@mui/material';
import { PaginationButton } from '../chart-button/chart-button.component';

export interface BarChartProps extends BarChartHookProps {}

export const BarChart: React.FC<BarChartProps> = memo((props: BarChartProps) => {
  const { options, series, disablePrevious, disableNext, onPageChange } = useBarChart(props);

  return (
    <Box>
      <Box display={'flex'} justifyContent={'flex-end'} margin={'16px'} marginRight={0}>
        <PaginationButton disablePrevious={disablePrevious} disableNext={disableNext} onChange={onPageChange} />
      </Box>
      <Chart options={options} series={series} type="bar" width={'100%'} height={205} />
    </Box>
  );
});
