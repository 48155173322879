import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { Agent } from 'src/app/common/types';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { RequestFunction } from 'src/app/common/components/ProTable';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { convertSort } from 'src/app/common/components/ProTable/pro-table.utils';
import { DownlineAgentListParam, fetchDownlineAgentList } from 'src/app/modules/event-v2/network';
import { getColumns } from './add-agent-from-list-dialog.utils';

export const useAddAgentFromListDialog = () => {
  const locale = useLang();
  const dispatch = useDispatch();
  const { t: Translation } = useTranslation();
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>('');

  const columns = useMemo(() => getColumns(Translation, locale, keyword), [Translation, locale, keyword]);

  const getAgentList: RequestFunction<Agent, Record<string, any>> = async (params, sort) => {
    const { keyword: search = '', page, pageSize: limit } = params;

    const listParams: DownlineAgentListParam = {
      search,
      sort: Object.values(convertSort(sort))[0],
      page: page + 1,
      limit,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { docs, totalDocs, total, ...rest } = await fetchDownlineAgentList(listParams, dispatch);
      return {
        success: true,
        data: docs,
        total: totalDocs,
        ...rest,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
        total: 0,
      };
    }
  };

  const onFilterChange = (params: FilterState) => {
    const { keyword } = params.filterState;
    setKeyword(keyword);
  };

  return { selectedAgents, columns, setSelectedAgents, getAgentList, onFilterChange };
};
