import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box, Button, FormControlLabel, LinearProgress, Radio, RadioGroup, Tab, Tabs, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LayoutSplashScreen } from 'src/app/layout';
import { useStyles } from './marketing-lead-detail.style';
import { useMarketingLeadDetail } from './marketing-lead-detail.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { INSIGHT_HOME_PATH, INSIGHT_MARKETING_LEAD_TABS } from '../../constants';
import { SlaViewComponent } from './component/sla-view/sla-view.component';
import { GraphsViewComponent } from './component/graphs-view/graphs-view.component';
import { InsightFilterComponent } from '../../components/insight-filter/insight-filter.component';
import { ListFilterData } from 'src/app/common/components/list-filter/list-filter.component';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
    agentCode: string;
  };

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

export const MarketingLeadDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { history } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const [value, setValue] = React.useState(INSIGHT_MARKETING_LEAD_TABS.SLA);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  // custom-hook
  const { loading, data, filterConfig, filterValues, defaultValues, setFilterValues, agentCode } =
    useMarketingLeadDetail();
  const goBack = () => {
    history.push(INSIGHT_HOME_PATH);
  };
  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={Translation('insight.marketing_lead.title')}
        rightNode={
          <Button variant="outlined" color="inherit" onClick={goBack}>
            {Translation('app.button.back')}
          </Button>
        }
      />
      <div className={styles.tabContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { backgroundColor: '#E8192C' } }}
          sx={{
            '.Mui-selected': {
              color: '#E8192C !important',
            },
          }}
        >
          <Tab value={INSIGHT_MARKETING_LEAD_TABS.SLA} label={Translation('sla_achievement')} wrapped />
          <Tab value={INSIGHT_MARKETING_LEAD_TABS.GRAPHS} label={Translation('graphs')} />
        </Tabs>
        {value === INSIGHT_MARKETING_LEAD_TABS.SLA && (
          <InsightFilterComponent
            width={350}
            filterConfigs={filterConfig}
            filterValues={filterValues}
            defaultValues={defaultValues}
            onConfirm={(value: ListFilterData) => {
              setFilterValues(value);
            }}
          />
        )}
      </div>
      <div>
        <TabPanel value={value} index={INSIGHT_MARKETING_LEAD_TABS.SLA}>
          {data && <SlaViewComponent data={data} />}
        </TabPanel>
        <TabPanel value={value} index={INSIGHT_MARKETING_LEAD_TABS.GRAPHS}>
          <GraphsViewComponent agentCode={agentCode} />
        </TabPanel>
      </div>
    </Page>
  );
};
