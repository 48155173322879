import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { Box, Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LayoutSplashScreen } from 'src/app/layout';
import styles from './prospect-detail.module.css';
import { useProspectDetail, TAB_TYPE } from './prospect-detail.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { TabBar } from 'src/app/common/components/tab-bar/tab-bar.component';
import { AllTab } from './all-tab/all-tab.component';
import { NewAddedTab } from './new-added-tab/new-added-tab.component';
import { InsightFilterComponent } from '../../components/insight-filter/insight-filter.component';

type PageProps = ParamsProps & RouteComponentProps & {};

export const ProspectDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { t } = useTranslation();

  // custom-hook
  const { tabs, currentTab, filterConfig, filterValues, defaultFilterValues, onTabChange, onFilterConfirm } =
    useProspectDetail();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={t('title.prospect')}
        rightNode={
          <div>
            <InsightFilterComponent
              width="auto"
              filterConfigs={filterConfig}
              filterValues={filterValues}
              onConfirm={onFilterConfirm}
              defaultValues={defaultFilterValues}
            />
          </div>
        }
      />
      <div>
        <TabBar tabs={tabs} currentValue={currentTab} onChange={onTabChange} />
        <div hidden={currentTab !== TAB_TYPE.ALL}>
          <AllTab filterValues={filterValues} />
        </div>
        <div hidden={currentTab !== TAB_TYPE.NEW_ADD}>
          <NewAddedTab filterValues={filterValues} />
        </div>
      </div>
    </Page>
  );
};
