import { ChartType, TimeRange } from 'src/app/common/components/charts/chart-button/chart-button.component';
import { SalesLeadStatusEnum } from '../../Leads/util/lead-status/lead-status.enum';

export interface Division {
  groupType: string;
  groupCode: string;
  groupName: string;
  divisionCode: string;
  isLeader: boolean;
}

export enum AllAgentGroupLevelEnum {
  AGENT = 'All Agents',
  UNIT = 'All Units',
  GROUP = 'All Groups',
  DIVISION = 'All Divisions',
  BRANCH = 'All Branches',
  AGENCY = 'All Agencies',
}

export enum InsightSession {
  'InsightSelectedAgentCode' = 'InsightSelectedAgentCode',
  'ProspectDetailFilterValues' = 'ProspectDetailFilterValues',
  'EmailToReceiveReport' = 'EmailToReceiveReport',
}

export interface AppointmentGraphParam {
  agentCode: string;
  type: string;
  durationType: '1' | '2' | '3';
  startDate: string;
  endDate: string;
}

export interface GraphResBase {
  count: number;
  year: number;
  date?: string;
}

export interface GraphDayItem extends GraphResBase {
  day: number;
  month: number;
}

export interface GraphWeekItem extends GraphResBase {
  week: number;
}

export interface GraphMonthItem extends GraphResBase {
  month: number;
}

export interface AppointmentGraphResponse<T extends GraphResBase> {
  agentCode: string;
  type: string;
  dateCounts: ({
    category: string;
  } & T)[];
}

export interface ChartItem {
  x: string[];
  y: number;
}

// lead-status detail req dto
export interface LeadsStatusDetailParam {
  agentCode: string;
  source?: string;
  startDate?: string;
  endDate?: string;
  campaignTypeId?: string;
}
// lead-status detail graph req dto
export interface LeadsStatusGraphParam {
  agentCode: string; // format: '111,222,333'
  dateType?: TimeRange;
  chartType?: ChartType;
  statusType?: StatusTypeEnum;
}
// only for graph api, not standard lead-status
export enum StatusTypeEnum {
  newLead = 'newLead',
  contacted = 'contacted',
  policyIssued = 'policyIssued',
}

export enum DisplayCategoryEnum {
  all = 'all',
  leadSource = 'leadSource', // display by category: "Agent Sourced" and "Marketing Lead Source"
}

export type FollowUpStatus = 'pending' | 'contactSuccess' | 'met' | 'quote' | 'proposal';

type LastFollowUpRecord = {
  createdAt: string;
  status: FollowUpStatus;
  submissionDate: string;
  expiredAt?: string | null;
};

type Summary = {
  followUp: {
    count: number;
    percentage: number;
  };
  policyIssued: {
    count: number;
    percentage: number;
  };
  dead: {
    count: number;
    percentage: number;
  };
};

export type Detail = {
  followUp: {
    stage: FollowUpStatus;
    count: number;
    percentage: number;
    aging: number;
  }[];
  policyIssued: {
    count: number;
    conversionRate: number;
  };
  dead: {
    stage: 'cant_contact' | 'cant_met' | 'cant_quote' | 'cant_proposal' | 'cant_policy';
    count: number;
    percentage: number;
    lastFollowUpRecord?: LastFollowUpRecord | null;
    reasons: {
      reason: string;
      count: number;
    }[];
  }[];
};

export type LeadStatusData = {
  workingLeadCount: number;
  summary: Summary;
  detail: Detail;
};

export enum LeadSourceEnum {
  all = 'all',
  marketingSource = 'Marketing Lead Source',
  agentSource = 'Agent Sourced',
  none = 'none',
}

// campaign type
export interface CampaignType {
  campaignTypeName: string;
  _id: string;
}

export interface MassLeadGraphParams {
  agentCode: string;
  chartType: string;
  dateType: string;
  status: string;
}
export interface DataSum {
  count: number;
  date: string;
  [key: string]: any; // other possible props
}

export interface LeadStatusDetailListDto {
  agentCode: string; // '11,22,33'
  source?: LeadSourceEnum;
  campaignTypeId?: string;
  startDate?: string;
  endDate?: string;
  status?: SalesLeadStatusEnum;
  deadReason?: string;
  lastFollowUpStatus?: string;
}

export enum InsightSectionEnum {
  DAILY_POINT = 'DAILY_POINT',
  LEAD_STATUS = 'LEAD_STATUS',
  MARKETING_LEAD = 'MARKETING_LEAD',
  AGENCY_CAMPAIGN_LEAD = 'AGENCY_CAMPAIGN_LEAD',
  MASS_LEAD = 'MASS_LEAD',
  PROSPECT = 'PROSPECT',
  ENGAGEMENT = 'ENGAGEMENT',
  APPOINTMENT = 'APPOINTMENT',
  SERVICING_CAMPAIGN = 'SERVICING_CAMPAIGN',
  INSURANCE_SUMMARY = 'INSURANCE_SUMMARY',
}

export interface InsightExportSetting {
  leadSource: InsightSourceRequestEnum;
  prospectSource: InsightSourceRequestEnum;
}

export enum InsightSourceRequestEnum {
  ALL = 'ALL',
  MARKETING_SOURCE = 'MARKETING_SOURCE',
  AGENT_SOURCE = 'AGENT_SOURCE',
}
