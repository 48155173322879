import { I18nGenericData } from 'src/app/i18n';
import { FormMode, AttachmentDef } from 'src/app/common/types';

export enum EventAccessibilityEnum {
  PUBLIC = 'PUBLIC',
  INTERNAL = 'INTERNAL',
}

export enum EventAudienceEnum {
  SALES_PROSPECT = 'SALES_PROSPECT',
  RECRUITMENT_PROSPECT = 'RECRUITMENT_PROSPECT',
  AGENT = 'AGENT',
}

export enum EventCategoryStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EventCategoryMaterialTypeEnum {
  EVENT_IMAGE = 'EVENT_IMAGE',
  DETAIL_TEMPLATE = 'DETAIL_TEMPLATE',
}

export type FormDialogState = {
  open: boolean;
  formMode?: FormMode;
  eventCategoryItem?: EventCategoryItem;
};

export type ReminderDialogState = {
  open: boolean;
};

export type EventCategoryItem = {
  _id: string;
  name: I18nGenericData<string>;
  description?: string;
  accessibility: EventAccessibilityEnum;
  audience: EventAudienceEnum;
  status: EventCategoryStatusEnum;
  lastUpdatedBy: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type EventCategoryMaterialItem = {
  _id: string;
  categoryId: string;
  type: EventCategoryMaterialTypeEnum;
  lang: string;
  blob: AttachmentDef;
  isActive: boolean;
};
