import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { TabBar } from 'src/app/common/components/tab-bar/tab-bar.component';
import { LeadDetail } from './component/lead-detail/lead-detail.component';
import { LeadGraph } from './component/lead-graph/lead-graph.component';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

enum TAB_ENUM {
  DETAIL = 'DETAIL',
  GRAPH = 'GRAPH',
}

export const MassLeadDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState<string>(TAB_ENUM.DETAIL);
  const Tabs = [
    {
      value: TAB_ENUM.DETAIL,
      label: t('leads_status_title_details'),
    },
    {
      value: TAB_ENUM.GRAPH,
      label: t('leads_status_title_graph'),
    },
  ];

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle title={t('mass_lead_header_title')} rightNode={null} />
      <div>
        <TabBar
          tabs={Tabs}
          currentValue={tabValue}
          onChange={(value) => {
            setTabValue(value);
          }}
        />
      </div>
      <div hidden={tabValue !== TAB_ENUM.DETAIL}>
        <LeadDetail />
      </div>
      <div hidden={tabValue !== TAB_ENUM.GRAPH}>
        <LeadGraph />
      </div>
    </Page>
  );
};
