import { IntlShape } from 'react-intl';
import { ModuleHierachy } from 'src/app/common/module/types';
import { EVENT_TITLE, EVENT_LIST_TITLE, EVENT_LIST_NAMESPACE } from './constants';
import EventListRoutes, { eventListPath } from './pages/event-list/event-list-routes';

export const eventModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    menuHeader: Translation(EVENT_TITLE),
    title: Translation(EVENT_LIST_TITLE),
    path: eventListPath,
    namespace: EVENT_LIST_NAMESPACE,
    component: EventListRoutes,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
    page: [
      {
        title: Translation('event.common.new_event'),
        path: `${eventListPath}/create`,
      },
      {
        title: Translation('event.form.event_details'),
        path: `${eventListPath}/edit`,
      },
      {
        title: Translation('event.form.event_details'),
        path: `${eventListPath}/view`,
        param: 'id',
        page: [
          {
            title: Translation('event.form.participant_details'),
            path: `${eventListPath}/view/:id/participant-details`,
          },
        ],
      },
    ],
  };
};
