import { makeStyles } from 'tss-react/mui';

export const useDetailsStyles = makeStyles()(() => ({
  container: {
    paddingBottom: 8,
  },
  textMargin: {
    marginBottom: 8,
  },
  remark: {
    color: '#979797',
    fontSize: 14,
    fontWeight: 400,
  },
}));
