import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import { regionLocale, RegionLocale } from 'src/app/i18n';
import PruIcon from 'src/app/common/components/PruIcon';
import { useStyles } from './multi-lang-accordion.style';
import { useCommonFormStyles } from '../common-form.style';

type MultiLangAccordionProps = {
  disabled?: boolean;
  error?: (locale: RegionLocale) => boolean;
  fillInOnChange: (locale: RegionLocale) => void;
  renderChildren: (locale: RegionLocale, index: number) => ReactElement;
};

export const MultiLangAccordion: FC<MultiLangAccordionProps> = ({
  disabled,
  error,
  fillInOnChange,
  renderChildren,
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();

  return (
    <div className={`${commonFormClasses.smallGapContentContainer} ${classes.container}`}>
      {regionLocale.map((locale, index) => (
        <Accordion
          key={locale}
          className={`${classes.accordion} ${error && error(locale) ? classes.error : undefined}`}
          expanded
        >
          <AccordionSummary className={classes.accordionSummary} expandIcon={undefined}>
            {Translation(`navBar.lang.${locale}`)}
            {index > 0 && (
              <Button
                disabled={disabled}
                variant="text"
                className={classes.copyButton}
                onClick={() => fillInOnChange(locale)}
              >
                <PruIcon icon="copy" customClasses={classes.copyIcon} />
                <span>
                  {Translation('common.use_lang_to_fill_in', {
                    lang: Translation(`navBar.lang.${regionLocale[0]}`),
                  })}
                </span>
              </Button>
            )}
          </AccordionSummary>
          <AccordionDetails className={`${commonFormClasses.contentContainer} ${classes.accordionDetail}`}>
            {renderChildren(locale, index)}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
