import React, { FC, memo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Button, ButtonGroup, Card, CircularProgress, Typography } from '@mui/material';

import styles from './graph-tab.module.css';
import { useGraphTab } from './graph-tab.hook';
import { getDisplayCategoryButtonGroup, getLeadTypeButtonGroup } from '../util/lead-status-detail.util';
import {
  ChartType,
  SwitchButton,
  TimeRangeButton,
} from 'src/app/common/components/charts/chart-button/chart-button.component';
import { AreaChart } from 'src/app/common/components/charts/area-chart/area-chart.component';
import { BarChart } from 'src/app/common/components/charts/bar-chart/bar-chart.component';
import { DisplayCategoryEnum } from '../../../types/types';
import { size } from 'lodash';

export const GraphTabComponent: FC = memo(() => {
  // i18n
  const { t } = useTranslation();
  const leadTypeGroup = getLeadTypeButtonGroup(t);
  const displayCateGroup = getDisplayCategoryButtonGroup(t);

  // custom-hook
  const {
    loading,
    data,
    selectedLead,
    timeRange,
    chartType,
    displayCategory,
    setSelectedLead,
    setDisplayCategory,
    setChartType,
    setTimeRange,
    handlePress,
    formatter,
  } = useGraphTab();
  const chartData =
    displayCategory === DisplayCategoryEnum.all
      ? [
          {
            name: t('insights_all'),
            data: data?.allLead || [],
          },
        ]
      : [
          {
            name: t('insights_filter_marketing'),
            data: data?.marketingLead || [],
          },
          {
            name: t('insights_filter_agent'),
            data: data?.agentLead || [],
          },
        ];
  return (
    <div className={loading ? styles.disabled : ''}>
      <Box className={styles.wrap} color="#999">
        {leadTypeGroup.map(({ label, value }) => (
          <Button
            className={styles.leadBtn}
            color={selectedLead === value ? 'secondary' : 'inherit'}
            variant="outlined"
            key={value}
            onClick={() => setSelectedLead(value)}
          >
            {label}
          </Button>
        ))}
      </Box>

      <Card>
        <Box margin="24px">
          <Box display="flex" justifyContent="flex-end" marginBottom="24px" color="#F0F0F0">
            <Box display="flex">
              <Box display="flex" flexDirection="column" color="#F0F0F0">
                <ButtonGroup variant="outlined" aria-label="filter button group" color="inherit">
                  {displayCateGroup.map(({ label, value }) => (
                    <Button key={value} onClick={() => setDisplayCategory(value)}>
                      <Typography color={displayCategory === value ? 'secondary' : '#999'}>{label}</Typography>
                    </Button>
                  ))}
                </ButtonGroup>
              </Box>
              <Box marginLeft="16px">
                <SwitchButton value={chartType} onChange={setChartType} />
              </Box>
              <Box marginLeft="16px">
                <TimeRangeButton value={timeRange} onChange={setTimeRange} />
              </Box>
            </Box>
          </Box>
          {loading && (
            <div className="tw-flex tw-justify-center tw-items-center">
              <CircularProgress size="2rem" color="secondary" />
            </div>
          )}

          <Box>
            {data ? (
              chartType === ChartType.area ? (
                <AreaChart series={chartData} labelsFormatter={formatter} highlightX={data.highlightX} />
              ) : (
                <BarChart series={chartData} labelsFormatter={formatter} highlightX={data.highlightX} />
              )
            ) : null}
          </Box>
        </Box>
      </Card>
    </div>
  );
});
