import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

import { Grid } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './servicing-campaign-detail.module.css';
import { CampaignDetail, useServicingCampaignDetail } from './servicing-campaign-detail.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { Card } from 'src/app/common/components/card/card.component';
import { PercentageBarChart } from 'src/app/common/components/charts/percentage-bar-chart/percentage-bar-chart.component';
import moment from 'moment';
import { DateFormat } from 'src/app/common/utils';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

const CampaignInfo = (props: { data: CampaignDetail }) => {
  const { data } = props;
  const { t } = useTranslation();
  const { feedbacks = [] } = data;
  let total = 0;
  feedbacks.forEach((item: { count: number }) => {
    total += item.count;
  });
  return (
    <Card style={{ padding: '24px' }}>
      <div>
        <div className={styles.campaignName}>{data.name}</div>
        <div className={styles.campaignInfo}>
          {moment(data.startDate).format(DateFormat.date)}-{moment(data.endDate).format(DateFormat.date)}
        </div>
      </div>
      <div className={styles.sectionBox}>
        <div className={styles.section}>
          <div className={styles.senctionTitle}>{t('eligible_customers')}</div>
          <div className={styles.sectionValue}>{total}</div>
        </div>
        {feedbacks.map((feedback: { type: string; count: number }) => {
          return (
            <div className={styles.section}>
              <div className={styles.sectionDesc}>{feedback.type}</div>
              <div className={styles.sectionDescValue}>{feedback.count}</div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export const ServicingCampaignDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { t } = useTranslation();

  // custom-hook
  const { summary, totalFeedback, activeCampaignCount, campaignDetails } = useServicingCampaignDetail();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle title={t('insights_servicing_campaign_title')} rightNode={null} />
      <div>
        <div className={styles.wrap}>
          <Card className={styles.wrapChart}>
            <div className={styles.flexRow}>
              <div className={styles.workingLeads}>
                {t('eligible_customers')}
                <div className={styles.num}>{totalFeedback}</div>
              </div>
              <div className={styles.workingLeads}>
                {t('active_campaigns')}
                <div className={styles.num}>{activeCampaignCount}</div>
              </div>
            </div>

            <PercentageBarChart data={summary} />
          </Card>

          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {campaignDetails.map((campaign) => {
              return (
                <Grid item xs={6}>
                  <CampaignInfo data={campaign} />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </Page>
  );
};
