import React, { useMemo } from 'react';
import { useChartPagination } from '../hook/chart-pagination.hook';

export interface BarChartHookProps {
  labelsFormatter?: (val: string) => string;
  labelColor?: string;
  highlightColor?: string;
  columnWidth?: string;
  contentColors?: { color: string; highlightColor: string }[];
  highlightX?: number[];
  series: {
    name: string;
    data: {
      x: any;
      y: string | number;
    }[];
  }[];
}

const DEFAULT_BAR_COLOR = [{ color: '#E6E6E6', highlightColor: '#E8192C' }];
const DEFAULT_DOUBLE_BAR_COLOR = [
  {
    color: '#FF872E',
    highlightColor: 'rgba(255, 135, 46, 0.5)',
  },
  {
    color: '#24C791',
    highlightColor: 'rgba(36, 199, 145, 0.5)',
  },
];

export const useBarChart = ({
  labelsFormatter,
  labelColor = '#999999',
  highlightColor = '#E8192C',
  columnWidth = '35%',
  contentColors: initContentColors,
  highlightX: initHighlightX,
  series: initSeries,
}: BarChartHookProps) => {
  const { displaySeries, disablePrevious, disableNext, onPageChange, highlightX } = useChartPagination({
    initSeries,
    initHighlightX,
  });
  const contentColors =
    initContentColors ?? (displaySeries.length === 1 ? DEFAULT_BAR_COLOR : DEFAULT_DOUBLE_BAR_COLOR);

  const options: ApexCharts.ApexOptions = useMemo(() => {
    const data = displaySeries[0].data;

    return {
      chart: {
        id: 'bar-chart-example',
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        marker: {
          // fillColors: contentColors.map(({ color }) => color),
          show: true,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.8,
          },
        },
      },
      xaxis: {
        type: 'category',
        // categories: c,
        axisTicks: {
          show: false, // hide ticks in x axis
        },
        tickPlacement: 'on',
        // min: 1,
        // max: 10,
        range: 9,
        labels: {
          formatter: labelsFormatter,
          style: {
            colors: data.map((_, index) => (highlightX && highlightX.includes(index) ? highlightColor : labelColor)),
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth,
        },
      },
      legend: {
        show: false,
      },
    };
  }, [displaySeries, contentColors, labelsFormatter, labelColor, columnWidth, highlightX, highlightColor]);

  const series: ApexAxisChartSeries = useMemo(() => {
    const bColor = contentColors;

    return displaySeries.map(({ name, data }, index) => {
      const { color, highlightColor } = bColor[index] ?? bColor[0];
      return {
        name,
        data: data.map(({ x, y }, i) => ({
          x,
          y,
          fillColor: highlightX && highlightX.includes(i) ? highlightColor : color,
        })),
      };
    });
  }, [contentColors, highlightX, displaySeries]);

  return {
    options,
    series,
    disablePrevious,
    disableNext,
    onPageChange,
  };
};
