import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import { Button } from 'src/app/common/components/button/button.component';
import ProFilterTable from 'src/app/common/components/ProTable';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components';
import { EventPublishStatusEnum } from '../../../types';
import { eventListPath } from '../event-list-routes';
import { useStyles } from './event-listing-page.style';
import { useEventListingPage } from './event-listing-page.hook';
import { NewEventDialog } from './components';

export const EventListingPage = ({
  cacheParams,
}: {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const {
    eventCategoryList,
    showCreateDialog,
    filterConfig,
    columns,
    actionRef,
    setShowCreateDialog,
    getEventList,
    onFilterChange,
    onPublish,
    onDownloadQRCode,
  } = useEventListingPage({ cacheParams });

  return (
    <Page>
      <PageTitle
        title={Translation('title.event.list')}
        rightNode={
          <Button
            className={classes.addNewButton}
            variant="contained"
            color="primary"
            onClick={() => setShowCreateDialog(true)}
          >
            <Add />
            {Translation('pruleads.prospect.list.addNew')}
          </Button>
        }
      />
      <ProFilterTable
        showSelect={false}
        rowKey="_id"
        Filter={ProSearchFilter}
        filterConfigs={filterConfig}
        filterPlaceholder={Translation('event.common.search_event_name')}
        customEmptyTitle={
          <div className={classes.noRecordContainer}>
            <div className={classes.noRecordText}>{Translation('common.no_record')}</div>
            <div className={classes.noRecordDetailText}>{Translation('common.no_record_detail')}</div>
          </div>
        }
        columns={columns}
        request={getEventList}
        onFilterChange={onFilterChange}
        actionRef={actionRef}
        operationSticky
        operationDef={[
          {
            title: Translation('app.button.publish'),
            onClick: (row) => onPublish(row),
            disabled: (row) => row.publishStatus !== EventPublishStatusEnum.DRAFT,
          },
          {
            title: Translation('section.common.operation.download_qr_code'),
            onClick: (row) => onDownloadQRCode(row),
            disabled: (row) => row.publishStatus !== EventPublishStatusEnum.PUBLISHED,
          },
        ]}
        onClickRow={(row) => history.push(`${eventListPath}/view/${row._id}`)}
      />
      {showCreateDialog && (
        <NewEventDialog
          open={showCreateDialog}
          eventCategoryList={eventCategoryList}
          onCancel={() => setShowCreateDialog(false)}
          onConfirm={(selected) => history.push(`${eventListPath}/create/${selected}`)}
        />
      )}
    </Page>
  );
};
