import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  stepper: {
    marginBottom: 40,
  },
  step: {
    '.MuiStepIcon-text': {
      fill: 'white',
      fontSize: 14,
      fontWeight: 600,
      fontFamily: 'Arial',
    },
    '.MuiStepIcon-root': {
      fontSize: 24,
      color: 'inherit',
      '&.Mui-active': {
        color: 'inherit',
      },
      '&.Mui-completed': {
        color: 'inherit',
      },
    },
    '.MuiStepLabel-iconContainer': {
      color: '#E8192C',
      '&.Mui-disabled': {
        color: '#CCCCCC',
      },
    },
    '.MuiStepLabel-label': {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Arial',
      '&.Mui-active': {
        color: '#E8192C',
      },
      '&.Mui-disabled': {
        color: '#999999',
      },
    },
    '.MuiStepLabel-labelContainer': {
      color: '#000000',
    },
    '.MuiStepButton-root': {
      padding: '24px 8px',
      margin: '-24px -8px',
    },
    '.MuiStepConnector-line': {
      borderColor: '#E5E5E5',
    },
  },
  errorIcon: {
    fontSize: 28,
    color: '#FF872E',
    margin: -2,
  },
}));
