import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { getLeadStatusDetail } from '../../../network/insightCrud';
import { InsightSession, LeadSourceEnum, LeadsStatusDetailParam, LeadStatusData } from '../../../types/types';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { ComponentProps } from './detail-tab.component';
import { leadStatusFormat } from '../util/lead-status-detail.util';

export const useDetailTab = ({ source, startDate, endDate, campaignTypeId }: ComponentProps) => {
  // i18n
  const locale = useLang();
  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  // useRequest to wrap api request.
  // DO NOT use useDataProvider except for <PruFilter />
  const { data, error, loading, run, runAsync } = useRequest(
    () => {
      const agentCode = sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode();
      const acmTypdId = campaignTypeId?.replaceAll(LeadSourceEnum.all, '') || '';
      const leadSource = source?.replaceAll(LeadSourceEnum.all, '') || '';
      const body: LeadsStatusDetailParam = {
        startDate,
        endDate,
        agentCode: agentCode,
        source: leadSource,
        campaignTypeId: source !== LeadSourceEnum.agentSource ? '' : acmTypdId,
      };
      return getLeadStatusDetail(body).then((response) => {
        const result = response.data || {};
        return leadStatusFormat({
          t,
          data: result as LeadStatusData,
          startDate,
          endDate,
          source: leadSource,
          campaignTypeId: acmTypdId,
        });
      });
    },
    { manual: true },
  );
  const disabledClick = sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) !== globalStore.getAgentCode();
  useEffect(() => {
    // manual call api request
    run();
  }, [source, startDate, endDate, campaignTypeId]);

  return {
    loading,
    error,
    data,
    disabledClick,
  };
};
