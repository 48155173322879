import { get, set } from 'lodash';
import { DateFormat, formatDate } from 'src/app/common/utils';
import { Appointment, EventData } from '../../../types/appointment-types';
import { ProColumns } from 'src/app/common/components/ProTable';
import { Button } from '@mui/material';
export const getColumns = ({
  t,
  styles,
  onDelete,
  onEdit,
  setTargetAppointment,
}: {
  t: (val: string) => string;
  styles: Record<string, any>;
  onDelete: any;
  onEdit: any;
  setTargetAppointment: any;
  keyword?: string;
}): ProColumns<EventData>[] => [
  {
    title: t('prospect.appoinment.title.purpose'),
    dataIndex: 'content',
    width: '290px',
    render: (currValue: Appointment) => {
      return currValue ? String(currValue.purpose) : '';
    },
  },
  {
    title: t('prospect.appoinment.title.location'),
    dataIndex: 'content',
    width: '176px',
    render: (currValue: Appointment) => {
      return currValue.location ? <span className={styles.content}>{String(currValue.location)}</span> : '';
    },
  },
  {
    title: t('prospect.appoinment.title.note'),
    dataIndex: 'content',
    width: '290px',
    render: (currValue: Appointment) => {
      return currValue.notes ? <span className={styles.content}>{String(currValue.notes)}</span> : '';
    },
  },
  {
    title: t('prospect.appoinment.title.date_and_time'),
    dataIndex: 'content',
    width: '176px',
    render: (currValue: Appointment) => {
      return formatDate(currValue.startDate, DateFormat.datetime);
    },
  },
  {
    title: t('prospect.appoinment.title.outcome'),
    dataIndex: 'content',
    width: '176px',
    render: (currValue: Appointment) => {
      return currValue.outcome ? String(currValue.outcome) : '';
    },
  },
  {
    title: t('prospect.appoinment.title.operation'),
    width: '290px',
    dataIndex: 'op',
    render: (currValue: string, item) => {
      return (
        <span>
          <Button
            variant="text"
            color="info"
            onClick={() => {
              onDelete(true);
              setTargetAppointment(item);
            }}
            className={styles.actionButton}
          >
            {t('app.button.delete')}
          </Button>
          <Button
            variant="text"
            color="info"
            onClick={() => {
              onEdit(true);
              setTargetAppointment(item);
            }}
            className={`${styles.actionButton} ${styles.buttonMargin}`}
          >
            {t('app.button.edit')}
          </Button>
        </span>
      );
    },
  },
];
