import { FC, useState, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Dialog, FormHelperText, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { AttachmentDef } from 'src/app/common/types';
import { toAbsoluteUrl, fileUpload } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { createBlobAsAgent, getBlobAsAgent } from 'src/app/common/network';
import { useStyles } from './upload-area.style';

type UploadAreaProps = {
  id: string;
  module: string;
  accessLevel: 'public' | 'private' | 'anonymous';
  disabled?: boolean;
  multiple?: boolean;
  error?: boolean;
  enableSuccessToast?: boolean;
  enableImageRatioReminder?: boolean;
  accept: string;
  sources: AttachmentDef[];
  uploadText: string;
  maxFileNum?: number;
  maxFileSize?: number;
  maxFileSizeUnit?: 'KB' | 'MB';
  onChange: (attachment: AttachmentDef, originalFile: File) => void;
  onRemove: (sourceIndex: number) => void;
};

const acceptErrMsgMapping: Record<string, string> = {
  'image/jpeg': 'jpg/jpeg',
  'image/png': 'png',
  'application/pdf': 'pdf',
  'video/mp4': 'mp4',
};

const getFileIcon = (fileType: string) => {
  switch (fileType) {
    case 'application/pdf':
      return toAbsoluteUrl('/media/svg/files/pdf.svg');
    case 'video/mp4':
      return toAbsoluteUrl('/media/svg/files/picture.svg');
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return toAbsoluteUrl('/media/svg/files/excel.svg');
    default:
      return toAbsoluteUrl('/media/svg/files/file.svg');
  }
};

export const UploadArea: FC<UploadAreaProps> = ({
  id,
  module,
  accessLevel,
  disabled,
  sources,
  uploadText,
  multiple,
  error,
  enableSuccessToast,
  enableImageRatioReminder,
  accept,
  maxFileNum,
  maxFileSize,
  maxFileSizeUnit,
  onChange,
  onRemove,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, any>) => intl.formatMessage({ id }, variable);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [showImageRatioReminder, setShowImageRatioReminder] = useState(false);
  const { classes } = useStyles({ isUploading });

  const showUpload = !disabled && (multiple || sources.length === 0) && sources.length !== maxFileNum;

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let errMsg = '';
      const acceptFileTypes = accept.split(',');
      if (!acceptFileTypes.includes(file.type)) {
        errMsg = Translation('global.max.file.type.allow', {
          num: acceptFileTypes.map((fileType) => acceptErrMsgMapping[fileType] || fileType.replace('.', '')).join('/'),
        });
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsg,
            },
          ]),
        );
        return;
      }
      if (maxFileSize && maxFileSizeUnit) {
        const fileSize = maxFileSizeUnit === 'KB' ? file.size / 1024 : file.size / 1024 / 1024;
        if (fileSize > maxFileSize) {
          errMsg = Translation('global.max.file.size.allow');
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: '',
                content: errMsg,
              },
            ]),
          );
          return;
        }
      }
      try {
        setIsUploading(true);
        const createBlobRes = await createBlobAsAgent({ mimeType: file.type, accessLevel, module }, dispatch);
        await fileUpload(createBlobRes[0].url, file);
        const blobDetail = await getBlobAsAgent({ resourceIds: createBlobRes[0].blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          onChange({ blobId: result.blobId, filename: file.name, url: result.url, type: file.type }, file);
          if (enableSuccessToast) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  content: `Upload file successfully!`,
                },
              ]),
            );
          }
          if (enableImageRatioReminder && file.type.startsWith('image/')) {
            const img = new Image();
            img.onload = () => {
              const aspectRatio = img.naturalWidth / img.naturalHeight;
              if (aspectRatio !== 2) {
                setShowImageRatioReminder(true);
              }
            };
            img.src = result.url;
          }
        }
      } catch (err) {
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <>
      <Dialog classes={{ paper: classes.dialogPaper }} open={showImageRatioReminder}>
        <div className={classes.reminderContent}>
          <div className={classes.reminderTitle}>{Translation('reminder_title')}</div>
          <div>{Translation('common.upload_area.img_ratio_reminder')}</div>
        </div>
        <div className={classes.reminderImg}>
          <img src={'/media/icon/ratio_reminder_example.png'} alt="example" />
        </div>
        <div className={classes.reminderFooter}>
          <Button className={classes.blueText} onClick={() => setShowImageRatioReminder(false)}>
            {Translation('common.got_it')}
          </Button>
        </div>
      </Dialog>
      <div className={classes.container}>
        {showUpload && (
          <div>
            <Button className={classes.uploadArea} onClick={() => document.getElementById(id)?.click()}>
              <input
                id={id}
                hidden
                type="file"
                accept={accept}
                onClick={(e) => {
                  if (isUploading) {
                    e.preventDefault();
                  } else {
                    const element = e.target as HTMLInputElement;
                    element.value = '';
                  }
                }}
                onChange={handleFile}
              />
              <div>
                <div className={classes.uploadText}>{uploadText}</div>
                <div className={classes.uploadInstruction}>
                  {Translation('common.upload_instruction', {
                    highlightedText: (
                      <span className={classes.uploadInstructionHighlight}>
                        {Translation('common.upload_instruction.browse')}
                      </span>
                    ),
                  })}
                </div>
              </div>
            </Button>
            {error && (
              <FormHelperText error={error} className={classes.errorText}>
                {MANDATORY_FIELD_ERROR_TEXT}
              </FormHelperText>
            )}
          </div>
        )}
        {sources.map((source, sourceIndex: number) => (
          <Fragment key={sourceIndex}>
            {source.type?.includes('image') ? (
              <div className={classes.previewImageContainer}>
                <img loading="lazy" className={classes.previewImage} src={source.url} alt="image" />
                <IconButton
                  disabled={disabled}
                  className={classes.removeImageButton}
                  onClick={() => onRemove(sourceIndex)}
                >
                  <Close className={classes.removeImageIcon} />
                </IconButton>
              </div>
            ) : (
              <div className={classes.previewFileContainer}>
                <div className={classes.previewFileInfoContainer}>
                  {source.type && <img className={classes.fileIcon} src={getFileIcon(source.type)} alt="file-icon" />}
                  {source.filename}
                </div>
                <IconButton disabled={disabled} onClick={() => onRemove(sourceIndex)}>
                  <Close className={classes.removeFileIcon} />
                </IconButton>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
};
