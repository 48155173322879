import { makeStyles } from 'tss-react/mui';

const FIELD_COLUMN_GAP = 24;

export const useStyles = makeStyles()(() => ({
  paper: {
    padding: '24px 24px 32px',
  },
  titleContainer: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 24,
  },
  contentContainer: {
    display: 'flex',
    columnGap: FIELD_COLUMN_GAP,
    rowGap: 14,
    flexWrap: 'wrap',
  },
  fieldContainer: {
    flex: `1 0 calc(50% - ${FIELD_COLUMN_GAP / 2}px)`,
  },
  mandatory: {
    color: 'red',
  },
}));
