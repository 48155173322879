import React, { FC, memo, useMemo, useRef } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { FilterTableContainer } from 'src/app/common/components/filter-table-container/filter-table-container.component';
import { ActionItems } from './action-items/action-items.component';
import { getColdLeads } from '../../../../util/api.util';
import { openLeadDetailInNewTab } from 'src/app/modules/Leads/util/leads.util';
import { getOpportunitiesColumns } from '../../../../util/list.util';

type ComponentProps = ParamsProps & {
  onLoad?: (
    record: any[],
    pageInfo?: {
      page?: number;
      pageSize?: number;
      total?: number;
    },
  ) => void;
};

export const ColdLeadsComponent: React.FC<ComponentProps> = memo(({ onLoad }: ComponentProps) => {
  const getColumns = useMemo(() => {
    return () => getOpportunitiesColumns({});
  }, []);

  const cacheParams = useRef({ sorter: { updatedAt: 'asc' } });

  return (
    <FilterTableContainer
      rowKey="_id"
      cacheParams={cacheParams}
      getData={getColdLeads}
      getColumns={getColumns}
      showFilter={false}
      ActionNode={ActionItems}
      onClickRow={(record) => openLeadDetailInNewTab(record)}
      onLoad={onLoad}
    />
  );
});
