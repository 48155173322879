import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { Box, Button, Tab, Tabs } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './lead-status-detail.module.css';
import { TabEnum, useLeadStatusDetail } from './lead-status-detail.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { ComponentProps, DetailTabComponent } from './detail-tab/detail-tab.component';
import { InsightFilterComponent } from '../../components/insight-filter/insight-filter.component';
import { get } from 'lodash';
import { TabContext, TabPanel } from '@mui/lab';
import { TabBar } from 'src/app/common/components/tab-bar/tab-bar.component';
import { GraphTabComponent } from './graph-tab/graph-tab.component';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const LeadStatusDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  // custom-hook
  const {
    loading,
    data,
    filterConfig,
    filterValues,
    tabs,
    tabValue,
    onFilterConfirm,
    onFilterSelect,
    handleTabChange,
    defaultValues,
  } = useLeadStatusDetail();

  const params = {
    source: filterValues.source,
    startDate: get(filterValues.dateRange, 'value.startDate'),
    endDate: get(filterValues.dateRange, 'value.endDate'),
    campaignTypeId: filterValues.campaignTypeId,
  } as ComponentProps;

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={t('leads_status')}
        rightNode={
          <Button variant="outlined" color="info" onClick={history.goBack}>
            {t('app.button.back')}
          </Button>
        }
      />
      <TabContext value={tabValue}>
        <TabBar
          tabs={tabs}
          onChange={handleTabChange}
          currentValue={tabValue}
          rightActions={
            <div className={tabValue === TabEnum.details ? styles.display : styles.hide}>
              <InsightFilterComponent
                width="auto"
                defaultValues={defaultValues}
                filterConfigs={filterConfig}
                filterValues={filterValues}
                onConfirm={onFilterConfirm}
                onFilterSelect={onFilterSelect}
              />
            </div>
          }
        />

        <TabPanel value={TabEnum.details}>
          <DetailTabComponent {...params} />
        </TabPanel>
        <TabPanel value={TabEnum.graphs}>
          <GraphTabComponent />
        </TabPanel>
      </TabContext>
    </Page>
  );
};
