import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { getDashboardProspects } from '../../../network/insightCrud';
import { formatNumber } from '../../../util/insight.util';

interface HookProps {
  agentCode?: string;
}

export const useProspect = ({ agentCode }: HookProps) => {
  // i18n
  const history = useHistory();
  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  const agentCodeList = agentCode || globalStore.getAgentCode();

  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  // useRequest to wrap api request.
  const { data, error, loading, run, runAsync } = useRequest(
    () => {
      return getDashboardProspects({ agentCode: agentCodeList }).then((response) => {
        const { totalProspects, lastIntervalDays, engagingPropsects, missedPropsects, noEngagement } = response.data;
        const lastIntervalDaysFormat = formatNumber(lastIntervalDays) as any;

        const result = [
          {
            value: formatNumber(totalProspects),
            label: t('total_prospects'),
          },
          {
            value: `${isNaN(lastIntervalDaysFormat) ? '-' : '+' + lastIntervalDaysFormat}`,
            label: t('added_in_last_7_days'),
          },
        ];
        return result;
      });
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    // manual call api request
    if (agentCodeList) {
      run();
    }
  }, [agentCodeList]);

  const handlePress = useCallback(() => {
    history.push('./prospect');
  }, []);

  const defaultData = useMemo(() => {
    const result = [
      {
        value: '-',
        label: t('total_prospects'),
      },
      {
        value: '-',
        label: t('added_in_last_7_days'),
      },
    ];
    return result;
  }, []);

  const tooltip = useMemo(() => {
    const tooltip = [
      {
        title: t('total_prospects'),
        content: t('prospect_des1'),
      },
      {
        title: t('added_in_last_7_days'),
        content: t('prospect_des2'),
      },
    ];
    return tooltip;
  }, []);

  return {
    loading,
    data: loading ? defaultData : data || defaultData,
    tooltip,
    handlePress,
  };
};
