import { LeadLabel } from '../Campaign/types/campaign-types';
import { ProspectSourceTypes, ProspectTypes } from './types/campaign-types';
export const AGENCY_CAMPAIGN_TITLE = 'title.agencyCampaign';
export const AGENCY_CAMPAIGN_LIST_TITLE = 'title.agencyCampaign.list';
export const AGENCY_CAMPAIGN_DASHBOARD_TITLE = 'title.agencyCampaign.dashboard';

export const AGENCY_CAMPAIGN_BASE_PATH = '/agencyCampaign';
export const AGENCY_CAMPAIGN_CAMPAIGN_PATH = '/campaign';
export const AGENCY_CAMPAIGN_DASHBOARD_PATH = '/dashboard';

export const AGENCY_CAMPAIGN_NAMESPACE = 'AGENCY_CAMPAIGN';
export const AGENCY_CAMPAIGN_DASHBOARD_NAMESPACE = 'AGENCY-CAMPAIGN-DASHBOARD';
export const AGENCY_CAMPAIGN_NAMESPACE_LIST = 'AGENCY_CAMPAIGN_LIST';

export const CAMPAIGN_BASE_PATH = '/campaign';

export const AGENCY_CAMPAIGN_LIST_PATH = `${CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_CAMPAIGN_PATH}`;

export enum CampaignPermissionType {
  AGENCY_CAMPAIGN = 'CREATE_AGENT-PORTAL_SURVEY-RESULT',
  // AGENCY_CAMPAIGN_LIST_READ="READ_ANY_CAMPAIGN_LIST",
  // AGENCY_CAMPAIGN_LIST_CREATE="CREATE_ANY_CAMPAIGN_LIST",
  // AGENCY_CAMPAIGN_LIST_UPDATE="UPDATE_ANY_CAMPAIGN_LIST",
  AGENCY_CAMPAIGN_LIST_READ = 'CREATE_AGENT-PORTAL_SURVEY-RESULT',
  AGENCY_CAMPAIGN_LIST_CREATE = 'CREATE_AGENT-PORTAL_SURVEY-RESULT',
  AGENCY_CAMPAIGN_LIST_UPDATE = 'CREATE_AGENT-PORTAL_SURVEY-RESULT',
}

export enum AgencyCampaignTypeStatusEnum {
  PENDING = 'pendingApproval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  DRAFT = 'draft',
}

export enum WorkflowApprovalStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export const GenderMap = {
  M: 'pruleads.prospect.list.male',
  F: 'pruleads.prospect.list.female',
};

export const ProspectTypesConfig = {
  [ProspectTypes.prospect_type_agent]: {
    i18nKey: 'pruleads.prospect.list.prospectTypeAgent',
  },
  [ProspectTypes.prospect_type_candidate]: {
    i18nKey: 'pruleads.prospect.list.prospectTypeCandidate',
  },
  [ProspectTypes.prospect_type_customer]: {
    i18nKey: 'pruleads.prospect.list.prospectTypeCustomer',
  },
  [ProspectTypes.prospect_type_non_candidate]: {
    i18nKey: 'pruleads.prospect.list.prospectTypeNonCandidate',
  },
  [ProspectTypes.prospect_type_non_customer]: {
    i18nKey: 'pruleads.prospect.list.prospectTypeNonCustomer',
  },
};

export const LeadTypesConfig = {
  [LeadLabel.existingCustomer]: {
    i18nKey: 'campaign.lead.label.existingCustomer',
  },
  [LeadLabel.potentialCustomer]: {
    i18nKey: 'campaign.lead.label.potentialCustomer',
  },
  [LeadLabel.referralCode]: {
    i18nKey: 'campaign.lead.label.referralCode',
  },
  [LeadLabel.redistributedLead]: {
    i18nKey: 'campaign.lead.label.redistributedLead',
  },
  [LeadLabel.reassignLead]: {
    i18nKey: 'campaign.lead.label.reassignLead',
  },
};

export const ProspectSourceTypesConfig = {
  [ProspectSourceTypes.prospect_source_agent]: {
    i18nKey: 'pruleads.prospect.list.salesAgent',
  },
  [ProspectSourceTypes.prospect_source_marketing]: {
    i18nKey: 'pruleads.prospect.list.salesMarketing',
  },
};

export const ELIGIBILITY_CHECK_BATCH_SIZE = 200;
