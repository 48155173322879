import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './new-added-tab.module.css';
import { useNewAddedTab } from './new-added-tab.hook';
import { DateRadioGroupType } from 'src/app/common/components/list-filter/components/list-filter-date-radio-group/list-filter-date-radio-group.component';
import { ListType, ProspectTypeList } from '../prospect-type-list/prospect-type-list.component';

type ComponentProps = ParamsProps & {
  filterValues: { source?: string; dateRange?: DateRadioGroupType };
};

export const NewAddedTab: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();

  // custom-hook
  const { prospectDetail } = useNewAddedTab(props);
  return (
    <>
      <ProspectTypeList data={prospectDetail} listType={ListType.NEW_ADD} />
    </>
  );
});
