import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { LeadListDialogType } from '../lead-list-full-screen-dialog/lead-list-full-screen-dialog.component';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import moment from 'moment';
import { DateFormat } from 'src/app/common/utils/date.util';
import {
  getColdLeadsCount,
  getContactedLeadsCount,
  getReassignLeadOverview,
  getTotalOpportunities,
} from '../../util/api.util';
import {
  successfullyContactedLeadYesterdayGaData,
  successfullyContactedLeadYesterdayGaData1,
  theseLeadAreGettingColdGaDataClick2,
  theseLeadAreGettingColdGaDataClick3,
  youGotANewOpportunityGaDataClick,
  youGotANewOpportunityGaDataClick1,
} from 'src/app/common/ga/types/ga-click-lead';
import { LeadListDialogType } from '../lead-list-full-screen-dialog/types/types';

export const storageKeyNewLead = `NewLead:${1}`;
export const storageKeyContactLead = `ContactLead:${2}`;
export const storageKeyAgingLead = `AgingLead:${3}`;

export const useLeadListReminder = () => {
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [showNewLeadReminder, setShowNewLeadReminder] = useState(false);
  const [showContactLeadReminder, setShowContactLeadReminder] = useState(false);
  const [showAgingLeadReminder, setShowAgingLeadReminder] = useState(false);
  const [showReassignLeadReminder, setShowReassignLeadReminder] = useState(false);
  const [reassignData, setReassignData] = useState<{ reassignLeadsCount: number; groupsString: string }>();
  const [dialogType, setDialogType] = useState<LeadListDialogType>();

  const currentDate = moment().format(DateFormat.date);

  const noNewLeadReminder = useCallback(async () => {
    const flag = await window.localStorage.getItem(storageKeyNewLead);
    const data = await getTotalOpportunities();
    if (flag === currentDate || typeof data?.total !== 'number' || data?.total <= 0) {
      setShowNewLeadReminder(false);
    } else {
      setShowNewLeadReminder(true);
    }
  }, [currentDate]);

  const noContactLeadReminder = useCallback(async () => {
    const flag = await window.localStorage.getItem(storageKeyContactLead);
    const data = await getContactedLeadsCount(dispatch);
    if (flag === currentDate || data <= 0) {
      setShowContactLeadReminder(false);
    } else {
      setShowContactLeadReminder(true);
    }
  }, [currentDate, dispatch]);

  const noAgingLeadReminder = useCallback(async () => {
    const flag = await window.localStorage.getItem(storageKeyAgingLead);
    const data = await getColdLeadsCount(dispatch);
    if (flag === currentDate || data < 20) {
      setShowAgingLeadReminder(false);
    } else {
      setShowAgingLeadReminder(true);
    }
  }, [currentDate, dispatch]);

  const noReassignLeadReminder = useCallback(async () => {
    const data = await getReassignLeadOverview(dispatch);
    if (data?.reassignLeadsCount && data.reassignLeadsCount !== 0) {
      setReassignData(data);
      setShowReassignLeadReminder(true);
    } else {
      setShowReassignLeadReminder(false);
    }
  }, [dispatch]);

  const skipTodayReminder = (dialogType: LeadListDialogType | undefined) => {
    switch (dialogType) {
      case LeadListDialogType.CONTACT_CHECK:
        window.localStorage.setItem(storageKeyContactLead, currentDate);
        break;
      case LeadListDialogType.NEW_OPPORTUNITIES:
        window.localStorage.setItem(storageKeyNewLead, currentDate);
        break;
      case LeadListDialogType.COLD_LEADS:
        window.localStorage.setItem(storageKeyAgingLead, currentDate);
        break;
      default:
        break;
    }
  };

  const gaOppData = useMemo(() => {
    switch (dialogType) {
      case LeadListDialogType.CONTACT_CHECK:
        return {
          update: successfullyContactedLeadYesterdayGaData,
          newApp: successfullyContactedLeadYesterdayGaData1,
        };
      case LeadListDialogType.NEW_OPPORTUNITIES:
        return {
          accept: youGotANewOpportunityGaDataClick,
          reject: youGotANewOpportunityGaDataClick1,
        };
      case LeadListDialogType.COLD_LEADS:
        return {
          accept: theseLeadAreGettingColdGaDataClick2,
          reject: theseLeadAreGettingColdGaDataClick3,
        };
      default:
        return {
          accept: {},
        };
    }
  }, [dialogType]);

  const oppProviderValue = useMemo(
    () => ({
      gaData: gaOppData,
    }),
    [gaOppData],
  );

  useEffect(() => {
    noNewLeadReminder();
    noAgingLeadReminder();
    noContactLeadReminder();
    noReassignLeadReminder();
  }, [noAgingLeadReminder, noContactLeadReminder, noNewLeadReminder, noReassignLeadReminder]);

  return {
    t,
    openFullScreenDialog,
    setOpenFullScreenDialog,
    showContactLeadReminder,
    setShowContactLeadReminder,
    showAgingLeadReminder,
    setShowAgingLeadReminder,
    showNewLeadReminder,
    setShowNewLeadReminder,
    showReassignLeadReminder,
    setShowReassignLeadReminder,
    reassignData,
    dialogType,
    setDialogType,
    skipTodayReminder,
    oppProviderValue,
  };
};
