import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'ahooks';

import { globalStore } from 'src/app/common/helpers/global-store.util';
import { getAppointmentCount } from '../../../network/insightCrud';
import moment from 'moment';

interface HookProps {
  // agent-code string, split by comma,
  // example: "00010001" or "00010001,00010002,00010003"
  agentCode?: string;
}

export const useAppointment = ({ agentCode }: HookProps) => {
  // i18n
  const history = useHistory();
  const { t } = useTranslation();

  // useRequest to wrap api request.
  const agentCodeList = agentCode || globalStore.getAgentCode();
  const { data, loading, run } = useRequest(
    () => {
      // appointment held
      const heldDto = {
        agentCode: agentCodeList,
        type: 'lead',
        startDate: '',
        endDate: '',
      };

      const today = new Date();
      // appointment count
      const countDto = {
        agentCode: agentCodeList,
        type: 'lead',
        startDate: moment(new Date(today.getFullYear(), today.getMonth(), today.getDate()))
          .add(1, 'days')
          .toISOString(),
        endDate: moment(new Date(today.getFullYear(), today.getMonth(), today.getDate()))
          .add(8, 'days')
          .toISOString(),
      };
      return Promise.all([getAppointmentCount(heldDto), getAppointmentCount(countDto)]).then((response) => {
        const [heldCount, upcomingCount] = response;
        const result = [
          {
            value: heldCount.data.count ?? '0',
            label: t('appointment_held'),
          },
          {
            value: upcomingCount.data.count ?? '0',
            label: t('appointment_next_7_days'),
          },
        ];
        return result;
      });
    },
    {
      manual: true,
    },
  );
  const handlePress = useCallback(() => {
    history.push('./appointment');
  }, []);

  useEffect(() => {
    // manual call api request
    if (agentCodeList) {
      run();
    }
  }, [agentCodeList]);
  const defaultData = useMemo(() => {
    const result = [
      {
        value: '-',
        label: t('appointment_held'),
      },
      {
        value: '-',
        label: t('appointment_next_7_days'),
      },
    ];
    return result;
  }, []);

  const tooltip = useMemo(() => {
    const tooltip = [
      {
        title: t('appointment_held'),
        content: t('appointment_held_des'),
      },
      {
        title: t('appointment_next_7_days'),
        content: t('appointment_number_of_appointments_in_next_7_days'),
      },
    ];
    return tooltip;
  }, []);

  return {
    loading,
    data: loading ? defaultData : data || defaultData,
    tooltip,
    handlePress,
  };
};
