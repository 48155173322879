import React, { FC, memo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Paper, Stack, Divider } from '@mui/material';

import styles from './insight-section.module.css';
import { ItemComponent } from './item/item.component';
import { TooltipComponent } from './tooltip/tooltip.component';

type ComponentProps = {
  title: string;
  onPress: () => void;
  data?: Array<{ value: string; label: string }>;
  tooltip?: Array<{ title: string; content: string }>;
  rightActions?: React.ReactNode;
};

export const InsightSectionComponent: React.FunctionComponent<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  // const { t } = useTranslation();
  const { data = [], tooltip, rightActions, onPress } = props;

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.title}>
          {props.title}
          <TooltipComponent tooltip={tooltip} />
          {rightActions ? rightActions : null}
        </div>
        <Stack
          className={styles.dataStack}
          onClick={onPress}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={'40px'}
        >
          {data.map((item, index) => {
            return <ItemComponent key={index} value={item.value} label={item.label} />;
          })}
        </Stack>
      </div>
    </>
  );
});
