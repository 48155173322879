import { map } from 'lodash';
import { RegionLocale } from 'src/app/i18n';
import { FilterComponentsEnum, ListFilterConfig } from 'src/app/common/components/list-filter';
import { ProColumns } from 'src/app/common/components/ProTable';
import { HighlightMatchedText } from 'src/app/common/components/highlight-matched-text/highlight-matched-text.component';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import {
  EventAccessibilityEnum,
  EventAudienceEnum,
  EventStatusEnum,
  EventPublishStatusEnum,
  EventCategoryItem,
  EventListItem,
} from '../../../../types';
import { EnrollQuotaField, EventStatusTag, PublishStatusTag } from '../components';

export const getFilterConfig: (
  Translation: (id: string) => string,
  locale: RegionLocale,
  eventCategoryList?: EventCategoryItem[],
) => Array<ListFilterConfig> = (Translation, locale, eventCategoryList = []) => [
  {
    key: 'categories',
    title: Translation('event.common.category'),
    inputLabel: Translation('event.common.select_category'),
    type: FilterComponentsEnum.DROPDOWN,
    items: map(eventCategoryList, (categoryItem) => ({
      itemKey: categoryItem._id,
      itemLabel: categoryItem.name[locale] || '',
    })),
  },
  {
    key: 'accessibilities',
    title: Translation('event.common.accessibility'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: map(EventAccessibilityEnum, (option) => ({
      itemKey: option,
      itemLabel: Translation(`event.common.accessibility.${option.toLowerCase()}`),
    })),
  },
  {
    key: 'audience',
    title: Translation('event.common.audience'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: map(EventAudienceEnum, (option) => ({
      itemKey: option,
      itemLabel: Translation(`event.common.audience.${option.toLowerCase()}`),
    })),
  },
  {
    key: 'eventStatuses',
    title: Translation('title.event.event.status'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: map(EventStatusEnum, (option) => ({
      itemKey: option,
      itemLabel: Translation(`event.common.event_status.${option.toLowerCase()}`),
    })),
  },
  {
    key: 'publishStatuses',
    title: Translation('component.formLabel.publish-status'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: map(EventPublishStatusEnum, (option) => ({
      itemKey: option,
      itemLabel: Translation(`event.common.publish_status.${option.toLowerCase()}`),
    })),
  },
];

export const getColumns: (
  Translation: (id: string) => string,
  locale: RegionLocale,
  keyword: string,
) => ProColumns<EventListItem>[] = (Translation, locale, keyword) => [
  {
    dataIndex: 'name',
    title: Translation('event.common.name'),
    width: '290px',
    render: (_, eventListItem) => (
      <HighlightMatchedText highLightText={keyword}>{eventListItem.name[locale] || '-'}</HighlightMatchedText>
    ),
  },
  {
    dataIndex: 'categoryName',
    title: Translation('event.common.category'),
    width: '290px',
    render: (_, eventListItem) => eventListItem.categoryName[locale] || '-',
  },
  {
    dataIndex: 'accessibility',
    title: Translation('event.common.accessibility'),
    width: '176px',
    render: (_, eventListItem) =>
      Translation(`event.common.accessibility.${eventListItem.accessibility.toLowerCase()}`),
  },
  {
    dataIndex: 'audience',
    title: Translation('event.common.audience'),
    width: '176px',
    render: (_, eventListItem) => Translation(`event.common.audience.${eventListItem.audience.toLowerCase()}`),
  },
  {
    dataIndex: 'startTime',
    title: Translation('event.form.event_start_time'),
    width: '176px',
    sorter: true,
    render: (_, eventListItem) => getDefaultDisplayDate(eventListItem.startTime, 'datetime'),
  },
  {
    dataIndex: 'endTime',
    title: Translation('event.form.event_end_time'),
    width: '176px',
    sorter: true,
    render: (_, eventListItem) => getDefaultDisplayDate(eventListItem.endTime, 'datetime'),
  },
  {
    dataIndex: 'regStartDate',
    title: Translation('event.form.registration_start_time'),
    width: '176px',
    sorter: true,
    render: (_, eventListItem) => getDefaultDisplayDate(eventListItem.regStartDate, 'datetime'),
  },
  {
    dataIndex: 'regEndDate',
    title: Translation('event.form.registration_end_time'),
    width: '176px',
    sorter: true,
    render: (_, eventListItem) => getDefaultDisplayDate(eventListItem.regEndDate, 'datetime'),
  },
  {
    dataIndex: 'enrollmentAndQuota',
    title: Translation('event.common.enrolled_quota'),
    width: '290px',
    render: (_, eventListItem) => <EnrollQuotaField enrollmentAndQuota={eventListItem.enrollmentAndQuota} />,
  },
  {
    dataIndex: 'createdBy',
    title: Translation('event.common.created_by'),
    width: '176px',
  },
  {
    dataIndex: 'lastUpdatedBy',
    title: Translation('event.common.last_updated_by'),
    width: '176px',
  },
  {
    dataIndex: 'updatedAt',
    title: Translation('component.formLabel.last-updated-time'),
    width: '176px',
    sorter: true,
    render: (_, eventListItem) => getDefaultDisplayDate(eventListItem.lastUpdatedAt, 'datetime'),
  },
  {
    dataIndex: 'eventStatus',
    title: Translation('title.event.event.status'),
    width: '176px',
    render: (_, eventListItem) => <EventStatusTag eventStatus={eventListItem.eventStatus} />,
  },
  {
    dataIndex: 'publishStatus',
    title: Translation('component.formLabel.publish-status'),
    width: '176px',
    render: (_, eventListItem) => <PublishStatusTag publishStatus={eventListItem.publishStatus} />,
  },
];
