import { FC, useMemo } from 'react';
import { Tag } from 'src/app/common/components/tag';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { EventStatusEnum } from 'src/app/modules/event-v2/types';

type EventStatusTagProps = {
  eventStatus: EventStatusEnum;
};

const getTagConfig = (Translation: (id: string) => string) => ({
  [EventStatusEnum.ONGOING]: {
    color: '#24C791',
    bgColor: '#DFF7EF',
    text: Translation(`event.common.event_status.${EventStatusEnum.ONGOING.toLowerCase()}`),
  },
  [EventStatusEnum.UPCOMING]: {
    color: '#4386E5',
    bgColor: '#EBF3FF',
    text: Translation(`event.common.event_status.${EventStatusEnum.UPCOMING.toLowerCase()}`),
  },
  [EventStatusEnum.PAST]: {
    color: '#333333',
    bgColor: '#F5F5F5',
    text: Translation(`event.common.event_status.${EventStatusEnum.PAST.toLowerCase()}`),
  },
  [EventStatusEnum.CANCELED]: {
    color: '#999999',
    bgColor: '#F5F5F5',
    text: Translation(`event.common.event_status.${EventStatusEnum.CANCELED.toLowerCase()}`),
  },
});

export const EventStatusTag: FC<EventStatusTagProps> = ({ eventStatus }) => {
  const { t: Translation } = useTranslation();
  const configs = useMemo(() => getTagConfig(Translation), [Translation]);

  return (
    <div style={{ display: 'inline-block' }}>
      <Tag<string> value={eventStatus} configs={configs} />
    </div>
  );
};
