import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { ActionType, RequestData } from 'src/app/common/components/ProTable';
import {
  getProspectCategories,
  getProspects,
} from 'src/app/modules/Prospect/pages/components/prospect-filter-table/util/api.util';
import { Prospect } from 'src/app/modules/Prospect/types/prospect-types';
import { DialogueItemType, DialogueKeyEnum } from 'src/app/modules/ai-talkbot/types/talkbot-export-leads-types';
import {
  getEligibleMarketingCampaignList,
  getEligibleServicingCampaignList,
  getTalkbotProspect,
} from '../../utils/api.util';
import { CampaignElement } from 'src/app/modules/Campaign/types/campaign-types';
import { SERVICING_DETAIL_PATH } from 'src/app/modules/Campaign/pages/ServicingCampaign/servicing-campaign-routes';
import { UnreadCampaignMap } from 'src/app/common/components/layout/components/aside/components/nested-menu';
import { updateMenuBadge } from 'src/redux/common/commonSlice';
import { useHistory } from 'react-router-dom';
import { MARKETING_DETAIL_PATH } from 'src/app/modules/Campaign/pages/MarketingCampaign/marketing-campaign-routes';
import { useRequest } from 'ahooks';

interface HookProps {
  curDialogue: DialogueItemType;
  // other params
}

export enum CampaignTabValues {
  MARKETING = 'MARKETING',
  SERVICING = 'SERVICING',
}

export enum BirthdayTabValues {
  PROSPECTS = 'PROSPECT',
  CAMPAIGNS = 'CAMPAIGNS',
}

export const useList = ({ curDialogue }: HookProps) => {
  // i18n
  const locale = useLang();
  const { t } = useTranslation();
  const { menuBadge } = useSelector((state: any) => state.common);
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState<string>(CampaignTabValues.MARKETING);
  const [selectedBirthdayTab, setSelectedBirthdayTab] = useState<string>(BirthdayTabValues.PROSPECTS);

  const marketingActionRef = useRef<ActionType>();
  const servicingActionRef = useRef<ActionType>();

  // redux
  const dispatch = useDispatch();

  const tabs = useMemo(() => {
    return [
      { label: t('talkbot.campaign.marketing'), value: CampaignTabValues.MARKETING },
      { label: t('talkbot.campaign.servicing'), value: CampaignTabValues.SERVICING },
    ];
  }, [t]);

  useEffect(() => {
    if (selectedTab === CampaignTabValues.MARKETING) {
      marketingActionRef?.current?.reload();
    } else {
      servicingActionRef?.current?.reload();
    }
  }, [curDialogue, selectedTab]);

  const getMarketingCampaignData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        current?: number | undefined;
        keyword?: string | undefined;
      },
      sort: { [key: string]: any },
    ): Promise<any> => getEligibleMarketingCampaignList({ ...params, flowId: curDialogue.flowId }, sort),
    [curDialogue.flowId],
  );

  const getServicingCampaignData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        current?: number | undefined;
        keyword?: string | undefined;
      },
      sort: { [key: string]: any },
    ): Promise<any> => getEligibleServicingCampaignList({ ...params, flowId: curDialogue.flowId }, sort, dispatch),
    [dispatch, curDialogue.flowId],
  );

  const getProspectData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<RequestData<Prospect>> => getTalkbotProspect(dispatch, params, sort),
    [dispatch],
  );

  const { data: prospectCategories } = useRequest(async () => getProspectCategories(locale, dispatch), {
    manual: false,
  });

  const toDetail = (e: CampaignElement) => {
    if (selectedTab === CampaignTabValues.SERVICING) {
      history.push(`${SERVICING_DETAIL_PATH}/${e._id}`);
    } else {
      history.push(`${MARKETING_DETAIL_PATH}/${e._id}`);
    }
    !e.lastViewedTime &&
      menuBadge[UnreadCampaignMap.SERVICING_CAMPAIGN] &&
      dispatch(
        updateMenuBadge({
          ...menuBadge,
          [UnreadCampaignMap.SERVICING_CAMPAIGN]: menuBadge[UnreadCampaignMap.SERVICING_CAMPAIGN] - 1,
        }),
      );
  };

  return {
    tabs,
    selectedTab,
    selectedBirthdayTab,
    marketingActionRef,
    servicingActionRef,
    prospectCategories,
    setSelectedTab,
    setSelectedBirthdayTab,
    getMarketingCampaignData,
    getServicingCampaignData,
    getProspectData,
    toDetail,
  };
};
