import React, { memo } from 'react';
import { Box, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export interface BarItemProps {
  flex: number;
  bgColor?: string;
  minWidth?: string;
}

export interface DotItemProps {
  label: string;
  bgColor: string;
  sx?: SxProps<Theme>;
}

export interface TitleItemProps {
  title: string;
  count: string;
  tooltip?: string;
  sx?: SxProps<Theme>;
}

export const TitleItem: React.FC<TitleItemProps> = memo(({ sx, title, count, tooltip }: TitleItemProps) => {
  return (
    <Box sx={sx} display={'inline-flex'} flexDirection={'column'} flex={0} alignItems={'flex-start'}>
      <Box display={'flex'} flexDirection={'row'} flex={0} alignItems={'center'} marginBottom={'4px'}>
        <Typography fontSize={14} fontWeight={500} color={'#999999'}>
          {title}
        </Typography>
        {tooltip ? (
          <Tooltip title={tooltip}>
            <InfoIcon sx={{ color: '#CCCCCC', width: '16px', height: '16px', marginLeft: '4px' }} />
          </Tooltip>
        ) : null}
      </Box>
      <Typography fontSize={30} fontWeight={700} color={'#333333'}>
        {count}
      </Typography>
    </Box>
  );
});

export const DotItem: React.FC<DotItemProps> = memo(({ label, bgColor, sx }: DotItemProps) => {
  return (
    <Box sx={sx} display={'flex'} flexDirection={'row'} flex={0} alignItems={'center'} justifyContent={'center'}>
      <Box bgcolor={bgColor} marginRight={'9px'} width="12px" height="12px" borderRadius={'6px'} />
      <Typography fontSize={14} fontWeight={500} color={bgColor} width={'max-content'}>
        {label}
      </Typography>
    </Box>
  );
});

export const BarItem: React.FC<BarItemProps> = memo(({ flex, bgColor, minWidth }: BarItemProps) => {
  return <Box height={'100%'} minWidth={minWidth} bgcolor={bgColor} flex={flex} />;
});
