import { Dispatch } from 'react';
import apiClient from '../../../common/network/axios';
import { apiErrorHandler, AGENT_URL, MANAGER_URL } from 'src/app/common/network';
import { getEnumKeyByEnumValue, getRegionIdByCode } from 'src/app/modules/Prospect/util/common.util';
import {
  AppointmentGraphResponse,
  AppointmentGraphParam,
  GraphResBase,
  LeadsStatusDetailParam,
  LeadsStatusGraphParam,
  MassLeadGraphParams,
  AllAgentGroupLevelEnum,
  LeadStatusDetailListDto,
  LeadSourceEnum,
} from '../types/types';
import { MarketingLeadSLA } from '../types/marketing-lead.types';
import { TimeRange } from 'src/app/common/components/charts/chart-button/chart-button.component';
import { get, isEmpty } from 'lodash';
import {
  sumCountsByDate,
  transformDayData,
  transformMonthData,
  transformWeekData,
} from 'src/app/common/components/charts/util/format-response.util';
import moment from 'moment';
import { getAgentName } from 'src/app/common/utils/i18n.util';

const queryPath = `${AGENT_URL}/agents/me`;
// const baseAgentUrl = `${APIM_BASE_URL}${APIM_AGENT}`;
// const baseProtectionReviewUrl = `${APIM_BASE_URL}${APIM_PROTECTION_REVIEW_EXP}`;

type insighatSectionsProps = {
  // agent-code string, split by comma,
  // example: "00010001" or "00010001,00010002,00010003"
  agentCode: string;
  startDate?: string;
  endDate?: string;
};
type insuranceSummarySectionsProps = {
  queryAgentCode: string;
  from?: string;
  to?: string;
};

export enum GroupLevelEnum {
  AGENCY = 'agency',
  DIVISION = 'division',
  BRANCH = 'branch',
  UNIT = 'unit',
  GROUP = 'group',
}
export const getLeadsStatus = async (body: insighatSectionsProps): Promise<any> => {
  const url = `${queryPath}/dashboard/leads-insight-status`;
  return await apiClient.post(url, body);
};

export const getInsuranceSummary = async (body: insuranceSummarySectionsProps): Promise<any> => {
  const url = `${queryPath}/insurance-summaries/insight/statistic`;
  return await apiClient.post(url, body);
};

export const getDashboardProspects = async (body: insighatSectionsProps): Promise<any> => {
  const url = `${queryPath}/dashboard/prospects/insights-prospect`;
  return await apiClient.post(url, body);
};

export const getMassLeadSummary = async (body: insighatSectionsProps): Promise<any> => {
  const url = `${queryPath}/leads/insight/mass-lead-summary`;
  return await apiClient.post(url, body);
};

export const getLeadsMarketing = async (body: insighatSectionsProps): Promise<any> => {
  const url = `${queryPath}/dashboard/leads-insight-marketing`;
  return await apiClient.post(url, body);
};

export const getAssistedLeadSummary = async (body: insighatSectionsProps): Promise<any> => {
  const url = `${queryPath}/agency-campaign/participated/summary`;
  return await apiClient.post(url, body);
};

export const getAssistedLeadDetails = async (body: insighatSectionsProps): Promise<any> => {
  const url = `${queryPath}/agency-campaign/participated/detail`;
  return await apiClient.post(url, body);
};

export const getDashboardEngagement = async (body: insighatSectionsProps): Promise<any> => {
  const url = `${queryPath}/dashboard/prospects/insights-engagement`;
  return await apiClient.post(url, body);
};

export const getAppointmentCount = async (body: insighatSectionsProps): Promise<any> => {
  // agents/calendars/me/events/count
  const url = `${AGENT_URL}/agents/calendars/me/events/count`;
  return await apiClient.post(url, body);
};

export async function getServicingCampaignSummary(body: insighatSectionsProps): Promise<any> {
  const url = `${queryPath}/leads/insight/servicing-campaign/summary`;
  return await apiClient.post(url, body);
}

export async function getServicingCampaignDetail(body: insighatSectionsProps): Promise<any> {
  const url = `${queryPath}/leads/insight/servicing-campaign/detail`;
  return await apiClient.post(url, body);
}

export const getInsightsProspectsAllList = async (agentCode: string, sourceType?: string): Promise<any> => {
  const url = `${queryPath}/dashboard/prospects/insights-prospect-all`;
  const body = {
    agentCode,
    sourceType,
  };
  return await apiClient.post(url, body);
};

export const getInsightsProspectsNewAddedList = async (data: {
  agentCode: string;
  startDate?: string;
  endDate?: string;
  sourceType?: string;
}): Promise<any> => {
  const url = `${queryPath}/dashboard/prospects/insights-prospect-newAdded`;
  const { agentCode, startDate, endDate, sourceType } = data;
  const body = {
    agentCode,
    startDate,
    endDate,
    sourceType,
  };
  return await apiClient.post(url, body);
};

/**
 * get all divisions for current agent unit
 * format: {
    groupType: string;
    groupCode: string;
    groupName: string;
    divisionCode: string;
    isLeader: boolean;
  }[]
 * @param agentCode
 * @returns
 */
export const getDivisions = async (agentCode: string) => {
  const url = `${MANAGER_URL}/managers/${agentCode}/downlines/divisions`;
  return await apiClient.get(url);
};

export const getGroupList = async (agentCode: string, groupLevel?: GroupLevelEnum) => {
  let group = getEnumKeyByEnumValue(GroupLevelEnum, groupLevel || '');

  const url = `${MANAGER_URL}/managers/${agentCode}/downlines/divisions`;
  const list = await apiClient.get(url, { params: { groupLevel: group } });
  const formatDivisions = list.data.map((item: any) => {
    const { groupCode, divisionCode, isLeader } = item;
    return {
      label: groupCode,
      value: groupCode,
      divisionCode,
      isDefault: isLeader,
    };
  });
  return formatDivisions;
};

export const getAgentCodeListByGroup = async (agentCode: string, groupCode: string, group?: string): Promise<any> => {
  const url = `${MANAGER_URL}/managers/${agentCode}/downlines/agents/${groupCode}`;
  return await apiClient.get(encodeURI(url), { params: { groupLevel: group } });
};

/**
 * get agent list by group-level and group-code,
 * sample: /agents/me/manager-view/agents?groupCode=D0487&groupLevel=division
 * response: [{ "agentCode": "00010277",
      "name": {
        "zhHk": {"surname": "c_name_00010277","firstName": "c_f_name_00010277"},
        "enUs": {
              "displayName": "s_name_00010277 ",
              "surname": "s_name_00010277",
              "firstName": "f_name_00010277",
              "otherName": "o_name_00010277",
              "nickName": "n_name_00010277"
          }
      }}]
 * @param groupCode e.g. "D0004" (this is divisionCode, maybe other unit-code, branch-code)
 * @param groupLevel GroupLevelEnum, e.g. 'division'
 * @returns
 */
export const getAgentListByGroupLevel = async (
  groupCode: string,
  groupLevel: GroupLevelEnum,
  locale: string,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  const url = `${queryPath}/manager-view/agents`;
  return await apiClient
    .get(url, { params: { groupCode, groupLevel } })
    .then((response) => {
      const agentList = response.data?.data || [];
      const arrAllAgent: string[] = [];
      const agentListMap = agentList
        .sort((a: any, b: any) => a.agentCode - b.agentCode)
        .map((item: any) => {
          const { agentCode, name } = item;
          arrAllAgent.push(agentCode);
          let agentName = getAgentName(name, locale);
          if (isEmpty(agentName)) {
            agentName = getAgentName(name, 'enUs');
          }
          return {
            label: agentName,
            value: agentCode,
          };
        });

      // picker list: insert 'All Agents' to the first place
      agentListMap.unshift({
        label: AllAgentGroupLevelEnum.AGENT,
        value: arrAllAgent.join(','),
      });
      return agentListMap;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getManagerViewGroupLevel = async (groupLevel?: GroupLevelEnum[]): Promise<any> => {
  const url = `${queryPath}/manager-view`;

  return await apiClient.get(encodeURI(url), { params: { groupLevel } });
};

export const getRuleGroups = async (agentCode: string): Promise<any> => {
  const url = `${queryPath}/dashboard/activity-rule-set`;
  return await apiClient.get(url, { params: { agentCode: agentCode } });
};

export const appointmentGraph = async <T extends GraphResBase>(
  data: AppointmentGraphParam,
  dispatch?: Dispatch<any>,
): Promise<AppointmentGraphResponse<T>> => {
  const path = `${AGENT_URL}/agents/calendars/me/events/duration-count`;
  return apiClient
    .post(path, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

/**
 * get insight lead status details(statistics)
 * @param body
 * @returns
 */
export async function getLeadStatusDetail(body: LeadsStatusDetailParam) {
  const url = `${queryPath}/leads/statistics`;
  return await apiClient.post(url, body);
}

const dateMap = {
  [TimeRange.day]: 'day',
  [TimeRange.month]: 'month',
  [TimeRange.week]: 'week',
};

// lead-status graph
export async function getLeadStatusGraph(body: LeadsStatusGraphParam) {
  const url = `${queryPath}/lead-dashboard/statistics/graph`;
  return await apiClient.post(url, { ...body, dateType: body.dateType && dateMap[body.dateType] }).then((res) => {
    const marketingLeadData = res.data?.find((i: any) => i.source === LeadSourceEnum.marketingSource)?.data;
    const agentLeadData = res.data?.find((i: any) => i.source === LeadSourceEnum.agentSource)?.data;
    // marketingLeadData + agentLeadData
    const allData = sumCountsByDate([marketingLeadData, agentLeadData]);

    const today = moment();
    const startYear = 2020; //fixed number
    const startDate = new Date(startYear, 1, 1).toISOString();

    if (body.dateType === TimeRange.day) {
      const marketingLead = transformDayData(marketingLeadData, startYear);
      const agentLead = transformDayData(agentLeadData, startYear);
      const allLead = transformDayData(allData as any, startYear);
      const highlightX = [marketingLead.findIndex((d) => moment(d?.x?.[0]).isSame(today, 'day'))];
      return { marketingLead, agentLead, allLead, highlightX };
    } else if (body.dateType === TimeRange.week) {
      const marketingLead = transformWeekData(marketingLeadData, startDate);
      const agentLead = transformWeekData(agentLeadData, startDate);
      const allLead = transformWeekData(allData as any, startDate);
      const highlightX = [marketingLead.findIndex((d) => today.isBetween(moment(d?.x?.[0]), moment(d?.x?.[1])))];
      return { marketingLead, agentLead, allLead, highlightX };
      // return transformWeekData(marketingLeadData, startDate);
    } else {
      const marketingLead = transformMonthData(marketingLeadData, startYear);
      const agentLead = transformMonthData(agentLeadData, startYear);
      const allLead = transformMonthData(allData as any, startYear);
      const highlightX = [marketingLead.findIndex((d) => moment(d?.x?.[0]).isSame(today, 'month'))];
      return { marketingLead, agentLead, allLead, highlightX };
    }
  });
}

/** Get Participated Type List */
export async function getParticipatedType(body: { agentCode: string }) {
  const url = `${queryPath}/agency-campaign/participated/type`;
  return await apiClient.post(url, body);
}

export const getMassLeadDetails = async (body: LeadsStatusDetailParam, dispatch?: Dispatch<any>): Promise<any> => {
  const path = `${AGENT_URL}/agents/me/leads/insight/mass-lead-detail`;
  return apiClient
    .post(path, body)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getMassLeadGraph = async (body: MassLeadGraphParams, dispatch?: Dispatch<any>): Promise<any> => {
  const path = `${AGENT_URL}/agents/me/leads/insight/mass-lead/graph`;
  return apiClient
    .post(path, body)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchInsightSLALead = (params: any, dispatch?: Dispatch<any>): Promise<MarketingLeadSLA> => {
  const path = `${AGENT_URL}/agents/me/dashboard/leads-insight-marketing-sla`;
  return apiClient
    .post(path, params)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchInsightSLAGraphs = async (body: any = {}, dispatch?: Dispatch<any>) => {
  const path = `${AGENT_URL}/agents/me/leads/lead-insight-marketing-sla-graph`;
  return apiClient
    .post(path, body)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getLeadStatusDetailList = async (body: LeadStatusDetailListDto, dispatch?: Dispatch<any>) => {
  const path = `${queryPath}/leads/leads-status/detail-list`;
  return apiClient
    .post(path, body)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const getInsightsProspectList = async (
  params: {
    startDate: string;
    endDate: string;
    sourceType: string;
    prospectCategoryId: string;
    pageNum: Number;
    pageSize: Number;
  },
  dispatch?: Dispatch<any>,
): Promise<any> => {
  const path = `${AGENT_URL}/agents/me/dashboard/prospects/prospect-list`;
  return apiClient
    .get(path, { params: { ...params } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const exportInsight = async (reportForm: any, dispatch?: Dispatch<any>): Promise<any> => {
  const path = `${queryPath}/dashboard/export-insight`;
  return apiClient
    .post(path, reportForm)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
