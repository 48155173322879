import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';
import moment from 'moment';
import { TimeRange, ChartType } from 'src/app/common/components/charts/chart-button/chart-button.component';
import { formatLabel } from 'src/app/common/components/charts/util/format-response.util';
import { getAppointmentDayRecord, getAppointmentWeekRecord, getAppointmentMonthRecord } from './util/api.util';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { InsightSession } from '../../types/types';
import { globalStore } from 'src/app/common/helpers/global-store.util';

export const useAppointment = ({ currentDate }: { currentDate?: string }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [timeRange, setTimeRange] = useState(TimeRange.day);
  const [chartType, setChartType] = useState(ChartType.bar);

  const [startDate, endDate] = useMemo(() => {
    const startDate = new Date(2020, 1, 1).toISOString();
    const endDate = moment(currentDate).add(1, 'month').toDate().toISOString();
    return [startDate, endDate];
  }, [currentDate]);

  const agentCode = useMemo(() => {
    return sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode();
  }, []);

  const { data: dayData = [] } = useRequest(
    () => getAppointmentDayRecord({ startDate, endDate, agentCode }, dispatch),
    {
      manual: false,
    },
  );

  const { data: weekData = [] } = useRequest(
    () => getAppointmentWeekRecord({ startDate, endDate, agentCode }, dispatch),
    {
      manual: false,
    },
  );

  const { data: monthData = [] } = useRequest(
    () => getAppointmentMonthRecord({ startDate, endDate, agentCode }, dispatch),
    {
      manual: false,
    },
  );

  const formatter = useCallback(
    (val: any) => {
      if (!val) return '';
      return formatLabel(val, timeRange);
    },
    [timeRange],
  );

  const chartData = useMemo(() => {
    let data;
    switch (timeRange) {
      case TimeRange.day:
        data = dayData;
        break;
      case TimeRange.week:
        data = weekData;
        break;
      case TimeRange.month:
        data = monthData;
        break;
    }
    return [
      {
        name: t('appointment'),
        data,
      },
    ];
  }, [dayData, monthData, timeRange, weekData, t]);

  const highlightX = useMemo(() => {
    const today = moment(currentDate);
    switch (timeRange) {
      case TimeRange.day:
        return [dayData.findIndex((d) => moment(d?.x?.[0]).isSame(today, 'day'))];
      case TimeRange.week:
        return [weekData.findIndex((d) => today.isBetween(moment(d?.x?.[0]), moment(d?.x?.[1])))];
      case TimeRange.month:
        return [monthData.findIndex((d) => moment(d?.x?.[0]).isSame(today, 'month'))];
    }
  }, [dayData, timeRange, weekData, monthData, currentDate]);

  return {
    startDate,
    endDate,
    chartType,
    setChartType,
    timeRange,
    setTimeRange,
    chartData,
    formatter,
    highlightX,
  };
};
