import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useCampaignLeadList } from './campaign-lead-list.hook';
import ProFilterTable from 'src/app/common/components/ProTable';
import { CampaignLeadItem } from 'src/app/modules/Campaign/types/campaign-types';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components/pro-search-filter/pro-search-filter.component';
import { UpdateServicingCampaignDialog } from '../update-servicing-campaign-dialog/update-servicing-campaign-dialog.component';
import { useHistory } from 'react-router-dom';
import { LEADS_DETAIL_PATH } from 'src/app/modules/Leads/constants';
import { MultiExpoButton } from 'src/app/modules/ai-talkbot/common/components/talkbot-export-button/talkbot-export-button.component';
import { AiTalkbotItemComponent } from '../../../MarketingCampaign/Detail/marketing-campaign-lead-list/ai-talkbot-item/ai-talkbot-item.component';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  data: Partial<CampaignLeadItem>[];
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  campaignId: string;
  detailsData: any;
};

export const ServicingCampaignLeadListComponent: React.FC<ComponentProps> = memo(
  ({ data, cacheParams, campaignId, detailsData }) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });
    const {
      refresh,
      filterConfig,
      columns,
      getData,
      rowSelection,
      params,
      onFilterChange,
      actionRef,
      showUpdatingDialog,
      onDialogConfirmClick,
      onDialogCancelClick,
    } = useCampaignLeadList({
      campaginDetail: data,
      cacheParams,
    });

    const history = useHistory();
    const toLead = (i: any) => {
      history.push(`${LEADS_DETAIL_PATH}/${i._id}`);
    };

    return (
      <>
        {detailsData?.talkbotRule?.enabled && (
          <AiTalkbotItemComponent
            exportedCount={detailsData.talkbotExportedCount}
            calledCount={detailsData.talkbotCalledCount}
            flowId={detailsData?.talkbotRule?.flowId}
          />
        )}
        <ProFilterTable
          filterConfigs={filterConfig}
          filterState={params}
          Filter={ProSearchFilter}
          filterPlaceholder={Translation('pruleads.prospect.list.search.placeholder')}
          rowKey="_id"
          rowSelection={rowSelection}
          columns={columns}
          request={getData}
          enableRefresh={false}
          showSelect={true}
          onFilterChange={onFilterChange}
          actionRef={actionRef}
          onClickRow={toLead}
          ActionNode={({ selectedRows }) => <MultiExpoButton selectedRows={selectedRows} onAction={refresh} />}
        />
        <UpdateServicingCampaignDialog
          campaignId={campaignId}
          open={showUpdatingDialog}
          onConfirm={onDialogConfirmClick}
          onCancel={onDialogCancelClick}
        />
      </>
    );
  },
);
