import { get, map } from 'lodash';
import { FilterComponentsEnum, ListFilterConfig } from 'src/app/common/components/list-filter';
import { ProColumns } from 'src/app/common/components/ProTable';
import { configManager } from 'src/app/common/utils';
import { renderDateTime } from 'src/app/modules/AgencyCampaign/pages/Campaign/campaign-list/util/list.util';
import {
  ExportDetailItemType,
  TalkBotCallActionTags,
  TalkBotCallInterestTags,
  TalkBotCallLeadStatusTags,
  TalkBotCallStatus,
} from 'src/app/modules/ai-talkbot/types/talkbot-export-history-types';
import { DialogueConfigItemType } from 'src/app/modules/ai-talkbot/types/talkbot-export-leads-types';
import { renderName, renderRecording, renderTag, renderValue } from '../detail.screen';

export const getColumns = ({
  t,
  setShowTransscript,
  setSelectedCallId,
  toDetail,
}: {
  t: (key: string, values?: Record<string, any>) => string;
  setShowTransscript: (show: boolean) => void;
  setSelectedCallId: (id: number) => void;
  toDetail(id: string): void;
}): ProColumns<ExportDetailItemType>[] => {
  const dialogConfigs = get(configManager.getConfig('sales-common'), 'Sales.talkbot.dialogues', []);
  return [
    {
      title: t('talkbot.export_history.prospect_name'),
      dataIndex: 'displayName',
      width: '176px',
      render: (currValue, item) => renderName(item, toDetail),
    },
    {
      title: t('talkbot.export_history.dialog_topic'),
      dataIndex: 'flowId',
      width: '176px',
      render: (curVal) => dialogConfigs?.find((item: DialogueConfigItemType) => item.flowId === curVal)?.dialogueKey,
    },
    {
      title: t('talkbot.export_history.campaign_name'),
      dataIndex: 'campaignName',
      width: '176px',
    },
    {
      title: t('talkbot.export_history.time_called'),
      sorter: true,
      dataIndex: 'exportDate',
      width: '176px',
      render: (curVal) => renderValue(curVal ? renderDateTime(curVal) : curVal),
    },
    {
      title: t('talkbot.export_history.called_id'),
      dataIndex: 'callId',
      width: '290px',
      render: (curVal) => renderValue(curVal),
    },
    {
      title: t('talkbot.export_history.status'),
      dataIndex: 'callStatus',
      width: '176px',
      render: (curVal) => renderTag(curVal, t),
    },
    {
      title: t('talkbot.export_history.contact_tag'),
      dataIndex: 'contactTags',
      width: '176px',
      render: (curVal) =>
        curVal?.length > 0 ? curVal?.map((item: string) => renderTag(item, t, 'tag')) : renderValue(),
    },
    {
      title: t('Recording'),
      dataIndex: 'aiCallDuration',
      width: '290px',
      render: (curVal, item) => renderRecording(item, setShowTransscript, setSelectedCallId),
    },
  ];
};

export const getFilterConfig = (t: (key: string, values?: Record<string, any>) => string): Array<ListFilterConfig> => {
  return [
    {
      key: 'callStatus',
      title: t('talkbot.export_history.call_status'),
      type: FilterComponentsEnum.CHECK_BOX_GROUP,
      items: map(TalkBotCallStatus, (option) => ({
        itemKey: option,
        itemLabel: t(`talkbot.export_history.call_status.${option.toLowerCase()}`),
      })),
    },
    {
      key: 'interest',
      title: t('talkbot.export_history.contact_tags'),
      subtitle: t('talkbot.export_history.interest'),
      type: FilterComponentsEnum.CHECK_BOX_GROUP,
      items: map(TalkBotCallInterestTags, (option) => ({
        itemKey: option,
        itemLabel: t(`talkbot.export_history.contact_tag.${option.toLowerCase()}`),
      })),
    },
    {
      key: 'actionItems',
      title: '',
      subtitle: t('talkbot.export_history.action_items'),
      type: FilterComponentsEnum.CHECK_BOX_GROUP,
      items: map(TalkBotCallActionTags, (option) => ({
        itemKey: option,
        itemLabel: t(`talkbot.export_history.contact_tag.${option.toLowerCase()}`),
      })),
    },
    {
      key: 'leadStatus',
      title: '',
      subtitle: t('talkbot.export_history.lead_status'),
      type: FilterComponentsEnum.CHECK_BOX_GROUP,
      items: map(TalkBotCallLeadStatusTags, (option) => ({
        itemKey: option,
        itemLabel: t(`talkbot.export_history.contact_tag.${option.toLowerCase()}`),
      })),
    },
  ];
};

export const tagConfig: { [key: string]: { color: string; bgColor: string } } = {
  green: {
    color: '#24C791',
    bgColor: '#DFF7EF',
  },
  blue: {
    color: '#4386E5',
    bgColor: '#EBF3FF',
  },
};
