import { EventAgentConfig } from 'src/app/modules/event-v2/types';

export type PublishFormState = {
  pubAgentConfig: EventAgentConfig;
};

type SetFormStateAction = {
  type: 'SET_FORM_STATE';
  payload: {
    value: Partial<PublishFormState>;
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof PublishFormState;
    value: any;
  };
};

export type PublishFormAction = SetFormStateAction | ModifyFieldAction;

export const publishFormReducer = (state: PublishFormState, action: PublishFormAction): PublishFormState => {
  switch (action.type) {
    case 'SET_FORM_STATE':
      return {
        ...state,
        ...action.payload.value,
      };
    case 'MODIFY_FIELD':
      const newState = {
        ...state,
        [action.payload.field]: action.payload.value,
      };
      return newState;
    default:
      return state;
  }
};
