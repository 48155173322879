export const INSIGHT_TITLE = 'menu.title.insight';

export const INSIGHT_NAMESPACE = 'INSIGHT';

export const INSIGHT_BASE_PATH = '/insight';
export const INSIGHT_HOME_PATH = `${INSIGHT_BASE_PATH}/home`;
export const INSIGHT_APPOINTMENT_PATH = `${INSIGHT_BASE_PATH}/appointment`;

export enum INSIGHT_MARKETING_LEAD_TABS {
  SLA,
  GRAPHS,
}
export const INSIGHT_STATUS_PATH = `${INSIGHT_BASE_PATH}/lead-status`;
export const INSIGHT_STATUS_LIST_PATH = `${INSIGHT_BASE_PATH}/lead-status-list`;
export const INSIGHT_MARKETING_PATH = `${INSIGHT_BASE_PATH}/marketing-lead`;
export const INSIGHT_MASS_PATH = `${INSIGHT_BASE_PATH}/mass-lead`;
export const INSIGHT_COMPANY_PATH = `${INSIGHT_BASE_PATH}/company-assisted-lead`;
export const INSIGHT_PROSPECT_PATH = `${INSIGHT_BASE_PATH}/prospect`;
export const INSIGHT_PROSPECT_TYPE_PATH = `${INSIGHT_BASE_PATH}/prospect/type`;
export const INSIGHT_SERVICING_PATH = `${INSIGHT_BASE_PATH}/servicing-campaign`;
export const INSIGHT_EXPORT_PATH = `${INSIGHT_BASE_PATH}/export-report`;
