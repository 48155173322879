import React, { FC, useRef } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
// import CampaignListPage from './List/CampaignListingPage';
import CampaignDetailPage from './Details/CampaignDetailPage';
import { GAData } from '../../../../common/ga/redux/gaSlice';
import { ListPage } from './campaign-list/list.screen';
import { DetailPage } from './detail/detail.screen';
import { AGENCY_CAMPAIGN_LIST_PATH } from '../../constants';

const agencyCampaignDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'View Agency Campaign',
  stage: 'View Agency Campaign Detail',
  screen_name: 'Agency Campaign Detail',
};
const agencyCampaignDetailGaDataRoute19: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Host Agency Campaign',
  stage: 'Create Agency Campaign Application',
  screen_name: 'Agency Campaign Detail',
};

const campaignListGaDataRoute17: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'View Agency Campaign',
  stage: 'View Campaign List',
  screen_name: 'Campaign List',
};

const AgencyCampaignRoutes: FC<RouteComponentProps> = (props) => {
  const cacheParams = useRef();
  return (
    <Switch>
      <PruRoute
        exact
        gaData={agencyCampaignDetailGaDataRoute19}
        path={`${AGENCY_CAMPAIGN_LIST_PATH}/create/:typeId`}
        render={(props) => <DetailPage {...props} cacheParams={cacheParams} />}
      />
      <PruRoute
        exact
        gaData={agencyCampaignDetailGaDataRoute}
        path={`${AGENCY_CAMPAIGN_LIST_PATH}/detail/:id`}
        render={(props) => <DetailPage {...props} cacheParams={cacheParams} />}
      />
      <PruRoute
        exact
        gaData={campaignListGaDataRoute17}
        path={`${AGENCY_CAMPAIGN_LIST_PATH}`}
        render={(props) => <ListPage {...props} cacheParams={cacheParams} />}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default AgencyCampaignRoutes;
