import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Paper, TextField } from '@mui/material';
import { useLang } from 'src/app/i18n';
import { getDisplayName } from 'src/app/common/utils';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { useStyles } from './agent-applicant-info.style';

export const AgentApplicantInfo: FC = () => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();
  const { classes } = useStyles();
  const agentInfo = useMemo(() => globalStore.getAgent(), []);

  return (
    agentInfo && (
      <Paper className={classes.paper}>
        <div className={classes.titleContainer}>{Translation('agent.common.applicant_info')}</div>
        <div className={classes.contentContainer}>
          <div className={classes.fieldContainer}>
            <TextField
              disabled={true}
              fullWidth
              size="medium"
              variant="outlined"
              label={
                <>
                  {Translation('agent.common.applicant_name')}
                  <span className={classes.mandatory}>*</span>
                </>
              }
              value={getDisplayName(agentInfo, locale)}
            />
          </div>
          <div className={classes.fieldContainer}>
            <TextField
              disabled={true}
              fullWidth
              size="medium"
              variant="outlined"
              label={
                <>
                  {Translation('agent.common.applicant_agent_code')}
                  <span className={classes.mandatory}>*</span>
                </>
              }
              value={agentInfo.agentCode}
            />
          </div>
          <div className={classes.fieldContainer}>
            <TextField
              disabled={true}
              fullWidth
              size="medium"
              variant="outlined"
              label={
                <>
                  {Translation('agent.common.applicant_email')}
                  <span className={classes.mandatory}>*</span>
                </>
              }
              value={agentInfo.email}
            />
          </div>
          <div className={classes.fieldContainer}>
            <TextField
              disabled={true}
              fullWidth
              size="medium"
              variant="outlined"
              label={
                <>
                  {Translation('agent.common.applicant_phone_number')}
                  <span className={classes.mandatory}>*</span>
                </>
              }
              value={agentInfo.phone.mobile}
            />
          </div>
        </div>
      </Paper>
    )
  );
};
