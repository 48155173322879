export const defaultRegFormQuestionNames = [
  'page1',
  'registrant_firstName',
  'registrant_lastName',
  'registrant_email',
  'countryCode_phoneNumber',
  'registrant_countryCode',
  'registrant_phoneNumber',
  'register_sessions',
];
