import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './marketing-lead.module.css';
import { useMarketingLead } from './marketing-lead.hook';
import { InsightSectionComponent } from '../../../components/insight-section/insight-section.component';
import { InsightFilterComponent } from '../../../components/insight-filter/insight-filter.component';

type ComponentProps = ParamsProps & {
  // agent-code string, split by comma,
  // example: "00010001" or "00010001,00010002,00010003"
  agentCode?: string;
};

export const MarketingLeadComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();

  const { data, tooltip, filterValues, filterConfig, defaultValues, onFilterConfirm, handlePress } = useMarketingLead({
    agentCode: props.agentCode,
  });
  return (
    <InsightSectionComponent
      onPress={handlePress}
      rightActions={
        <div className={styles.dateRange}>
          <InsightFilterComponent
            width={250}
            filterConfigs={filterConfig}
            filterValues={filterValues}
            defaultValues={defaultValues}
            onConfirm={onFilterConfirm}
          />
        </div>
      }
      title={t('marketing_lead')}
      data={data}
      tooltip={tooltip}
    />
  );
});
