import { makeStyles } from 'tss-react/mui';

const CONTENT_PADDING = 24;

export const useStyles = makeStyles()(() => ({
  subSessionContainer: {
    position: 'relative',
    padding: CONTENT_PADDING,
    backgroundColor: '#FAFAFA',
  },
  subSessionDeleteContainer: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  deleteIcon: {
    color: 'black',
  },
}));
