import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';
import moment from 'moment';
import { getMassLeadGraphData } from './util/api.util';
import { InsightSession } from 'src/app/modules/Insight/types/types';
import { ChartType, TimeRange } from 'src/app/common/components/charts/chart-button/chart-button.component';
import { formatLabel } from 'src/app/common/components/charts/util/format-response.util';
import { globalStore } from 'src/app/common/helpers/global-store.util';

export const useLeadGraph = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentAgentCode =
    sessionStorage.getItem(InsightSession.InsightSelectedAgentCode) || globalStore.getAgentCode();
  const [startDate, endDate] = useMemo(() => {
    const startDate = new Date(2020, 1, 1).toISOString();
    const endDate = moment().add(1, 'month').toDate().toISOString();
    return [startDate, endDate];
  }, []);

  const [chartType, setChartType] = useState(ChartType.area);
  const [timeRange, setTimeRange] = useState(TimeRange.day);

  const formatter = useCallback(
    (val: any) => {
      if (!val) return '';
      return formatLabel(val, timeRange);
    },
    [timeRange],
  );

  const { data: dayData = [] } = useRequest(
    () =>
      getMassLeadGraphData(
        {
          agentCode: currentAgentCode ?? '',
          dateType: 'day',
          chartType: 'line',
          startDate,
        },
        dispatch,
      ),
    {
      manual: false,
    },
  );

  const { data: weekData = [] } = useRequest(
    () =>
      getMassLeadGraphData(
        {
          agentCode: currentAgentCode ?? '',
          dateType: 'week',
          chartType: 'line',
          startDate,
        },
        dispatch,
      ),
    {
      manual: false,
    },
  );

  const { data: monthData = [] } = useRequest(
    () =>
      getMassLeadGraphData(
        {
          agentCode: currentAgentCode ?? '',
          dateType: 'month',
          chartType: 'line',
          startDate,
        },
        dispatch,
      ),
    {
      manual: false,
    },
  );

  const highlightX = useMemo(() => {
    const today = moment();
    switch (timeRange) {
      case TimeRange.day:
        return [dayData.findIndex((d) => moment(d?.x?.[0]).isSame(today, 'day'))];
      case TimeRange.week:
        return [weekData.findIndex((d) => today.isBetween(moment(d?.x?.[0]), moment(d?.x?.[1])))];
      case TimeRange.month:
        return [monthData.findIndex((d) => moment(d?.x?.[0]).isSame(today, 'month'))];
    }
  }, [dayData, timeRange]);

  const chartData = useMemo(() => {
    let data;
    switch (timeRange) {
      case TimeRange.day:
        data = dayData;
        break;
      case TimeRange.week:
        data = weekData;
        break;
      case TimeRange.month:
        data = monthData;
        break;
    }
    return [
      {
        name: t('mass_lead_accepted'), // todo
        data,
      },
    ];
  }, [dayData, monthData, timeRange, weekData]);

  return { chartData, highlightX, timeRange, chartType, formatter, setChartType, setTimeRange };
};
