import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Agent, AttachmentDef } from 'src/app/common/types';
import { AddTargetAgentsEnum } from 'src/app/modules/event-v2/types';
import { fetchEligibleAgentList } from 'src/app/modules/event-v2/network';

type OnChangeProps = {
  agentCodeList?: string[];
  agentListFile?: AttachmentDef;
};

export type HookProps = {
  initialAgentCodeList: string[];
  onChange: (props: OnChangeProps) => void;
};

type RemoveAgentDialogState = {
  open: boolean;
  agentCodeList: string[];
  onResetRowSelected?: () => void;
};

const initialDialogState: RemoveAgentDialogState = {
  open: false,
  agentCodeList: [],
};

export const useAddAgents = ({ initialAgentCodeList, onChange }: HookProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSelectOptionDialog, setShowSelectOptionDialog] = useState<boolean>(false);
  const [showAddAgentDialog, setShowAddAgentDialog] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<AddTargetAgentsEnum>(AddTargetAgentsEnum.ADD_FROM_LIST);
  const [agentList, setAgentList] = useState<Agent[]>([]);
  const [removeAgentDialogState, setRemoveAgentDialogState] = useState<RemoveAgentDialogState>(initialDialogState);
  const initialRender = useRef(true);

  const reloadAgentList = async (agentCodeList: string[]) => {
    setIsLoading(true);
    try {
      if (agentCodeList.length > 0) {
        const res = await fetchEligibleAgentList({ agentCodes: agentCodeList }, dispatch);
        setAgentList(res.docs);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      reloadAgentList(initialAgentCodeList);
      initialRender.current = false;
    }
  }, [initialAgentCodeList]);

  const handleRemoveAgent = () => {
    const newAgentList = agentList.filter((agent) => !removeAgentDialogState.agentCodeList.includes(agent.agentCode));
    setAgentList(newAgentList);
    onChange({ agentCodeList: newAgentList.map((agent) => agent.agentCode) });
    if (removeAgentDialogState.onResetRowSelected) {
      removeAgentDialogState.onResetRowSelected();
    }
    setRemoveAgentDialogState(initialDialogState);
  };

  const updateAgentCodeList = (agentCodeList: string[]) => {
    const oldAgentCodeList = agentList.map((agent) => agent.agentCode);
    const newAgentCodeList = [...new Set([...oldAgentCodeList, ...agentCodeList])];
    return newAgentCodeList;
  };

  const handleAddAgent = (agentCodeList: string[]) => {
    const newAgentCodeList = updateAgentCodeList(agentCodeList);
    onChange({ agentCodeList: newAgentCodeList });
    reloadAgentList(newAgentCodeList);
  };

  const handleImportAgent = (agentCodeList: string[], agentListFile: AttachmentDef) => {
    const newAgentCodeList = updateAgentCodeList(agentCodeList);
    onChange({ agentCodeList: newAgentCodeList, agentListFile });
    reloadAgentList(newAgentCodeList);
  };

  return {
    isLoading,
    showSelectOptionDialog,
    showAddAgentDialog,
    selectedOption,
    agentList,
    removeAgentDialogState,
    setShowSelectOptionDialog,
    setShowAddAgentDialog,
    setSelectedOption,
    setRemoveAgentDialogState,
    handleRemoveAgent,
    handleAddAgent,
    handleImportAgent,
  };
};
