import { useEffect, useReducer, useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { regionLocale, initI18nGenericData } from 'src/app/i18n';
import { useErrorHandler, ErrorFieldType, ErrorFieldDef } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { StepStatusEnum, ComponentProps, useUpdateChecker } from 'src/app/common/components/pru-stepped-form-agent';
import {
  RegistrationMethodEnum,
  RegistrationNotificationMethodEnum,
  RegistrationAttendanceRuleEnum,
  EventFormCommonProps,
  EventItem,
} from 'src/app/modules/event-v2/types';
import { CreateUpdateEventBody, modifyEventItem } from 'src/app/modules/event-v2/network';
import { registrationAttendanceFormReducer, RegistrationAttendanceFormState } from '../../../reducers';
import { checkInvalidQuotaFormat, stripHtmlTags } from '../../../common';
import { getDefaultRegFormBody } from '../registration-form/registration-form.utils';
import { getDefaultWalkInFormBody } from '../walk-in-form/walk-in-form.utils';

type HookProps = ComponentProps<EventFormCommonProps>;

const detailToStateConvertor = (eventItem?: EventItem): RegistrationAttendanceFormState => {
  const initI18nStringData = initI18nGenericData<string>(regionLocale, '');
  const initI18nBody = initI18nGenericData<string>(
    regionLocale,
    'Dear {firstName}, {lastName}, your registration is received successfully. Here is your participation QR code {code}.',
  );
  return eventItem
    ? {
        regQuotaPerAgent: eventItem.regQuotaPerAgent?.toString(),
        regStartDate: eventItem.regStartDate,
        regEndDate: eventItem.regEndDate,
        regLink: eventItem.regLink,
        regNotificationMethod: eventItem.regNotificationMethod,
        regConfirmationBody: eventItem.regConfirmationBody ?? {
          title: initI18nStringData,
          body: initI18nBody,
        },
        regAttendanceRule:
          eventItem.regAttendanceRule ??
          (!eventItem.multiSession ? RegistrationAttendanceRuleEnum.CHECK_IN : undefined),
        regAllowWalkIn: eventItem.regAllowWalkIn,
        sessions: eventItem.multiSession
          ? eventItem.sessions.map((sessionItem) => ({
              ...sessionItem,
              regAttendanceRule:
                sessionItem.regAttendanceRule ??
                (sessionItem.subSessions.length === 0 ? RegistrationAttendanceRuleEnum.CHECK_IN : undefined),
              subSessions: sessionItem.subSessions.map((subSessionItem) => ({
                ...subSessionItem,
                regAttendanceRule: subSessionItem.regAttendanceRule ?? RegistrationAttendanceRuleEnum.CHECK_IN,
              })),
            }))
          : [],
      }
    : {
        regQuotaPerAgent: undefined,
        regStartDate: null,
        regEndDate: null,
        regLink: undefined,
        regNotificationMethod: undefined,
        regConfirmationBody: undefined,
        regAttendanceRule: undefined,
        regAllowWalkIn: undefined,
        sessions: [],
      };
};

export const useRegistrationAttendanceForm = ({
  formCommonProps,
  onStepChange,
  componentUpdateStepStatus,
  setDiscardChanges,
}: HookProps) => {
  const dispatch = useDispatch();
  const { eventItem, isReset, setChangesUnsaved, setIsReset, reloadData } = formCommonProps;
  const initialState = useMemo(() => detailToStateConvertor(eventItem), [eventItem]);
  const [formState, formDispatch] = useReducer(registrationAttendanceFormReducer, initialState);
  const initI18nStringData = initI18nGenericData<string>(regionLocale, '');

  const { errorState, onSubmitErrorValidator, immediateErrorValidator, onDismissErrorHandler, resetErrorState } =
    useErrorHandler(formState, [
      {
        name: 'regQuotaPerAgentFormat',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => checkInvalidQuotaFormat(formState.regQuotaPerAgent, true),
      },
      {
        name: 'regStartDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'regEndDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endDateBeforeStartDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.regStartDate && formState.regEndDate) {
            return !!moment(new Date(formState.regStartDate)).isSameOrAfter(moment(new Date(formState.regEndDate)));
          } else {
            return false;
          }
        },
      },
      // Template Mandatory
      {
        name: 'regNotificationMethod',
        fieldType: ErrorFieldType.MANDATORY,
      },
      ...(() => {
        let errorFieldDef: ErrorFieldDef[] = [];
        // Template Mandatory
        regionLocale.forEach((locale) => {
          errorFieldDef = [
            ...errorFieldDef,
            {
              name: `regConfirmationBody-title-${locale}`,
              fieldType: ErrorFieldType.MANDATORY,
              condition: () =>
                formState.regNotificationMethod === RegistrationNotificationMethodEnum.EMAIL &&
                !!!formState.regConfirmationBody?.title?.[locale],
            },
            {
              name: `regConfirmationBody-body-${locale}`,
              fieldType: ErrorFieldType.MANDATORY,
              condition: () => !!!formState.regConfirmationBody?.body?.[locale],
            },
          ];
        });
        // Attendance
        if (eventItem?.multiSession === false) {
          errorFieldDef = [
            ...errorFieldDef,
            {
              name: 'regAllowWalkIn',
              fieldType: ErrorFieldType.MANDATORY,
            },
          ];
        } else if (eventItem?.multiSession === true) {
          formState.sessions.forEach((session) => {
            if (session.multiSubSession === false) {
              errorFieldDef = [
                ...errorFieldDef,
                {
                  name: `${session.key}-regAllowWalkIn`,
                  fieldType: ErrorFieldType.MANDATORY,
                  condition: () => session.regAllowWalkIn === undefined,
                },
              ];
            } else {
              session.subSessions.forEach((subSession) => {
                errorFieldDef = [
                  ...errorFieldDef,
                  {
                    name: `${subSession.key}-regAllowWalkIn`,
                    fieldType: ErrorFieldType.MANDATORY,
                    condition: () => subSession.regAllowWalkIn === undefined,
                  },
                ];
              });
            }
          });
        }
        return errorFieldDef;
      })(),
    ]);

  useEffect(() => {
    const dismissFields: string[] = [];
    regionLocale.forEach((locale) => {
      if (formState.regConfirmationBody?.body[locale]) {
        dismissFields.push(`regConfirmationBody-body-${locale}`);
      }
    });
    onDismissErrorHandler(dismissFields, true);
  }, [formState.regConfirmationBody?.body, regionLocale]);

  const resetFormState = () => {
    formDispatch({
      type: 'SET_FORM_STATE',
      payload: {
        value: initialState,
      },
    });
    resetErrorState();
    componentUpdateStepStatus({
      newStatus: eventItem?.regStartDate ? StepStatusEnum.FINISHED : StepStatusEnum.PROGRESS,
      saveChanges: true,
      forceUpdateStatus: true,
    });
    setIsReset(true);
  };

  useEffect(() => {
    setDiscardChanges(resetFormState);
    return () => {
      setDiscardChanges(undefined);
    };
  }, []);

  useEffect(() => {
    immediateErrorValidator();
  }, [formState.regQuotaPerAgent, formState.regStartDate, formState.regEndDate, formState.regLink]);

  useUpdateChecker({ formState, isReset, setChangesUnsaved, setIsReset });

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const walkInAllowed =
        formState.regAllowWalkIn ||
        formState.sessions.some(
          (session) => session.regAllowWalkIn || session.subSessions.some((subSession) => subSession.regAllowWalkIn),
        );
      const body: CreateUpdateEventBody = {
        regMethod: RegistrationMethodEnum.TEMPLATE,
        regQuotaPerAgent: formState.regQuotaPerAgent ? parseInt(formState.regQuotaPerAgent) : null,
        regStartDate: formState.regStartDate,
        regEndDate: formState.regEndDate,
        regLink: formState.regLink,
        regNotificationMethod: formState.regNotificationMethod,
        regConfirmationBody:
          formState.regNotificationMethod === RegistrationNotificationMethodEnum.SMS
            ? {
                title: initI18nStringData,
                body: regionLocale.reduce(
                  (acc, locale) => ({ ...acc, [locale]: stripHtmlTags(formState.regConfirmationBody?.body[locale]) }),
                  {},
                ),
              }
            : formState.regConfirmationBody,
        regAttendanceRule: formState.regAttendanceRule,
        regAllowWalkIn: formState.regAllowWalkIn,
        regFormBody: eventItem
          ? getDefaultRegFormBody({ ...eventItem, regNotificationMethod: formState.regNotificationMethod }, 'contact')
          : undefined,
        walkInFormBody:
          eventItem && walkInAllowed
            ? getDefaultWalkInFormBody({ ...eventItem, regNotificationMethod: formState.regNotificationMethod })
            : undefined,
        sessions: formState.sessions,
      };
      try {
        if (eventItem) {
          await modifyEventItem(eventItem._id, body, dispatch);
          await reloadData();
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Data saved successfully',
              },
            ]),
          );
          onStepChange({
            newActiveStep: { stepIndex: 3 },
            currentStepStatus: StepStatusEnum.FINISHED,
            forceNavigation: true,
            saveChanges: true,
          });
        }
      } catch (err) {}
    } else {
      componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING });
    }
  };

  return {
    formState,
    errorState,
    initI18nStringData,
    formDispatch,
    onDismissErrorHandler,
    onSubmit,
  };
};
