import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import {
  deleteEventAssistantItem,
  fetchEventAssistantList,
} from 'src/app/modules/event-v2/network/event-assistant-crud';
import { EventAssistantDialogState, EventAssistantItem, SendLinkDialogState } from 'src/app/modules/event-v2/types';
import { ActionType } from 'src/app/common/components/ProTable';

interface HookProps {
  eventId: string;
  // other params
}

const initialSendLinkDialogState = {
  open: false,
  selectedRows: [],
};

export const useEventAssistantList = ({ eventId }: HookProps) => {
  const actionRef = useRef<ActionType>();
  const [eventAssistantDialogState, setEventAssistantDialogState] = useState<EventAssistantDialogState>({
    open: false,
  });
  const [sendLinkDialogState, setSendLinkDialogState] = useState<SendLinkDialogState>(initialSendLinkDialogState);

  // redux
  const dispatch = useDispatch();

  const getData = useCallback(async (params: { page: number; pageSize: number }) => {
    const { page, pageSize } = params;
    const res = await fetchEventAssistantList(eventId, { page: page + 1, limit: pageSize });
    if (res) {
      const { docs, totalDocs } = res;
      return {
        success: true,
        data: docs,
        total: totalDocs ?? 0,
      };
    }
    return {
      success: true,
      data: [],
      total: 0,
    };
  }, []);

  const onOpenEventAssistantDialog = useCallback((data?: EventAssistantItem) => {
    setEventAssistantDialogState({ open: true, eventAssistantItem: data });
  }, []);

  const onCloseEventAssistantDialog = useCallback(() => {
    setEventAssistantDialogState({ open: false });
  }, []);

  const onRefresh = useCallback(() => {
    actionRef?.current?.reload();
  }, []);

  const onRemove = useCallback(
    async (id: string) => {
      if (eventId) {
        try {
          await deleteEventAssistantItem(eventId, id, dispatch);
          actionRef?.current?.reload();
          setSendLinkDialogState((prev) => ({
            ...prev,
            selectedRows: prev.selectedRows.filter((row) => row._id !== id),
          }));
          actionRef?.current?.setSelectedRow?.((prev: any[]) => prev?.filter((row) => row._id !== id));
        } catch (err) {}
      }
    },
    [dispatch, eventId],
  );

  const resetState = useCallback(() => {
    setSendLinkDialogState(initialSendLinkDialogState);
    actionRef.current?.clearSelected?.();
  }, []);

  return {
    getData,
    eventAssistantDialogState,
    setEventAssistantDialogState,
    onOpenEventAssistantDialog,
    onCloseEventAssistantDialog,
    actionRef,
    onRefresh,
    onRemove,
    sendLinkDialogState,
    setSendLinkDialogState,
    resetState,
  };
};
