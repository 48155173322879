import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useLang } from 'src/app/i18n';
import { Tooltip } from '@mui/material';
import { EnrollmentQuotaItem } from 'src/app/modules/event-v2/types';
import { sessionKeyToDisplayName } from 'src/app/modules/event-v2/pages/event-list/form/common';
import { useStyles } from './enroll-quota-field.style';

type EnrollQuotaFieldProps = {
  enrollmentAndQuota?: EnrollmentQuotaItem[];
};

export const EnrollQuotaField: FC<EnrollQuotaFieldProps> = ({ enrollmentAndQuota }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();
  const { classes } = useStyles();

  return (
    <>
      {enrollmentAndQuota ? (
        <div style={{ display: 'inline-block' }}>
          {enrollmentAndQuota.map((item, index) => {
            const { sessionKey, sessionName, registerNumber } = item;
            const quota = item.quota === 'NA' ? 'Unlimited' : item.quota;
            const enrollQuotaValue = `${registerNumber}/ ${quota}`;
            const sessionKeyDisplayName = sessionKey !== 'EVENT' ? sessionKeyToDisplayName(sessionKey) : '';
            return (
              <div key={`enroll-quota-${index}`}>
                {sessionKey !== 'EVENT' && (
                  <Tooltip
                    arrow
                    placement="bottom"
                    title={Translation('event.common.enrolled_quota.session_tooltip', {
                      sessionKey: sessionKeyDisplayName,
                      sessionName: sessionName?.[locale] || '',
                    })}
                    PopperProps={{
                      popperOptions: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <span className={classes.session}>[{sessionKeyDisplayName}]</span>
                  </Tooltip>
                )}
                <span className={classes.enrollQuotaValue}>{enrollQuotaValue}</span>
              </div>
            );
          })}
        </div>
      ) : (
        '-'
      )}
    </>
  );
};
