import { makeStyles } from 'tss-react/mui';

const DIALOG_WIDTH = 1000;

export const useStyles = makeStyles()(() => ({
  dialogPaper: {
    width: DIALOG_WIDTH,
    maxWidth: DIALOG_WIDTH,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 24,
  },
  remarkMargin: {
    marginTop: 8,
  },
  addSessionButton: {
    width: '100%',
    padding: 16,
    border: '1px dashed #CCCCCC',
    fontSize: 16,
    columnGap: 8,
  },
}));
