import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useProspect } from './prospect.hook';
import { InsightSectionComponent } from '../../../components/insight-section/insight-section.component';

type ComponentProps = ParamsProps & {
  agentCode?: string;
};

export const ProspectComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();

  // custom-hook
  const { data, tooltip, handlePress } = useProspect({
    agentCode: props.agentCode,
  });
  return <InsightSectionComponent onPress={handlePress} title={t('prospect')} data={data} tooltip={tooltip} />;
});
