import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Autocomplete, Box, Button, Checkbox, Grid, MenuItem, TextField, Typography } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './export-report.module.css';
import { useExportReport } from './export-report.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { Card } from 'src/app/common/components/card/card.component';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLang } from 'src/app/i18n';
import { getAgentName } from 'src/app/common/utils/i18n.util';

import { makeStyles } from 'tss-react/mui';
import dayjs from 'dayjs';
import ProFilterTable, { CheckType } from 'src/app/common/components/ProTable';
import { ListFilterComponent } from 'src/app/common/components/list-filter/list-filter.component';
import { DateFormat } from 'src/app/common/utils';
import PruDialog from 'src/app/common/components/PruDialog';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const ExportReportPage: React.FC<PageProps> = (props: PageProps) => {
  const { t } = useTranslation();
  const locale = useLang();

  const useStyles = makeStyles()(() => ({
    root: {
      '& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root': {
        flexWrap: 'nowrap',
        overflow: 'hidden',
      },
    },
  }));

  const classes = useStyles();

  // custom-hook
  const {
    form,
    startDate,
    endDate,
    designation,
    selectedDesignation,
    agents,
    selectedAgents,
    displayAgentName,
    restCount,
    allAgentOption,
    columns,
    rowSelection,
    defaultSelection,
    showFilter,
    filterValues,
    filterConfig,
    disableSubmit,
    showDialog,
    isManager,
    defaultFilterValues,
    setShowDialog,
    setShowFilter,
    getData,
    onSelectedAgents,
    onSelectAllAgents,
    onSelectedDesignation,
    onChangeDate,
    onInputChanged,
    filterOptions,
    onFilterChanged,
    checkForm,
    submit,
  } = useExportReport();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={t('export_report')}
        rightNode={
          <div>
            <Button
              variant="outlined"
              className={styles.backButton}
              onClick={() => {
                props.history.goBack();
              }}
            >
              {t('app.button.back')}
            </Button>
            <Button variant="contained" color="secondary" onClick={checkForm} disabled={disableSubmit}>
              {t('app.button.confirm')}
            </Button>
          </div>
        }
      />
      <Card className={styles.cardWrap}>
        <Typography className={styles.title}>{t('report_information')}</Typography>

        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              label={t('report_name')}
              variant="outlined"
              defaultValue={form.name}
              required
              className={styles.input}
              size="medium"
              fullWidth
              onChange={(e) => {
                onInputChanged('name', e.target.value);
              }}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={`${t('email')}(${t('to_receive_report')})`}
              variant="outlined"
              required
              className={styles.input}
              defaultValue={form.email}
              size="medium"
              fullWidth
              onChange={(e) => {
                onInputChanged('email', e.target.value);
              }}
            ></TextField>
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              label={t('insights_filter_start_date')}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  size: 'medium',
                  fullWidth: true,
                },
              }}
              onChange={(value: dayjs.Dayjs | null) => {
                onChangeDate(value, 'startDate');
              }}
              defaultValue={dayjs(startDate)}
              format={DateFormat.date.toUpperCase()}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label={t('insights_filter_end_date')}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  size: 'medium',
                  fullWidth: true,
                },
              }}
              onChange={(value: dayjs.Dayjs | null) => {
                onChangeDate(value, 'endDate');
              }}
              defaultValue={dayjs(endDate)}
              format={DateFormat.date.toUpperCase()}
            />
          </Grid>

          {isManager && (
            <>
              <Grid item xs={6}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label={t('export_report_division')}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  key={selectedDesignation}
                  defaultValue={selectedDesignation}
                  SelectProps={{
                    size: 'medium',
                  }}
                  className={styles.input}
                  onChange={(e) => {
                    onSelectedDesignation(e.target.value as any);
                  }}
                >
                  {designation?.map((item, index) => (
                    <MenuItem key={`${item.groupCode}_${index}`} value={item.groupCode}>
                      {item.groupCode}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.classes.root}>
                  <Autocomplete
                    multiple
                    fullWidth
                    id="checkboxes-tags-demo"
                    options={agents}
                    onChange={(e, value) => {
                      onSelectedAgents(value.map((item) => item.agentCode ?? item));
                    }}
                    value={selectedAgents}
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => getAgentName(option.agentName, locale)}
                    filterOptions={filterOptions}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        {option.agentCode !== allAgentOption.agentCode ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox checked={selected} />
                            <Box sx={{ marginLeft: '12px' }}>
                              <Typography fontSize={16}>{getAgentName(option.agentName, locale)}</Typography>
                              <Typography fontSize={12} color={'#999999'}>
                                {option.agentCode}
                              </Typography>
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              checked={selected}
                              onChange={() => {
                                onSelectAllAgents(selected);
                              }}
                            />
                            <Box sx={{ marginLeft: '12px' }}>
                              <Typography fontSize={16}>{allAgentOption.label}</Typography>
                            </Box>
                          </Box>
                        )}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label={t('agent')} variant="outlined" size="medium" />
                    )}
                    renderTags={(value, getTagProps) => (
                      <>
                        <Typography noWrap textOverflow={'ellipsis'}>
                          {displayAgentName.toString()}
                        </Typography>
                        {restCount > 0 ? `+${restCount}` : ''}
                      </>
                    )}
                    isOptionEqualToValue={(option: any, value: any) => {
                      return option.agentCode === value;
                    }}
                  />
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Card>

      <Card className={styles.sectionCardWrap}>
        <Box display={'flex'} justifyContent={'space-between'} margin={'24px'}>
          <Typography fontSize={16} fontWeight={600}>
            {t('select_section')}
          </Typography>
          <Button variant="outlined" onClick={() => setShowFilter(true)} sx={{ borderColor: '#cccccc' }}>
            {t('export_setting')}
          </Button>
        </Box>
        <ProFilterTable
          showFilterIcon={false}
          showFilter={false}
          rowKey="value"
          columns={columns}
          request={getData}
          enableRefresh={false}
          hidePagination={true}
          checkType={CheckType.checkbox}
          rowSelection={rowSelection}
          defaultSelectedRows={defaultSelection}
        />
        {showFilter ? (
          <ListFilterComponent
            open={showFilter}
            filterValues={filterValues}
            filterConfigs={filterConfig}
            onClose={() => setShowFilter(false)}
            onConfirm={onFilterChanged}
            defaultValues={defaultFilterValues}
          />
        ) : null}

        <PruDialog
          open={showDialog}
          onOk={submit}
          onCancel={() => setShowDialog(false)}
          dialogTitle={t('are_you_sure_sending_report_to_below_address')}
        >
          <Typography>{form.email}</Typography>
        </PruDialog>
      </Card>
    </Page>
  );
};
