import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { ActionNodeTypeProps } from 'src/app/common/components/ProTable';
import { Lead } from 'src/app/modules/Leads/types/types';
import { batchAccept, rejectOpportunities } from '../../../../../util/api.util';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  theseLeadAreGettingColdGaDataClick,
  theseLeadAreGettingColdGaDataClick1,
} from 'src/app/common/ga/types/ga-click-lead';

export type ActionNodeProps = ActionNodeTypeProps<Lead>;

export const useActionItems = ({ selectedRows, onAction }: ActionNodeProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [acceptLoading, setAcceptLoading] = useState(false);

  const [toReject, setToReject] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const accept = async () => {
    if (acceptLoading) return;
    setAcceptLoading(true);
    await batchAccept(
      selectedRows.map((i) => i._id),
      dispatch,
      t,
    );
    setAcceptLoading(false);
    onAction(['clearRow', 'refresh']);
    takeUIClickEvent(theseLeadAreGettingColdGaDataClick);
  };

  const reject = async (rejectReason: string[]) => {
    if (rejectLoading) return;
    setRejectLoading(true);
    await rejectOpportunities(
      selectedRows.map((i) => i._id),
      rejectReason,
      dispatch,
      t,
    );
    setRejectLoading(false);
    onAction(['clearRow', 'refresh']);
    takeUIClickEvent(theseLeadAreGettingColdGaDataClick1);
  };

  return {
    t,
    acceptLoading,
    accept,
    toReject,
    setToReject,
    reject,
    rejectLoading,
    setAcceptLoading,
    setRejectLoading,
  };
};
