import { map } from 'lodash';
import { FilterComponentsEnum, ListFilterConfig } from 'src/app/common/components/list-filter';
import {
  AttendanceStatusEnum,
  ParticipantContactEnum,
  ParticipantItem,
  ParticipantRoleEnum,
} from 'src/app/modules/event-v2/types';
import { renderAttendanceTag, renderOperation } from '../participant-list/participant-list.component';
import { ProColumns } from 'src/app/common/components/ProTable';
import { renderValue } from 'src/app/modules/event-v2/util/event-v2.util';
import { getDefaultDisplayDate } from 'src/app/common/utils';

export const RADIO_ALL = 'All';

export const getFilterConfig = ({
  t,
  isWalkInList,
}: {
  t: (id: string) => string;
  isWalkInList?: boolean;
}): Array<ListFilterConfig> => {
  const walkInFilterConfig: ListFilterConfig[] = [
    {
      key: 'firstName',
      title: t('event.form.name'),
      type: FilterComponentsEnum.INPUT,
      items: [],
      inputLabel: t('event.form.first_name'),
    },
    {
      key: 'lastName',
      title: '',
      type: FilterComponentsEnum.INPUT,
      items: [],
      inputLabel: t('event.form.last_name'),
    },
    {
      key: 'role',
      title: t('event.form.role'),
      type: FilterComponentsEnum.PURE_RADIO_GROUP,
      items: [
        {
          itemKey: RADIO_ALL,
          itemLabel: t('component.status.all'),
        },
        ...map(ParticipantRoleEnum, (option) => ({
          itemKey: option,
          itemLabel: t(`event.form.role.${option.toLowerCase()}`),
        })),
      ],
    },
    {
      key: 'referer',
      title: t('event.form.referred_agent_code'),
      type: FilterComponentsEnum.INPUT,
      items: [],
    },
    {
      key: 'contact',
      title: t('event.form.contact'),
      type: FilterComponentsEnum.CHECK_BOX_GROUP,
      items: map(ParticipantContactEnum, (option) => ({
        itemKey: option,
        itemLabel: t(`event.form.contact.${option.toLowerCase()}`),
      })),
    },
  ];
  if (!isWalkInList) {
    walkInFilterConfig.push(
      {
        key: 'registrationTime',
        title: t('event.form.registration_time'),
        type: FilterComponentsEnum.DATE_TIME_RANGE_PICKER,
        items: [],
      },
      {
        key: 'qrCode',
        title: t('event.form.participation_qr_code'),
        type: FilterComponentsEnum.INPUT,
        items: [],
      },
      {
        key: 'requestAttendanceStatus',
        title: t('event.form.attendance_status'),
        type: FilterComponentsEnum.PURE_RADIO_GROUP,
        items: [
          {
            itemKey: RADIO_ALL,
            itemLabel: t('component.status.all'),
          },
          ...map(AttendanceStatusEnum, (option) => ({
            itemKey: option,
            itemLabel: t(`event.form.attendance_status.${option.toLowerCase()}`),
          })),
        ],
      },
    );
  }
  return walkInFilterConfig;
};

export const getColumns = ({
  t,
  isWalkInList,
  hideAttendanceStatus,
  actions,
}: {
  t: (key: string) => string;
  isWalkInList?: boolean;
  hideAttendanceStatus: boolean;
  actions: {
    onClickAttendance: (id: string) => void;
    onClickRemove: (id: string) => void;
    disableRemoveAction?: boolean;
  };
}): ProColumns<ParticipantItem>[] => {
  // operation item
  const operationColumn: ProColumns<ParticipantItem> = {
    title: t('section.common.operation'),
    sticky: true,
    dataIndex: 'operation',
    width: '290px',
    render: (currValue: string, item: ParticipantItem) => renderOperation({ t, item, isWalkInList, actions }),
  };

  // walk in list
  const commonColumns: ProColumns<ParticipantItem>[] = [
    {
      title: t('event.form.first_name'),
      dataIndex: 'firstName',
      width: '176px',
      render: renderValue,
    },
    {
      title: t('event.form.last_name'),
      dataIndex: 'lastName',
      width: '176px',
      render: renderValue,
    },
    {
      title: t('event.form.role'),
      dataIndex: 'role',
      width: '176px',
      render: (curValue: string, row: any) =>
        curValue
          ? curValue === ParticipantRoleEnum.AGENT
            ? `${t(`event.form.role.${curValue.toLowerCase()}`)} (${row.agentCode || '-'})`
            : t(`event.form.role.${curValue.toLowerCase()}`)
          : '-',
    },
    {
      title: t('event.form.referred_agent'),
      dataIndex: 'referer',
      width: '176px',
      render: renderValue,
    },
    {
      title: t('event.form.contact'),
      dataIndex: 'contact',
      width: '176px',
      render: (curValue: string) => renderValue(t(`event.form.contact.${curValue.toLowerCase()}`)),
    },
    {
      title: isWalkInList ? t('event.form.form_submission_time') : t('event.form.registration_time'),
      dataIndex: 'registrationTime',
      width: '176px',
      render: (curValue: Date) => getDefaultDisplayDate(curValue, 'datetime'),
    },
    {
      title: t('event.form.participation_qr_code'),
      dataIndex: 'qrCode',
      width: '176px',
      render: renderValue,
    },
  ];

  if (!isWalkInList) {
    // RSVP list
    commonColumns.push(
      {
        title: t('event.form.created_via'),
        dataIndex: 'createdFrom',
        width: '176px',
        render: (curValue: string) => renderValue(t(`event.form.created_via.${curValue.toLowerCase()}`)),
      },
      {
        title: t('event.common.created_by'),
        dataIndex: 'createdBy',
        width: '176px',
        render: renderValue,
      },
      {
        title: t('event.common.last_updated_by'),
        dataIndex: 'lastUpdatedBy',
        width: '176px',
        render: renderValue,
      },
      {
        title: t('component.formLabel.last-updated-time'),
        dataIndex: 'updatedAt',
        width: '176px',
        render: (curValue: Date) => getDefaultDisplayDate(curValue, 'datetime'),
      },
    );

    if (!hideAttendanceStatus) {
      commonColumns.push({
        title: t('event.form.attendance_status'),
        dataIndex: 'attendanceStatus',
        width: '176px',
        render: (curValue: AttendanceStatusEnum) => renderAttendanceTag(curValue, t),
      });
    }
  }

  commonColumns.push(operationColumn);
  return commonColumns;
};

export const formatParticipantFilter = (filterState: Record<string, any>) => {
  const { page, pageSize, role, requestAttendanceStatus, registrationTime, ...others } = filterState;
  return {
    page: page + 1,
    limit: pageSize,
    role: role === RADIO_ALL ? undefined : role,
    requestAttendanceStatus: requestAttendanceStatus === RADIO_ALL ? undefined : requestAttendanceStatus,
    regStartDate: registrationTime?.startTime,
    regEndDate: registrationTime?.endTime,
    ...others,
  };
};

export const getTagConfig = (t: (key: string) => string) => {
  return {
    [AttendanceStatusEnum.ATTENDED]: {
      color: '#24C791',
      bgColor: '#DFF7EF',
      text: t('event.form.attendance_status.attended'),
    },
    [AttendanceStatusEnum.NOT_ATTENDED]: {
      color: '#EDA62B',
      bgColor: '#FFF5E0',
      text: t('event.form.attendance_status.not_attended'),
    },
    [AttendanceStatusEnum.CANCELLED]: {
      color: '#333333',
      bgColor: '#F5F5F5',
      text: t('event.form.attendance_status.cancelled'),
    },
  };
};
