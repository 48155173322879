import React from 'react';
import { ProColumns } from 'src/app/common/components/ProTable';
import { AgentItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { HighlightMatchedText } from 'src/app/common/components/highlight-matched-text/highlight-matched-text.component';

export const getColumns = ({
  t,
  keyword,
}: {
  t: (val: string) => string;
  keyword?: string;
}): ProColumns<AgentItem>[] => {
  return [
    {
      title: t('agencyCampaign.common.unit'),
      dataIndex: 'unit',
      sorter: true,
      width: '290px',
      render: (val, row) => renderHighlight(row.unit ? (row.unit as string) : '', keyword),
    },
    {
      title: t('agencyCampaign.common.businessName'),
      dataIndex: 'businessName',
      width: '290px',
      render: (val, row) => renderHighlight(row?.name?.enUs?.displayName ?? '', keyword),
    },
    {
      title: t('agencyCampaign.common.agentCode'),
      dataIndex: 'agentCode',
      sorter: true,
      width: '176px',
      render: (val, row) => renderHighlight(row.agentCode ? (row.agentCode as string) : '-', keyword),
    },
  ];
};

export const renderHighlight = (currValue: string, keyword?: string) => {
  return <HighlightMatchedText highLightText={keyword ?? ''}>{currValue}</HighlightMatchedText>;
};
