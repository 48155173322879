import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { EventPublishStatusEnum, EventItem } from 'src/app/modules/event-v2/types';
import { CreateUpdateEventBody, modifyEventItem, deleteEventItem } from 'src/app/modules/event-v2/network';
import { eventListPath } from '../../../event-list-routes';

export type HookProps = {
  eventItem?: EventItem;
};

export const useTopButton = ({ eventItem }: HookProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDraft = eventItem?.publishStatus === EventPublishStatusEnum.DRAFT;
  const isPublished = eventItem?.publishStatus === EventPublishStatusEnum.PUBLISHED;

  const disableModify = useMemo(() => {
    if (eventItem) {
      return (
        eventItem.publishStatus === EventPublishStatusEnum.UNPUBLISHED ||
        (eventItem.publishStatus === EventPublishStatusEnum.PUBLISHED && moment().isSameOrAfter(eventItem.eventDate))
      );
    }
    return false;
  }, [eventItem?.publishStatus, eventItem?.eventDate]);

  const allowCancel = useMemo(() => {
    if (eventItem && isPublished) {
      return moment().isBefore(eventItem.startTime);
    }
    return false;
  }, [isPublished, eventItem?.startTime]);

  const handleDiscard = () => {
    history.push(eventListPath);
  };

  const handleDelete = async () => {
    try {
      if (eventItem) {
        await deleteEventItem(eventItem._id, dispatch);
        history.push(`${eventListPath}`);
      }
    } catch (err) {}
  };

  const handleUnpublish = async () => {
    try {
      if (eventItem) {
        const body: CreateUpdateEventBody = {
          publishStatus: EventPublishStatusEnum.UNPUBLISHED,
        };
        await modifyEventItem(eventItem._id, body, dispatch);
        history.push(`${eventListPath}`);
      }
    } catch (err) {}
  };

  const handleModify = () => {
    history.replace(`${eventListPath}/edit/${eventItem?._id}`);
  };

  return {
    isDraft,
    isPublished,
    disableModify,
    allowCancel,
    handleDiscard,
    handleDelete,
    handleUnpublish,
    handleModify,
  };
};
