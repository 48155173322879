import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  accordion: {
    width: '100%',
    borderRadius: 8,
    '&.Mui-expanded': {
      margin: '0',
    },
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
  },
  error: {
    border: '1px solid #FF4842',
    borderRadius: 8,
  },
  accordionSummary: {
    fontSize: 18,
    fontWeight: 600,
    padding: '0 24px',
    '&.MuiAccordionSummary-root': {
      cursor: 'default',
      '&:hover': {
        cursor: 'default',
      },
    },
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
    },
  },
  accordionDetail: {
    padding: '0 24px 32px',
  },
  copyButton: {
    color: '#4386E5',
    marginLeft: 20,
    columnGap: 4,
  },
  copyIcon: {
    fontSize: 20,
    height: 20,
    marginTop: -15,
  },
}));
