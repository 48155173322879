import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  pageTitle: {
    marginBottom: 24,
  },
  pageContent: {
    position: 'relative',
  },
  tab: {
    fontSize: 14,
    fontWeight: 500,
  },
}));
