import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Grid } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './lead-detail.module.css';
import { CampaignDetail, useLeadDetail } from './lead-detail.hook';
import { PercentageBarChart } from 'src/app/common/components/charts/percentage-bar-chart/percentage-bar-chart.component';
import { Card } from 'src/app/common/components/card/card.component';
import { TooltipComponent } from 'src/app/common/components/tooltip/tooltip.component';
import moment from 'moment';
import { DateFormat } from 'src/app/common/utils';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
};

const CampaignInfo = (props: { data: CampaignDetail }) => {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <Card style={{ padding: '24px' }}>
      <div>
        <div className={styles.campaignName}>{data.name}</div>
        <div className={styles.campaignInfo}>
          {moment(data.startDate).format(DateFormat.date)}-{moment(data.endDate).format(DateFormat.date)}
        </div>
      </div>
      <div className={styles.sectionBox}>
        <div className={styles.section}>
          <div className={styles.senctionTitle}>{t('leads_received_label')}</div>
          <div className={styles.sectionValue}>{data.detail.total}</div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionDesc}>{t('mass_lead_accepted')}</div>
          <div className={styles.sectionDescValue}>{data.detail.accepted}</div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionDesc}>{t('mass_lead_rejected')}</div>
          <div className={styles.sectionDescValue}>{data.detail.rejected}</div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionDesc}>{t('mass_lead_pending')}</div>
          <div className={styles.sectionDescValue}>{data.detail.other}</div>
        </div>
      </div>
      <div className={styles.sectionBox}>
        <div className={styles.section}>
          <div className={styles.senctionTitle}>{t('accepted_policy_issued')}</div>
          <div className={styles.sectionValue}>{data.detail.AcceptedPolicyIssued}</div>
        </div>
      </div>
      <div className={styles.sectionBox}>
        <div className={styles.section}>
          <div className={styles.senctionTitle}>{t('accepted_cannot_be_converted')}</div>
          <div className={styles.sectionValue}>{data.detail.AcceptedNotConverted}</div>
        </div>
      </div>
    </Card>
  );
};

export const LeadDetail: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();

  // custom-hook
  const { receivedLead, activeCampaign, summary, showBarChart } = useLeadDetail();
  return (
    <>
      <div className={styles.wrap}>
        <Card className={styles.wrapChart}>
          <div className={styles.flexRow}>
            <div className={styles.workingLeads}>
              {t('leads_received_label')}
              <div className={styles.num}>{receivedLead}</div>
            </div>
            <div className={styles.workingLeads}>
              {t('active_campaign_label')}
              <div className={styles.num}>{activeCampaign.length}</div>
            </div>
          </div>

          {showBarChart && <PercentageBarChart data={summary} />}
        </Card>

        <Grid container spacing={2} style={{ marginTop: '1rem' }}>
          {activeCampaign.map((campaign) => {
            return (
              <Grid item xs={6}>
                <CampaignInfo data={campaign} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
});
