import { FC, useState, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Dialog, FormHelperText, IconButton, Toolbar } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { AttachmentDef } from 'src/app/common/types';
import { toAbsoluteUrl, downloadAs } from 'src/app/common/utils';
import PruIcon from '../PruIcon';
import { useStyles } from './file-gallery.style';
import { PdfViewer } from '../pdf-viewer';

type FileItem = AttachmentDef & {
  _id?: string;
};

type FileGalleryProps = {
  disabled?: boolean;
  disablePreviewImage?: boolean;
  disableLightbox?: boolean;
  disableSelect?: boolean;
  error?: boolean;
  files: FileItem[];
  selected?: string;
  onSelect?: (e: ChangeEvent<HTMLInputElement>, file: FileItem) => void;
};

export const FileGallery: FC<FileGalleryProps> = ({
  disabled,
  disablePreviewImage,
  disableLightbox,
  disableSelect,
  error,
  files,
  selected,
  onSelect,
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, any>) => intl.formatMessage({ id }, variable);
  const pdfIcon = toAbsoluteUrl('/media/svg/files/pdf.svg');

  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { classes, cx } = useStyles();

  const handleOpen = (index: number) => {
    setOpen(true);
    setActiveIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    const url = files[activeIndex]?.url;
    if (url) {
      downloadAs(url, files[activeIndex]?.filename);
    }
  };

  const handleClickThumbnail = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <div>
      {!disablePreviewImage ? (
        <div className={classes.previewImageList}>
          {files.map((file, index) => (
            <div
              key={`preview-${index}`}
              className={cx(classes.previewImageContainer, disableLightbox && classes.autoCursor)}
            >
              <img
                loading="lazy"
                className={classes.previewImage}
                src={file.url}
                onClick={() => {
                  handleOpen(index);
                }}
              />
              {!disableSelect && (
                <Checkbox
                  disabled={disabled}
                  className={classes.checkbox}
                  checked={file._id === selected}
                  onChange={(e) => {
                    if (onSelect) {
                      onSelect(e, file);
                    }
                  }}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div
          className={classes.previewButton}
          onClick={() => {
            handleOpen(0);
          }}
        >
          <img className={classes.previewButtonIcon} src={pdfIcon} alt="" />
          <div className={classes.previewButtonText}>
            {Translation('common.click_to_preview_templates', {
              highlightedText: (
                <span className={classes.previewButtonTextHighlight}>
                  {Translation('global.text.preview').toLowerCase()}
                </span>
              ),
            })}
          </div>
        </div>
      )}
      {error && (
        <FormHelperText error={error} className={classes.errorText}>
          {MANDATORY_FIELD_ERROR_TEXT}
        </FormHelperText>
      )}
      {!disableLightbox && (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <Toolbar className={classes.topBar}>
            <IconButton className={classes.iconButton} onClick={handleDownload}>
              <PruIcon icon="download" customClasses={classes.icon} />
            </IconButton>
            <IconButton className={classes.iconButton} onClick={handleClose}>
              <Cancel className={classes.icon} />
            </IconButton>
          </Toolbar>
          <div className={classes.fileContainer}>
            {files[activeIndex].type === 'application/pdf' ? (
              <PdfViewer file={files[activeIndex]?.url} pageNumber={1} />
            ) : (
              <img className={classes.image} src={files[activeIndex]?.url} alt="" />
            )}
          </div>
          <Toolbar className={classes.bottomBar}>
            {files.map((file, index) => {
              const thumbnail = file.type === 'application/pdf' ? pdfIcon : file.url;
              return (
                <div
                  key={`thumbnail-${index}`}
                  className={classes.thumbnailContainer}
                  onClick={() => handleClickThumbnail(index)}
                >
                  <img loading="lazy" className={classes.thumbnail} src={thumbnail} alt="" />
                  <div className={cx(classes.thumbnailIndex, index === activeIndex && classes.activeIndex)}>
                    {index + 1}
                  </div>
                </div>
              );
            })}
          </Toolbar>
        </Dialog>
      )}
    </div>
  );
};
