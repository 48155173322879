import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  addNewButton: {
    columnGap: 8,
  },
  noRecordContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    marginBottom: 24,
  },
  noRecordText: {
    fontSize: 24,
    fontWeight: 600,
  },
  noRecordDetailText: {
    fontSize: 16,
    fontWeight: 400,
    color: '#999999',
  },
}));
