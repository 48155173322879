import React, { useMemo } from 'react';
import { RANG, useChartPagination } from '../hook/chart-pagination.hook';

export interface AreaChartHookProps {
  labelsFormatter?: (val: string) => string;
  labelColor?: string;
  highlightColor?: string;
  contentColors?: { color: string; highlightColor: string }[];
  highlightX?: number[];
  series: {
    name: string;
    data: {
      x: any;
      y: string | number;
    }[];
  }[];
}

const DEFAULT_BAR_COLOR = [{ color: '#ED1B2E', highlightColor: 'rgba(237, 27, 46, 0.1)' }];
const DEFAULT_DOUBLE_BAR_COLOR = [
  {
    color: '#FF872E',
    highlightColor: 'rgba(255, 135, 46, 0.5)',
  },
  {
    color: '#24C791',
    highlightColor: 'rgba(36, 199, 145, 0.5)',
  },
];

export const useAreaChart = ({
  labelsFormatter,
  labelColor = '#999999',
  highlightColor = '#E8192C',
  contentColors: initContentColors,
  highlightX: initHighlightX,
  series: initSeries,
}: AreaChartHookProps) => {
  const { displaySeries, disablePrevious, disableNext, onPageChange, highlightX } = useChartPagination({
    initSeries,
    initHighlightX,
  });
  const contentColors =
    initContentColors ?? (displaySeries.length === 1 ? DEFAULT_BAR_COLOR : DEFAULT_DOUBLE_BAR_COLOR);

  const options: ApexCharts.ApexOptions = useMemo(() => {
    const data = displaySeries[0].data;
    const colors = contentColors.map(({ color }) => color);
    const gradientColors = contentColors.map(({ highlightColor }) => highlightColor);

    return {
      chart: {
        id: 'area-chart-example',
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        marker: {
          fillColors: contentColors.map(({ color }) => color),
        },
      },
      xaxis: {
        type: 'category',
        // categories: c,
        axisTicks: {
          show: false, // hide ticks in x axis
        },
        tickPlacement: 'on',
        // min: 1,
        // max: 10,
        range: RANG,
        labels: {
          formatter: labelsFormatter,
          style: {
            colors: data.map((_, index) => (highlightX && highlightX.includes(index) ? highlightColor : labelColor)),
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: 'straight',
        colors,
        width: 1.5,
      },
      markers: {
        size: 6, // Set the size of the dots
        colors, // Set the color of the dots
        strokeColors: colors, // Set the color of the dot border, the same as the color of the dot
        strokeWidth: 2, // Set the width of the dot border
        strokeOpacity: 1, // Set the opacity of the dot border
        strokeDashArray: 0, // Set the dotted line style of the dot border
        fillOpacity: 0, // Set the fill opacity of the dot to 0, that is, a hollow dot
        shape: 'circle', // Set the shape of the dot
        radius: 2, // Set the radius of the circle
      },
      fill: {
        type: 'gradient',
        colors,
        gradient: {
          // type: 'vertical',
          // shadeIntensity: 0.1,
          gradientToColors: gradientColors,
        },
      },
    };
  }, [displaySeries, contentColors, labelsFormatter, labelColor, highlightX, highlightColor]);

  const series: ApexAxisChartSeries = useMemo(() => {
    const bColor = contentColors ?? DEFAULT_BAR_COLOR;

    return displaySeries.map(({ name, data }, index) => {
      const { color, highlightColor } = bColor[index] ?? bColor[0];
      return {
        name,
        data: data.map(({ x, y }, i) => ({
          x,
          y,
          fillColor: highlightX && highlightX.includes(i) ? color : highlightColor,
        })),
      };
    });
  }, [contentColors, highlightX, displaySeries]);

  return {
    options,
    series,
    disablePrevious,
    disableNext,
    onPageChange,
  };
};
