import React, { FC, memo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Typography } from '@mui/material';
import { Card } from 'src/app/common/components/card/card.component';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import styles from './lead-graph.module.css';
import { useLeadGraph } from './lead-graph.hook';
import {
  ChartType,
  SwitchButton,
  TimeRangeButton,
} from 'src/app/common/components/charts/chart-button/chart-button.component';
import { AreaChart } from 'src/app/common/components/charts/area-chart/area-chart.component';
import { BarChart } from 'src/app/common/components/charts/bar-chart/bar-chart.component';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
};

export const LeadGraph: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();

  // custom-hook
  const { chartData, highlightX, timeRange, chartType, formatter, setChartType, setTimeRange } = useLeadGraph();

  return (
    <Card style={{ marginTop: '24px' }}>
      <Box margin="24px">
        <Box display="flex" justifyContent={'space-between'} marginBottom={'24px'} color={'#F0F0F0'}>
          <Typography fontSize={'24px'} fontWeight={600} color={'#333333'}>
            {t('mass_lead_accepted')}
          </Typography>

          <Box display="flex" flexDirection={'row'}>
            <Box>
              <SwitchButton value={chartType} onChange={setChartType} />
            </Box>
            <Box marginLeft={'16px'}>
              <TimeRangeButton value={timeRange} onChange={setTimeRange} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box margin="24px">
        {chartType === ChartType.area ? (
          <AreaChart series={chartData} labelsFormatter={formatter} highlightX={highlightX} />
        ) : (
          <BarChart series={chartData} labelsFormatter={formatter} highlightX={highlightX} />
        )}
      </Box>
    </Card>
  );
});
