import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Tabs, Tab, Tooltip } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './detail.style';
import { TabsConfig, useDetail } from './detail.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { TabLeadComponent } from './tab-lead/tab-lead.component';
import { TabApplicationComponent } from './tab-application/tab-application.component';
import { TabApprovalComponent } from './tab-approval/tab-approval.component';
import { TabDocumentComponent } from './tab-document/tab-document.component';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { RsvpStatus } from '../../../types/campaign-types';
import { LoadingButton, Button } from 'src/app/common/components/button/button.component';
import { RsvpReminderComponent } from './rsvp-reminder/rsvp-reminder.component';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  agencyCampaignDetailGaDataClick,
  agencyCampaignDetailGaDataClick64,
  agencyCampaignDetailGaDataClick65,
  agencyCampaignDetailGaDataClick66,
} from 'src/app/common/ga/types/ga-click';

export type LeadTabProps = ParamsProps &
  RouteComponentProps & {
    cacheParams: React.MutableRefObject<Record<string, any> | undefined>;
  };

export const DetailPage: React.FC<LeadTabProps> = ({ history, location, cacheParams }: LeadTabProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    campaignTypeStructureData,
    validationHandler,
    currentCampaign,
    selectedTab,
    formState,
    disabled,
    disabledEdit,
    campaignEndDateEqualToStartDate,
    campaignEndDateGreaterThanTarget,
    toggleLeadUploadWizard,
    setToggleLeadUploadWizard,
    onTabChange,
    formDispatch,
    backToList,
    onSaveCampaign,
    participantNameRules,
    participantSelected,
    setParticipantSelected,
    disableBulkSelect,
    setDisableBulkSelect,
    setRemoveSuccessDialogOpen,
    sendInvitationToParticipant,
    formStateUpdated,
    onSubmitCampaign,
    isProofUpdated,
    isAttachmentUpdated,
    isCampaignInfoUpdated,
    updateRSVPStatus,
    approvalData,
    onSubmitDocuments,
    getTranslations,
    canEventDocSubmit,
    setCanEventDocSubmit,
    canSubmitCampaign,
    submitInProgress,
    showReminder,
    setShowReminder,
    checkStepHaveError,
    onSaveCmapaignInfo,
    onSaveAttachment,
    acmAddLeadSetting,
    addLeadDisable,
    isACMApproved,
    isInSchedule,
    isCampaignFilesUpdated,
    noPostDoc,
    submitDateShouldGreaterThan,
  } = useDetail({
    history: history,
    location: location,
  });

  return (
    <>
      {showReminder ? (
        <RsvpReminderComponent
          onAccept={() => {
            updateRSVPStatus(RsvpStatus.Accepted);
          }}
          onClose={() => setShowReminder(false)}
        />
      ) : null}
      <Page>
        <HeaderBreadCrumbs />
        <PageTitle
          title={Translation('agencyCampaign.detail.title')}
          rightNode={
            <Button variant="outlined" color="primary" onClick={backToList}>
              {Translation('app.button.back')}
            </Button>
          }
        />
        {campaignTypeStructureData ? (
          <>
            <div className={styles.tabsContainer}>
              <Tabs value={selectedTab} onChange={onTabChange} textColor="secondary" indicatorColor="secondary">
                <Tab
                  label={Translation('agencyCampaign.detail.leftBar.application')}
                  tabIndex={0}
                  value={TabsConfig.APPLICATION}
                  className={styles.tabsText}
                />
                <Tab
                  label={Translation('agencyCampaign.detail.leftBar.approval')}
                  tabIndex={1}
                  value={TabsConfig.APPROVAL}
                  className={styles.tabsText}
                  onClick={() => {
                    takeUIClickEvent(agencyCampaignDetailGaDataClick64 as any);
                  }}
                />
                {currentCampaign.campaignOwner === globalStore.getAgentCode() || !currentCampaign.campaignId ? (
                  <Tab
                    label={Translation('agencyCampaign.detail.leftBar.postEventDocs')}
                    tabIndex={2}
                    value={TabsConfig.POST_DOC}
                    className={styles.tabsText}
                    onClick={() => {
                      takeUIClickEvent(agencyCampaignDetailGaDataClick65 as any);
                    }}
                  />
                ) : null}
                <Tab
                  label={Translation('agencyCampaign.detail.leftBar.lead')}
                  tabIndex={3}
                  value={TabsConfig.LEAD}
                  className={styles.tabsText}
                  onClick={() => {
                    takeUIClickEvent(agencyCampaignDetailGaDataClick66 as any);
                  }}
                />
              </Tabs>
              {selectedTab === TabsConfig.APPLICATION ? (
                <LoadingButton
                  color="primary"
                  variant="contained"
                  className={styles.tabsButton}
                  onClick={() => {
                    if (submitInProgress) return;
                    onSubmitCampaign();
                  }}
                  disabled={!canSubmitCampaign}
                  loading={submitInProgress}
                >
                  {Translation('app.button.submit')}
                </LoadingButton>
              ) : null}
              {selectedTab === TabsConfig.POST_DOC && !noPostDoc ? (
                <Button
                  disabled={!canEventDocSubmit}
                  color="primary"
                  variant="contained"
                  className={styles.postDocSubmitBtn}
                  onClick={() => {
                    ModalService.confirm({
                      title: getTranslations().are_you_sure_to_submit,
                      description: getTranslations().please_make_sure_all_the_documents_are_correct,
                      onConfirm: () => {
                        onSubmitDocuments();
                      },
                    });
                  }}
                >
                  {Translation('agencyCampaign.document.submit')}
                </Button>
              ) : null}
              {selectedTab === TabsConfig.LEAD ? (
                <Tooltip
                  title={
                    addLeadDisable ? (
                      <div className={styles.tooltip}>{Translation('agencyCampaign.lead.tooltip.notAllow')}</div>
                    ) : (
                      ''
                    )
                  }
                  arrow
                >
                  <span>
                    <Button
                      color="primary"
                      variant="contained"
                      className={styles.tabsButton}
                      onClick={() => {
                        setToggleLeadUploadWizard(true);
                        takeUIClickEvent(agencyCampaignDetailGaDataClick);
                      }}
                      disabled={addLeadDisable}
                    >
                      {Translation('app.button.add')}
                    </Button>
                  </span>
                </Tooltip>
              ) : null}
            </div>

            {selectedTab === TabsConfig.APPLICATION && campaignTypeStructureData ? (
              <TabApplicationComponent
                onSaveCampaign={onSaveCampaign}
                campaignTypeStructureData={campaignTypeStructureData}
                campaign={currentCampaign}
                formState={formState}
                formDispatch={formDispatch}
                errorState={validationHandler.errorState}
                disabled={disabled}
                disabledEdit={disabledEdit}
                validationHandler={validationHandler}
                campaignEndDateEqualToStartDate={campaignEndDateEqualToStartDate}
                campaignEndDateGreaterThanTarget={campaignEndDateGreaterThanTarget}
                cacheParams={cacheParams}
                participantRange={participantNameRules}
                setParticipantSelected={setParticipantSelected}
                campaignObjId={currentCampaign.campaignObjId}
                participantSelected={participantSelected}
                startDate={currentCampaign.startDate}
                disableBulkSelect={disableBulkSelect}
                setDisableBulkSelect={setDisableBulkSelect}
                setRemoveSuccessDialogOpen={setRemoveSuccessDialogOpen}
                sendInvitationToParticipant={sendInvitationToParticipant}
                campaignOwner={currentCampaign.campaignOwner}
                isCampaignOwner={
                  currentCampaign.campaignOwner === globalStore.getAgentCode() || !currentCampaign.campaignId
                }
                isRequiredRsvp={currentCampaign.isRequiredRsvp}
                formStateUpdated={formStateUpdated}
                isProofUpdated={isProofUpdated}
                isAttachmentUpdated={isAttachmentUpdated}
                isCampaignInfoUpdated={isCampaignInfoUpdated}
                updateRSVP={updateRSVPStatus}
                checkStepHaveError={checkStepHaveError}
                onSaveInfo={onSaveCmapaignInfo}
                onSaveAttachment={onSaveAttachment}
                isCampaignFilesUpdated={isCampaignFilesUpdated}
                startDateLimitation={submitDateShouldGreaterThan}
              />
            ) : null}
            {selectedTab === TabsConfig.APPROVAL ? (
              <TabApprovalComponent
                campaignTypeStructureData={campaignTypeStructureData}
                formDispatch={formDispatch}
                approvalHistoryList={currentCampaign.approvalHistoryList}
                remarkList={currentCampaign.remarksList}
                submitData={currentCampaign.submitData}
                campaignObjId={currentCampaign.campaignObjId}
                approvalData={approvalData}
                previousApprovalData={currentCampaign.previousApprovalData}
              />
            ) : null}
            {selectedTab === TabsConfig.POST_DOC ? (
              <TabDocumentComponent
                campaignTypeStructureData={campaignTypeStructureData}
                formDispatch={formDispatch}
                formState={formState}
                sectionKey={'postDocuments'}
                onSubmitDocuments={onSubmitDocuments}
                errorState={validationHandler.errorState}
                validationHandler={validationHandler}
                campaign={currentCampaign}
                disabled={disabled}
                toggleSubmit={setCanEventDocSubmit}
                noPostDoc={noPostDoc}
              />
            ) : null}
            {selectedTab === TabsConfig.LEAD ? (
              <TabLeadComponent
                campaignTypeStructureData={campaignTypeStructureData}
                campaign={currentCampaign}
                formState={formState}
                formDispatch={formDispatch}
                cacheParams={cacheParams}
                toggleUploadWizard={toggleLeadUploadWizard}
                setToggleUploadWizard={setToggleLeadUploadWizard}
                campaignOwner={currentCampaign.campaignOwner}
                acmAddLeadSetting={acmAddLeadSetting}
                isACMApproved={isACMApproved}
                isInSchedule={isInSchedule}
              />
            ) : null}
          </>
        ) : null}
      </Page>
    </>
  );
};
