import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 24px 0',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
  },
  closeIcon: {
    fontSize: 32,
    color: 'black',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
  },
  buttonContainer: {
    display: 'flex',
    alignItmes: 'center',
    columnGap: 12,
  },
  contentContainer: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
  },
  downloadTemplateContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,
  },
  downloadIcon: {
    color: '#4386E5',
    fontSize: 20,
  },
  downloadText: {
    color: '#4386E5',
    textDecorationColor: '#4386E5',
    fontSize: 14,
    fontWeight: 600,
  },
  errorTitle: {
    color: '#E8192C',
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 16,
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
    padding: 24,
    border: '1px solid #F0F0F0',
    borderRadius: 8,
    minHeight: 400,
  },
}));
